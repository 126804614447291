import React, { useState } from 'react';
import InfoBlackIcon from 'assets/icons/InfoBlackIcon';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { useAccountData } from 'context/AccountDataContext';
import { useOnboarding } from 'context/OnboardingContext';
import { fetchChangeEmailSms } from 'services/Account/POST/ChangeEmailSmsService';
import { useRef } from 'react';
import { useEffect } from 'react';
import { EmailDataFormContainer, AlertContainer, Instruction, AlertButton, ContainerHeaderAlert } from './styles';
import { Field } from 'formik';
import TextFieldRequester from 'components/shared/TextFieldRequester';
import SnackbarAlert from 'components/shared/SnackbarAlert';

export default function EmailDataForm({
  values,
  isLoading = true,
  hasVerifiedPhone = true,
}) {
  const { nextProfileSettingsStep, previousProfileSettingsStep } = useOnboarding()
  const { setMessageIdSMSEmail, setOldEmail } = useAccountData()
  const [isLoadingSMSRequest, setLoadingSMSRequest] = useState(false)
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [hasRequestSuccessed, setRequestSuccessed] = useState(true)
  const mounted = useRef(false);

  const requestSMS = async () => {
    if (hasVerifiedPhone) {
      setLoadingSMSRequest(true)
      await fetchChangeEmailSms()
        .then(({ data }) => {
          if (data?.message_id && mounted.current) {
            setOldEmail(values.email)
            setMessageIdSMSEmail(data.message_id)
            nextProfileSettingsStep()
          } else {
            console.error("erro ", data)
            setRequestSuccessed(false)
            setSnackbarOpen(true)
          }
        })
      setLoadingSMSRequest(false)
    }
  }
  const handleClick = () => {
    previousProfileSettingsStep()
    previousProfileSettingsStep()
  }
  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])
  return (
    <>
      {
        isLoading ?
          <LoadingIndicator /> :
          <EmailDataFormContainer>
            <Instruction>
              Confirme o e-mail da conta
            </Instruction>
            <Field
              as={TextFieldRequester}
              name="email"
              isLoadingRequest={isLoadingSMSRequest}
              disabledButton={!hasVerifiedPhone}
              receivedFunction={requestSMS}
            />
            {
              !hasVerifiedPhone &&
              <AlertContainer>
                <InfoBlackIcon />
                <ContainerHeaderAlert>
                  <Instruction>
                    Para editar o e-mail da conta, é necessário ter um número de celular verificado.
                  </Instruction>
                  <AlertButton
                    variant="outlined"
                    onClick={handleClick}
                  >
                    Verificar celular
                  </AlertButton>
                </ContainerHeaderAlert>
              </AlertContainer>
            }
          </EmailDataFormContainer>
      }
      <SnackbarAlert
        isOpen={isSnackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        message={hasRequestSuccessed ? "Código reenviado." : "Ops! Você já atingiu o limite de reenvio de código por SMS hoje.\nTente novamente após 24h."}
        hasSuccessed={hasRequestSuccessed}
      />
    </>
  )
}
