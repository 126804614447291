import DashboardBody from 'components/Dashboard/DashboardBody';
import DashboardHeader from 'components/Dashboard/DashboardHeader';
import RedirectToLogin from 'components/shared/RedirectToLogin';
// import RedirectToWelcome from 'components/shared/RedirectToWelcome';
import DashboardProvider from 'context/DashboardContext';
import DashboardMyAccountProvider from 'context/DashboardMyAccountContext';
import React from 'react';
import { GAMapRoutes } from 'utils/GAMapRoutes';
import { DashboardContainer } from './styles';

export default function Dashboard() {
  GAMapRoutes()
  return <>
    <DashboardProvider>
      <DashboardMyAccountProvider>
        <DashboardContainer>
          <DashboardHeader />
          <DashboardBody />
        </DashboardContainer>
      </DashboardMyAccountProvider>
    </DashboardProvider>
    {/* <RedirectToWelcome /> */}
    <RedirectToLogin />
  </>;
}
