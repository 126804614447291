import { Button } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, primaryColors, shadowStyles } from "theme/styleguide";

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${primaryColors.blue_5};
  width: 100%;
  min-height: 100vh;
  padding-top: 32px;
  @media (min-width: 768px)
  {
    padding-top: 40px;
  }
`
export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 20px);
  max-width: 432px;
  min-height: 300px;
  background: ${grayScale.white};
  margin: 16px;
  margin-top: 16px;
  margin-bottom: 52px;
  padding: 32px 16px;
  border-radius: 4px;
  ${shadowStyles.drop_shadow_2}
  @media (min-width: 768px)
  {
    margin-top: 32px;
    padding: 32px 22px;
    min-height: 300px;
  }
`
export const InstructionsContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 16px;
  span{
    display: block;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: ${grayScale.gray_3};
    margin-bottom: 20px;
    white-space: pre-wrap;
  }
  @media (min-width: 768px)
  {
    span{
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 22px;
    white-space: pre-wrap;
  }
  }
`
export const StyledButton = styled(Button)`
  width: 100%;
  max-width: 254px;
  min-height: 36px;
  text-transform: uppercase;
  margin-bottom: 12px;
  ${({ variant }) => variant !== "outlined" &&
    `
    background: ${primaryColors.blue_1};
    `
  }
  @media (min-width: 768px)
  {
    max-width: 322px;
    min-height: 49px;
  }
`