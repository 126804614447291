import React from 'react';
import { PartnersDocumentsUploadMenuItemContainer } from './styles';
import { Button } from '@material-ui/core';
import usePartnersDocumentsUploadDataSetup from 'components/FormsSetup/AccountVerification/usePartnersDocumentsUploadDataSetup';
import { SameSizeButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import PartnersDocumentsUploadForm from 'components/Onboarding/OnboardingForms/AccountVerificationForms/PartnersDocumentsUploadForm';
import LoadableButton from 'components/shared/LoadableButton';
import { Form, Formik } from 'formik';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import CancelFormFillingDialog from 'components/CompleteRegisterMenu/shared/CancelFormFillingDialog';

export default function PartnersDocumentsUploadMenuItem() {
  const { goBackToCompleteRegisterMenu, toggleCancelFormFillingDialog, updateCompletedRegisterStep } = useDashboardMyAccount()
  const { initialValues, validationSchema, isLoading, handleSubmit, selectedPartnerData, documentRequired, documentStatus } = usePartnersDocumentsUploadDataSetup({
    afterSubmit: () => {
      updateCompletedRegisterStep("documentUploadChain")
      goBackToCompleteRegisterMenu()
    },
  })
  return <>
    <PartnersDocumentsUploadMenuItemContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={documentRequired && validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <PartnersDocumentsUploadForm
                selectedPartnerData={selectedPartnerData}
                isLoading={isLoading}
                documentStatus={documentStatus}
                documentRequired={documentRequired}
                formikProps={formikProps}
              />
              <SameSizeButtonsContainer
                $shouldNotReverseColumn
              >
                <LoadableButton
                  type="submit"
                  disabled={(documentRequired && !formikProps.isValid) || (formikProps.isSubmitting || isLoading)}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_completar_cadastro_documento_socio_botao_enviar"}
                >
                  Continuar
                </LoadableButton>
                <Button
                  variant="outlined"
                  disabled={formikProps.isSubmitting || isLoading}
                  onClick={toggleCancelFormFillingDialog}
                  id={"tela_completar_cadastro_documento_socio_botao_cancelar"}
                >
                  Cancelar
                </Button>
              </SameSizeButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </PartnersDocumentsUploadMenuItemContainer>
    <CancelFormFillingDialog
      cancelFillingFormFunction={goBackToCompleteRegisterMenu}
    />
  </>;
}
