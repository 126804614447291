import React from 'react';
import SwitchableOption from 'components/shared/SwitchableOption';
import { BillingEmailFormContainer, CardBody, CardTitle, StyledExpandableCard, BodyText, CardBodyInstruction } from './styles';
import EmailExampleList from 'components/Onboarding/OnboardingSteps/shared/EmailExampleList';
import monitoramentoImage from 'assets/images/emails/monitoramento.png'
import aprovadoImage from 'assets/images/emails/aprovado.png'
import reprovadoImage from 'assets/images/emails/reprovado.png'
import estornoCartaoImage from 'assets/images/emails/estorno_cartao.png'
import estornoTEFImage from 'assets/images/emails/estorno_tef.png'
import canceladoImage from 'assets/images/emails/cancelado.png'
import aguardandoImage from 'assets/images/emails/aguardando.png'
import naoAutorizadoImage from 'assets/images/emails/nao_autorizado.png'
import rastreioImage from 'assets/images/emails/rastreio.png'
import LoadingIndicator from 'components/shared/LoadingIndicator';

const storeActivedEmails = [
  {
    title: "Confirmação de pedido manual",
    description: "E-mail enviado quando realizada a inclusão de um pedido manual através do painel administrativo.",
  },
  {
    title: "Notificação de pagamento do pedido",
    description: "E-mail enviado assim que um pedido receber informação de pagamento.",
  },
  {
    title: "Informações sobre o pedido",
    description: "E-mail enviado quando o cliente realizar um pedido na loja (exceto pedido manual).",
  },
]

const emailsExamplesList = {
  orderStatus: [
    {
      title: "Transação em monitoramento",
      exampleImage: monitoramentoImage,
    },
    {
      title: "Transação aprovada",
      exampleImage: aprovadoImage,
    },
    {
      title: "Transação reprovada",
      exampleImage: reprovadoImage,
    },
    {
      title: "Transação estornada - cartão de crédito",
      exampleImage: estornoCartaoImage,
    },
    {
      title: "Transação estornada - TEF",
      exampleImage: estornoTEFImage,
    },
    {
      title: "Transação cancelada",
      exampleImage: canceladoImage,
    },
  ],
  saleRecovery: [
    {
      title: "Aguardando pagamento",
      exampleImage: aguardandoImage,
    },
    {
      title: "Venda não aprovada no cartão",
      exampleImage: naoAutorizadoImage,
    },
  ],
  orderTracking: [
    {
      title: "Informações sobre o rastreio do pedido",
      exampleImage: rastreioImage,
    }
  ]
}

export default function BillingEmailForm({
  disabled = false,
  isLoading = true,
}) {
  return <>
    {
      isLoading ?
        <LoadingIndicator /> :
        <BillingEmailFormContainer>
          <BodyText style={{ marginBottom: "56px" }}>
            Defina quais e-mails serão enviados pela Vindi para os seus compradores.
          </BodyText>
          <SwitchableOption
            label="E-mails de status do pedido"
            name="hasOrderStatus"
            disabled={disabled}
          />
          <EmailExampleList
            list={emailsExamplesList.orderStatus}
          />
          <SwitchableOption
            label="E-mails de recuperação de venda"
            name="hasSaleRecovery"
            disabled={disabled}
          />
          <EmailExampleList
            list={emailsExamplesList.saleRecovery}
          />
          <SwitchableOption
            label="E-mail de rastreio"
            name="hasOrderTracking"
            disabled={disabled}
          />
          <EmailExampleList
            list={emailsExamplesList.orderTracking}
          />
          <StyledExpandableCard
            title="E-mails transacionais ativos na sua loja"
          >
            {
              storeActivedEmails.map((e, i) => {
                return (
                  <React.Fragment key={i}>
                    <CardTitle>{e.title}</CardTitle>
                    <CardBody>{e.description}</CardBody>
                  </React.Fragment>
                )
              })
            }
            <CardBodyInstruction>
              Para consultar demais e-mails transacionais ativos em sua loja, acesse o menu Tray <b>Configurações</b> {"  >  "} <b>Loja</b> {"  >  "} <b>E-mails</b>
            </CardBodyInstruction>
          </StyledExpandableCard>
        </BillingEmailFormContainer>
    }
  </>;
}
