import styled from "styled-components";
import { grayScale, primaryColors, textStyles } from "theme/styleguide";

export const CompleteLaterDialogContainer = styled.div`
  
`
export const AlertCustomContent = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 32px;
`
export const AlertTitle = styled.span`
  ${textStyles.h5_16px}
  font-weight: bold;
  color: ${primaryColors.blue_5};
`