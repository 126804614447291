/* eslint-disable max-len */
import React from 'react';

export default function InsertDriveFileIcon({ size = 1, color = "#91B5E3" }) {
  return <>
    <i>
      <svg
        width={40 * size}
        height={40 * size}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M23.3346 3.3335H10.0013C8.16797 3.3335 6.68464 4.8335 6.68464 6.66683L6.66797 33.3335C6.66797 35.1668 8.1513 36.6668 9.98463 36.6668H30.0013C31.8346 36.6668 33.3346 35.1668 33.3346 33.3335V13.3335L23.3346 3.3335ZM10.0013 33.3335V6.66683H21.668V15.0002H30.0013V33.3335H10.0013Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
