import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchPostTerms = async () => {
  const yapay_code = getDataStorage('yapay_code')
  return apiPrivate.post("/signin/terms", {},
    {
      headers: {
        code: yapay_code,
      }
    })
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}