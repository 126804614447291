import { Button } from '@material-ui/core';
import SMSEmailVerificationForm from 'components/Onboarding/OnboardingForms/AccountDataForms/SMSEmailVerificationForm/SMSEmailVerificationForm';
import { useOnboarding } from 'context/OnboardingContext';
import { Form, Formik } from 'formik';
import React from 'react';
import { SMSVerificationStepContainer } from './styles';
import { ButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import useEmailSMSVerificationSetup from 'components/FormsSetup/AccountData/useEmailSMSVerificationSetup';
import LoadableButton from 'components/shared/LoadableButton';
import { getCurrentOnboardingStepsCollection } from 'utils/getCurrentOnboardingStepsCollection';

export default function EmailSMSVerificationStep() {
  const stepsCollectionLength = getCurrentOnboardingStepsCollection("accountDataSteps").length - 1
  const { nextProfileSettingsStep, nextOnboardingSection, previousProfileSettingsStep, profileSettingsStep } = useOnboarding()
  const { initialValues, validationSchema, isLoading, handleSubmit } = useEmailSMSVerificationSetup({
    afterSubmit: () => {
      if (profileSettingsStep < stepsCollectionLength) {
        nextProfileSettingsStep()
      } else {
        nextOnboardingSection()
      }
    }
  })
  return <>

    <SMSVerificationStepContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <SMSEmailVerificationForm
                {...formikProps}
                isLoading={isLoading}
                initialValues={initialValues}
              />
              <ButtonsContainer>
                <LoadableButton
                  type="submit"
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_inserir_cod_SMS_editar_email_onboarding_botao_continuar"}
                >
                  Continuar
                </LoadableButton>
                <Button
                  variant="outlined"
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  onClick={previousProfileSettingsStep}
                  id={"tela_inserir_cod_SMS_editar_email_onboarding_botao_cancelar"}
                >
                  Cancelar
                </Button>
              </ButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </SMSVerificationStepContainer>
  </>;
}
