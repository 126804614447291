import { Alert } from "@material-ui/lab";
import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";


export const StyledAlert = styled(Alert)`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  ${({ $marginTop }) => $marginTop && 'margin-top:' + $marginTop + ';'}
  white-space: pre-wrap;
  padding: 12px 16px;
  b{
    font-weight: bold;
  }
  span{
    ${textStyles.tray_text_14px}
  }
  @media (min-width: 768px)
  {
    ${textStyles.tray_text_14px}
    margin-right: 20px;
    span{
      ${textStyles.tray_h5_15px}
    }
  }
`