export const accountVerificationVariantsDictionary = {
  personal: {
    digitalAccountVariant: [
      "titularId",
      // "titularSelfie"
      ],
    revalidatingVariant: [
      "titularId",
      // "addressProofing"
      ],
    simplifiedVariant: [
      "titularId",
      // "addressProofing"
      ],
  },
  business: {
    digitalAccountVariant: [
      "titularId",
      // "titularSelfie",
      "businessProofing",
      "attorneyLetter",
      "partnersId"
      ],
    revalidatingVariant: [
      "titularId",
      // "addressProofing",
      "businessProofing",
      "attorneyLetter"
      ],
    simplifiedVariant: [
      "titularId",
      // "addressProofing",
      "businessProofing",
      "attorneyLetter"
      ],
  },
}