import React from 'react';
import { AttorneyPowerUploadMenuItemContainer } from './styles';
import useAttorneyPowerUploadSetup from 'components/FormsSetup/AccountVerification/useAttorneyPowerUploadSetup';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import { Form, Formik } from 'formik';
import AttorneyPowerUploadForm from 'components/Onboarding/OnboardingForms/AccountVerificationForms/AttorneyPowerUploadForm';
import { ButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import { StyledLoadableButton } from 'components/Dashboard/DashboardAccountVerification/styles';

export default function AttorneyPowerUploadMenuItem() {
  const { goBackToAccountVerificationMenu } = useDashboardMyAccount()
  const { initialValues, validationSchema, isLoading, handleSubmit, documentRequired, documentStatus } = useAttorneyPowerUploadSetup({
    afterSubmit: goBackToAccountVerificationMenu,
  })
  return <>
    <AttorneyPowerUploadMenuItemContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={documentRequired && validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <AttorneyPowerUploadForm
                isLoading={isLoading}
                documentStatus={documentStatus}
                documentRequired={documentRequired}
                sendButtonText={
                  ["approved", "pending"].includes(documentStatus?.status?.tag) ?
                    "Continuar" :
                    "Enviar"
                }
                {...formikProps}
              />
              <ButtonsContainer>
                <StyledLoadableButton
                  type="submit"
                  disabled={
                    (documentRequired && !formikProps.isValid) ||
                    (isLoading || formikProps.isSubmitting)
                  }
                  isLoading={formikProps.isSubmitting}
                  id="envio-documentos_doc-procuracao_botao_enviar"
                >
                  {
                    ["approved", "pending"].includes(documentStatus?.status?.tag) ?
                      "Continuar" :
                      "Enviar"
                  }
                </StyledLoadableButton>
              </ButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </AttorneyPowerUploadMenuItemContainer>
  </>;
}
