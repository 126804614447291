import React, { useEffect, useState } from 'react';
import { CardTitle, RoundedButton } from 'components/Dashboard/DashboardOverview/styles';
import { SallesStatusContainer, TitleRow, EmptyStateImage, SpinnerContainer } from './styles';
import ProductionQuantity from 'assets/icons/ProductionQuantity';
import HorizontalScrollable from 'components/Dashboard/HorizontalScrollable';
import SallesStatusChart from 'components/Dashboard/SallesStatusChart';
import { useCallback } from 'react';
import { CircularProgress } from '@material-ui/core';
import { fetchGetLastTransactionService } from 'services/Reports/POST/GetLastTransactionServiceService';
import { getDDMMYYYY, getFirstDayMonthYear, getLastyDayMonthYear } from 'utils/getDDMMYYYY';
import EmptyData from 'components/Dashboard/EmptyData';
import EmptyStatus from 'assets/images/emptyStatus.png';
import { useRef } from 'react';

const filterButtonsList = [
  {
    title: "Hoje",
    value: 1,
    id: "home_status-vendas_filtro-hoje",
  },
  {
    title: "Este mês",
    value: 2,
    id: "home_status-vendas_filtro-este-mes",
  },
]

export default function SallesStatus() {
  const mounted = useRef(false);
  const [selectedFilter, setSelectedFilter] = useState(1)
  const [sallesStatus, setSallesStatus] = useState({})
  const [totalQuantity, setTotalQuantity] = useState(0)
  const [loading, setLoading] = useState(true)
  const handleFilter = (value) => {
    setSelectedFilter(value)
  }

  const getSallesStatusData = useCallback(async () => {
    setLoading(true)
    let start, end
    if (selectedFilter === 1) {
      end = getDDMMYYYY(Date.now())
      start = getDDMMYYYY(Date.now())
    } else {
      start = getFirstDayMonthYear()
      end = getLastyDayMonthYear()
    }
    const response = await fetchGetLastTransactionService(start, end);
    if (mounted.current) {
      if (response?.data?.report?.data_response?.transactions?.rows?.row) {
        let auxObject = {}
        let auxTotalQuantity = 0
        response?.data?.report?.data_response?.transactions?.rows?.row.forEach(element => {
          auxObject[element.status_name] = parseInt(element.quantity)
          auxTotalQuantity += parseInt(element.quantity)
        });
        setSallesStatus(auxObject)
        setTotalQuantity(auxTotalQuantity)
      }
      setLoading(false)
    }
  }, [selectedFilter])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  useEffect(() => {
    getSallesStatusData()
  }, [getSallesStatusData])


  return <>
    <SallesStatusContainer>
      <HorizontalScrollable>
        {
          [...filterButtonsList].reverse().map((e, i) => {
            return (
              <RoundedButton
                onClick={() => { handleFilter(e.value) }}
                $selected={e.value === selectedFilter}
                id={e.id}
                key={i}
              >
                {e.title}
              </RoundedButton>
            )
          })
        }
      </HorizontalScrollable>
      <TitleRow>
        <ProductionQuantity />
        <CardTitle>Status das vendas</CardTitle>
      </TitleRow>
      {loading ?
        <SpinnerContainer>
          <CircularProgress />
        </SpinnerContainer> :
        totalQuantity > 0 ?
          <SallesStatusChart
            sallesData={sallesStatus}
            totalQuantity={totalQuantity}
          /> :
          <EmptyData
            Img={() => <EmptyStateImage src={EmptyStatus} />}
            title={`Ops! Não há dados de status das vendas \n para o período selecionado`}
            subTitle='Tente selecionar outro período nos filtros acima'
          />
      }
    </SallesStatusContainer>
  </>;
}
