import React, { useState } from 'react';
import { StatusNotificationAlertContainer, StyledAlert, StyledButton, StyledIconButton } from './styles';
import { Collapse, Tooltip } from '@material-ui/core';
import CloseIcon from 'assets/icons/CloseIcon';

export default function StatusNotificationAlert({
  statusTag,
  statusDictionary,
}) {
  const [isAlertOpen, setAlertOpen] = useState(true)
  const handleClose = () => {
    setAlertOpen(false)
  }

  return <>
    <StatusNotificationAlertContainer>
      {
        !!statusDictionary?.[statusTag] &&
        <Collapse
          in={isAlertOpen}
        >
          <StyledAlert
            severity={statusDictionary[statusTag]?.severity}
          >
            {
              statusDictionary[statusTag]?.isCloseable &&
              <Tooltip
                title="Fechar"
                arrow
              >
                <StyledIconButton
                  size="small"
                  onClick={handleClose}
                  $hideOnDesktop
                >
                  <CloseIcon
                    size={0.66}
                  />
                </StyledIconButton>
              </Tooltip>
            }
            {statusDictionary[statusTag]?.message}
            {
              statusDictionary[statusTag]?.buttonText &&
              <StyledButton
                onClick={statusDictionary[statusTag]?.buttonFunction}
              >
                {statusDictionary[statusTag]?.buttonText}
              </StyledButton>
            }
            {
              statusDictionary[statusTag]?.isCloseable &&
              <Tooltip
                title="Fechar"
                arrow
              >
                <StyledIconButton
                  size="small"
                  onClick={handleClose}
                >
                  <CloseIcon
                    size={0.66}
                  />
                </StyledIconButton>
              </Tooltip>
            }
          </StyledAlert>
        </Collapse>
      }
    </StatusNotificationAlertContainer>
  </>;
}
