import ExpandMoreIcon from 'assets/icons/ExpandMoreIcon';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { Field, FieldArray } from 'formik';
import React from 'react';
import { PartnersDataFormContainer, SectionTitle, StyledAccordion, StyledAccordionSummary, StyledAccordionDetails, StyledAlert, PPEInstructions, PartnersDataSection, StyledButton, StyledIconButton } from './styles';
import { InputContainerRow, StyledTextField } from 'components/Onboarding/OnboardingForms/AccountDataForms/TitularDataForm/styles';
import { formatIdentifier } from 'utils/formatIdentifier';
import RadioOptions from 'components/shared/RadioOptions';
import WarningCircle from 'assets/icons/WarningCircle';
import { FormHelperText, Tooltip } from '@material-ui/core';
import { formatPhone } from 'utils/formatPhone';
import AddressFields from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/AddressFields';
import AddCircleIcon from 'assets/icons/AddCircleIcon';
import { primaryColors } from 'theme/styleguide';
import TrashCan from 'assets/icons/TrashCan/TrashCan';
import { useState } from 'react';
import CheckCircleIcon from 'assets/icons/CheckCircleIcon';
import ErrorIcon from 'assets/icons/ErrorIcon';
import { useEffect } from 'react';
import DatePicker from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/DatePicker';
import AlertDialog from 'components/shared/AlertDialog';
import SnackbarAlert from 'components/shared/SnackbarAlert';
import { createEmptyPartner } from 'utils/createEmptyPartnerObject';
import useWindowWidth from 'utils/useWindowWidth';

const partnerOptions = [
  {
    value: "true",
    label: "Sócio(a) pessoa física"
  },
  {
    value: "false",
    label: "Sócio(a) pessoa jurídica"
  },
]

// const over25PercentageOptions = [
//   {
//     value: "true",
//     label: "Sim"
//   },
//   {
//     value: "false",
//     label: "Não"
//   },
// ]

const isPPEOptions = [
  {
    value: "false",
    label: "Não"
  },
  {
    value: "true",
    label: "Sim"
  },
]

const PersonalPartnerFields = ({
  values,
  errors,
  touched,
  disabledForm = false,
  setFieldValue = () => { },
  index = 0,
}) => {
  return <>
    <PartnersDataSection>
      <InputContainerRow>
        <Field
          as={StyledTextField}
          name={`partnersList.${index}.name`}
          label="Nome completo"
          size="small"
          error={touched.partnersList?.[index]?.name && !!errors.partnersList?.[index]?.name}
          helperText={touched.partnersList?.[index]?.name && errors.partnersList?.[index]?.name}
          disabled={disabledForm}
        />
        <Field
          as={StyledTextField}
          name={`partnersList.${index}.CPF`}
          label="CPF"
          size="small"
          error={touched.partnersList?.[index]?.CPF && !!errors.partnersList?.[index]?.CPF}
          helperText={touched.partnersList?.[index]?.CPF && errors.partnersList?.[index]?.CPF}
          inputProps={{ maxLength: 14 }}
          onChange={(e) => {
            setFieldValue(`partnersList.${index}.CPF`, formatIdentifier(e.target.value))
          }}
          disabled={disabledForm}
        />
        <Field
          as={DatePicker}
          name={`partnersList.${index}.birthDate`}
          label="Data de nascimento"
          size="small"
          setFieldValue={setFieldValue}
          error={touched.partnersList?.[index]?.birthDate && !!errors.partnersList?.[index]?.birthDate}
          helperText={touched.partnersList?.[index]?.birthDate && errors.partnersList?.[index]?.birthDate}
          disabled={disabledForm}
          $fullWidth={false}
        />
        <Field
          as={StyledTextField}
          name={`partnersList.${index}.telephone`}
          label="Telefone"
          size="small"
          error={touched.partnersList?.[index]?.telephone && !!errors.partnersList?.[index]?.telephone}
          helperText={touched.partnersList?.[index]?.telephone && errors.partnersList?.[index]?.telephone}
          onChange={(e) => { setFieldValue(`partnersList.${index}.telephone`, formatPhone(e.target.value)) }}
          disabled={disabledForm}
        />
        <Field
          as={StyledTextField}
          name={`partnersList.${index}.motherName`}
          label="Nome completo da mãe"
          size="small"
          error={touched.partnersList?.[index]?.motherName && !!errors.partnersList?.[index]?.motherName}
          helperText={touched.partnersList?.[index]?.motherName && errors.partnersList?.[index]?.motherName}
          disabled={disabledForm}
        />
      </InputContainerRow>
    </PartnersDataSection>
    <PartnersDataSection>
      <SectionTitle>
        Este(a) sócio(a) é uma Pessoa Politicamente Exposta (PPE)?
      </SectionTitle>
      <PPEInstructions>
        Consideram-se Pessoas Politicamente Expostas aquelas que desempenham ou tenham desempenhado cargos ou funções públicas relevantes nos últimos cinco anos, no Brasil ou em outros países.
      </PPEInstructions>
      <RadioOptions
        receivedValue={values.partnersList?.[index]?.isPPE}
        receivedSetter={(e) => { setFieldValue(`partnersList.${index}.isPPE`, e) }}
        options={isPPEOptions}
        disabled={disabledForm}
      />
    </PartnersDataSection>
    <PartnersDataSection>
      <SectionTitle>
        Endereço residencial do(a) sócio(a)
      </SectionTitle>
      <InputContainerRow>
        <AddressFields
          namePrefix={`partnersList.${index}.address`}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          disabledForm={disabledForm}
        />
      </InputContainerRow>
    </PartnersDataSection>
  </>
}
const BusinessPartnerFields = ({
  values,
  errors,
  touched,
  disabledForm = false,
  setFieldValue = () => { },
  index = 0,
}) => {
  return <>
    <PartnersDataSection>
      <InputContainerRow>
        <Field
          as={StyledTextField}
          name={`partnersList.${index}.socialName`}
          label="Razão social"
          size="small"
          error={touched.partnersList?.[index]?.socialName && !!errors.partnersList?.[index]?.socialName}
          helperText={touched.partnersList?.[index]?.socialName && errors.partnersList?.[index]?.socialName}
          disabled={disabledForm}
        />
        <Field
          as={StyledTextField}
          name={`partnersList.${index}.CNPJ`}
          label="CNPJ"
          size="small"
          error={touched.partnersList?.[index]?.CNPJ && !!errors.partnersList?.[index]?.CNPJ}
          helperText={touched.partnersList?.[index]?.CNPJ && errors.partnersList?.[index]?.CNPJ}
          inputProps={{ maxLength: 18 }}
          onChange={(e) => {
            setFieldValue(`partnersList.${index}.CNPJ`, formatIdentifier(e.target.value))
          }}
          disabled={disabledForm}
        />
        {/* <PercentageContainer>
          <PercentageInstructions>
            Esta empresa sócia possui sócios(as) com 25% ou mais de participação no capital social dela?
          </PercentageInstructions>
          <Field
            as={StyledTextField}
            name={`partnersList.${index}.hasPartnersWithOver25Percent`}
            select
            size="small"
            error={touched.partnersList?.[index]?.hasPartnersWithOver25Percent && !!errors.partnersList?.[index]?.hasPartnersWithOver25Percent}
            helperText={touched.partnersList?.[index]?.hasPartnersWithOver25Percent && errors.partnersList?.[index]?.hasPartnersWithOver25Percent}
            disabled={disabledForm}
          >
            {
              over25PercentageOptions.map((e, j) => (
                <MenuItem
                  value={e.value}
                  key={j}
                >
                  {e.label}
                </MenuItem>
              ))
            }
          </Field>
        </PercentageContainer> */}
      </InputContainerRow>
    </PartnersDataSection>
    {/* {
      JSON.parse(values.partnersList?.[index]?.hasPartnersWithOver25Percent) &&
      <>
        <PartnersDataSection>
          <SectionTitle>
            Dados do(a) proprietário(a) da empresa sócia
          </SectionTitle>
          <InputContainerRow>
            <Field
              as={StyledTextField}
              name={`partnersList.${index}.name`}
              label="Nome completo"
              size="small"
              error={touched.partnersList?.[index]?.name && !!errors.partnersList?.[index]?.name}
              helperText={touched.partnersList?.[index]?.name && errors.partnersList?.[index]?.name}
              disabled={disabledForm}
            />
            <Field
              as={StyledTextField}
              name={`partnersList.${index}.CPF`}
              label="CPF"
              size="small"
              error={touched.partnersList?.[index]?.CPF && !!errors.partnersList?.[index]?.CPF}
              helperText={touched.partnersList?.[index]?.CPF && errors.partnersList?.[index]?.CPF}
              inputProps={{ maxLength: 14 }}
              onChange={(e) => {
                setFieldValue(`partnersList.${index}.CPF`, formatIdentifier(e.target.value))
              }}
              disabled={disabledForm}
            />
            <Field
              as={StyledTextField}
              name={`partnersList.${index}.telephone`}
              label="Telefone"
              size="small"
              error={touched.partnersList?.[index]?.telephone && !!errors.partnersList?.[index]?.telephone}
              helperText={touched.partnersList?.[index]?.telephone && errors.partnersList?.[index]?.telephone}
              onChange={(e) => { setFieldValue(`partnersList.${index}.telephone`, formatPhone(e.target.value)) }}
              disabled={disabledForm}
            />
          </InputContainerRow>
        </PartnersDataSection>
        <PartnersDataSection>
          <SectionTitle>
            Endereço residencial do(a) proprietário(a) da empresa sócia
          </SectionTitle>
          <AddressFields
            namePrefix={`partnersList.${index}.address`}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            disabledForm={disabledForm}
          />
        </PartnersDataSection>
        <MorePartnersInstructions>
          {`Se esta empresa sócia possui mais de um(a) sócio(a) com 25% de participação,\n`}
          <a href="/">
            {`clique aqui para informar os(as) demais sócios(as)`}
          </a>
        </MorePartnersInstructions>
      </>
    } */}
  </>
}

export default function PartnersDataForm({
  values,
  errors,
  touched,
  isLoading = true,
  disabledForm = false,
  setFieldValue = () => { },
  validateForm = () => { },
}) {
  const windowWidth = useWindowWidth()
  const [expandedPanel, setExpandedPanel] = useState("panel0")
  const [isAlertOpen, setAlertOpen] = useState(false)
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [selectedPartnerIndex, setSelectedPartnerIndex] = useState()
  const [fieldArrayHelpers, setFieldArrayHelpers] = useState()

  const handlePanelSelect = (panel) => (_e, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false)
  }

  const handleOpenAlert = (arrayHelpers, index) => {
    setFieldArrayHelpers(arrayHelpers)
    setSelectedPartnerIndex(index)
    setAlertOpen(true)
  }
  const handleDismiss = () => {
    setAlertOpen(false)
  }
  const handleDelete = () => {
    setExpandedPanel(false)
    if (values.partnersList[selectedPartnerIndex].isNewRegister === false) {
      setFieldValue(
        'excludedPartnersList',
        [...values.excludedPartnersList, values.partnersList[selectedPartnerIndex]]
      )
    }
    fieldArrayHelpers.remove(selectedPartnerIndex)
    setAlertOpen(false)
    setSnackbarOpen(true)
  }

  useEffect(() => {
    validateForm()
  }, [expandedPanel, validateForm])
  return <>
    <PartnersDataFormContainer>
      {
        isLoading ?
          <LoadingIndicator /> :
          <>
            <SectionTitle>
              Dados das pessoas sócias com 25% ou mais de participação
            </SectionTitle>
            <FieldArray
              name="partnersList"
              render={arrayHelpers => {
                return (
                  <>
                    {
                      values.partnersList?.map((e, index) => {
                        return (
                          <StyledAccordion
                            expanded={expandedPanel === `panel${index}`}
                            onChange={handlePanelSelect(`panel${index}`)}
                            key={index}
                          >
                            <StyledAccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                            >
                              <span>
                                {
                                  JSON.parse(e?.isPFPartner) ?
                                    e?.name :
                                    e?.socialName
                                }
                              </span>
                              {
                                !!errors.partnersList?.[index] ?
                                  <ErrorIcon
                                    size={0.75}
                                  /> :
                                  <CheckCircleIcon
                                    color={primaryColors.green_5}
                                    size={1.5625}
                                  />
                              }
                            </StyledAccordionSummary>
                            <StyledAccordionDetails>
                              {
                                windowWidth < 768 &&
                                <Tooltip
                                  title="Excluir"
                                  arrow
                                >
                                  <StyledIconButton
                                    size="small"
                                    onClick={() => {
                                      handleOpenAlert(arrayHelpers, index)
                                    }}
                                  >
                                    <TrashCan />
                                  </StyledIconButton>
                                </Tooltip>
                              }
                              <RadioOptions
                                receivedValue={e?.isPFPartner}
                                receivedSetter={(e) => { setFieldValue(`partnersList.${index}.isPFPartner`, e) }}
                                options={partnerOptions}
                                disabled={disabledForm}
                              />
                              <StyledAlert
                                severity="info"
                                icon={<WarningCircle />}
                              >
                                <b>
                                  {`Atenção: `}
                                </b>
                                {
                                  e?.isPFPartner === "true" ?
                                    "após o preenchimento das informações será necessário enviar uma foto do RG ou CNH de todas as pessoas sócias informadas." :
                                    `após o preenchimento das informações será necessário enviar o documento de constituição da empresa sócia informada.`
                                }
                              </StyledAlert>
                              {
                                windowWidth >= 768 &&
                                <Tooltip
                                  title="Excluir"
                                  arrow
                                >
                                  <StyledIconButton
                                    size="small"
                                    onClick={() => {
                                      handleOpenAlert(arrayHelpers, index)
                                    }}
                                  >
                                    <TrashCan />
                                  </StyledIconButton>
                                </Tooltip>
                              }
                              {
                                JSON.parse(e?.isPFPartner) ?
                                  <PersonalPartnerFields
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    disabledForm={disabledForm}
                                    setFieldValue={setFieldValue}
                                    index={index}
                                  /> :
                                  <BusinessPartnerFields
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    disabledForm={disabledForm}
                                    setFieldValue={setFieldValue}
                                    index={index}
                                  />
                              }
                            </StyledAccordionDetails>
                          </StyledAccordion>
                        )
                      })
                    }
                    <StyledButton
                      onClick={() => {
                        setExpandedPanel(`panel${values.partnersList.length}`)
                        arrayHelpers.push(createEmptyPartner())
                      }}
                      disabled={disabledForm}
                      variant="outlined"
                      startIcon={
                        <AddCircleIcon
                          color={
                            disabledForm ?
                              primaryColors.blue_5 + "33" :
                              primaryColors.blue_5
                          }
                          size={0.8}
                        />
                      }
                    >
                      Adicionar mais pessoas sócias
                    </StyledButton>
                    {
                      typeof (errors?.partnersList) === "string" &&
                      <FormHelperText
                        error
                      >
                        {errors?.partnersList}
                      </FormHelperText>
                    }
                    <AlertDialog
                      description="Deseja realmente excluir esta pessoa sócia?"
                      open={isAlertOpen}
                      buttonsProps={[
                        {
                          title: "Excluir",
                          function: handleDelete
                        },
                        {
                          title: "Cancelar",
                          function: handleDismiss
                        }
                      ]}
                    />
                    <SnackbarAlert
                      isOpen={isSnackbarOpen}
                      onClose={() => { setSnackbarOpen(false) }}
                      message="Sócio excluído"
                    />
                  </>
                )
              }}
            />
          </>
      }
    </PartnersDataFormContainer>
  </>;
}
