import AdditionalDataMenuItem from 'components/CompleteRegisterMenu/AdditionalDataMenuItem';
import CompleteBusinessAccountTitularDataMenuItem from 'components/CompleteRegisterMenu/CompleteBusinessAccountTitularDataMenuItem';
import PartnersDataPreludeMenuItem from 'components/CompleteRegisterMenu/PartnersDataPreludeMenuItem';
import PartnersDataMenuItem from 'components/CompleteRegisterMenu/PartnersDataMenuItem';
import UserIdUploadMenuItem from 'components/CompleteRegisterMenu/UserIdUploadMenuItem';
import UserFacePictureUploadMenuItem from 'components/CompleteRegisterMenu/UserFacePictureUploadMenuItem';
import EnterpriseProofingUploadMenuItem from 'components/CompleteRegisterMenu/EnterpriseProofingUploadMenuItem';
import PartnersDocumentsUploadMenuItem from 'components/CompleteRegisterMenu/PartnersDocumentsUploadMenuItem';
import AttorneyPowerUploadMenuItem from 'components/CompleteRegisterMenu/AttorneyPowerUploadMenuItem';

export const completeRegisterStepsComponentDictionary = {
  personal: {
    additionalData: {
      pageTitle: "Completar cadastro - Dados da pessoa titular",
      component: <AdditionalDataMenuItem />,
    },
    documentUploadChain: {
      pageTitle: "Envio de documentos - Foto do(a) titular",
      component: <UserFacePictureUploadMenuItem />,
    },
    userIdUpload: {
      pageTitle: "Envio de documentos - Foto do RG ou CNH",
      component: <UserIdUploadMenuItem />,
    },
  },
  business: {
    additionalData: {
      pageTitle: "Completar cadastro - Dados da empresa",
      component: <AdditionalDataMenuItem />,
    },
    completeBusinessAccountTitularData: {
      pageTitle: "Completar cadastro - Dados da pessoa titular",
      component: <CompleteBusinessAccountTitularDataMenuItem />,
    },
    partnersDataPrelude: {
      pageTitle: "Completar cadastro - Dados dos sócios",
      component: <PartnersDataPreludeMenuItem />,
    },
    partnersData: {
      pageTitle: "Completar cadastro - Dados dos sócios",
      component: <PartnersDataMenuItem />,
    },
    documentUploadChain: {
      pageTitle: "Envio de documentos - Foto do(a) titular",
      component: <UserFacePictureUploadMenuItem />,
    },
    userIdUpload: {
      pageTitle: "Envio de documentos - Foto do RG ou CNH",
      component: <UserIdUploadMenuItem />,
    },
    enterpriseProofingUpload: {
      pageTitle: "Envio de documentos - Documento de constituição da empresa",
      component: <EnterpriseProofingUploadMenuItem />,
    },
    attorneyPowerUpload: {
      pageTitle: "Envio de documentos - Procuração",
      component: <AttorneyPowerUploadMenuItem />,
    },
    partnersDocumentsUpload: {
      pageTitle: "Envio de documentos - Documentos dos sócios",
      component: <PartnersDocumentsUploadMenuItem />,
    },
  }
}