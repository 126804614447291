import apiPrivate from 'services/apiPrivate'
import { getStoredCredential } from 'utils/getStoredCredential'

export const fetchSearchBankAccounts = async () => {

  return apiPrivate.post("/v1/bank_accounts/search", {
    ...getStoredCredential(),
  })
    .then(({ data }) => {
      return data.bank_account.data_response.bank_accounts.bank_account
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}