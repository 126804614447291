import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";
import { Button, darken } from "@material-ui/core";

export const SettingsMenuContainer = styled.div`
  margin-top: -22px;
  @media (min-width: 768px)
  {
    margin-top: -44px;
  }
`
export const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-left: 0px;
  flex: 1;
`
export const MenuItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 28px 20px;
  background: rgba(84, 110, 122, 0.07);
  border-radius: 4px;
  margin-bottom: 16px;
  text-align: start;
  :hover{
    background: ${darken("#546e7a12", 1)};
  }
  @media (min-width: 920px)
  {
    max-width: 560px;
    align-items: flex-start;
    i:last-child{
      display: none;
    }
  }
`
export const ItemTitle = styled.span`
  display: block;
  ${textStyles.tray_h5_15px}
  color: ${grayScale.gray_3};
  @media (min-width: 920px)
  {
    ${textStyles.tray_h4_18px}
  }
`
export const ItemDescription = styled.span`
  display: block;
  ${textStyles.tray_text_12px}
  color: ${grayScale.gray_3};
`
export const ItemColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 32px;
`
export const ImageCard = styled.div`
  display: none;
  text-align: center;
  img{
    max-width: 298px;
    max-height: 298px;
  }
  @media (min-width: 920px)
  {
    flex: 1;
    display: initial;
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 42px;
  @media (min-width: 920px)
  {
    margin-bottom: 72px;
  }
`
export const SmallInstruction = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-bottom: 20px;
  text-align: center;
  @media (min-width: 920px)
  {
    text-align: start;
  }
`
export const StyledButton = styled(Button)`
  display: block;
  margin: auto;
  margin-bottom: 40px;
  @media (min-width: 920px)
  {
    display: initial;
  }
`