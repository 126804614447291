import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const CompleteRegistrationInstructionsContainer = styled.div`
  
`
export const InstructionsTitle = styled.span`
  display: block;
  ${textStyles.tray_h3_24px}
  color: ${grayScale.black};
  margin-bottom: 32px;
`
export const InstructionsText = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  white-space: pre-wrap;
  margin-bottom: 72px;
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 64px;
  >div{
    flex: 1;
    >img{
      display: block;
      width: 272px;
      margin: 0 auto;
    }
  }
`
export const ListTitle = styled.div`
  display: block;
  ${textStyles.tray_text_14px}
  font-size: 16px;
  font-weight: bold;
  color: ${grayScale.black};
  margin-bottom: 16px;
`
export const DataUpdateList = styled.ul`
  padding-left: 24px;
  margin-bottom: 64px;
  li{
    ${textStyles.tray_text_14px}
    color: ${grayScale.gray_3};
    margin-bottom: 24px;
  }
`
export const DocumentsList = styled.ul`
  margin-top: 32px;
  padding-left: 0px;
`
export const DocumentsInstructionItemContainer = styled.li`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`
export const DocumentsInstructionItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${textStyles.tray_text_14px}
  font-size: 16px;
  font-weight: bold;
  color: ${grayScale.gray_3};
  >i{
    margin-right: 16px;
  }
`
export const DocumentsInstructionItemBody = styled.div`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-top: 16px;
  margin-left: 46px;
  >ul{
    list-style: disc;
    margin-top: 24px;
    margin-bottom: 32px;
    padding-left: 24px;
    >li{
      margin-bottom: 12px;
    }
  }
`