/* eslint-disable max-len */
import React from 'react';

export default function AddCircleIcon({ size = 1, color = "#2E7EE7", style }) {
  return <>
    <i
      style={style}
    >
      <svg
      width={30*size}
      height={30*size}
      viewBox="0 0 30 30"
      fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.25 8.75H13.75V13.75H8.75V16.25H13.75V21.25H16.25V16.25H21.25V13.75H16.25V8.75ZM15 2.5C8.1 2.5 2.5 8.1 2.5 15C2.5 21.9 8.1 27.5 15 27.5C21.9 27.5 27.5 21.9 27.5 15C27.5 8.1 21.9 2.5 15 2.5ZM15 25C9.4875 25 5 20.5125 5 15C5 9.4875 9.4875 5 15 5C20.5125 5 25 9.4875 25 15C25 20.5125 20.5125 25 15 25Z"
          fill={color}
          />
      </svg>
    </i>
  </>;
}
