import React, { Fragment } from 'react';
import { MainSettingsContainer } from './styles';
import StepFormHeader from 'components/Onboarding/shared/StepFormHeader';
import DetailedStepper from 'components/shared/DetailedStepper';
import { useOnboarding } from 'context/OnboardingContext';
import { mainSettingsStepsDictionary } from 'library/mainSettingsStepsDictionary';
import { getCurrentOnboardingStepKey } from 'utils/getCurrentOnboardingStepKey';
import { getStepsCollectionShowingCounter } from 'utils/getStepsCollectionShowingCounter';
import { getOrigemTypeVariantSectionKey } from 'utils/getOrigemTypeVariantSectionKey';
import { getDataStorage } from 'utils/storage';
import { accountVerificationStepsDictionary } from 'library/accountVerificationStepsDictionary';
import { getHideOnCountStepsGoingBack } from 'utils/getHideOnCountStepsGoingBack';
import { accountDataStepsDictionary } from 'library/accountDataStepsDictionary';

export default function MainSettingsSection() {
  const { mainSettingsStep, previousMainSettingsStep, previousOnboardingStep, profileSettingsStep, previousProfileSettingsStep, accountVerificationStep, previousAccountVerificationStep } = useOnboarding()
  const origemTypeVariantSteps = getOrigemTypeVariantSectionKey()
  const currentStepKey = getCurrentOnboardingStepKey(origemTypeVariantSteps, mainSettingsStep)
  const totalSteps = getStepsCollectionShowingCounter(origemTypeVariantSteps, mainSettingsStepsDictionary)
  const onboardingVariant = getDataStorage("onboardingVariant") || "onboardingDVariant"

  const handleGoBack = async () => {
    if (mainSettingsStep > 0) {
      previousMainSettingsStep()
    } else {
      if (accountVerificationStep > 0) {
        if (["onboardingAVariant", "onboardingCVariant"].includes(onboardingVariant)) { // FLUXO DE GOBACK PARA DADOS DA CONTA
          if (accountDataStepsDictionary[getCurrentOnboardingStepKey("accountDataSteps", profileSettingsStep)]?.hideOnCounting) {
            const hideOnCountStepsGoingBack = getHideOnCountStepsGoingBack("accountDataSteps", profileSettingsStep, accountDataStepsDictionary)
            for (let i = 0; i < hideOnCountStepsGoingBack; i++) {
              previousProfileSettingsStep()
            }
          }
        }
        else if (["onboardingBVariant", "onboardingDVariant"].includes(onboardingVariant)) { // FLUXO DE GOBACK PARA ENVIO DE DOCUMENTOS
          if (accountVerificationStepsDictionary[getCurrentOnboardingStepKey("documentsSendingSteps", accountVerificationStep)]?.hideOnCounting) {
            const hideOnCountStepsGoingBack = getHideOnCountStepsGoingBack("documentsSendingSteps", accountVerificationStep, accountVerificationStepsDictionary)
            for (let i = 0; i < hideOnCountStepsGoingBack; i++) {
              previousAccountVerificationStep()
            }
          }
        }
      }
      previousOnboardingStep()
    }
  }

  return <>
    {
      !mainSettingsStepsDictionary[currentStepKey]?.hideBackButton &&
      <StepFormHeader
        handleGoBack={handleGoBack}
      />
    }
    <MainSettingsContainer>
      <DetailedStepper
        currentStep={mainSettingsStep + 1}
        // totalSteps={onboardingVariant === "onboardingDigitalAccountVariant" ? totalSteps : totalSteps - 1} // TO-DO: Descomentar quando for definido sobre a conta digital
        totalSteps={totalSteps - 1}
        title={mainSettingsStepsDictionary[currentStepKey]?.stepTitle}
      />
      {
        Object.keys(mainSettingsStepsDictionary).map((key, i) => {
          return (
            <Fragment
              key={i}
            >
              {
                key === currentStepKey &&
                mainSettingsStepsDictionary[key].stepComponent
              }
            </Fragment>
          )
        })
      }
    </MainSettingsContainer>
  </>;
}
