import styled from "styled-components";
import { Avatar, ButtonBase } from "@material-ui/core";
import { grayScale, textStyles } from "theme/styleguide";

export const UserProfilePictureFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  @media (min-width: 768px)
  {
    margin-top: 80px;
  }
`
export const StyledIconButton = styled(ButtonBase)`
  width: 136px;
  height: 136px;
  border-radius: 100%;
  background-image: url(${({ $backgroundImage }) => $backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  >span.buttonText{
    display: none;
  }
  :hover{
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${({ $backgroundImage }) => $backgroundImage});
    >span.buttonText{
      display: block;
      ${textStyles.tray_text_14px}
      font-weight: 700;
      color: ${grayScale.white};
      white-space: pre-wrap;
      text-transform: uppercase;
    }
  }
  @media (min-width: 768px)
  {
    width: 104px;
    height: 104px;
  }
`
export const StyledAvatar = styled(Avatar)`
  width: 136px;
  height: 136px;
  @media (min-width: 768px)
  {
    width: 104px;
    height: 104px;
  }
`
export const ActionsContainer = styled.span`
  margin-top: 24px;
`