import { MenuItem } from '@material-ui/core';
import { StyledTextField } from 'components/Onboarding/OnboardingForms/AccountDataForms/TitularDataForm/styles';
import React from 'react';

const statesDictionary = [
  {
    acronym: "AC",
    name: "Acre",
  },
  {
    acronym: "AL",
    name: "Alagoas",
  },
  {
    acronym: "AM",
    name: "Amazonas",
  },
  {
    acronym: "AP",
    name: "Amapá",
  },
  {
    acronym: "BA",
    name: "Bahia",
  },
  {
    acronym: "CE",
    name: "Ceará",
  },
  {
    acronym: "DF",
    name: "Distrito Federal",
  },
  {
    acronym: "ES",
    name: "Espírito Santo",
  },
  {
    acronym: "GO",
    name: "Goiás",
  },
  {
    acronym: "MA",
    name: "Maranhão",
  },
  {
    acronym: "MG",
    name: "Minas Gerais",
  },
  {
    acronym: "MS",
    name: "Mato Grosso do Sul",
  },
  {
    acronym: "MT",
    name: "Mato Grosso",
  },
  {
    acronym: "PA",
    name: "Pará",
  },
  {
    acronym: "PB",
    name: "Paraíba",
  },
  {
    acronym: "PE",
    name: "Pernambuco",
  },
  {
    acronym: "PI",
    name: "Piauí",
  },
  {
    acronym: "PR",
    name: "Paraná",
  },
  {
    acronym: "RJ",
    name: "Rio de Janeiro",
  },
  {
    acronym: "RN",
    name: "Rio Grande do Norte",
  },
  {
    acronym: "RO",
    name: "Rondônia",
  },
  {
    acronym: "RR",
    name: "Roraima",
  },
  {
    acronym: "RS",
    name: "Rio Grande do Sul",
  },
  {
    acronym: "SC",
    name: "Santa Catarina",
  },
  {
    acronym: "SE",
    name: "Sergipe",
  },
  {
    acronym: "SP",
    name: "São Paulo",
  },
  {
    acronym: "TO",
    name: "Tocantins",
  }
]

export default function StatePicker({
  ...props
}) {
  return <>
    <StyledTextField
      {...props}
      select
    >
      {
        statesDictionary.map((e, i) => (
          <MenuItem
            value={e.acronym}
            key={i}
          >
            {e.name}
          </MenuItem>
        ))
      }
    </StyledTextField>
  </>;
}
