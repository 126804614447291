import React from 'react';
import { FirstFoldContainer, MainContent, LeftContent, RightContent, Title, Subtitle, BenefitsTitle, BenefitDescription, StyledButton, BenefitItem, StyledImage } from './styles';
import LandingCheckIcon from 'assets/icons/LandingCheckIcon';
import SellerImage from 'assets/images/landing/seller.png'

const benefitsList = [
  {
    description: <>
      <b>Mais comodidade:</b> tenha as principais informações da Vindi no mesmo painel da loja
    </>
  },
  {
    description: <>
      <b>Mais praticidade:</b> não é preciso informar sua senha a cada novo acesso
    </>
  },
  {
    description: <>
      Acesse as <b>últimas transações</b>, <b>status das vendas</b> e <b>próximos valores</b> que serão creditados em sua conta dentro da plataforma Tray
    </>
  },
]
export default function FirstFold({
  handleInstallApp = () => { },
  disabled = false,
}) {
  return <>
    <FirstFoldContainer>
      <MainContent>
        <LeftContent>
          <Title>
            <span className='highlight'>
              {`A solução nativa da Tray `}
            </span>
            {`para receber suas vendas online!`}
          </Title>
          <Subtitle>
            {`Conte com toda a inteligência e segurança da Vindi para descomplicar sua gestão de pagamentos`}
          </Subtitle>
          <BenefitsTitle>
            {`Benefícios do App Vindi`}
          </BenefitsTitle>
          {
            benefitsList.map((e, i) => {
              return (
                <BenefitItem
                  key={i}
                >
                  <LandingCheckIcon />
                  <BenefitDescription>
                    {e.description}
                  </BenefitDescription>
                </BenefitItem>
              )
            })
          }
        </LeftContent>
        <RightContent>
          <StyledImage
            src={SellerImage}
            alt="Vindi"
          />
        </RightContent>
      </MainContent>
      <StyledButton
        onClick={handleInstallApp}
        disabled={disabled}
        id={"landing-page-plugin_botao_instale-ja-app"}
      >
        Instale já o App Vindi
      </StyledButton>
    </FirstFoldContainer>
  </>;
}
