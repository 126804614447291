import { DialogContent, Zoom } from '@material-ui/core';
import React from 'react';
import { StyledButton, StyledDialogActions, StyledDialogContentText, StyledDialog, StyledAlertImage } from './styles';
import AlertImage from 'assets/images/alert.png';

export default function AlertDialog({
  description = "",
  open = true,
  onClose = () => { },
  buttonsProps = [],
  hasLargerMinWidthButtons,
  customContent,
}) {
  return <>
    <StyledDialog
      open={open}
      onClose={onClose}
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <Zoom in>
          <StyledAlertImage
            src={AlertImage}
            alt="Alerta"
          />
        </Zoom>
        {
          customContent ||
          <StyledDialogContentText id="alert-dialog-description">
            {description}
          </StyledDialogContentText>
        }
      </DialogContent>
      <StyledDialogActions>
        {
          buttonsProps.map((e, i) => {
            return (
              <StyledButton
                onClick={e.function}
                color="primary"
                variant={i === 0 ? "contained" : "outlined"}
                key={i}
                autoFocus={i === 0}
                $hasLargerMinWidthButtons={hasLargerMinWidthButtons}
                {...!!e.id && { id: e.id }}
              >
                {e.title}
              </StyledButton>
            )
          })
        }
      </StyledDialogActions>
    </StyledDialog>
  </>;
}
