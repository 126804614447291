import ReactGA from 'react-ga';

const TRACKING_ID = "UA-56869843-3"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

export const GAEventTracker = ({ category = "Button", action = "Click", label = "Minha conta" }) => {
  if (process.env.REACT_APP_STAGE === "production") {
    ReactGA.event({ category, action, label });
  }
  return
}