import React from 'react';
import { Collapse } from '@material-ui/core';
import ReportProblem from 'assets/icons/ReportProblem';
import { MessageContainer, MessageText, IconContainer, StickyAlertContainer, StickyContainer } from './styles';

export default function StickyAlert({ isOpen = true }) {
  return <>
    <Collapse
      in={isOpen}
    >
      <StickyAlertContainer>
        <StickyContainer>
          <MessageContainer>
            <IconContainer>
              <ReportProblem />
            </IconContainer>
            <MessageText>
              Atenção! Para instalar e utilizar este aplicativo é necessário desbloquear cookies de terceiros de seu navegador
            </MessageText>
          </MessageContainer>
        </StickyContainer>
      </StickyAlertContainer>
    </Collapse>
  </>;
}
