import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchGetConstitution = async () => {
  const code = getDataStorage('yapay_code')
  return apiPrivate.get("/myaccount/account/constitution_forms", {
    headers: {
      code
    }
  })
    .then(({ data }) => {
      return data?.data?.filter((e) => e.active) || []
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}