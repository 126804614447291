import LoadingIndicator from 'components/shared/LoadingIndicator';
import React from 'react';
import { BankAccountProofingUploadFormContainer, ContainerRight, StyledAlert, ContainerLeft, Container, ListProofContainer, BodyText, TextBankInfoContainer, BodyTextOpacity, BankCard, BankIconContainer, TextBankContainer, TrayH5, TextContainer, BankIcon, ListProofItem, FileRulesList, BankCardLoadingCointainer } from './styles';
import AccountBank from 'assets/images/AccountBank.png';
import { bankDictionary } from 'library/bankDicionary';
import FilePicker from 'components/shared/FilePicker';
import InsertDriveFileIcon from 'assets/icons/InsertDriveFileIcon';
import { fetchGetBankAccount } from 'services/Account/GET/GetBankAccountService';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { parseStringToCNPJ } from 'utils/parseStringToCNPJ';
import { parseStringToCPF } from 'utils/parseStringToCPF';
import { fetchSearchBankAccounts } from 'services/BankAccounts/POST/SearchBankAccountsService';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import DocumentsRequiredAlert from 'components/Onboarding/OnboardingForms/AccountVerificationForms/shared/DocumentsRequiredAlert';
import { CircularProgress } from '@material-ui/core';
import { filterActiveBankAccount } from 'utils/filterActiveBankAccount';

export default function BankAccountProofingUploadForm({
  isLoading = true,
  documentRequired = false,
  documentStatus,
  sendButtonText = "Continuar",
  setFieldValue = () => { },
}) {
  const [bankAccount, setBankAccount] = useState(null)
  const [bank, setBank] = useState(null)
  const [account, setAccount] = useState(null)
  const [isLoadingBankData, setLoadingBankData] = useState(true)
  const [automaticVerifications, setAutomaticVerifications] = useState(false) // enquanto for true, não devera mostrar o input de arquivo
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])
  const getBankInfo = async () => {
    const fetchedGetBankAccount = await fetchGetBankAccount()
    if (mounted.current) {
      const accountData = await fetchGetAccountMyAccount()
      if (mounted.current) {
        const bankData = await fetchSearchBankAccounts()
        if (mounted.current) {
          setBankAccount(filterActiveBankAccount(fetchedGetBankAccount)) // TO-DO: Replicar lógica para pegar cadastro bancário active e primary
          setAccount(accountData)
          setAutomaticVerifications(accountData?.automatic_verifications)
          setBank(bankData)
          setLoadingBankData(false)
        }
      }
    }
  }

  useEffect(() => {
    getBankInfo()
  }, [])

  return <>
    <BankAccountProofingUploadFormContainer>
      {
        isLoading ?
          <LoadingIndicator /> :
          <>
            <Container>
              <ContainerLeft>
                <BodyText>
                  Envie um comprovante de titularidade dos seguintes dados bancários cadastrados em sua conta Vindi:
                </BodyText>
                <BankCard>
                  {
                    isLoadingBankData ?
                      <BankCardLoadingCointainer>
                        <CircularProgress
                          size={42}
                          thickness={4}
                        />
                      </BankCardLoadingCointainer> :
                      <>
                        <BankIconContainer>
                          <BankIcon
                            src={
                              bankDictionary[bank.bank_name]?.icon ||
                              bankDictionary["placeholder"]?.icon
                            }
                            alt={bank.bank_name}
                          />
                        </BankIconContainer>
                        <TextBankContainer>
                          <TrayH5>
                            {bank.bank_name}
                          </TrayH5>
                          <BodyTextOpacity>
                            {bankAccount?.bank_account_type.description}
                          </BodyTextOpacity>
                          <TextBankInfoContainer>
                            <BodyText>
                              {`AG ${bankAccount?.agency}   |   Conta ${bankAccount?.number}`}
                            </BodyText>
                            <BodyText>
                              {bankAccount?.pj ? `CNPJ ${parseStringToCNPJ(account.cnpj)}` : `CPF ${parseStringToCPF(account.cpf)}`}
                            </BodyText>
                          </TextBankInfoContainer>
                        </TextBankContainer>
                      </>
                  }
                </BankCard>
                <TrayH5>
                  Comprovantes aceitos (envie apenas um):
                </TrayH5>
                <ListProofContainer>
                  <ListProofItem>
                    - Print screen do internet banking
                  </ListProofItem>
                  <ListProofItem>
                    - Foto do cabeçalho de um extrato impresso em caixa eletrônico
                  </ListProofItem>
                  <ListProofItem>
                    - Foto de uma folha de cheque
                  </ListProofItem>
                  <ListProofItem>
                    - Foto ou print screen de um comprovante de depósito
                  </ListProofItem>
                </ListProofContainer>
                <StyledAlert severity="info">
                  Atenção:  não serão aceitas fotos do cartão de crédito como comprovante.
                </StyledAlert>
                <TextContainer>
                  <BodyText>
                    No comprovante é necessário conter os seguintes dados: <b>banco, titular, número da agência e da conta.</b>
                  </BodyText>
                </TextContainer>
                <TextContainer>
                  <BodyText>
                    No caso de Pessoa Jurídica, a conta bancária deverá estar vinculada ao CNPJ da empresa. Não são aceitas contas cujo titular é o proprietário ou representante legal.
                  </BodyText>
                </TextContainer>
                <FileRulesList>
                  <li>
                    Formatos de arquivo aceitos: jpeg, png, gif, bmp, webp e pdf
                  </li>
                  <li>
                    Tamanho máximo do arquivo: 4,5 MB
                  </li>
                </FileRulesList>
                <FilePicker
                  fieldName={"proofPhoto"}
                  setFieldValue={setFieldValue}
                  title="Adicionar comprovante"
                  Icon={InsertDriveFileIcon}
                  acceptedFiles={[".jpeg", ".jpg", ".png", ".gif", ".bmp", ".webp", ".pdf"]}
                  disabled={automaticVerifications || !documentRequired}
                  id={"envio-documentos_comprovante-bancario_botao_add-comprovante"}
                />
              </ContainerLeft>
              <ContainerRight>
                <img
                  src={AccountBank}
                  alt="Conta bancária"
                />
              </ContainerRight>
            </Container>
            <DocumentsRequiredAlert
              documentStatus={documentStatus}
              sendButtonText={sendButtonText}
            />
          </>
      }
    </BankAccountProofingUploadFormContainer>
  </>;
}
