import { useCallback, useEffect, useState } from 'react';
import { object, string } from 'yup';
import { useRef } from 'react';
import { useAccountData } from 'context/AccountDataContext';
import { fetchValidateSmsEmail } from 'services/Account/POST/ValidateSmsEmailService';
import { GAEventTracker } from 'utils/GAEventTracker';

export default function useEmailEditSetup({
  afterSubmit = () => { },
  errorSubmit = () => { }
}) {
  const mounted = useRef(false);
  const { messageIdSMSEmail, oldEmail, SMSEmail } = useAccountData()
  const [isLoading, setLoading] = useState(true)
  const [initialValues, setInitialValues] = useState({
    newEmail: "",
  })
  const validationSchema = object({
    newEmail: string(),
  })

  const fetchAccountData = useCallback(async () => {
    if (mounted.current) {
      setInitialValues({
        newEmail: "",
      });
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async ({
    newEmail
  }) => {
    if (newEmail !== oldEmail && mounted.current) {
      await fetchValidateSmsEmail(SMSEmail, messageIdSMSEmail, oldEmail, newEmail)
        .then((res) => {
          if (res.data) {
            GAEventTracker({
              category: "Onboarding",
              action: "Editar e-mail da conta",
              label: "Atualização do email da conta concluído"
            })
            afterSubmit()
          } else {
            errorSubmit()
          }
        })
    } else {
      afterSubmit()
    }
  }

  useEffect(() => {
    fetchAccountData()
  }, [fetchAccountData])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
  })
}