import { Select, TextField } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const PixFormContainer = styled.div`
  
`
export const Container = styled.div`
    max-width: 550px;

    @media (min-width: 820px)
  {
    min-width:350px;
  }

`
export const SubTitle = styled.h4`
  ${textStyles.tray_h4_18px}
  margin: 0px;
  color: ${grayScale.gray_3};
  margin-bottom: 8px;
`
export const ContainerForm = styled.div`
  display:flex;
  flex-direction: column;
  @media (min-width: 820px)
  {
    flex-direction: row;
    max-width: 550px;
    margin-top: -40px;
  }
`
export const ContainerTextNumberWeb = styled.div`
  display: none;
  @media (min-width: 820px)
  {
    display: block;
  }
`
export const ContainerTextNumberMobile = styled.div`
  display: block;
  @media (min-width: 820px)
  {
    display: none;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  @media (min-width: 992px)
  {
    margin-bottom: 0px;
  }
`
export const ImageCard = styled.div`
  display: none;
  padding: 16px;
  img{
    max-width: 296px;
  }
  @media (min-width: 820px)
  {
    display: initial;
    min-height: 180px;
    margin: 0 auto;
    height: fit-content;
  }
`

export const StyledSelect = styled(Select)`
  width: 289px;
  height: 38px;
  padding: 16px;
  ${textStyles.body_1_14px}
  .MuiSelect-select:focus{
    background: transparent !important;
  }
  @media (min-width: 820px)
  {
    width: 100%;
    max-width: 250px;
  }
`
export const StyledTextField = styled(TextField)`
  width: 151px;
  margin-right: 16px;
  ${textStyles.body_1_14px}
  .MuiSelect-select:focus{
    background: transparent !important;
  }
  .MuiOutlinedInput-input {
    padding: 9px 14px;
  }
  @media (min-width: 820px)
  {
    width: 116px;
  }
`
export const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  place-content: center;
  margin-bottom: 24px;
  width: 100%;
  @media (min-width: 820px){
    place-content: flex-start;
    width: 100%;
    max-width: 250px;
    align-items: baseline;
  }
`
export const SelectLabel = styled.span`
  display: block;
  ${textStyles.body_1_14px}
`