/* eslint-disable max-len */
import React from 'react';

export default function PaymentIcon({ size = 1, color = "#2E7EE7" }) {
  return <>
    <i>
      <svg
        width={30 * size}
        height={30 * size}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.75 17.5V7.5C23.75 6.125 22.625 5 21.25 5H3.75C2.375 5 1.25 6.125 1.25 7.5V17.5C1.25 18.875 2.375 20 3.75 20H21.25C22.625 20 23.75 18.875 23.75 17.5ZM21.25 17.5H3.75V7.5H21.25V17.5ZM12.5 8.75C10.425 8.75 8.75 10.425 8.75 12.5C8.75 14.575 10.425 16.25 12.5 16.25C14.575 16.25 16.25 14.575 16.25 12.5C16.25 10.425 14.575 8.75 12.5 8.75ZM28.75 8.75V22.5C28.75 23.875 27.625 25 26.25 25H5C5 23.75 5 23.875 5 22.5H26.25V8.75C27.625 8.75 27.5 8.75 28.75 8.75Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
