import { parseStringToCEP } from 'utils/parseStringToCEP';
import { formatIdentifier } from 'utils/formatIdentifier';
import { formatPhone } from 'utils/formatPhone';
import { createEmptyPartner } from "utils/createEmptyPartnerObject"

export const parsePartners = (receivedPartnersList = [], createPartnerOnEmptyArray = true) => {
  if (receivedPartnersList.length > 0) {
    return receivedPartnersList.map((e) => ({
      isNewRegister: false,
      isPFPartner: String(e?.cnpj === ""),
      name: e?.name || "",
      CPF: formatIdentifier(e?.cpf || ""),
      CNPJ: formatIdentifier(e?.cnpj || ""),
      telephone: formatPhone(e?.phone?.phone || ""),
      birthDate: e?.birth_date?.replace(/-/g, '/') || null,
      isPPE: String(!!e?.politically_exposed),
      address: {
        street: e?.address?.street || "",
        number: e?.address?.number || "",
        complement: e?.address?.complement || "",
        neighborhood: e?.address?.neighborhood || "",
        city: e?.address?.city || "",
        state: e?.address?.state || "",
        zipCode: parseStringToCEP(e?.address?.zipcode || ""),
      },
      motherName: e?.mother_name || "",
      socialName: e?.name || "",
      hasPartnersWithOver25Percent: String(false),
      partnerID: e?.id,
      disabledForm: true,
      isLoading: false,
    }))
  } else if (createPartnerOnEmptyArray) {
    return [createEmptyPartner()]
  } else {
    return []
  }
}