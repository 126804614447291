import styled from "styled-components";
import { StyledTextField } from 'components/Dashboard/AccountDataMenu/styles';
export const BankAccountFieldsContainer = styled.div`
  @media (min-width: 768px)
  {
    margin-bottom: 256px;
  }
`
export const AccountNumberStyledTextField = styled(StyledTextField)`
  max-width: 66.66%;
  padding-right: 20px;
  @media (min-width: 768px)
  {
    max-width: 35%;
    padding-right: 20px;
  }
`
export const VerifyingDigitStyledTextField = styled(StyledTextField)`
  max-width: 33.33%;
  padding-left: 0px;
  @media (min-width: 768px)
  {
    max-width: 15%;
    padding-left: 0px;
  }
`