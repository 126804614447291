import React from 'react';
import { Form, Formik } from 'formik';
import SnackbarAlert from 'components/shared/SnackbarAlert';
import { UserProfilePictureFormContainer } from './styles';
import UserProfilePictureFields from 'fields/Dashboard/MyAaccountTab/UserDataMenu/shared/UserProfilePictureFields/UserProfilePictureFields';
import useUserProfilePictureDataSetup from 'setup/Dashboard/MyAaccountTab/UserDataMenu/shared/useUserProfilePictureDataSetup';

export default function UserProfilePictureForm() {
  const { initialValues, validationSchema, isLoading, handleSubmit, isSnackbarOpen, setSnackbarOpen, } = useUserProfilePictureDataSetup({})
  return <>
    <UserProfilePictureFormContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <UserProfilePictureFields
                {...formikProps}
                isLoading={isLoading}
                handleSubmit={handleSubmit}
              />
            </Form>
          )
        }
      </Formik>
      <SnackbarAlert
        isOpen={isSnackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        message="Salvo"
      />
    </UserProfilePictureFormContainer>
  </>;
}