/* eslint-disable max-len */
import React from 'react';

export default function WarningBank({ size = 1, color = "#FFB020" }) {
  return <>
    <i>
      <svg width={24 * size} height={24 * size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 21H23L12 2L1 21ZM13 18H11V16H13V18ZM13 14H11V10H13V14Z" fill={color} />
      </svg>
    </i>
  </>;
}
