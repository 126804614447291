/* eslint-disable max-len */
import React from 'react';

export default function UploadFileIcon({ size = 1, color = "#235390" }) {
  return <>
    <i>
      <svg
        width={40 * size}
        height={40 * size}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M23.3337 3.3335H10.0003C8.16699 3.3335 6.68366 4.8335 6.68366 6.66683L6.66699 33.3335C6.66699 35.1668 8.15032 36.6668 9.98366 36.6668H30.0003C31.8337 36.6668 33.3337 35.1668 33.3337 33.3335V13.3335L23.3337 3.3335ZM30.0003 33.3335H10.0003V6.66683H21.667V15.0002H30.0003V33.3335ZM13.3337 25.0168L15.6837 27.3668L18.3337 24.7335V31.6668H21.667V24.7335L24.317 27.3835L26.667 25.0168L20.017 18.3335L13.3337 25.0168Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
