/* eslint-disable max-len */
import React from 'react';

export default function DeleteIcon({ size = 1, color = "#546E7A" }) {
  return <>
    <i>
      <svg
      width={30*size}
      height={30*size}
      viewBox="0 0 30 30"
      fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 23.75C7.5 25.125 8.625 26.25 10 26.25H20C21.375 26.25 22.5 25.125 22.5 23.75V8.75H7.5V23.75ZM10 11.25H20V23.75H10V11.25ZM19.375 5L18.125 3.75H11.875L10.625 5H6.25V7.5H23.75V5H19.375Z"
          fill={color}
          />
      </svg>
    </i>
  </>;
}
