import BusinessAccountTitularPersonForm from 'forms/Dashboard/MyAccountTab/AccountDataMenu/BusinessAccount/BusinessAccountTitularPersonForm';
import EnterpriseForm from 'forms/Dashboard/MyAccountTab/AccountDataMenu/BusinessAccount/EnterpriseForm';
import PartnersForm from 'forms/Dashboard/MyAccountTab/AccountDataMenu/BusinessAccount/PartnersForm';
import PersonalAccountTitularPersonForm from 'forms/Dashboard/MyAccountTab/AccountDataMenu/PersonalAccount/PersonalAccountTitularPersonForm';
import BankAccountForm from 'forms/Dashboard/MyAccountTab/AccountDataMenu/shared/BankAccountForm';
import BusinessIcon from 'assets/icons/BusinessIcon';
import PeopleAltIcon from 'assets/icons/PeopleAltIcon';
import PersonIcon from 'assets/icons/PersonIcon';
import SavingsIcon from 'assets/icons/SavingsIcon';

export const accountDataMenuTabsDictionary = {
  personal: [
    {
      title: "Pessoa titular",
      icon: (props) => PersonIcon({ ...props }),
      component: <PersonalAccountTitularPersonForm />
    },
    {
      title: "Dados bancários",
      icon: (props) => SavingsIcon({ ...props }),
      component: <BankAccountForm />
    },
  ],
  business: [
    {
      title: "Empresa",
      icon: (props) => BusinessIcon({ ...props }),
      component: <EnterpriseForm />,
    },
    {
      title: "Pessoa titular",
      icon: (props) => PersonIcon({ ...props }),
      component: <BusinessAccountTitularPersonForm />
    },
    {
      title: "Pessoas sócias",
      icon: (props) => PeopleAltIcon({ ...props }),
      component: <PartnersForm />,
      hideEditButton: true,
      hasDigitalAccountDependency: true,
    },
    {
      title: "Dados bancários",
      icon: (props) => SavingsIcon({ ...props }),
      component: <BankAccountForm />
    },
  ]
}