import { useCallback, useEffect, useState, useRef } from 'react';
import { fetchGetBusinessPartnersList } from 'services/BusinessPartners/GET/GetBusinessPartnersListService';
import { fetchCreateBusinessPartner } from 'services/BusinessPartners/POST/CreateBusinessPartnerService';
import { fetchDeleteBusinessPartner } from 'services/BusinessPartners/POST/DeleteBusinessPartnerService';
import { fetchUpdateBusinessPartner } from 'services/BusinessPartners/POST/UpdateBusinessPartnerService';
import { checkForUniqueValueOnKey } from 'utils/checkForUniqueValueOnKey';
import { parsePartners } from 'utils/parsePartners';
import { validateIdentifier } from 'utils/validateIdentifier';
import { object, string, date, array } from 'yup';

const validateNameOnYup = () => {
  return {
    is: "true",
    then: string()
      .required('Este campo é obrigatório'),
  }
}

const validateSocialNameOnYup = () => {
  return {
    is: "false",
    then: string()
      .required('Este campo é obrigatório'),
  }
}

const validateCPFOnYup = () => {
  return {
    is: "true",
    then: string()
      .test('valid-identifier', 'O CPF informado é inválido', validateIdentifier)
      .required("Este campo é obrigatório"),
  }
}
const validateCNPJOnYup = () => {
  return {
    is: "false",
    then: string()
      .test('valid-identifier', 'O CNPJ informado é inválido', validateIdentifier)
      .required("Este campo é obrigatório"),
  }
}

const validateBirthDateOnYup = () => {
  return {
    is: "true",
    then: date()
      .nullable()
      .transform((curr, orig) => orig === '' ? null : curr)
      .typeError("Informe uma data válida")
      .required('Este campo é obrigatório'),
  }
}

const validateTelephoneOnYup = () => {
  return {
    is: "true",
    then: string()
      .min(14, 'O número de telefone informado deve possuir ao menos 14 dígitos')
      .max(15, 'O número de telefone informado deve possuir no máximo 15 dígitos')
      .required('Este campo é obrigatório'),
  }
}

const validateAddressOnYup = () => {
  return {
    is: "true",
    then: object()
      .shape({
        street: string()
          .required('Este campo é obrigatório'),
        number: string()
          .required('Este campo é obrigatório'),
        complement: string(),
        neighborhood: string()
          .required('Este campo é obrigatório'),
        city: string()
          .required('Este campo é obrigatório'),
        state: string()
          .required('Este campo é obrigatório'),
        zipCode: string()
          .min(9, 'O CEP informado é inválido')
          .required('Este campo é obrigatório'),
      })
  }
}

const validateMotherNameOnYup = () => {
  return {
    is: "true",
    then: string()
      .required('Este campo é obrigatório'),
  }
}

const validateIsPPE = () => {
  return {
    is: "true",
    then: string()
      .required('Este campo é obrigatório'),
  }
}
export default function usePartnersDataSetup({
  reloadAfterSubmit = false,
  afterSubmit = () => { },
}) {
  const mounted = useRef(false)
  const [initialValues, setInitialValues] = useState()
  const [oldValues, setOldValues] = useState({})
  const [validationSchema, setValidationSchema] = useState()
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [isLoading, setLoading] = useState(true)

  const fetchData = useCallback(async () => {
    const partnersData = await fetchGetBusinessPartnersList()

    const validationObject = {
      partnersList: array()
        .of(
          object()
            .shape({
              isPFPartner: string()
                .required("Este campo é obrigatório"),
              name: string()
                .when(['isPFPartner'], validateNameOnYup()),
              socialName: string()
                .when(['isPFPartner'], validateSocialNameOnYup()),
              CPF: string()
                .when(['isPFPartner'], validateCPFOnYup()),
              CNPJ: string()
                .when(['isPFPartner'], validateCNPJOnYup()),
              birthDate: date()
                .when(['isPFPartner'], validateBirthDateOnYup())
                .nullable(),
              telephone: string()
                .when(['isPFPartner'], validateTelephoneOnYup()),
              motherName: string()
                .when(['isPFPartner'], validateMotherNameOnYup()),
              isPPE: string()
                .when(['isPFPartner'], validateIsPPE()),
              address: object()
                .when(['isPFPartner'], validateAddressOnYup()),
            })
            .uniqueProperty('CPF', 'O CPF informado já está em uso por outro sócio', 'isPFPartner', "true")
            .uniqueProperty('CNPJ', 'O CNPJ informado já está em uso por outro sócio', 'isPFPartner', "false")
        )
    }

    const initialValuesObject = {
      partnersList: parsePartners(partnersData, false) || [],
      excludedPartnersList: [],
      requestTime: new Date(), // Necessário para gerar atualização dos componentes caso não haja alteração no retorno das requisições.
    }

    if (mounted.current) {
      setInitialValues(initialValuesObject)
      setOldValues(initialValuesObject)
      setValidationSchema(object(validationObject))
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async ({
    partnersList,
    excludedPartnersList,
  }) => {
    if (excludedPartnersList.length > 0) {
      for (const excludedPartner of excludedPartnersList) { // É a exclusão de um sócio
        await fetchDeleteBusinessPartner({
          partner_id: excludedPartner.partnerID
        })
      }
    } else if (partnersList.filter((e) => e.isNewRegister).length > 0) { // É a criação de um novo sócio
      for (const partner of partnersList) {
        if (partner.isNewRegister) {
          const isPFPartner = JSON.parse(partner.isPFPartner)
          const partnerObject = {
            ...(isPFPartner && {
              name: partner.name,
              cpf: partner.CPF,
              birth_date: partner.birthDate,
              politically_exposed: partner.isPPE,
              phone: partner.telephone,
              phone_type_tag: "business",
              street: partner.address.street,
              number: partner.address.number,
              complement: partner.address.complement,
              neighborhood: partner.address.neighborhood,
              city: partner.address.city,
              state: partner.address.state,
              zipcode: partner.address.zipCode,
              mother_name: partner.motherName,
            }),
            ...(!isPFPartner && {
              name: partner.socialName,
              cnpj: partner.CNPJ,
            }),
            business_partner_kind_id: 1,
            profile: "OWNER",
            profile_type: "REGULAR",
          }
          await fetchCreateBusinessPartner(partnerObject)
        }
      }
    } else if (JSON.stringify(partnersList) !== JSON.stringify(oldValues.partnersList)) { // É a atualização de um sócio
      for (const partner of partnersList) {
        const isPFPartner = JSON.parse(partner.isPFPartner)
        const partnerObject = {
          partner_id: partner.partnerID,
          ...(isPFPartner && {
            ...(checkForUniqueValueOnKey("CPF", partner, oldValues) && {
              cpf: partner.CPF,
            }),
            name: partner.name,
            birth_date: partner.birthDate,
            politically_exposed: partner.isPPE,
            phone: partner.telephone,
            phone_type_tag: "business",
            street: partner.address.street,
            number: partner.address.number,
            complement: partner.address.complement,
            neighborhood: partner.address.neighborhood,
            city: partner.address.city,
            state: partner.address.state,
            zipcode: partner.address.zipCode,
            mother_name: partner.motherName,
          }),
          ...(!isPFPartner && {
            ...(checkForUniqueValueOnKey("CNPJ", partner, oldValues) && {
              cnpj: partner.CNPJ,
            }),
            name: partner.socialName,
          }),
        }
        await fetchUpdateBusinessPartner(partnerObject)
      }
    }

    if (reloadAfterSubmit) {
      await fetchData()
    }
    setSnackbarOpen(true)
    afterSubmit()
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return ({
    initialValues,
    oldValues,
    validationSchema,
    handleSubmit,
    isLoading,
    isSnackbarOpen,
    setSnackbarOpen,
  })
}