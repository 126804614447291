/* eslint-disable max-len */
import React from 'react';

export default function QrCode2({ size = 1, color = "white" }) {
  return <>
    <i>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.75 26.25H16.25V23.75H18.75V26.25ZM16.25 17.5H13.75V23.75H16.25V17.5ZM26.25 15H23.75V20H26.25V15ZM23.75 12.5H21.25V15H23.75V12.5ZM8.75 15H6.25V17.5H8.75V15ZM6.25 12.5H3.75V15H6.25V12.5ZM15 6.25H17.5V3.75H15V6.25ZM5.625 5.625V9.375H9.375V5.625H5.625ZM11.25 11.25H3.75V3.75H11.25V11.25ZM5.625 20.625V24.375H9.375V20.625H5.625ZM11.25 26.25H3.75V18.75H11.25V26.25ZM20.625 5.625V9.375H24.375V5.625H20.625ZM26.25 11.25H18.75V3.75H26.25V11.25ZM23.75 23.75V20H18.75V22.5H21.25V26.25H26.25V23.75H23.75ZM21.25 15H16.25V17.5H21.25V15ZM16.25 12.5H8.75V15H11.25V17.5H13.75V15H16.25V12.5ZM17.5 11.25V8.75H15V6.25H12.5V11.25H17.5ZM8.4375 6.5625H6.5625V8.4375H8.4375V6.5625ZM8.4375 21.5625H6.5625V23.4375H8.4375V21.5625ZM23.4375 6.5625H21.5625V8.4375H23.4375V6.5625Z" fill="#2E7EE7" />
      </svg>
    </i>
  </>;
}
