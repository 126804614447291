import LoadingIndicator from 'components/shared/LoadingIndicator';
import React from 'react';
import { FormControl } from '@material-ui/core';
import { Field } from 'formik';
import { EmailEditFormContainer, Label, Input, StyledAlert } from './styles';
export default function EmailEditForm({
  isLoading = true,
  values,
}) {
  return (
    <>
      {isLoading ?
        <LoadingIndicator /> :
        <EmailEditFormContainer>
          <Label>Informe o novo e-mail da conta</Label>
          <FormControl>
            <Field
              label="E-mail"
              name="newEmail"
              size="small"
              as={Input}
            />
          </FormControl>
          <StyledAlert severity='info'>
            Importante:<span style={{ fontWeight: 'normal' }}> o e-mail de login também será alterado para o novo e-mail informado.</span>
          </StyledAlert>
        </EmailEditFormContainer>
      }
    </>
  )
}
