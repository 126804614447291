import styled from "styled-components";
import { grayScale, borderStyles, textStyles } from "theme/styleguide";

export const UserInfoDisplayerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 552px;
  padding: 28px 24px;
  ${borderStyles.border_1}
  div{
    margin-left: 24px;
  }
`
export const NameDisplayer = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  font-weight: bold;
`
export const IdentifierDisplayer = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
`