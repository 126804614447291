import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const AdditionalDataDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  >div{
    display: flex;
    flex-direction: row;
    >div{
      margin-left: 16px;
      span{
        display: block;
        ${textStyles.tray_text_14px}
        color: ${grayScale.gray_3};
        :first-child{
          ${textStyles.tray_h5_15px}
          color: ${grayScale.black};
        }
      }
    }
  }
  >span{
    ${textStyles.tray_text_14px}
    color: ${grayScale.gray_blend};
    margin-bottom: 16px;
  }
  @media (min-width: 768px)
  {
    margin-bottom: 48px;
  }
`