import AlertDialog from 'components/shared/AlertDialog';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import React from 'react';
import { AlertCustomContent } from './styles';

export default function CancelFormFillingDialog({
  continueFillingFormFunction = () => { },
  cancelFillingFormFunction = () => { },
  cancelFillingButton = "modal_cancelar_preenchimento_botao_cancelar_preenchimento",
  continueFillingButtonId = "modal_cancelar_preenchimento_botao_continuar_preenchendo",
}) {
  const { isCancelFormFillingDialogOpen, toggleCancelFormFillingDialog } = useDashboardMyAccount()

  const handleCancelFillingForm = () => {
    toggleCancelFormFillingDialog()
    cancelFillingFormFunction()
  }
  const handleContinueFillingForm = () => {
    toggleCancelFormFillingDialog()
    continueFillingFormFunction()
  }
  return <>
    <AlertDialog
      open={isCancelFormFillingDialogOpen}
      hasLargerMinWidthButtons
      buttonsProps={[
        {
          title: "Cancelar preenchimento",
          function: handleCancelFillingForm,
          ...(!!cancelFillingButton && {
            id: cancelFillingButton,
          }),
        },
        {
          title: "Continuar preenchendo",
          function: handleContinueFillingForm,
          ...(!!continueFillingButtonId && {
            id: continueFillingButtonId,
          }),
        }
      ]}
      customContent={
        <AlertCustomContent>
          <b>
            Deseja realmente cancelar o preenchimento dos dados?
          </b>
          Ao cancelar, os dados que já foram preenchidos nesta página não serão salvos.
        </AlertCustomContent>
      }
    />
  </>;
}
