export const groupsAcceptedFilesDictionary = {
  document: {
    business: {
      description: "jpeg e jpg",
      value: [".jpeg", ".jpg"],
    },
    personal: {
      description: "jpeg, jpg e png",
      value: [".jpeg", ".jpg", ".png"],
    },
  },
  selfie: {
    business: {
      description: "jpeg e jpg",
      value: [".jpeg", ".jpg"],
    },
    personal: {
      description: "jpeg, jpg e png",
      value: [".jpeg", ".jpg", ".png"],
    },
  },
  address: {
    business: {
      description: "jpeg, jpg e pdf",
      value: [".jpeg", ".jpg", ".pdf"],
    },
    personal: {
      description: "jpeg, jpg e pdf",
      value: [".jpeg", ".jpg", ".pdf"],
    },
  },
  company: {
    business: {
      description: "pdf, jpeg e jpg",
      value: [".pdf", ".jpeg", ".jpg"],
    },
  },
  attorney: {
    business: {
      description: "pdf",
      value: [".pdf"],
    },
  },
  partner: {
    document: {
      description: "jpeg e jpg",
      value: [".jpeg", ".jpg"],
    },
    company: {
      description: "pdf, jpeg e jpg",
      value: [".pdf", ".jpeg", ".jpg"],
    },
  },
}