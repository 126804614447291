import Cookies from 'universal-cookie';
const cookies = new Cookies();

const config = {
  path: '/',
  sameSite: 'none',
  secure: true
}
export const getDataStorage = (key) => {
  return cookies.get(key);
}
export const setDataStorage = (key, value) => {
  return cookies.set(key, value, config);
}
export const deleteDataStorage = (key) => {
  cookies.remove(key, config)
}
export const clearDataStorage = () => {
  const keys = Object.keys(cookies.getAll())
  keys.forEach(element => {
    cookies.remove(element, config)
  });
}
export const checkCookie = () => {
  try {
    setDataStorage("is3rdPartyCookieAvailableAllowed", "yes")
    const permission = getDataStorage("is3rdPartyCookieAvailableAllowed")
    if (permission === "yes") {
      deleteDataStorage("is3rdPartyCookieAvailableAllowed")
      return true
    } else {
      deleteDataStorage("is3rdPartyCookieAvailableAllowed")
      return false
    }
  } catch (error) {
    return false
  }
}
