import styled from "styled-components";
import { grayScale } from "theme/styleguide";

export const OnboardingContainer = styled.div`
  padding: 32px 15px;
  background: ${grayScale.white};
  min-height: 100vh;
  @media (min-width: 768px)
  {
    padding: 56px 96px;
    background: rgba(232, 238, 249, 0.3);
    >div{
      max-width: 1366px;
      margin: 0 auto;
    }
  }
`