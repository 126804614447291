import Alert from "@material-ui/lab/Alert";
import styled from "styled-components";
import { grayScale, primaryColors } from "theme/styleguide";

export const StyledAlert = styled(Alert)`
  width: ${({ $hasSuccessed }) => $hasSuccessed === 'success' ? "342px" : "368px"};
  max-width: 100%;
  color: ${grayScale.white};
  white-space: pre-wrap;
  background-color: ${({ $hasSuccessed }) => $hasSuccessed === 'success' ? primaryColors.green_5 : primaryColors.red_balance};
  border-radius: 2px;
  .MuiAlert-icon{
    align-items: center;
  }
`