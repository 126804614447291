import { Button } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const MainSettingsStartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`
export const StyledImage = styled.img`
  max-width: 110px;
  min-height: 110px;
  margin-bottom: 24px;
  @media (min-width: 768px)
  {
    max-width: 137px;
    min-height: 137px;
    margin-bottom: 40px;
  }
`
export const Title = styled.span`
  ${textStyles.tray_h4_18px};
  color: ${grayScale.black};
  margin-bottom: 24px;
  white-space: pre-wrap;
  @media (min-width: 768px)
  {
    ${textStyles.tray_h3_24px};
  }
`
export const Description = styled.span`
  ${textStyles.tray_text_14px};
  color: ${grayScale.gray_3};
  margin-bottom: 160px;
  max-width: 592px;
  @media (min-width: 768px)
  {
    margin-bottom: 32px;
    max-width: 592px;
  }
`
export const StyledButton = styled(Button)`
  margin-bottom: 18px;
  min-width: 254px;
  @media (min-width: 768px)
  {
    min-width: 124px;
  }
`