import LoadableButton from "components/shared/LoadableButton";
import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const DashboardAccountVerificationContainer = styled.div`
  padding-bottom: 16px;
`
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  button{
    margin-right: 16px;
  }
`
export const MenuTitle = styled.span`
  display: block;
  ${textStyles.tray_h4_18px}
  color: ${grayScale.black};
  @media (min-width: 768px)
  {
    ${textStyles.tray_h3_24px}
  }
`
export const FormTitle = styled.span`
  display: block;
  ${textStyles.tray_h5_15px}
  color: ${grayScale.black};
  font-weight: 800;
  @media (min-width: 768px)
  {
    ${textStyles.tray_h4_18px}
  }
`
export const StyledLoadableButton = styled(LoadableButton)`
  width: 100%;
  min-width: fit-content;
  max-width: 124px;
`