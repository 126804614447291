import styled from "styled-components";
import { grayScale, primaryColors, textStyles } from "theme/styleguide";

export const CancelFormFillingDialogContainer = styled.div`
  
`
export const AlertCustomContent = styled.span`
  display: block;
  max-width: 432px;
  white-space: pre-wrap;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  text-align: center;
  margin: 0 auto;
  margin-bottom: 24px;
  b{
    display: block;
    ${textStyles.tray_h5_15px}
    color: ${primaryColors.blue_5};
    margin-bottom: 16px;
  }
`