const documentsUploadDescription = <>
  <ul>
    <li>
      foto do rosto da pessoa titular;
    </li>
    <li>
      foto do RG ou CNH das pessoas sócias com 25% ou mais de participação - se houver;
    </li>
    <li>
      documento de constituição de empresa sócia com 25% ou mais de participação - se houver;
    </li>
    <li>
      foto do RG ou CNH da pessoa titular;
    </li>
    <li>
      documento de constituição da empresa.
    </li>
  </ul>
  <span>
    Ao clicar em “enviar documentos”, você será direcionado(a) para a página que contém as instruções para fazer o envio de cada documento.
  </span>
</>

export const completeRegisterStepsDescriptionDictionary = {
  personal: [
    {
      stepName: "additionalData",
      stepTitle: "Dados da pessoa titular",
      stepDescription: "RG, nome completo da mãe, data de nascimento, renda mensal, entre outros.",
      id: "tela_completar_cadastro_botao_informar_dados_titular",
    },
    {
      stepName: "documentUploadChain",
      stepTitle: "Envio de documentos",
      buttonText: "Enviar documentos",
      stepDescription: documentsUploadDescription,
      doneDescription: "Os documentos já foram enviados.",
      id: "tela_completar_cadastro_botao_enviar_documentos",
    },
  ],
  business: [
    {
      stepName: "additionalData",
      stepTitle: "Dados da empresa",
      stepDescription: "Natureza jurídica, data de abertura, faturamento mensal, entre outros.",
      id: "tela_completar_cadastro_botao_informar_dados_empresa",
    },
    {
      stepName: "completeBusinessAccountTitularData",
      stepTitle: "Dados da pessoa titular",
      stepDescription: "RG, nome completo da mãe, data de nascimento, renda mensal, entre outros.",
      id: "tela_completar_cadastro_botao_informar_dados_titular",
    },
    {
      stepName: "partnersDataPrelude",
      stepTitle: "Dados dos(as) sócios(as) com 25% ou mais de participação - se houver",
      stepDescription: "CPF ou CNPJ, data de nascimento, endereço, entre outros.",
      id: "tela_completar_cadastro_botao_informar_dados_socios",
    },
    {
      stepName: "documentUploadChain",
      stepTitle: "Envio de documentos",
      buttonText: "Enviar documentos",
      stepDescription: documentsUploadDescription,
      doneDescription: "Os documentos já foram enviados.",
      id: "tela_completar_cadastro_botao_enviar_documentos",
    },
  ]
}