import YapayIcon from 'assets/icons/YapayIcon';
import React, { useState } from 'react';
import { InnerContainer, InstructionsContainer, LoginContainer, StyledButton } from './styles';
import { useEffect } from 'react';
import { useQuery } from 'utils/useQuery';
import { useCallback } from 'react';
import { fetchAccessToken } from 'services/Authorizations/POST/AccessTokenService';
import { useHistory } from 'react-router-dom';
import { fetchAuth } from 'services/StoreAPI/POST/AuthService';
import { useRef } from 'react';
import { deleteDataStorage, getDataStorage, setDataStorage } from 'utils/storage';
import { fetchSearch } from 'services/StoreKeys/POST/SearchService';
import { checkUserAllowence } from 'utils/checkUserAllowence';
import ExternalService from 'utils/ExternalService';
import { fetchBondAccount } from 'services/BondAccount/GET/BondAccountService';
import { fetchBondAccountDetailYapay } from 'services/BondAccount/GET/BondAccountDetailYapayService';
import { fetchGetCodePermissioned } from 'services/Authorizations/POST/GetCodePermissionedService';
import { fetchGetAccessTokenPermissioned } from 'services/Authorizations/POST/GetAccessTokenPermissionedService';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { GAMapRoutes } from 'utils/GAMapRoutes';
import { CircularProgress } from '@material-ui/core';

export default function Login() {
  GAMapRoutes()
  const trayConsumerKey = ExternalService.buildExternalToken('tray_consumer_key')
  const webAppHost = ExternalService.buildExternalUrl('webapp')
  const mounted = useRef(false)
  const query = useQuery()
  const history = useHistory()
  const [name, setName] = useState("")
  // const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(true)

  const handleNavigationToYapayLogin = () => {
    const yapayPortalURL = `${ExternalService.buildExternalUrl('signin')}/login/plugincredenciamento`
    window.open(yapayPortalURL, "_parent")
  }

  const handleForgotPassword = () => {
    const yapayPortalURL = `${ExternalService.buildExternalUrl('signin')}/password/reset?integration_id=plugincredenciamento`
    window.open(yapayPortalURL, "_parent")
  }

  const handleNavigationToTrayLogin = useCallback(({ storeHost }) => {
    window.location.replace(`${decodeURI(storeHost)}/auth.php?response_type=code&consumer_key=${trayConsumerKey}&callback=${webAppHost}/login`)
  }, [trayConsumerKey, webAppHost])

  const handleDatabaseSync = useCallback(async () => {
    fetchSearch()
      .then((response) => {
        if (typeof (response?.onboarding_step) !== "number") {
          if (mounted.current) {
            history.replace("/landing")
          }
        }
      })
  }, [history])

  const handleLogout = useCallback(() => {
    history.replace("/logout")
  }, [history])

  const handleYapayPermissionedLogin = useCallback(() => {
    fetchGetCodePermissioned()
      .then((code) => {
        fetchGetAccessTokenPermissioned(code)
          .then((res) => {
            if (res) {
              history.replace("/dashboard")
            }
          })
      })
  }, [history])

  const handleTrayLogin = useCallback(({
    storeURL,
    storeHost,
    storeID,
    storePlanID,
    adminUser,
    storeCode,
    origem,
  }) => {
    fetchAuth({
      storeURL,
      storeHost,
      storeID,
      storePlanID,
      adminUser,
      storeCode,
      origem,
    }).then(async () => {
      const main_user = await checkUserAllowence()
      if (main_user) {
        // const { name, email } = await fetchBondAccountDetailYapay()
        const { name } = await fetchBondAccountDetailYapay()
        await handleDatabaseSync()
        if (mounted.current) {
          setName(name)
          // setEmail(email)
          setLoading(false)
        }
      } else {
        handleYapayPermissionedLogin()
      }
    })
  }, [handleDatabaseSync, handleYapayPermissionedLogin])

  const handleYapayLogin = useCallback(({
    code
  }) => {
    setLoading(true)
    fetchAccessToken(code)
      .then(async () => {
        if (mounted.current) {
          const accountValid = await fetchBondAccount()
          const store_host = getDataStorage("store_host")
          if (accountValid) { // volta pro iframe
            const { token_id } = await fetchGetAccountMyAccount()
            setDataStorage("token_id", token_id)
            window.open(`${store_host}/admin/#/auth.php?response_type=code&consumer_key=${trayConsumerKey}&callback=${webAppHost}`, "_parent")
          } else {
            handleLogout()
          }
        }
      })
  }, [webAppHost, trayConsumerKey, handleLogout])


  const getQueryParams = useCallback(() => {
    const storeURL = query.get("api_address") // Retornará valor apenas quando for redirect da Tray, e neste caso o parâmetro code é da Tray e não da Yapay
    const storeHost = query.get("store_host") || query.get("url")
    const storeID = query.get("store")
    const storePlanID = query.get("store_plan_id")
    const adminUser = query.get("adm_user")
    const code = query.get("code")
    const origem = query.get("origem") || "ecommerce"

    if (!!storeURL && !!code) {
      handleTrayLogin({
        storeURL,
        storeHost,
        storeID,
        storePlanID,
        adminUser,
        storeCode: code,
        origem,
      })
    } else if (!!code) {
      handleYapayLogin({
        code
      })
    } else if (!!storeHost) {
      handleNavigationToTrayLogin({
        storeHost
      })
    } else { // TO-DO: Testar remoção desse else, parece redudante com o handleNavigationToTrayLogin
      const storeHost = getDataStorage('store_host')
      if (!!storeHost) {
        window.location.replace(`${decodeURI(storeHost)}/auth.php?response_type=code&consumer_key=${trayConsumerKey}&callback=${webAppHost}/login`)
      }
    }
  }, [query, handleTrayLogin, handleYapayLogin, handleNavigationToTrayLogin, webAppHost, trayConsumerKey])

  const handleInitSession = useCallback(async () => {
    const yapay_code = getDataStorage("yapay_code")
    deleteDataStorage("full_name")
    if (!!yapay_code) {
      await fetchAccessToken(yapay_code)
      const accountValid = await fetchBondAccount()
      if (accountValid) {
        const onboarding_step = getDataStorage("onboarding_step")
        const allowence = await checkUserAllowence()
        if (onboarding_step === "-1" || !allowence) {
          history.replace("/dashboard")
        } else {
          history.replace("/welcome")
        }
      } else {
        handleLogout()
      }
    } else {
      getQueryParams()
    }
  }, [getQueryParams, history, handleLogout])

  useEffect(() => {
    handleInitSession()
  }, [handleInitSession])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  return <>
    <LoginContainer>
      <YapayIcon color="white" size={1.18} />
      <InnerContainer>
        {
          loading ?
            <CircularProgress
              size={42}
              thickness={4}
            /> :
            <>
              <InstructionsContainer>
                <span>
                  Olá {name}
                </span>
                <span>
                  {`Boas-vindas ao app da Vindi Pagamentos!\nPara prosseguir, clique em `}
                  <b>
                    {`“Já tenho senha Vindi” `}
                  </b>
                  {`e informe a mesma senha que você utiliza para acessar o portal de vendas Vindi.\n\nSe você ainda não possui senha ou a esqueceu, clique em "Não tenho senha" e na tela seguinte informe o e-mail da sua conta Tray.`}
                </span>
              </InstructionsContainer>
              <StyledButton
                onClick={handleNavigationToYapayLogin}
                id={"login_plugin_tela-boas-vindas_botao_ja-tenho-senha-vindi"}
              >
                Já tenho senha Vindi
              </StyledButton>
              <StyledButton
                variant="outlined"
                onClick={handleForgotPassword}
                id={"login_plugin_tela-boas-vindas_botao_nao-tenho-senha-vindi"}
              >
                Não tenho senha
              </StyledButton>
              {/* <BottomInstructions>
                {`Em caso de dúvidas, clique no link abaixo para falar com nosso time de atendimento:`}
              </BottomInstructions> */}
            </>
        }
      </InnerContainer>
    </LoginContainer>
  </>;
}
