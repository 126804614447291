import { createContext, useContext, useState } from 'react';
const AccountDataContext = createContext();

export default function AccountDataProvider({ children }) {
  // const mounted = useRef(false)
  // const [myAccountData, setMyAccountData] = useState({})
  // const [isLoading, setLoading] = useState(true)
  const [messageIdSMSEmail, setMessageIdSMSEmail] = useState(null)
  const [SMSEmail, setSMSEmail] = useState(null)
  const [oldEmail, setOldEmail] = useState(null)
  const [messageIdSMSPhone, setMessageIdSMSPhone] = useState(null)
  const [newPhone, setNewPhone] = useState(null)

  // const fetchData = useCallback(async () => {
  //   const fetchedMyAccount = await fetchGetAccountMyAccount()

  //   if (mounted.current) {
  //     setMyAccountData(fetchedMyAccount)
  //     setLoading(false)
  //   }
  // }, [])

  // useEffect(() => {
  //   mounted.current = true
  //   return () => { mounted.current = false }
  // }, [])

  // useEffect(() => {
  //   fetchData()
  // }, [fetchData])

  return <>
    <AccountDataContext.Provider value={{
      // myAccountData,
      // isLoading,
      messageIdSMSEmail,
      setMessageIdSMSEmail,
      messageIdSMSPhone,
      setMessageIdSMSPhone,
      SMSEmail,
      setSMSEmail,
      oldEmail,
      setOldEmail,
      newPhone,
      setNewPhone
    }}>
      {children}
    </AccountDataContext.Provider>
  </>;
}

export function useAccountData() {
  const {
    // myAccountData,
    // isLoading,
    messageIdSMSEmail,
    setMessageIdSMSEmail,
    messageIdSMSPhone,
    setMessageIdSMSPhone,
    SMSEmail,
    setSMSEmail,
    oldEmail,
    setOldEmail,
    newPhone,
    setNewPhone } = useContext(AccountDataContext)
  return {
    // myAccountData,
    // isLoading,
    messageIdSMSEmail,
    setMessageIdSMSEmail,
    messageIdSMSPhone,
    setMessageIdSMSPhone,
    SMSEmail,
    setSMSEmail,
    oldEmail,
    setOldEmail,
    newPhone,
    setNewPhone
  }
}
