import React, { Fragment } from 'react';
import { formatToBRL } from 'utils/formatToBRL';
import { getFormatedDate } from 'utils/getFormatedDate';
import { TransactionMoney } from 'components/Dashboard/DashboardOverview/styles';
import EmptyData from 'components/Dashboard/EmptyData';
import { StyledTable, StyledTableBody, StyledTableCell, StyledTableHead, StyledTableRow, StyledTableHeadCell, StyledTableHeadRow, TableHeadTitle, Row, TransactionDescription, TransactionOrderNumber, TransactionDateNumber } from './styles';

export default function TableViewer({
  tableBodyData = [],
  emptyData = {},
}) {
  const tableHeaders = ["Data", "Descrição", "Pedido", "Valor"]
  return <>
    {
      tableBodyData.length > 0 ?
        <StyledTable>
          <StyledTableHead>
            <StyledTableHeadRow>
              {
                tableHeaders.map((e, i) => {
                  return (
                    <StyledTableHeadCell
                      key={i}
                    >
                      <TableHeadTitle>
                        {e}
                      </TableHeadTitle>
                    </StyledTableHeadCell>
                  )
                })
              }
            </StyledTableHeadRow>
          </StyledTableHead>
          <StyledTableBody>
            {
              tableBodyData.map((e, i) => {
                return (
                  <Fragment key={i}>
                    <StyledTableRow key={i}>
                      <StyledTableCell>
                        <Row>
                          <TransactionDateNumber>
                            {getFormatedDate(e?.date * 1000, true)}
                          </TransactionDateNumber>
                        </Row>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Row>
                          <TransactionDescription>
                            {e?.description}
                          </TransactionDescription>
                        </Row>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TransactionOrderNumber>
                          {e?.order_number}
                        </TransactionOrderNumber>
                      </StyledTableCell>
                      <StyledTableCell $isMoneyCell>
                        <TransactionMoney
                          $isPositive={e?.amount >= 0}
                        >
                          {formatToBRL(e?.amount)}
                        </TransactionMoney>
                      </StyledTableCell>
                    </StyledTableRow>
                  </Fragment>
                )
              })
            }
          </StyledTableBody>
        </StyledTable> :
        <EmptyData
          Img={() => emptyData.img}
          title={emptyData.title}
          subTitle={emptyData.subTitle}
        />
    }
  </>;
}
