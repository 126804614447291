const mainSettingsVariants = {
  mainSettingsEcommerceVariantSteps: [
    "creditCardStep",
    "boletoStep",
    "customizeStep",
    "pixStep",
    "billingEmailsStep",
    "successUploading",
  ],
  mainSettingsRecurrenceVariantSteps: [
    "boletoStep",
    "customizeStep",
    "pixStep",
    "successUploading",
  ],
}

const documentsSendingVariants = {
  documentsSendingPersonalVariantSteps: [
    "documentsUploadInstructions",
    "userIdUploadStep",
    // "addressProofingUploadStep",
  ],
  documentsSendingBusinessVariantSteps: [
    "documentsUploadInstructions",
    "userIdUploadStep",
    "attorneyPowerUploadStep",
    "enterpriseProofingUploadStep",
    // "addressProofingUploadStep",
  ],
}

const accountDataVariants = {
  accountDataStepsWithBanking: [
    "titularAndAddressStep",
    "phoneDataStep",
    "phoneSMSVerificationStep",
    "emailDataStep",
    "emailSMSVerificationStep",
    "emailEditStep",
    "bankAccountForm",
  ],
  accountDataSteps: [
    "titularAndAddressStep",
    "phoneDataStep",
    "phoneSMSVerificationStep",
    "emailDataStep",
    "emailSMSVerificationStep",
    "emailEditStep",
  ],
}

export const onboardingVariantsDictionary = {
  onboardingAVariant: {
    sections: [
      "accountDataSection",
      "mainSettingsSection",
    ],
    stepsCollection: {
      accountDataSteps: [
        "titularAndAddressStep",
        "phoneDataStep",
        "phoneSMSVerificationStep",
        "emailDataStep",
        "emailSMSVerificationStep",
        "emailEditStep",
      ],
      ...mainSettingsVariants,
    },
  },
  onboardingBVariant: {
    sections: [
      "accountDataSection",
      "documentsSendingSection",
      "mainSettingsSection",
    ],
    stepsCollection: {
      ...accountDataVariants,
      ...documentsSendingVariants,
      ...mainSettingsVariants,
    },
  },
  onboardingCVariant: {
    sections: [
      "accountDataSection",
      "mainSettingsSection",
      // "completeRegisterSection",  // TO-DO: Descomentar quando for definido sobre a conta digital
    ],
    stepsCollection: {
      accountDataSteps: [
        "titularAndAddressStep",
        "phoneDataStep",
        "phoneSMSVerificationStep",
        "emailDataStep",
        "emailSMSVerificationStep",
        "emailEditStep",
      ],
      ...mainSettingsVariants,
      completeRegisterSteps: [
        "completeRegistrationInstructions",
      ],
    },
  },
  onboardingDVariant: {
    sections: [
      "accountDataSection",
      "documentsSendingSection",
      "mainSettingsSection",
      // "completeRegisterSection", // TO-DO: Descomentar quando for definido sobre a conta digital
    ],
    stepsCollection: {
      ...accountDataVariants,
      ...documentsSendingVariants,
      ...mainSettingsVariants,
      completeRegisterSteps: [
        "completeRegistrationInstructions",
      ],
    },
  },
}