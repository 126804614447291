import LoadingIndicator from 'components/shared/LoadingIndicator';
import { Field } from 'formik';
import React, { useEffect, useState } from 'react';
import SMSImage from 'assets/images/sms-code.png'
import { FormControl } from '@material-ui/core';
import { SMSPhoneVerificationFormContainer, CodeInput, InstructionLabel, CodeContainer, InputsContainer, Image, StyledButton, CodeInstruction } from './styles';
import { fetchUpdateSigninProfiles } from 'services/Account/POST/UpdateSigninProfilesService';
import { useAccountData } from 'context/AccountDataContext';
import SnackbarAlert from 'components/shared/SnackbarAlert';

export default function SMSPhoneVerificationForm({
  isLoading = true,
  isOnboarding = false,
  values,
}) {
  const { setMessageIdSMSPhone, newPhone } = useAccountData()
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [hasRequestSuccessed, setRequestSuccessed] = useState(true)

  useEffect(() => {
    if (values.code1.length === 1) {
      const nextInput = document.querySelector(`input[name=code2]`)
      nextInput.focus()
      nextInput.select()
    }
  }, [values.code1])

  useEffect(() => {
    if (values.code2.length === 1) {
      const nextInput = document.querySelector(`input[name=code3]`)
      nextInput.focus()
      nextInput.select()
    }
  }, [values.code2])

  useEffect(() => {
    if (values.code3.length === 1) {
      const nextInput = document.querySelector(`input[name=code4]`)
      nextInput.focus()
      nextInput.select()
    }
  }, [values.code3])

  useEffect(() => {
    if (values.code4.length === 1) {
      const nextInput = document.querySelector(`input[name=code5]`)
      nextInput.focus()
      nextInput.select()
    }
  }, [values.code4])

  useEffect(() => {
    if (values.code5.length === 1) {
      const nextInput = document.querySelector(`input[name=code6]`)
      nextInput.focus()
      nextInput.select()
    }
  }, [values.code5])

  useEffect(() => {
    if (values.code6.length === 1) {
      const currentInput = document.querySelector(`input[name=code6]`)
      currentInput.blur()
    }
  }, [values.code6])

  const ResendSMSCode = async () => {
    await fetchUpdateSigninProfiles({
      phone: newPhone,
    })
      .then(({ data }) => {
        if (data?.message_id) {
          setMessageIdSMSPhone(data.message_id)
          setRequestSuccessed(true)
        } else {
          setRequestSuccessed(false)
        }
        setSnackbarOpen(true)
      })
  }
  return (
    <>
      {
        isLoading ?
          <LoadingIndicator /> :
          <SMSPhoneVerificationFormContainer
            $isOnboarding={isOnboarding}
          >
            <InputsContainer>
              <InstructionLabel>Informe o código enviado por SMS para o seu número de celular</InstructionLabel>
              <FormControl>
                <CodeContainer>
                  <Field
                    name="code1"
                    size="small"
                    inputProps={{
                      maxLength: 1
                    }}
                    as={CodeInput}
                  />
                  <Field
                    name="code2"
                    size="small"
                    inputProps={{
                      maxLength: 1
                    }}
                    as={CodeInput}
                  />
                  <Field
                    name="code3"
                    size="small"
                    inputProps={{
                      maxLength: 1
                    }}
                    as={CodeInput}
                  />
                  <Field
                    name="code4"
                    size="small"
                    inputProps={{
                      maxLength: 1
                    }}
                    as={CodeInput}
                  />
                  <Field
                    name="code5"
                    size="small"
                    inputProps={{
                      maxLength: 1
                    }}
                    as={CodeInput}
                  />
                  <Field
                    name="code6"
                    size="small"
                    inputProps={{
                      maxLength: 1
                    }}
                    as={CodeInput}
                  />
                </CodeContainer>
              </FormControl>
              <CodeInstruction>
                O código recebido é válido por 1 minuto. Após este período, é necessário reenviar um novo código.
              </CodeInstruction>
              <StyledButton
                variant="outlined"
                onClick={ResendSMSCode}
              >
                Reenviar
              </StyledButton>
              <SnackbarAlert
                isOpen={isSnackbarOpen}
                onClose={() => { setSnackbarOpen(false) }}
                message={hasRequestSuccessed ? "Código reenviado." : "Ops! Você já atingiu o limite de reenvio de código por SMS hoje.\nTente novamente após 24h."}
                hasSuccessed={hasRequestSuccessed}
              />
            </InputsContainer>
            <Image src={SMSImage} />
          </SMSPhoneVerificationFormContainer>
      }
    </>
  )
}
