import React from 'react';
import { Field } from 'formik';
import { SectionTitle, StyledMoneyTextField } from 'components/Onboarding/OnboardingForms/AccountDataForms/AdditionalDataForm/styles';
import { PersonalAdditionalDataFieldsContainer, PersonalAccountDataSection, PersonalAccountDataInnerColumnContainer, PersonalAccountDataInnerRowContainer, PPEInstructions } from './styles';
import AdditionalDataImage from 'assets/images/trocar-cpf.png';
import RadioOptions from 'components/shared/RadioOptions';
import AddressFields from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/AddressFields';
import TelephoneContactFields from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/TelephoneContactFields';
import ProfessionAutoComplete from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/ProfessionAutoComplete';
import DatePicker from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/DatePicker';
import { StyledTextField } from 'components/Onboarding/OnboardingForms/AccountDataForms/CompleteBusinessAccountTitularDataForm/styles';
import { formatRG } from 'utils/formatRG';

const isPPEOptions = [
  {
    value: "false",
    label: "Não"
  },
  {
    value: "true",
    label: "Sim"
  },
]

export default function PersonalAdditionalDataFields({
  values,
  errors,
  touched,
  disabledForm = false,
  setFieldValue = () => { },
  isOnboardingStep,
  bankOptionsList,
  accountTypesList,
  missingPendencies,
}) {

  const handleRGUpdate = (e) => {
    setFieldValue("RG", formatRG(e.target.value))
  }
  const handleMonthlyIncomeUpdate = (_e, value) => {
    setFieldValue("monthlyIncome", value)
  }

  return <>
    <PersonalAdditionalDataFieldsContainer>
      <PersonalAccountDataInnerRowContainer>
        <PersonalAccountDataInnerColumnContainer>
          <PersonalAccountDataSection>
            <SectionTitle>
              Dados da pessoa titular da conta
            </SectionTitle>
            <Field
              as={StyledTextField}
              name="RG"
              label="RG"
              size="small"
              error={touched.RG && !!errors.RG}
              helperText={touched.RG && errors.RG}
              inputProps={{ maxLength: 12 }}
              onChange={handleRGUpdate}
              disabled={disabledForm}
              $fullWidth
            />
            <Field
              as={DatePicker}
              name="RGIssueDate"
              label="Data de emissão do RG"
              size="small"
              setFieldValue={setFieldValue}
              error={touched.RGIssueDate && !!errors.RGIssueDate}
              helperText={
                (touched.RGIssueDate && errors.RGIssueDate) ||
                "A data de emissão do RG encontra-se no canto superior direito do verso do documento e também pode ser consultada no site “Meu INSS”, na seção Meu cadastro > Documentação."
              }
              disabled={disabledForm}
            />
            <Field
              as={StyledTextField}
              name="motherName"
              label="Nome completo da mãe"
              size="small"
              error={touched.motherName && !!errors.motherName}
              helperText={touched.motherName && errors.motherName}
              disabled={disabledForm}
              $fullWidth
            />
            <Field
              as={DatePicker}
              name="birthDate"
              label="Data de nascimento"
              size="small"
              setFieldValue={setFieldValue}
              error={touched.birthDate && !!errors.birthDate}
              helperText={touched.birthDate && errors.birthDate}
              disabled={disabledForm}
            />
            <Field
              as={ProfessionAutoComplete}
              name="profession"
              label="Profissão"
              setFieldValue={setFieldValue}
              values={values}
              error={touched.profession && !!errors.profession}
              helperText={(touched.profession && errors.profession) ||
                "Informe aqui sua profissão por formação, ou seja, título profissional obtido através de um curso técnico, superior ou de pós-graduação. Caso não tenha um título profissional, selecione a opção mais próxima do seu cargo ou ocupação."
              }
              disabled={disabledForm}
              $fullWidth
            />
            <Field
              as={StyledMoneyTextField}
              name="monthlyIncome"
              label="Renda mensal (média)"
              size="small"
              onChange={handleMonthlyIncomeUpdate}
              error={touched.monthlyIncome && !!errors.monthlyIncome}
              helperText={
                (touched.monthlyIncome && errors.monthlyIncome) ||
                "A renda mensal média é a soma de todos o seus rendimentos financeiros, ou seja, todo o dinheiro que você recebe durante o mês, que pode ser o seu salário, pensões, recebimento de aluguéis, entre outros."
              }
              disabled={disabledForm}
            />
            <TelephoneContactFields
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              disabledForm={disabledForm}
              hideAddButton
            />
          </PersonalAccountDataSection>
        </PersonalAccountDataInnerColumnContainer>
        <PersonalAccountDataInnerColumnContainer>
          <img
            src={AdditionalDataImage}
            alt="Dados da conta"
          />
        </PersonalAccountDataInnerColumnContainer>
      </PersonalAccountDataInnerRowContainer>
      <PersonalAccountDataSection>
        <SectionTitle>
          O(a) titular da conta é uma Pessoa Politicamente Exposta (PPE)?
        </SectionTitle>
        <PPEInstructions>
          Consideram-se Pessoas Politicamente Expostas aquelas que desempenham ou tenham desempenhado cargos ou funções públicas relevantes nos últimos cinco anos, no Brasil ou em outros países.
        </PPEInstructions>
        <RadioOptions
          receivedValue={values.isPPE}
          receivedSetter={(e) => { setFieldValue("isPPE", e) }}
          options={isPPEOptions}
          disabled={disabledForm}
        />
      </PersonalAccountDataSection>
      <PersonalAccountDataSection>
        <SectionTitle>
          Endereço do(a) titular da conta
        </SectionTitle>
        <AddressFields
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          disabledForm={disabledForm}
        />
      </PersonalAccountDataSection>
      {
        // TO-DO: Descomentar ou remover por completo quando for decidido o que fazer
        //   <PersonalAccountDataSection>
        //   <SectionTitle>
        //     Conta bancária do(a) titular
        //   </SectionTitle>
        //   <BankAccountFields
        //     values={values}
        //     errors={errors}
        //     touched={touched}
        //     disabledForm={disabledForm}
        //     isOnboardingStep={isOnboardingStep}
        //     bankOptionsList={bankOptionsList}
        //     accountTypesList={accountTypesList}
        //     missingPendencies={missingPendencies}
        //     setFieldValue={setFieldValue}
        //   />
        // </PersonalAccountDataSection>
      }
    </PersonalAdditionalDataFieldsContainer>
  </>;
}
