/* eslint-disable max-len */
import React from 'react';

export default function Camera({ size = 1, color = "#91B5E3" }) {
  return <>
    <i>
      <svg
        width={40 * size}
        height={40 * size}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M33.333 6.66683H28.0497L24.9997 3.3335H14.9997L11.9497 6.66683H6.66634C4.83301 6.66683 3.33301 8.16683 3.33301 10.0002V30.0002C3.33301 31.8335 4.83301 33.3335 6.66634 33.3335H33.333C35.1663 33.3335 36.6663 31.8335 36.6663 30.0002V10.0002C36.6663 8.16683 35.1663 6.66683 33.333 6.66683ZM33.333 30.0002H6.66634V10.0002H13.4163L16.4663 6.66683H23.533L26.583 10.0002H33.333V30.0002ZM19.9997 11.6668C15.3997 11.6668 11.6663 15.4002 11.6663 20.0002C11.6663 24.6002 15.3997 28.3335 19.9997 28.3335C24.5997 28.3335 28.333 24.6002 28.333 20.0002C28.333 15.4002 24.5997 11.6668 19.9997 11.6668ZM19.9997 25.0002C17.2497 25.0002 14.9997 22.7502 14.9997 20.0002C14.9997 17.2502 17.2497 15.0002 19.9997 15.0002C22.7497 15.0002 24.9997 17.2502 24.9997 20.0002C24.9997 22.7502 22.7497 25.0002 19.9997 25.0002Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
