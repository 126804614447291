import { Alert } from "@material-ui/lab";
import styled from "styled-components";
import { borderStyles, grayScale, primaryColors, textStyles } from "theme/styleguide";

export const BankAccountProofingUploadFormContainer = styled.div`
  margin-bottom: 40px;
`
export const Container = styled.div`
  display: flex;
  margin-top: 28px;
  justify-content: space-between;
  @media (min-width: 768px)
  {
    margin-top: 49px;
  }
`
export const ContainerLeft = styled.div`
  max-width:556px;
`
export const ContainerRight = styled.div`
  margin-right:180px;
`
export const BankIconContainer = styled.div`
  margin-top:24px;
  margin-left:20px;
`
export const BankIcon = styled.img`
  width: 100%;
  height: 100%;
  max-width: 40px;
  max-height: 40px;
`
export const ListProofContainer = styled.ul`
  margin-top: 20px;
  margin-bottom: 8px;
  list-style: none;
  padding: 0px;
  padding-bottom: 12px;
`
export const TextContainer = styled.div`
  margin-bottom:35px;
`
export const StyledAlert = styled(Alert)`
  width: 100%;
  color: ${grayScale.gray_3};
  margin-bottom: 48px;
  background: ${primaryColors.alert_blue};
  @media (min-width: 768px)
  {
    margin-bottom: 48px;
  }
`
export const TextBankContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top:26px;
  margin-left:13px;
`
export const TextBankInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top:30px;
  margin-bottom:25px;
`
export const BodyText = styled.span`
  ${textStyles.tray_text_14px}
  text-align: left;
  color:${grayScale.gray_3};
  b{
    ${textStyles.tray_h5_15px}
    text-align: left;
  }
`
export const BodyTextOpacity = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  text-align: left;
  opacity:0.6;
  color:${grayScale.gray_3};
`
export const ListProofItem = styled.li`
  ${textStyles.tray_text_14px}
  text-align: left;
  color:${grayScale.gray_3};
`
export const TrayH5 = styled.span`
  font-family: Nunito Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color:${grayScale.gray_3};
`
export const BankCard = styled.div`
  display:flex;
  min-width: 556px;
  min-height: 160px;
  ${borderStyles.border_1};
  margin-top:14px;
  margin-bottom:58px;
`
export const BankCardLoadingCointainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`
export const FileRulesList = styled.ul`
  padding: 0px 15px;
  margin-bottom: 66px;
  >li{
    ${textStyles.tray_text_14px}
    text-align: left;
    opacity:0.6;
    color:${grayScale.gray_3};
  }
`