import { useCallback, useEffect, useState, useRef } from 'react';
import { fetchGetAccount } from 'services/Account/GET/GetAccountService';
import { fetchGetBankAccount } from 'services/Account/GET/GetBankAccountService';
import { fetchBankOptions } from 'services/BankAccounts/GET/BankOptionsService';
import { fetchBankAccountCreate } from 'services/BankAccounts/POST/BankAccountCreateService';
import { fetchBankAccountType } from 'services/BankAccounts/POST/BankAccountTypeService';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { filterActiveBankAccount } from 'utils/filterActiveBankAccount';
import { validateBank } from 'utils/validateBank';
import { object, string } from 'yup';

export default function useBankAccountDataSetup({
  reloadAfterSubmit = false,
  afterSubmit = () => { },
}) {
  const mounted = useRef(false)
  const [initialValues, setInitialValues] = useState()
  const [oldValues, setOldValues] = useState({})
  const [validationSchema, setValidationSchema] = useState()
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [bankOptionsList, setBankOptionsList] = useState([])
  const [accountTypesList, setAccountTypesList] = useState([])

  const fetchData = useCallback(async () => {
    const { type } = await fetchGetAccount()
    const fetchedMyAccount = await fetchGetAccountMyAccount()
    const fetchedBankOptions = await fetchBankOptions()
    const fetchedBankAccountTypes = await fetchBankAccountType()
    const fetchedBankAccountList = await fetchGetBankAccount()
    const fetchedBankAccount = filterActiveBankAccount(fetchedBankAccountList)

    const validationObject = {
      bankAccount: object()
        .shape({
          bankCode: string()
            .test('valid-bank', 'O banco informado é inválido', (receivedBankCode) =>
              validateBank({
                receivedBankCode,
                bankOptionsList: fetchedBankOptions,
              })
            )
            .required("Este campo é obrigatório"),
          bankAccountType: string()
            .required("Este campo é obrigatório"),
          bankAgency: string()
            .length(4, "Este campo deve possuir 4 dígitos")
            .required("Este campo é obrigatório"),
          bankAccountNumber: string()
            .max(10, "Este campo deve possuir no máximo 10 dígitos")
            .required("Este campo é obrigatório"),
          verifyingDigit: string()
            .max(2, "Este campo deve possuir no máximo 2 dígitos")
            .required("Este campo é obrigatório"),
        }),
    }

    const initialValuesObject = {
      bankAccount: {
        bankCode: fetchedBankAccount?.code || "",
        bankAccountType: fetchedBankAccount?.bank_account_type?.id || "",
        bankAgency: fetchedBankAccount?.agency || "",
        bankAccountNumber: fetchedBankAccount?.number?.split("-")?.[0] || "",
        verifyingDigit: fetchedBankAccount?.number?.split("-")?.[1] || "",
        pj: fetchedBankAccount?.pj || type === "business",
        name: fetchedBankAccount?.name || fetchedMyAccount?.name || fetchedMyAccount?.social_name || "",
      }
    }

    if (mounted.current) {
      setInitialValues(initialValuesObject)
      setOldValues(initialValuesObject)
      setValidationSchema(object(validationObject))
      setBankOptionsList(fetchedBankOptions || [])
      setAccountTypesList(fetchedBankAccountTypes || [])
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async ({
    bankAccount,
  }) => {
    if (JSON.stringify(bankAccount) !== JSON.stringify(oldValues.bankAccount) &&
      !!bankAccount.bankCode &&
      !!bankAccount.bankAccountType &&
      !!bankAccount.bankAccountNumber &&
      !!bankAccount.bankAgency &&
      !!bankAccount.verifyingDigit
    ) {
      await fetchBankAccountCreate({
        code: bankAccount.bankCode,
        bank_account_type_id: bankAccount.bankAccountType,
        number: `${bankAccount.bankAccountNumber}-${bankAccount.verifyingDigit}`,
        agency: bankAccount.bankAgency,
        name: bankAccount.name,
        pj: bankAccount.pj,
      })
    }
    if (reloadAfterSubmit) {
      await fetchData()
    }
    setSnackbarOpen(true)
    afterSubmit()
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    isSnackbarOpen,
    setSnackbarOpen,
    bankOptionsList,
    accountTypesList,
  })
}