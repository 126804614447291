import { Checkbox, MenuItem } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { GAEventTracker } from 'utils/GAEventTracker';
import { ReplyParcelsOptionsContainer, Instruction, CheckBoxContainer, SelectContainer, SelectStyled, ParcelText } from './styles';

export default function ReplyParcelsOptions({
  parcelsOptions,
  formikRef,
  replySelectedParcels,
  setReplySelectedParcels,
  fromOnboarding = false
}) {

  const [splits, setSplits] = useState(null)
  const [selectedParcel, setSelectedParcel] = useState(0)

  useEffect(() => {
    setSplits(parcelsOptions?.[0]?.splits?.split)
  }, [parcelsOptions])

  const handleChange = useCallback(() => {
    if (replySelectedParcels && selectedParcel > 0) {
      GAEventTracker({
        category: fromOnboarding ? "Onboarding" : "Página configurações",
        action: "Utilizar replicação de parcelas",
        label: "Aplicar o mesmo parcelamento para todas as bandeiras"
      })
      parcelsOptions.map((e, i) => {
        if (selectedParcel <= e?.splits?.split?.length) {
          formikRef.current.setFieldValue(`parcelValues.${i}`, selectedParcel)
        }
        return e
      })
    }
  }, [formikRef, parcelsOptions, replySelectedParcels, selectedParcel, fromOnboarding])

  useEffect(() => {
    handleChange()
  }, [selectedParcel, handleChange])

  return <>

    {splits &&
      <ReplyParcelsOptionsContainer>
        <CheckBoxContainer>
          <Checkbox
            checked={replySelectedParcels}
            color="primary"
            onChange={(e) => {
              setReplySelectedParcels(e.target.checked)
              handleChange()
            }}
            id={"configuracoes_cartao-de-credito_checkbox_aplicar-mesmo-parcelamento"}
          />
          <Instruction>
            Aplicar o mesmo parcelamento para todas as bandeiras
          </Instruction>
        </CheckBoxContainer>
        <SelectContainer>
          <ParcelText>
            Parcelar em até
          </ParcelText>
          <SelectStyled
            id="parcelas"
            disabled={!replySelectedParcels}
            value={selectedParcel}
            onChange={(e) => { setSelectedParcel(e.target.value) }}
          >
            <MenuItem disabled value={0}>
              Selecione a parcela
            </MenuItem>
            {splits.map((e, i) => {
              return (
                <MenuItem
                  value={e.split_transaction}
                  key={`key-${i}`}>
                  {e.split_transaction}x sem juros
                </MenuItem>
              )
            })}
          </SelectStyled>
        </SelectContainer>
      </ReplyParcelsOptionsContainer>}
  </>;
}
