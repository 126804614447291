import apiPrivate from 'services/apiPrivate'
import { getStoredCredential } from 'utils/getStoredCredential'

export const fetchCreateAccountSellerSettings = async ({
  automatic_cancel = null,
  automatic_cancel_time = null,
  pix_default_qrcode_lifespan_in_minutes = null
}) => {

  return apiPrivate.post("/accounts_seller_settings/create", {
    automatic_cancel,
    automatic_cancel_time,
    pix_default_qrcode_lifespan_in_minutes
  }, {
    headers: {
      ...getStoredCredential(),
    }
  })
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}