export const parseParcelsOptionsToArray = (receivedParcelsOptions = [], receivedParcelValues = []) => {
  const arrAux = []
  receivedParcelsOptions.forEach((e, i) => {
    const { split } = e.splits
    const { payment_method_id } = e
    if (split.length > 0) {
      split.forEach((f) => {
        let percentage
        if (Number(f.split_transaction) <= Number(receivedParcelValues[i])) {
          percentage = 100
        } else {
          percentage = 0
        }
        arrAux.push({
          payment_method_id,
          split_transaction: f.split_transaction,
          percentage
        })
      })
    } else {
      arrAux.push({
        payment_method_id,
        split_transaction: split.split_transaction,
        percentage: 100
      })
    }
  })
  return arrAux
}