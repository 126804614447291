import styled from "styled-components";
import { Collapse } from "@material-ui/core";

export const FormButtonsContainer = styled(Collapse)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 16px;
  button{
    margin-bottom: 16px;
  }
  @media (min-width: 768px)
  {
    flex-direction: row;
    justify-content: flex-start;
    margin: 0px;
    button{
      min-width: fit-content;
      width: 124px;
    }
    button:first-child{
      margin-right: 16px;
    }
  }
  @media (max-width: 767px)
  {
    button{
      width: calc(100% - 16px); //254px
      padding: 6px;
    }
  }
`