import React from 'react';
import { DashboardNotificationsContainer } from './styles';
import BankAccountError from 'components/Dashboard/BankAccountError';
import { getDataStorage } from 'utils/storage';
import BlockedTransactionsBanner from 'components/Dashboard/BlockedTransactionsBanner';
import PendingDocumentsBanner from 'components/Dashboard/PendingDocumentsBanner';
import CompleteRegisterWarningDialog from 'components/Dashboard/CompleteRegisterWarningDialog';
import RegularizationStatusNotifier from 'components/Dashboard/RegularizationStatusNotifier';
// import DigitalAccountStatusNotifier from 'components/Dashboard/DigitalAccountStatusNotifier';
import CompleteRegisterInviteDialog from 'components/Dashboard/CompleteRegisterInviteDialog';
import CompleteRegisterProgressBar from 'components/Dashboard/CompleteRegisterProgressBar';

export default function DashboardNotifications() {
  const isMainUser = getDataStorage("main_user")
  return <>
    {
      isMainUser &&
      <>
        <DashboardNotificationsContainer>
          <RegularizationStatusNotifier />
          {/* <DigitalAccountStatusNotifier /> // TO-DO: Descomentar quando definido que deve ser exibido de volta */}
          <BlockedTransactionsBanner />
          <PendingDocumentsBanner />
          <CompleteRegisterProgressBar />
          <BankAccountError />
        </DashboardNotificationsContainer>
        <CompleteRegisterInviteDialog />
        <CompleteRegisterWarningDialog />
      </>
    }
  </>;
}
