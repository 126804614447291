import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

const childrenCellsSize = `
  td:nth-child(1){
    flex: 1;
  }
  td:nth-child(2){
    flex: 3;
  }
  td:nth-child(3){
    flex: 1;
  }
  td:nth-child(4){
    flex: 1;
  }
`

export const StyledTable = styled.table`
  display: none;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  margin-bottom: 32px;
  @media (min-width: 920px)
  {
    display: table;
  }
`
export const StyledTableHead = styled.thead`
`
export const StyledTableBody = styled.tbody`
`
export const StyledTableHeadRow = styled.tr`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
  border-bottom: 1px solid ${grayScale.gray_20p100};
  ${childrenCellsSize}
  td:nth-child(4){
    text-align: end;
  }
`
export const StyledTableRow = styled.tr`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  ${childrenCellsSize}
  border-bottom: 1px solid ${grayScale.gray_20p100};
  padding-bottom: 10px;
  padding-left: 8px;
  padding-right: 8px;
  margin-bottom: 12px;
`
export const StyledTableHeadCell = styled.td`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const StyledTableCell = styled.td`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ $isMoneyCell }) => $isMoneyCell &&
    `
      text-align: right;
    `
  };
`
export const TableHeadTitle = styled.span`
  ${textStyles.tray_h5_15px}
  font-weight: 800;
  color: ${grayScale.gray_3};
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
`
export const TransactionDescription = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const TransactionOrderNumber = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const TransactionDateNumber = styled.span`
  ${textStyles.tray_h5_15px}
  color: ${grayScale.gray_3};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`