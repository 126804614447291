import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";
import LoadableButton from 'components/shared/LoadableButton';

export const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 12px;
  margin-top: 32px ;
  @media (min-width: 768px)
  {
    align-items: flex-start;
      max-width: 1366px;
      margin: 24px auto;
      padding-left: 32px;
  }
`
export const LogoContainer = styled.div`
  >i{
    margin-left: -20px;
  }
  @media (max-width: 767px)
  {
    align-self: center;
    >i{
      margin-left: 0px;
    }
  }
`
export const WelcomeTitle = styled.span`
  ${textStyles.tray_h4_18px}
  color: ${grayScale.black};
  white-space: pre-wrap;
  margin-top: 32px;
  margin-bottom: 32px;
  align-self: center;
  @media (min-width: 768px)
  {
    ${textStyles.tray_h3_24px}
    align-self: flex-start;
  }
`
export const WelcomeMessage = styled.span`
  ${textStyles.tray_text_14px};
  color: ${grayScale.gray_3};
  margin-bottom: 42px;
  @media (min-width: 768px)
  {
    width: 100%;
    max-width: 700px;
  }
`
export const StepMessage = styled.span`
  ${textStyles.tray_h5_15px}
  color: ${grayScale.gray_3};
  margin-bottom: 32px;
`
export const StyledList = styled.ul`
  padding-left: 32px;
  margin-top: 0px;
  margin-bottom: 42px;
`
export const StyledListItem = styled.li`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
`
export const WelcomeImage = styled.img`
  width: 111px;
  height: 111px;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px)
  {
    align-self: flex-start;
    width: 230px;
    height: 230px;
  }
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 42px;
  width: 100%;
  @media (min-width: 768px)
  {
    flex-direction: row;
  }
  
`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const StyledLoadableButton = styled(LoadableButton)`
  margin-bottom: 64px;
  @media (max-width: 767px)
  {
    width: calc(100% - 16px); //254px
    padding: 6px;
  }
`
export const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
`
export const ConsentInstructions = styled.span`
  ${textStyles.tray_text_14px};
  color: ${grayScale.gray_3};
  white-space: pre-wrap;
  margin-top: 56px;
  margin-bottom: 42px;
  a{
    font-weight: bold;
    color: ${grayScale.gray_3};
  }
  @media (min-width: 768px)
  {
    margin-bottom: 112px;
  }
`