import apiPrivate from 'services/apiPrivate'
import { getStoredCredential } from 'utils/getStoredCredential'

export const fetchSales = async (start_date_created, end_date_created) => {

  return apiPrivate.get("/v3/sales", {
    headers: {
      'content-type': 'application/json',
      ...getStoredCredential(),
    },
    params: {
      start_date_created,
      end_date_created,
    }
  })
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}