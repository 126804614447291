import { Button, IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import styled from "styled-components";
import { textStyles } from "theme/styleguide";

const severityPallete = {
  info: `
    background: #2E7EE71A;
  `,
  success: `
    background: #D9F4EC;
  `,
  warning: `
    background: #FFF1D1;
  `,
  error: `
    background: #D331311A;
  `,
}

export const StatusNotificationAlertContainer = styled.div`
  
`
export const StyledAlert = styled(Alert)`
  ${textStyles.tray_text_14px}
  white-space: pre-wrap;
  ${({ severity }) => severityPallete[severity]}
  margin-bottom: 8px;
  padding-right: 50px;
  .MuiAlert-message{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  @media (min-width: 768px)
  {
    padding-right: 16px;
    .MuiAlert-message{
    flex-direction: row;
    }
  }
`
export const StyledButton = styled(Button)`
  align-self: center;
  min-width: 100%;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  @media (min-width: 768px)
  {
    min-width: 176px;
    margin-top: 0px;
    margin-left: 32px;
    margin-right: 32px;
  }
`
export const StyledIconButton = styled(IconButton)`
  margin-top: -4px;
  display: ${({ $hideOnDesktop }) => $hideOnDesktop ? "initial" : "none"};
  @media (max-width: 767px)
  {
    align-self: flex-end;
    margin-right: -32px;
    margin-bottom: -20px;
  }
  @media (min-width: 768px)
  {
    display: ${({ $hideOnDesktop }) => $hideOnDesktop ? "none" : "initial"};
  }
`
