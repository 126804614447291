import React from 'react';
import { Field } from 'formik';
import DatePicker from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/DatePicker';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { StyledTextField, StyledMoneyTextField, FieldSectionTitle, FieldSectionDescription, StyledAlert } from 'components/Dashboard/AccountDataMenu/styles';
import { PersonalAccountTitularPersonFieldsContainer } from './styles';
import { formatIdentifier } from 'utils/formatIdentifier';
import { formatRG } from 'utils/formatRG';
import ProfessionAutoComplete from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/ProfessionAutoComplete';
import RadioOptions from 'components/shared/RadioOptions';
import AddressFields from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/AddressFields';
import ReportProblem from 'assets/icons/ReportProblem';
import { primaryColors } from 'theme/styleguide';
import { Collapse } from '@material-ui/core';
import TelephoneContactFields from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/TelephoneContactFields';

const isPPEOptions = [
  {
    value: "false",
    label: "Não"
  },
  {
    value: "true",
    label: "Sim"
  },
]
export default function PersonalAccountTitularPersonFields({
  values,
  errors,
  touched,
  isLoading = true,
  disabledForm = false,
  setFieldValue = () => { },
  hasDigitalAccount = false,
}) {
  const handleCPFUpdate = (e) => {
    setFieldValue("CPF", formatIdentifier(e.target.value))
  }
  const handleRGUpdate = (e) => {
    setFieldValue("RG", formatRG(e.target.value))
  }
  const handleMonthlyIncomeUpdate = (_e, value) => {
    setFieldValue("monthlyIncome", value)
  }
  return <>
    <PersonalAccountTitularPersonFieldsContainer>
      {
        isLoading ?
          <LoadingIndicator /> :
          <>
            <Field
              as={StyledTextField}
              name="name"
              label="Nome"
              size="small"
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
              disabled={disabledForm}
            />
            <Field
              as={StyledTextField}
              name="CPF"
              label="CPF"
              size="small"
              error={touched.CPF && !!errors.CPF}
              helperText={touched.CPF && errors.CPF}
              inputProps={{ maxLength: 14 }}
              onChange={handleCPFUpdate}
              disabled
            />
            {
              hasDigitalAccount &&
              <>
                <Field
                  as={DatePicker}
                  name="birthDate"
                  label="Data de nascimento"
                  size="small"
                  setFieldValue={setFieldValue}
                  error={touched.birthDate && !!errors.birthDate}
                  helperText={touched.birthDate && errors.birthDate}
                  disabled={disabledForm}
                  $fullWidth={false}
                />
                <Field
                  as={StyledTextField}
                  name="RG"
                  label="RG"
                  size="small"
                  error={touched.RG && !!errors.RG}
                  helperText={touched.RG && errors.RG}
                  inputProps={{ maxLength: 12 }}
                  onChange={handleRGUpdate}
                  disabled={disabledForm}
                />
                <Field
                  as={DatePicker}
                  name="RGIssueDate"
                  label="Data de emissão do RG"
                  size="small"
                  setFieldValue={setFieldValue}
                  error={touched.RGIssueDate && !!errors.RGIssueDate}
                  helperText={
                    (touched.RGIssueDate && errors.RGIssueDate) ||
                    "A data de emissão pode ser consultada no verso do documento e no site “Meu INSS”, na seção Meu cadastro > Documentação."
                  }
                  disabled={disabledForm}
                  $fullWidth={false}
                />
                <Field
                  as={ProfessionAutoComplete}
                  name="profession"
                  label="Profissão"
                  setFieldValue={setFieldValue}
                  values={values}
                  error={touched.profession && !!errors.profession}
                  helperText={(touched.profession && errors.profession) ||
                    "Informe aqui sua profissão por formação, ou seja, título profissional obtido através de um curso técnico, superior ou de pós-graduação. Caso não tenha um título profissional, selecione a opção mais próxima do seu cargo ou ocupação."
                  }
                  disabled={disabledForm}
                />
                <Field
                  as={StyledMoneyTextField}
                  name="monthlyIncome"
                  label="Renda mensal (média)"
                  size="small"
                  onChange={handleMonthlyIncomeUpdate}
                  error={touched.monthlyIncome && !!errors.monthlyIncome}
                  helperText={
                    (touched.monthlyIncome && errors.monthlyIncome) ||
                    "A renda mensal média é a soma de todos o seus rendimentos financeiros, ou seja, todo o dinheiro que você recebe durante o mês, que pode ser o seu salário, pensões, recebimento de aluguéis, entre outros."
                  }
                  disabled={disabledForm}
                />
                <Field
                  as={StyledTextField}
                  name="motherName"
                  label="Nome completo da mãe"
                  size="small"
                  error={touched.motherName && !!errors.motherName}
                  helperText={touched.motherName && errors.motherName}
                  disabled={disabledForm}
                />
              </>
            }
            <FieldSectionTitle>
              Contato
            </FieldSectionTitle>
            <TelephoneContactFields
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              disabledForm={disabledForm}
              hideContainerPadding
            />
            {
              hasDigitalAccount &&
              <>
                <FieldSectionTitle>
                  A pessoa titular da conta é uma Pessoa Politicamente Exposta (PPE)?
                </FieldSectionTitle>
                <FieldSectionDescription>
                  Consideram-se Pessoas Politicamente Expostas aquelas que desempenham ou tenham desempenhado cargos ou funções públicas relevantes nos últimos cinco anos, no Brasil ou em outros países.
                </FieldSectionDescription>
                <RadioOptions
                  receivedValue={values?.isPPE}
                  receivedSetter={(e) => { setFieldValue("isPPE", e) }}
                  options={isPPEOptions}
                  disabled={disabledForm}
                />
              </>
            }
            <FieldSectionTitle>
              Endereço da pessoa titular
            </FieldSectionTitle>
            <AddressFields
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              disabledForm={disabledForm}
            />
            <Collapse
              in={!disabledForm}
            >
              <StyledAlert
                severity="error"
                icon={
                  <ReportProblem
                    color={primaryColors.red_balance}
                  />
                }
              >
                <b>
                  {`Atenção! Seus recebimentos podem ficar temporariamente bloqueados se o nome da pessoa titular for editado.\n\n`}
                </b>
                {`Isso ocorre devido a uma rápida validação automática que fazemos do novo nome informado como medida de segurança para garantir a autenticidade da pessoa titular da conta. Em alguns casos, poderá ser solicitado que você envie um documento para comprovação.\n\nApós o novo nome ser validado, os recebimentos serão desbloqueados e a conta poderá continuar operando normalmente na Vindi.`}
              </StyledAlert>
            </Collapse>
          </>
      }
    </PersonalAccountTitularPersonFieldsContainer>
  </>;
}