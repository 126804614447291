import { Typography } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import styled from "styled-components";
import { grayScale, primaryColors, textStyles } from "theme/styleguide";

export const CreditCardFormContainer = styled.div`
  
`
export const ParcelsOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 904px;
  margin-bottom: 48px;
  @media (min-width: 920px)
  {
    margin-bottom: 65px;
    flex-direction: row;
  }
`
export const FeesInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
  @media (min-width: 920px)
  {
    flex-direction: row;
    margin-bottom: 88px;
    div:nth-child(odd){
      margin-right: 20px;
    }
  }
`
export const StyledAlert = styled(Alert)`
  width: 100%;
  color: ${grayScale.gray_3};
  max-width: 1038px;
  margin-bottom: 48px;
  background: ${primaryColors.alert_blue};
  @media (min-width: 768px)
  {
    margin-bottom: 32px;
  }
`
export const TrayText14 = styled(Typography)`
 ${textStyles.tray_text_14px};
 margin-bottom:35px;
`