import { Tooltip } from '@material-ui/core';
import Camera from 'assets/icons/CameraIcon/Camera';
import TrashCan from 'assets/icons/TrashCan/TrashCan';
import React, { useState, useEffect } from 'react';
import { grayScale } from 'theme/styleguide';
import { useFilePicker } from 'use-file-picker';
import { FilePickerContainer, EmptyContainer, LabelText, FullText, FullContainer, FullContainerDefault, StyledIconButton, StyledImage } from './styles';

export default function FilePicker({
  style,
  title = "",
  Icon = Camera,
  setFieldValue = () => { },
  fieldName,
  acceptedFiles = [".jpeg", ".jpg", ".png", ".gif", ".bmp", ".webp"],
  multipleFile = false,
  maxFileSize = 4.5,
  disabled = false,
  marginRight = "0px",
  id = "",
}) {
  const [file, handleFile] = useState("")
  useEffect(() => {
    setFieldValue(fieldName, file)
  }, [file, fieldName, setFieldValue])

  const [fileName, setFileName] = useState()
  const [openFileSelector, { filesContent, clear }] = useFilePicker({
    accept: acceptedFiles,
    multiple: multipleFile,
    readAs: "DataURL",
    maxFileSize: maxFileSize
  })

  useEffect(() => {
    if (!multipleFile && filesContent?.[0]?.content) {
      handleFile(filesContent[0].content)
      setFileName(filesContent[0].name)
    }
    if (!filesContent?.[0]) {
      handleFile("")
      setFileName("")
    }
  }, [filesContent, handleFile, multipleFile])

  return <>
    <FilePickerContainer
      disabled={disabled}
      $marginRight={marginRight}
    >
      {
        !file ?
          <EmptyContainer
            style={style}
            onClick={openFileSelector}
            {...!!id && { id }}
            disabled={disabled}
          >
            <Icon
              color={disabled ? grayScale.gray_20p100 : "#91B5E3"}
            />
            <LabelText
              disabled={disabled}
            >
              {title}
            </LabelText>
          </EmptyContainer>
          :
          <FullContainer style={style}>
            {
              (fileName?.includes(".png") || fileName?.includes(".jpeg") || fileName?.includes(".jpg")) ?
                <StyledImage
                  src={file}
                  alt="Imagem para upload"
                />
                :
                <FullContainerDefault>
                  <Icon
                    color={disabled ? grayScale.gray_20p100 : "#91B5E3"}
                  />
                  <FullText>
                    {fileName}
                  </FullText>
                </FullContainerDefault>
            }
            <Tooltip
              title="Excluir"
              arrow
            >
              <StyledIconButton
                size="small"
                onClick={clear}
              >
                <TrashCan />
              </StyledIconButton>
            </Tooltip>
          </FullContainer>
      }
    </FilePickerContainer>
  </>;
}
