import { useCallback, useEffect, useState, useRef } from 'react';
import { object, string, array } from 'yup';
import { fetchSearchAccountSellerSettings } from 'services/AccountsSellerSettings/POST/SearchAccountSellerSettingsService';
import { parseBoletoInstructionsToArray } from 'utils/parseBoletoInstructionsToArray';
import { parseArrayToBoletoInstruction } from 'utils/parseArrayToBoletoInstruction';
import { fetchUpdateAccountSellerSettings } from 'services/AccountsSellerSettings/POST/UpdateAccountSellerSettingsService';

export default function useBoletoSetup({
  afterSubmit = () => { }
}) {
  const mounted = useRef(false);

  const [initialValues, setInitialValues] = useState({
    daysCounting: '',
    instructions: [],
  })
  const [isLoading, setLoading] = useState(true)
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)

  const validationSchema = object({
    daysCounting: string()
      .required('Este campo é obrigatório'),
    instructions: array()
      .of(
        string()
          .max(60, 'Este campo deve ter no máximo 60 caracteres')
          .required('Este campo deve ser preenchido ou excluído')
      )
  })

  const fetchBoletoData = useCallback(async () => {
    const instrucao_boleto = await fetchSearchAccountSellerSettings("instrucao_boleto")
    let billet_days_expirate = await fetchSearchAccountSellerSettings("billet_days_expirate")
    if (billet_days_expirate === "[object Object]") {
      billet_days_expirate = "1"
    }
    if (mounted.current) {
      setInitialValues({
        daysCounting: String(billet_days_expirate),
        instructions: parseBoletoInstructionsToArray(instrucao_boleto),
      })
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async ({
    daysCounting,
    instructions
  }) => {
    await fetchUpdateAccountSellerSettings({
      billet_days_expirate: daysCounting,
      instrucao_boleto: parseArrayToBoletoInstruction(instructions),
    })
    setSnackbarOpen(true)
    setInitialValues({
      daysCounting,
      instructions
    })
    afterSubmit()
  }

  useEffect(() => {
    fetchBoletoData()
  }, [fetchBoletoData])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    isSnackbarOpen,
    setSnackbarOpen
  })
}