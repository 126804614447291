import { useOnboarding } from 'context/OnboardingContext';
import { Form, Formik } from 'formik';
import React from 'react';
import { PhoneDataStepContainer } from './styles';
import { ButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import PhoneDataForm from 'components/Onboarding/OnboardingForms/AccountDataForms/PhoneDataForm';
import usePhoneDataSetup from 'components/FormsSetup/AccountData/usePhoneDataSetup';
import LoadableButton from 'components/shared/LoadableButton';
import { getHideOnCountStepsGoingForward } from 'utils/getHideOnCountStepsGoingForward';
import { getCurrentOnboardingStepsCollection } from 'utils/getCurrentOnboardingStepsCollection';
import { accountDataStepsDictionary } from 'library/accountDataStepsDictionary';

export default function PhoneDataStep() {
  const stepsCollectionLength = getCurrentOnboardingStepsCollection("accountDataSteps").length - 1
  const { nextProfileSettingsStep, profileSettingsStep, nextOnboardingSection } = useOnboarding()
  const { initialValues, validationSchema, isLoading, handleSubmit } = usePhoneDataSetup({
    afterSubmit: () => {
      if (profileSettingsStep < stepsCollectionLength) {
        nextProfileSettingsStep()
      } else {
        nextOnboardingSection()
      }
    },
    afterSubmitSkipSMS: () => {
      if (profileSettingsStep < stepsCollectionLength) {
        const hideOnCountStepsGoingBack = getHideOnCountStepsGoingForward("accountDataSteps", profileSettingsStep, accountDataStepsDictionary)
        if (profileSettingsStep + hideOnCountStepsGoingBack <= stepsCollectionLength) {
          for (let i = 0; i < hideOnCountStepsGoingBack; i++) {
            nextProfileSettingsStep()
          }
        } else {
          nextOnboardingSection()
        }
      } else {
        nextOnboardingSection()
      }
    }
  })
  return <>
    <PhoneDataStepContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <PhoneDataForm
                {...formikProps}
                isLoading={isLoading}
                initialValues={initialValues}
              />
              <ButtonsContainer>
                <LoadableButton
                  type="submit"
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_verif_celular_onboarding_botao_continuar"}
                >
                  Salvar e continuar
                </LoadableButton>
              </ButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </PhoneDataStepContainer>
  </>;
}
