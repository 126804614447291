import styled from "styled-components";
import { grayScale, shadowStyles, textStyles } from "theme/styleguide";

export const CancelledBoletoInfoCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1120px;
  margin-bottom: 64px;
  padding: 16px;
  box-shadow: none;
  border-radius: 4px;
  ${shadowStyles.drop_shadow_3}
  @media (min-width: 768px)
  {
    padding: 16px 24px;
    margin-bottom: 136px;
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${({ $open }) => $open ? "32px" : "0px"};
  transition: ease-in-out .2s;
  button{
    margin-top: -12px;
    padding: 4px;
  }
  .nonClickable{
    display: none;
  }
  .clickable{
    display: block;
    transform: scaleY(${({ $open }) => $open ? "1" : "-1"});
    transition: ease-in-out .2s;
  }
  @media (min-width: 768px)
  {
    margin-bottom: 8px;
    justify-content: flex-start;
    align-items: center;
    .nonClickable{
      display: block;
    }
    .clickable{
      display: none;
    }
  }
`
export const Title = styled.span`
  ${textStyles.tray_h5_15px}
  color: ${grayScale.gray_3};
  @media (min-width: 768px)
  {
    margin-left: 32px;
    margin-top: -8px;
    margin-bottom: 0px;
    ${textStyles.tray_h4_18px}
  }
`
export const InfoText = styled.span`
  display: block;
  ${textStyles.subtitle_3_12px}
  font-weight: 400;
  margin-bottom: 24px;
  @media (min-width: 768px)
  {
    ${textStyles.body_1_14px}
    margin-left: 72px;
    margin-bottom: 8px;
  }
`