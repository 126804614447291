/* eslint-disable max-len */
import React from 'react';

export default function LandingThumbsUpIcon({ size = 1, color = "#00A3FF" }) {
  return <>
    <i>
      <svg
        width={64 * size}
        height={64 * size}
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.0834 57.8334H11.3334C9.96313 57.8334 8.64897 57.289 7.68003 56.3201C6.71109 55.3511 6.16675 54.037 6.16675 52.6667V34.5834C6.16675 33.2131 6.71109 31.8989 7.68003 30.93C8.64897 29.961 9.96313 29.4167 11.3334 29.4167H19.0834M37.1667 24.25V13.9167C37.1667 11.8613 36.3502 9.89002 34.8968 8.43661C33.4434 6.9832 31.4722 6.16669 29.4167 6.16669L19.0834 29.4167V57.8334H48.2234C49.4694 57.8474 50.6786 57.4107 51.628 56.6037C52.5775 55.7967 53.2032 54.6737 53.3901 53.4417L56.9551 30.1917C57.0675 29.4512 57.0175 28.6951 56.8087 27.9758C56.5999 27.2566 56.2372 26.5913 55.7457 26.0261C55.2543 25.4609 54.6458 25.0093 53.9625 24.7027C53.2792 24.396 52.5373 24.2415 51.7884 24.25H37.1667Z"
          stroke={color}
          strokeWidth="6.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </i>
  </>;
}
