import styled from "styled-components";
import { ButtonsContainer } from "components/Onboarding/OnboardingBody/styles";

export const UserEmailFormContainer = styled.div`
  margin-bottom: 16px;
  >div.MuiFormControl-root.MuiTextField-root{
    max-width: 100%;
  }
  @media (min-width: 920px)
  {
    width: 560px;
    max-width: 50%;
    margin-bottom: 84px;
  }
`
export const StyledButtonsContainer = styled(ButtonsContainer)`
  @media (min-width: 920px)
  {
    button{
      min-width: 128px;
    }
  }
`