import MainSettingsSection from "components/Onboarding/OnboardingSections/MainSettingsSection";
import AccountVerificationSection from "components/Onboarding/OnboardingSections/AccountVerificationSection";
import AccountDataSection from "components/Onboarding/OnboardingSections/AccountDataSection";
import UploadFileIcon from "assets/icons/UploadFileIcon";
import SettingsIcon from "assets/icons/SettingsIcon";
import AccountCircleIcon from "assets/icons/AccountCircleIcon";
import CompleteRegisterSection from "components/Onboarding/OnboardingSections/CompleteRegisterSection";

export const onboardingSectionsDictionary = {
  accountDataSection: {
    welcomingTitle: "Dados da conta",
    headerTitle: "Dados da conta",
    headerIcon: (props) => AccountCircleIcon({ ...props }),
    hideOnHeader: false,
    section: <AccountDataSection />,
  },
  documentsSendingSection: {
    welcomingTitle: "Envio de documentos",
    headerTitle: "Envio de documentos",
    headerIcon: (props) => UploadFileIcon({ ...props }),
    hideOnHeader: false,
    section: <AccountVerificationSection />,
  },
  mainSettingsSection: {
    welcomingTitle: "Configurações principais de recebimento",
    headerTitle: "Configurações principais",
    headerIcon: (props) => SettingsIcon({ ...props, size: 1.33 }),
    hideOnHeader: false,
    section: <MainSettingsSection />,
  },
  completeRegisterSection: {
    welcomingTitle: "Completar cadastro (opcional)",
    headerTitle: "",
    headerIcon: (props) => AccountCircleIcon({ ...props }),
    hideOnHeader: true,
    section: <CompleteRegisterSection />,
  },
}