import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchCreateBusinessPartner = async (receivedTitularPartner = {}) => {
  const code = getDataStorage('yapay_code')
  return apiPrivate.post("/myaccount/business_partners", {
    ...receivedTitularPartner,
  }, {
    headers: {
      code,
    }
  })
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}