import apiPrivate from 'services/apiPrivate'
import { getStoredCredential } from 'utils/getStoredCredential'

export const fetchSearchAccountSellerSettings = async (tag) => {

  return apiPrivate.post("/accounts_seller_settings/search", {
    tag,
  }, {
    headers: {
      ...getStoredCredential(),
    }
  })
    .then(({ data }) => {
      return String(data?.accounts_seller_setting?.data_response?.seller_settings?.seller_setting?.value)
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}