import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const UserDataMenuContainer = styled.div`
  margin-top: 16px;
  @media (min-width: 1024px)
  {
    margin-top: 0px;
  }
`
export const FieldSectionTitle = styled.span`
  display: block;
  ${textStyles.h5_16px}
  font-weight: 700;
  color: ${grayScale.black};
  margin-top: ${({ $hasBiggerMarginTop }) => $hasBiggerMarginTop ? "40px" : "32px"};
  margin-bottom: 24px;
  @media (min-width: 920px)
  {
    margin-top: ${({ $hasBiggerMarginTop }) => $hasBiggerMarginTop ? "80px" : "32px"};
  }
`
export const FieldSectionDescription = styled.span`
  display: block;
  white-space: pre-wrap;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-bottom: 32px;
  @media (min-width: 920px)
  {
    font-size: 16px;
    margin-bottom: 16px;
  }
`
export const NameContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 16px;
  @media (min-width: 920px)
  {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 84px;
    >div{
      flex: 1;
      max-width: 560px;
    }
  }
`