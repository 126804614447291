export const getDDMMYYYY = (time) => {
  const date = new Date(time);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`
}
export const getFirstDayMonthYear = (time = new Date()) => {
  const date = new Date(time);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return `01/${month < 10 ? '0' : ''}${month}/${year}`;
}
export const getLastyDayMonthYear = (time = new Date()) => {
  const date = new Date(time);
  const day = String(new Date(time.getFullYear(), time.getMonth() + 1, 0).getDate()).padStart(2, '0')
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  return `${day}/${month < 10 ? '0' : ''}${month}/${year}`;
}