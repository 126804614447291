/* eslint-disable max-len */
import React from 'react';

export default function PeopleAltIcon({ size = 1, color = "#546E7A", opacity = "0.6" }) {
  return <>
    <i>
      <svg
        width={30 * size}
        height={30 * size}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g
          clipPath="url(#clip0_7307_915)">
          <path
            d="M20.8375 16.4125C22.55 17.575 23.75 19.15 23.75 21.25V25H28.75V21.25C28.75 18.525 24.2875 16.9125 20.8375 16.4125Z"
            fill={color}
            fillOpacity={opacity}
          />
          <path
            d="M18.75 15C21.5125 15 23.75 12.7625 23.75 10C23.75 7.2375 21.5125 5 18.75 5C18.1625 5 17.6125 5.125 17.0875 5.3C18.125 6.5875 18.75 8.225 18.75 10C18.75 11.775 18.125 13.4125 17.0875 14.7C17.6125 14.875 18.1625 15 18.75 15Z"
            fill={color}
            fillOpacity={opacity}
          />
          <path
            d="M11.25 15C14.0125 15 16.25 12.7625 16.25 10C16.25 7.2375 14.0125 5 11.25 5C8.4875 5 6.25 7.2375 6.25 10C6.25 12.7625 8.4875 15 11.25 15ZM11.25 7.5C12.625 7.5 13.75 8.625 13.75 10C13.75 11.375 12.625 12.5 11.25 12.5C9.875 12.5 8.75 11.375 8.75 10C8.75 8.625 9.875 7.5 11.25 7.5Z"
            fill={color}
            fillOpacity={opacity}
          />
          <path
            d="M11.25 16.25C7.9125 16.25 1.25 17.925 1.25 21.25V25H21.25V21.25C21.25 17.925 14.5875 16.25 11.25 16.25ZM18.75 22.5H3.75V21.2625C4 20.3625 7.875 18.75 11.25 18.75C14.625 18.75 18.5 20.3625 18.75 21.25V22.5Z"
            fill={color}
            fillOpacity={opacity}
          />
        </g>
      </svg>
    </i>
  </>;
}
