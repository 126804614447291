import { Dialog, DialogContent } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const StyledDialog = styled(Dialog)`
`
export const StyledDialogContent = styled(DialogContent)`
  text-align: center;
  padding: 8px 4px;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #88888888;
    }
  ::-webkit-scrollbar-thumb:hover {
    background: #66666688;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #55555588;
  }
`
export const StyledImage = styled.img`
  max-width: 100%;
`
export const DialogHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
`
export const Title = styled.span`
  ${textStyles.tray_h4_18px}
  color: ${grayScale.gray_3}
`