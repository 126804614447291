import React from 'react';
import { Form, Formik } from 'formik';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import PartnersDocumentsUploadForm from 'components/Onboarding/OnboardingForms/AccountVerificationForms/PartnersDocumentsUploadForm';
import { ButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import usePartnersDocumentsUploadDataSetup from 'components/FormsSetup/AccountVerification/usePartnersDocumentsUploadDataSetup';
import { StyledLoadableButton } from 'components/Dashboard/DashboardAccountVerification/styles';
import { PartnersDocumentsUploadMenuItemContainer } from './styles';
export default function PartnersDocumentsUploadMenuItem() {
  const { goBackToAccountVerificationMenu, selectedPartnerId, setSelectedPartnerId } = useDashboardMyAccount()
  const { initialValues, validationSchema, isLoading, handleSubmit, selectedPartnerData, documentRequired, documentStatus } = usePartnersDocumentsUploadDataSetup({
    isFilteringPartner: true,
    filteredPartnerId: selectedPartnerId,
    afterSubmit: () => {
      setSelectedPartnerId()
      goBackToAccountVerificationMenu()
    },
  })
  return <>
    <PartnersDocumentsUploadMenuItemContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={documentRequired && validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <PartnersDocumentsUploadForm
                selectedPartnerData={selectedPartnerData}
                isLoading={isLoading}
                documentStatus={documentStatus}
                documentRequired={documentRequired}
                formikProps={formikProps}
                showTitle={false}
              />
              <ButtonsContainer>
                <StyledLoadableButton
                  type="submit"
                  disabled={
                    (documentRequired && !formikProps.isValid) ||
                    (isLoading || formikProps.isSubmitting)
                  }
                  isLoading={formikProps.isSubmitting}
                  id={
                    selectedPartnerData?.isPFPartner ?
                      "envio-documentos_foto-RG-CNH-socios_botao_enviar" :
                      "envio-documentos_doc-constituicao_socio_botao_enviar"
                  }
                >
                  {
                    ["approved", "pending"].includes(documentStatus?.status?.tag) ?
                      "Continuar" :
                      "Enviar"
                  }
                </StyledLoadableButton>
              </ButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </PartnersDocumentsUploadMenuItemContainer>
  </>;
}
