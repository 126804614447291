import React from 'react';
import { Field } from 'formik';
import AddressFields from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/AddressFields';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { TitularAndAddressFieldsContainer, StepBody, InputContainerRow, InputSectionContainer, SectionTitle } from './styles';
import { StyledTextField } from 'components/Onboarding/OnboardingBody/styles';
import { formatIdentifier } from 'utils/formatIdentifier';

export default function TitularAndAddressFields({
  errors,
  touched,
  setFieldValue = () => { },
  isLoading = true,
  isBusinessAccount = false,
}) {
  return <>
    <TitularAndAddressFieldsContainer>
      {
        isLoading ?
          <LoadingIndicator /> :
          <>
            <StepBody>
              Por favor, confirme os dados da sua conta
            </StepBody>
            {
              isBusinessAccount ?
                <>
                  <InputSectionContainer>
                    <SectionTitle>
                      Dados da empresa
                    </SectionTitle>
                    <InputContainerRow>
                      <Field
                        as={StyledTextField}
                        name="socialName"
                        label="Razão social"
                        size="small"
                        error={touched.socialName && !!errors.socialName}
                        helperText={touched.socialName && errors.socialName}
                      />
                      <Field
                        as={StyledTextField}
                        name="CNPJ"
                        label="CNPJ"
                        size="small"
                        error={touched.CNPJ && !!errors.CNPJ}
                        helperText={touched.CNPJ && errors.CNPJ}
                        inputProps={{ maxLength: 18 }}
                        onChange={(e) => {
                          setFieldValue("CNPJ", formatIdentifier(e.target.value))
                        }}
                        disabled
                      />
                    </InputContainerRow>
                  </InputSectionContainer>
                  <InputSectionContainer>
                    <SectionTitle>
                      Dados da pessoa titular
                    </SectionTitle>
                    <InputContainerRow>
                      <Field
                        as={StyledTextField}
                        name="name"
                        label="Nome completo"
                        size="small"
                        error={touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                      />
                      <Field
                        as={StyledTextField}
                        name="CPF"
                        label="CPF"
                        size="small"
                        error={touched.CPF && !!errors.CPF}
                        helperText={touched.CPF && errors.CPF}
                        inputProps={{ maxLength: 14 }}
                        onChange={(e) => {
                          setFieldValue("CPF", formatIdentifier(e.target.value))
                        }}
                      />
                    </InputContainerRow>
                  </InputSectionContainer>
                  <InputSectionContainer>
                    <SectionTitle>
                      Endereço da empresa
                    </SectionTitle>
                    <InputContainerRow>
                      <AddressFields
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                      />
                    </InputContainerRow>
                  </InputSectionContainer>
                </> :
                <>
                  <InputSectionContainer>
                    <SectionTitle>
                      Dados da pessoa titular
                    </SectionTitle>
                    <InputContainerRow>
                      <Field
                        as={StyledTextField}
                        name="name"
                        label="Nome completo"
                        size="small"
                        error={touched.name && !!errors.name}
                        helperText={touched.name && errors.name}
                      />
                      <Field
                        as={StyledTextField}
                        name="CPF"
                        label="CPF"
                        size="small"
                        error={touched.CPF && !!errors.CPF}
                        helperText={touched.CPF && errors.CPF}
                        inputProps={{ maxLength: 14 }}
                        onChange={(e) => {
                          setFieldValue("CPF", formatIdentifier(e.target.value))
                        }}
                        disabled
                      />
                    </InputContainerRow>
                  </InputSectionContainer>
                  <InputSectionContainer>
                    <SectionTitle>
                      Endereço
                    </SectionTitle>
                    <InputContainerRow>
                      <AddressFields
                        errors={errors}
                        touched={touched}
                        setFieldValue={setFieldValue}
                      />
                    </InputContainerRow>
                  </InputSectionContainer>
                </>
            }
          </>
      }
    </TitularAndAddressFieldsContainer>
  </>;
}