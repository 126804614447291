import CalendarTodayIcon from 'assets/icons/CalendarTodayIcon';
import SavingsIcon from 'assets/icons/SavingsIcon';
import React, { useCallback, useEffect, useState } from 'react';
import { getFormatedDate } from 'utils/getFormatedDate';
import { formatToBRL } from 'utils/formatToBRL';
import { CardHeaderRow, CardTitle, SmallInstruction, StyledTextButton } from 'components/Dashboard/DashboardOverview/styles';
import { DateContainer, DateText, NextValuesToCreditContainer, SalesMoney, SalesText, ValueItem, ValuesList, EmptyStateImage, SpinnerContainer, ContainerList, ContainerListButon } from './styles';
import EmptyToCredit from 'assets/images/emptyToCredit.png'
import { CircularProgress } from '@material-ui/core';
import { useRef } from 'react';
import ExternalService from 'utils/ExternalService';
import { GAEventTracker } from 'utils/GAEventTracker';
import { fetchGetSchedulings } from 'services/Schedulings/GET/GetSchedulingsService';
import EmptyData from 'components/Dashboard/EmptyData';

export default function NextValuesToCredit() {
  const mounted = useRef(false);

  const [APIData, setAPIData] = useState([])
  const [loading, setLoading] = useState(true)

  const getNextValuesToCreditData = useCallback(async () => {
    setLoading(true)
    const today = new Date();
    const start = parseInt(new Date().getTime() / 1000);
    const last = parseInt(new Date(today.getFullYear(), today.getMonth(), today.getDate() + 5).getTime() / 1000);
    const data = await fetchGetSchedulings(start, last, 12);
    if (mounted.current) {
      setAPIData(data?.data?.data.slice(0, 5) || [])
      setLoading(false)
    }
  }, [])

  const handleOpenPortal = () => {
    GAEventTracker({
      category: "Página Visão Geral",
      action: "Ver todas de valores a creditar",
      label: `Clicar no botão "ver todos" da sessão próximos valores a creditar`
    })
    window.open(`${ExternalService.buildExternalUrl('intermediador')}/`, '_blank')
  }

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  useEffect(() => {
    getNextValuesToCreditData()
  }, [getNextValuesToCreditData])

  return <>
    <NextValuesToCreditContainer>
      <CardHeaderRow>
        <SavingsIcon />
        <CardTitle>Próximos valores a creditar</CardTitle>
      </CardHeaderRow>
      {
        loading ?
          <SpinnerContainer>
            <CircularProgress />
          </SpinnerContainer> :
          APIData.length > 0 ?
            <ContainerList>
              <ValuesList>
                {
                  APIData.map((e, i) => {
                    return (
                      <ValueItem key={i}>
                        <DateContainer>
                          <CalendarTodayIcon />
                          <DateText>
                            {getFormatedDate(new Date(e.date * 1000))}
                          </DateText>
                        </DateContainer>
                        <SalesText>
                          {e.totSales}
                        </SalesText>
                        <SalesMoney>
                          {formatToBRL(e.amount)}
                        </SalesMoney>
                      </ValueItem>
                    )
                  })
                }
              </ValuesList>
              <ContainerListButon>
                <StyledTextButton
                  variant="outlined"
                  onClick={handleOpenPortal}
                  id={"home_agenda-recebiveis_botao_ver-todos"}
                >
                  Ver todos
                </StyledTextButton>
                <SmallInstruction>
                  Ao clicar em “Ver todos” você será direcionado para o portal Vindi
                </SmallInstruction>
              </ContainerListButon>
            </ContainerList> :
            <EmptyData
              Img={() => <EmptyStateImage src={EmptyToCredit} />}
              title={`Eba! Todos os valores referente as suas vendas\njá foram creditados no seu saldo`}
              subTitle={`Assim que houver novos valores a creditar, eles serão\n exibidos aqui.`}
            />
      }
    </NextValuesToCreditContainer>
  </>;
}
