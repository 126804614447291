import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const DropdownCardContainer = styled.div`
    width: 100%;
`

export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
`
export const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0px;
  i.nonClickable{
    display: none;
    margin-right: 32px;
  }
  .MuiAccordionSummary-content{
    align-items: center;
    margin: 0px;
  }
  .MuiAccordionSummary-expandIcon{
    margin-right: -8px;
  }
  @media (min-width: 768px)
  {
    padding: 12px 24px;
    i.nonClickable{
      display: initial;
      margin-right: 32px;
    }
    .MuiAccordionSummary-expandIcon{
      margin-right: 16px;
    }
  }
`
export const Title = styled.span`
  ${textStyles.tray_h5_15px};
  line-height: 20px;
  color: ${grayScale.gray_3};
  font-weight:bold;
  @media (min-width: 768px)
  {
    margin-top: -4px;
    font-weight: bold;
    font-size: 16px;
    line-height: 23px;
    color: ${grayScale.gray_3};
  }
`
export const StyledAccordionDetails = styled(AccordionDetails)`
  flex-direction: column;
  font-weight: bold;
  .MuiAccordionSummary-root{
      padding: 0px;
  }
  @media (min-width: 768px)
  {
    padding: 20px 94px;
  }
`