/* eslint-disable max-len */
import React from 'react';

export default function CheckCircleIcon({ size = 1, color = "white" }) {
  return <>
    <i>
      <svg
        width={16 * size}
        height={16 * size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM6.3999 12L2.3999 7.99996L3.5279 6.87196L6.3999 9.73596L12.4719 3.66396L13.5999 4.79996L6.3999 12Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
