import React from 'react';
import { FeesInfoCardContainer, CardColumn, CardInfo, CardTitle } from './styles';

export default function FeesInfoCard({
  title = "",
  info = "",
  icon,
}) {
  return <>
    <FeesInfoCardContainer>
      {icon}
      <CardColumn>
        <CardTitle>
          {title}
        </CardTitle>
        <CardInfo>
          {info}
        </CardInfo>
      </CardColumn>
    </FeesInfoCardContainer>
  </>;
}
