/* eslint-disable max-len */
import React from 'react';

export default function PaletteIcon({ size = 1, color = "#2E7EE7" }) {
  return <>
    <i>
      <svg
        width={30 * size}
        height={30 * size}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 27.5C8.1125 27.5 2.5 21.8875 2.5 15C2.5 8.1125 8.1125 2.5 15 2.5C21.8875 2.5 27.5 7.55 27.5 13.75C27.5 17.8875 24.1375 21.25 20 21.25H17.7875C17.4375 21.25 17.1625 21.525 17.1625 21.875C17.1625 22.025 17.225 22.1625 17.325 22.2875C17.8375 22.875 18.125 23.6125 18.125 24.375C18.125 26.1 16.725 27.5 15 27.5ZM15 5C9.4875 5 5 9.4875 5 15C5 20.5125 9.4875 25 15 25C15.35 25 15.625 24.725 15.625 24.375C15.625 24.175 15.525 24.025 15.45 23.9375C14.9375 23.3625 14.6625 22.625 14.6625 21.875C14.6625 20.15 16.0625 18.75 17.7875 18.75H20C22.7625 18.75 25 16.5125 25 13.75C25 8.925 20.5125 5 15 5Z"
          fill={color}
        />
        <path
          d="M8.125 16.25C9.16053 16.25 10 15.4105 10 14.375C10 13.3395 9.16053 12.5 8.125 12.5C7.08947 12.5 6.25 13.3395 6.25 14.375C6.25 15.4105 7.08947 16.25 8.125 16.25Z"
          fill={color}
        />
        <path
          d="M11.875 11.25C12.9105 11.25 13.75 10.4105 13.75 9.375C13.75 8.33947 12.9105 7.5 11.875 7.5C10.8395 7.5 10 8.33947 10 9.375C10 10.4105 10.8395 11.25 11.875 11.25Z"
          fill={color}
        />
        <path
          d="M18.125 11.25C19.1605 11.25 20 10.4105 20 9.375C20 8.33947 19.1605 7.5 18.125 7.5C17.0895 7.5 16.25 8.33947 16.25 9.375C16.25 10.4105 17.0895 11.25 18.125 11.25Z"
          fill={color}
        />
        <path
          d="M21.875 16.25C22.9105 16.25 23.75 15.4105 23.75 14.375C23.75 13.3395 22.9105 12.5 21.875 12.5C20.8395 12.5 20 13.3395 20 14.375C20 15.4105 20.8395 16.25 21.875 16.25Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
