import styled from "styled-components";

export const LastTransactionsContainer = styled.div`
  padding: 0px;
  @media (min-width: 768px)
  {
    padding-bottom: 5px;
  }
`
export const EmptyStateImage = styled.img`
  max-width: 195px;
`
export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  margin-top: 16px;
  @media (min-width: 920px)
  {
    height: 368px;
  }
`