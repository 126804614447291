import React from 'react';
import { DashboardAccountVerificationContainer, FormTitle, MenuTitle, TitleContainer } from './styles';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from 'assets/icons/ArrowBackIcon';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import AccountVerificationMenu from 'components/Dashboard/AccountVerificationMenu';
import { accountVerificationDictionary } from 'library/accountVerificationDictionary';

export default function DashboardAccountVerification() {
  const { currentAccountVerificationMenuItem, goBackToAccountVerificationMenu } = useDashboardMyAccount()

  return <>
    <DashboardAccountVerificationContainer>
      <TitleContainer>
        {
          currentAccountVerificationMenuItem !== "menu" &&
          <IconButton
            size="small"
            onClick={goBackToAccountVerificationMenu}
          >
            <ArrowBackIcon />
          </IconButton>
        }
        <MenuTitle>
          Envio de documentos
        </MenuTitle>
      </TitleContainer>
      {
        currentAccountVerificationMenuItem === "menu" ?
          <AccountVerificationMenu /> :
          <>
            <FormTitle>
              {
                accountVerificationDictionary[currentAccountVerificationMenuItem]?.title
              }
            </FormTitle>
            {
              accountVerificationDictionary[currentAccountVerificationMenuItem]?.component
            }
          </>
      }
    </DashboardAccountVerificationContainer>
  </>;
}
