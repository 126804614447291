/* eslint-disable max-len */
import React from 'react';

export default function ExpandLessIcon({ size = 1, color = "#546E7A", ...props }) {
  return <>
    <i
      {...props}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
        opacity="0.54"
        fillRule="evenodd"
        clipRule="evenodd"
          d="M7.4 15.3999L12 10.7999L16.6 15.3999L18 13.9999L12 7.9999L6 13.9999L7.4 15.3999Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
