export const formatPhone = (phone = "") => {
  if (typeof phone === "string") {
    phone = phone.replace(/\D/g, "")
    phone = phone.replace(/^(\d{2})(\d)/g, "($1) $2")
    if (phone.length >= 13) {
      phone = phone.replace(/(\d)(\d{4})$/, "$1-$2")
    }
  } else {
    phone = ""
  }
  return phone
}