/* eslint-disable max-len */
import React from 'react';

export default function CircleCheckedIcon({ size = 1, color = "#21C712" }) {
  return <>
    <svg
      width={86 * size}
      height={86 * size}
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.4055 12.5943C65.2839 4.47284 54.4857 0 43 0C31.5141 0 20.7158 4.47284 12.5943 12.5943C4.47267 20.7159 0 31.5143 0 43C0 54.4857 4.47267 65.2839 12.5943 73.4055C20.7159 81.5272 31.5141 86 43 86C54.4857 86 65.2839 81.5272 73.4055 73.4055C81.5272 65.2839 86 54.4857 86 43C86 31.5143 81.5272 20.7161 73.4055 12.5943ZM43 80.9609C22.0682 80.9609 5.03906 63.9318 5.03906 43C5.03906 22.0682 22.0682 5.03906 43 5.03906C63.9318 5.03906 80.9609 22.0682 80.9609 43C80.9609 63.9318 63.9318 80.9609 43 80.9609Z"
        fill={color}
      />
      <path
        d="M63.5437 29.2025C62.5599 28.2189 60.9646 28.2189 59.9808 29.2027L37.7318 51.4517L26.0198 39.7397C25.036 38.7559 23.4407 38.7559 22.4567 39.7397C21.4728 40.7235 21.4728 42.3189 22.4567 43.3028L35.9501 56.7963C36.4421 57.2883 37.087 57.5342 37.7316 57.5342C38.3763 57.5342 39.0213 57.2881 39.5131 56.7963L63.5437 32.7657C64.5277 31.7819 64.5277 30.1865 63.5437 29.2025Z"
        fill={color}
      />
    </svg>
  </>;
};