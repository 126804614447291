import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const EmailUpdateInstructionsMenuItemContainer = styled.div`
`
export const Instruction = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  white-space: pre-wrap;
  margin-bottom: 152px;
  @media (min-width: 768px)
  {
    margin-bottom: 336px;
  }
`