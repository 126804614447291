import React from 'react';
import { individualLegalNatureBusinessTags } from 'library/individualBusinessLegalNatureTags';
import { Field } from 'formik';
import RadioOptions from 'components/shared/RadioOptions';
import TelephoneContactFields from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/TelephoneContactFields';
import DatePicker from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/DatePicker';
import ConstitutionPicker from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/ConstitutionPicker';
import BankAccountFields from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/BankAccountFields';
import AddressFields from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/AddressFields';
import { SectionTitle, StyledMoneyTextField, StyledTextField } from 'components/Onboarding/OnboardingForms/AccountDataForms/AdditionalDataForm/styles';
import AdditionalDataImage from 'assets/images/trocar-cnpj.png';
import { MenuItem } from '@material-ui/core';
import { BusinessAdditionalFieldsContainer, BusinessAccountDataContainer, BusinessAccountDataInnerColumnContainer, BusinessAccountDataInnerRowContainer, SocietyPercentageInstructions, BankInstructions } from './styles';
import useWindowWidth from 'utils/useWindowWidth';

const partnersWithOver25PercentageOptions = [
  {
    value: "false",
    label: "Não"
  },
  {
    value: "true",
    label: "Sim"
  },
]

export default function BusinessAdditionalFields({
  values,
  errors,
  touched,
  disabledForm = false,
  setFieldValue = () => { },
  isOnboardingStep,
  constitutionList,
  legalNatureList,
  bankOptionsList,
  accountTypesList,
  missingPendencies,
  showPartnersPercentageSection = false,
}) {
  const windowWidth = useWindowWidth()

  const handleMonthlyRevenue = (_e, value) => {
    setFieldValue("monthlyRevenue", value)
  }

  const isSelectedLegalNatureIndividualBusiness = (selectedLegalNatureID) => {
    const selectedLegalNatureTag = legalNatureList.filter((e) =>
      e.id === selectedLegalNatureID
    )?.[0]?.tag
    return individualLegalNatureBusinessTags.includes(selectedLegalNatureTag)
  }

  return <>
    <BusinessAdditionalFieldsContainer>
      <BusinessAccountDataContainer>
        <SectionTitle>
          Dados da empresa
        </SectionTitle>
        <BusinessAccountDataInnerRowContainer>
          <BusinessAccountDataInnerColumnContainer>
            <Field
              as={StyledTextField}
              name="legalNature"
              label="Natureza jurídica"
              size="small"
              select
              helperText={touched.legalNature && errors.legalNature}
              error={touched.legalNature && !!errors.legalNature}
              disabled={disabledForm}
              $fullWidth
            >
              {
                legalNatureList.map((e, i) => (
                  <MenuItem
                    value={e.id}
                    key={i}
                  >
                    {e.name}
                  </MenuItem>
                ))
              }
            </Field>
            <Field
              as={ConstitutionPicker}
              name="constitutionForm"
              label="Forma de constituição"
              size="small"
              options={constitutionList}
              error={touched.constitutionForm && !!errors.constitutionForm}
              helperText={
                (touched.constitutionForm && errors.constitutionForm) ||
                "É a caracterização da empresa, que pode ser constituída de uma ou mais pessoas empresárias, sócias ou acionistas."
              }
              disabled={disabledForm}
              $fullWidth
            />
            <Field
              as={DatePicker}
              name="formationDate"
              label="Data de abertura da empresa"
              size="small"
              setFieldValue={setFieldValue}
              error={touched.formationDate && !!errors.formationDate}
              helperText={touched.formationDate && errors.formationDate}
              disabled={disabledForm}
            />
            <Field
              as={StyledMoneyTextField}
              name="monthlyRevenue"
              label="Faturamento mensal"
              size="small"
              onChange={handleMonthlyRevenue}
              error={touched.monthlyRevenue && !!errors.monthlyRevenue}
              helperText={
                (touched.monthlyRevenue && errors.monthlyRevenue) ||
                `Faturamento é o total em R$ das vendas de uma empresa. Neste caso, você deve informar o valor médio total das suas vendas no período de um mês.\n\nEx: uma pessoa que vende bolos, vendeu 20 bolos em um mês no valor de R$ 50,00 cada. Então seu faturamento mensal foi de R$ 1.000,00.\n `
              }
              disabled={disabledForm}
            />
            <TelephoneContactFields
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
              disabledForm={disabledForm}
              hideAddButton
              hideContainerPadding={windowWidth < 1024}
            />
          </BusinessAccountDataInnerColumnContainer>
          <BusinessAccountDataInnerColumnContainer>
            <img
              src={AdditionalDataImage}
              alt="Dados da conta"
            />
          </BusinessAccountDataInnerColumnContainer>
        </BusinessAccountDataInnerRowContainer>
      </BusinessAccountDataContainer>
      <BusinessAccountDataContainer>
        <SectionTitle>
          Endereço da empresa
        </SectionTitle>
        <AddressFields
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
          disabledForm={disabledForm}
        />
      </BusinessAccountDataContainer>
      <BusinessAccountDataContainer>
        <SectionTitle>
          Conta bancária da empresa
        </SectionTitle>
        <BankInstructions>
          A conta informada deve ser vinculada ao CNPJ da empresa.
        </BankInstructions>
        <BankAccountFields
          values={values}
          errors={errors}
          touched={touched}
          disabledForm={disabledForm}
          isOnboardingStep={isOnboardingStep}
          bankOptionsList={bankOptionsList}
          accountTypesList={accountTypesList}
          missingPendencies={missingPendencies}
          setFieldValue={setFieldValue}
        />
      </BusinessAccountDataContainer>
      {
        showPartnersPercentageSection &&
        !isSelectedLegalNatureIndividualBusiness(values.legalNature) &&
        <BusinessAccountDataContainer>
          <SectionTitle>
            A empresa possui sócios(as) com 25% ou mais de participação?
          </SectionTitle>
          <SocietyPercentageInstructions>
            A participação do(a) sócio(a) corresponde ao seu percentual investido no capital social, ou seja, sua parte da empresa.
          </SocietyPercentageInstructions>
          <RadioOptions
            receivedValue={values.hasPartnersWithOver25Percent}
            receivedSetter={(e) => { setFieldValue("hasPartnersWithOver25Percent", e) }}
            options={partnersWithOver25PercentageOptions}
            disabled={disabledForm}
          />
        </BusinessAccountDataContainer>
      }
    </BusinessAdditionalFieldsContainer>
  </>;
}
