import AdditionalDataDetails from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/AdditionalDataDetails';
import BusinessAdditionalFields from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/BusinessAdditionalFields';
import PersonalAdditionalDataFields from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/PersonalAdditionalDataFields';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import React from 'react';
import { AdditionalDataFormContainer } from './styles';

export default function AdditionalDataForm({
  values,
  errors,
  touched,
  isLoading = true,
  displayedName = "",
  identifier = "",
  isBusinessAccount,
  constitutionList = [],
  legalNatureList = [],
  bankOptionsList = [],
  accountTypesList = [],
  missingPendencies,
  isOnboardingStep = false,
  disabledForm = false,
  setFieldValue = () => { },
  showPartnersPercentageSection = false,
}) {
  return <>
    <AdditionalDataFormContainer>
      {
        isLoading ?
          <LoadingIndicator /> :
          <>
            <AdditionalDataDetails
              displayedName={displayedName}
              identifier={identifier}
              isBusinessAccount={isBusinessAccount}
            />
            {
              isBusinessAccount ?
                <BusinessAdditionalFields
                  values={values}
                  errors={errors}
                  touched={touched}
                  isBusinessAccount={isBusinessAccount}
                  constitutionList={constitutionList}
                  legalNatureList={legalNatureList}
                  bankOptionsList={bankOptionsList}
                  accountTypesList={accountTypesList}
                  missingPendencies={missingPendencies}
                  setFieldValue={setFieldValue}
                  disabledForm={disabledForm}
                  isOnboardingStep={isOnboardingStep}
                  showPartnersPercentageSection={showPartnersPercentageSection}
                /> :
                <PersonalAdditionalDataFields
                  values={values}
                  errors={errors}
                  touched={touched}
                  isBusinessAccount={isBusinessAccount}
                  bankOptionsList={bankOptionsList}
                  accountTypesList={accountTypesList}
                  missingPendencies={missingPendencies}
                  setFieldValue={setFieldValue}
                  disabledForm={disabledForm}
                  isOnboardingStep={isOnboardingStep}
                />
            }
          </>
      }
    </AdditionalDataFormContainer>
  </>;
}
