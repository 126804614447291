import { useCallback, useEffect, useState } from 'react';
import { object, string, array, date } from 'yup';
import { useRef } from 'react';
import { validateIdentifier } from 'utils/validateIdentifier';
import { fetchGetBusinessPartnersList } from 'services/BusinessPartners/GET/GetBusinessPartnersListService';
import { fetchDeleteBusinessPartner } from 'services/BusinessPartners/POST/DeleteBusinessPartnerService';
import { fetchCreateBusinessPartner } from 'services/BusinessPartners/POST/CreateBusinessPartnerService';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { parsePartners } from 'utils/parsePartners';
import { fetchUpdateBusinessPartner } from 'services/BusinessPartners/POST/UpdateBusinessPartnerService';
import { checkForUniqueValueOnKey } from 'utils/checkForUniqueValueOnKey';

const validateNameOnYup = () => {
  return {
    is: "true",
    then: string()
      .required('Este campo é obrigatório'),
  }
}

const validateCPFOnYup = () => {
  return {
    is: "true",
    then: string()
      .test('valid-identifier', 'O CPF informado é inválido', validateIdentifier)
      .required("Este campo é obrigatório"),
  }
}

const validateTelephoneOnYup = () => {
  return {
    is: "true",
    then: string()
      .min(14, 'O número de telefone informado deve possuir ao menos 14 dígitos')
      .max(15, 'O número de telefone informado deve possuir no máximo 15 dígitos')
      .required('Este campo é obrigatório'),
  }
}

const validateAddressOnYup = () => {
  return {
    is: "true",
    then: object()
      .shape({
        street: string()
          .required('Este campo é obrigatório'),
        number: string()
          .required('Este campo é obrigatório'),
        complement: string(),
        neighborhood: string()
          .required('Este campo é obrigatório'),
        city: string()
          .required('Este campo é obrigatório'),
        state: string()
          .required('Este campo é obrigatório'),
        zipCode: string()
          .min(9, 'O CEP informado é inválido')
          .required('Este campo é obrigatório'),
      })
  }
}

const validateMotherNameOnYup = () => {
  return {
    is: "true",
    then: string()
      .required('Este campo é obrigatório'),
  }
}

export default function usePartnersDataSetup({
  reloadAfterSubmit = false,
  afterSubmit = () => { },
}) {
  const mounted = useRef(false);
  const [isLoading, setLoading] = useState(true)
  const [initialValues, setInitialValues] = useState()
  const [oldValues, setOldValues] = useState({})
  const [validationSchema, setValidationSchema] = useState()
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [displayedName, setDisplayedName] = useState("")
  const [identifier, setIdentifier] = useState("")

  const fetchAccountData = useCallback(async () => {
    const fetchedPartnersList = await fetchGetBusinessPartnersList()
    const userData = await fetchGetAccountMyAccount()

    const validationObject = {
      partnersList: array()
        .of(
          object()
            .shape({
              isPFPartner: string()
                .required("Este campo é obrigatório"),
              socialName: string()
                .when(['isPFPartner'], {
                  is: "false",
                  then: string()
                    .required('Este campo é obrigatório'),
                }),
              CNPJ: string()
                .when(['isPFPartner'], {
                  is: "false",
                  then: string()
                    .test('valid-identifier', 'O CNPJ informado é inválido', validateIdentifier)
                    .required("Este campo é obrigatório"),
                }),
              name: string()
                .when(['isPFPartner'], validateNameOnYup()),
              CPF: string()
                .when(['isPFPartner'], validateCPFOnYup()),
              // telephone: string()
              //   .min(14, 'O número de telefone informado deve possuir ao menos 14 dígitos')
              //   .max(15, 'O número de telefone informado deve possuir no máximo 15 dígitos')
              //   .required('Este campo é obrigatório'),
              // address: object()
              //   .shape({
              //     street: string()
              //       .required('Este campo é obrigatório'),
              //     number: string()
              //       .required('Este campo é obrigatório'),
              //     complement: string(),
              //     neighborhood: string()
              //       .required('Este campo é obrigatório'),
              //     city: string()
              //       .required('Este campo é obrigatório'),
              //     state: string()
              //       .required('Este campo é obrigatório'),
              //     zipCode: string()
              //       .min(9, 'O CEP informado é inválido')
              //       .required('Este campo é obrigatório'),
              //   }),
              birthDate: date()
                .when(['isPFPartner'], {
                  is: "true",
                  then: date()
                    .nullable()
                    .typeError("Informe uma data válida")
                    .required('Este campo é obrigatório'),
                }).nullable(),
              isPPE: string()
                .when(['isPFPartner'], {
                  is: "true",
                  then: string()
                    .required('Este campo é obrigatório'),
                }),
              // hasPartnersWithOver25Percent: string()
              //   .when(['isPFPartner'], {
              //     is: "false",
              //     then: string()
              //       .required('Este campo é obrigatório'),
              //   }),
              // name: string()
              //   .when(['isPFPartner'], validateNameOnYup())
              //   .when(['hasPartnersWithOver25Percent'], validateNameOnYup()),
              // CPF: string()
              //   .when(['isPFPartner'], validateCPFOnYup())
              //   .when(['hasPartnersWithOver25Percent'], validateCPFOnYup()),
              // telephone: string()
              //   .when(['isPFPartner'], validateTelephoneOnYup())
              //   .when(['hasPartnersWithOver25Percent'], validateTelephoneOnYup()),
              // address: object()
              //   .when(['isPFPartner'], validateAddressOnYup())
              //   .when(['hasPartnersWithOver25Percent'], validateAddressOnYup()),
              telephone: string()
                .when(['isPFPartner'], validateTelephoneOnYup()),
              address: object()
                .when(['isPFPartner'], validateAddressOnYup()),
              motherName: string()
                .when(['isPFPartner'], validateMotherNameOnYup()),
            })
            .uniqueProperty('CPF', 'O CPF informado já está em uso por outro sócio', 'isPFPartner', "true")
            .uniqueProperty('CNPJ', 'O CNPJ informado já está em uso por outro sócio', 'isPFPartner', "false")
        )
        .min(1, 'O número de sócios informados deve ser ao menos um')
      ,
    }
    const initialValuesObject = {
      partnersList: parsePartners(fetchedPartnersList),
      excludedPartnersList: [],
    }

    if (mounted.current) {
      setDisplayedName(userData?.social_name)
      setIdentifier(userData?.cnpj)
      setOldValues(initialValuesObject)
      setInitialValues(initialValuesObject)
      setValidationSchema(object(validationObject))
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async ({ // TO-DO: Aprimorar o envio do formulário
    partnersList,
    excludedPartnersList,
  }) => {

    if (excludedPartnersList.length > 0) {
      for (const excludedPartner of excludedPartnersList) { // É a exclusão de um sócio
        await fetchDeleteBusinessPartner({
          partner_id: excludedPartner.partnerID
        })
      }
    }
    if (partnersList.filter((e) => e.isNewRegister).length > 0) { // É a criação de um novo sócio
      for (const partner of partnersList) {
        if (partner.isNewRegister) {
          const isPFPartner = JSON.parse(partner.isPFPartner)
          const partnerObject = {
            ...(isPFPartner && {
              name: partner.name,
              cpf: partner.CPF,
              birth_date: partner.birthDate,
              politically_exposed: partner.isPPE,
              phone: partner.telephone,
              phone_type_tag: "business",
              street: partner.address.street,
              number: partner.address.number,
              complement: partner.address.complement,
              neighborhood: partner.address.neighborhood,
              city: partner.address.city,
              state: partner.address.state,
              zipcode: partner.address.zipCode,
              mother_name: partner.motherName,
            }),
            ...(!isPFPartner && {
              name: partner.socialName,
              cnpj: partner.CNPJ,
            }),
            business_partner_kind_id: 1,
            profile: "OWNER",
            profile_type: "REGULAR",
          }
          await fetchCreateBusinessPartner(partnerObject)
        }
      }
    }
    if (JSON.stringify(partnersList) !== JSON.stringify(oldValues.partnersList)) { // É a atualização de um sócio
      for (const partner of partnersList) {
        if (!partner.isNewRegister) {
          const isPFPartner = JSON.parse(partner.isPFPartner)
          const partnerObject = {
            partner_id: partner.partnerID,
            ...(isPFPartner && {
              ...(checkForUniqueValueOnKey("CPF", partner, oldValues) && {
                cpf: partner.CPF,
              }),
              name: partner.name,
              birth_date: partner.birthDate,
              politically_exposed: partner.isPPE,
              phone: partner.telephone,
              phone_type_tag: "business",
              street: partner.address.street,
              number: partner.address.number,
              complement: partner.address.complement,
              neighborhood: partner.address.neighborhood,
              city: partner.address.city,
              state: partner.address.state,
              zipcode: partner.address.zipCode,
              mother_name: partner.motherName,
            }),
            ...(!isPFPartner && {
              ...(checkForUniqueValueOnKey("CNPJ", partner, oldValues) && {
                cnpj: partner.CNPJ,
              }),
              name: partner.socialName,
            }),
          }
          await fetchUpdateBusinessPartner(partnerObject)
        }
      }
    }

    // ###

    // if (JSON.stringify(partnersList) !== JSON.stringify(oldValues.partnersList) &&
    //   partnersList.length > 0
    // ) {
    //   // Deleta todos os sócios que já eram registrados
    //   for (const excludedPartner of oldValues.partnersList) {
    //     if (!excludedPartner.isNewRegister) {
    //       await fetchDeleteBusinessPartner({
    //         partner_id: excludedPartner.partnerID
    //       })
    //     }
    //   }

    //   // Cria todos os novos sócios e recria os sócios que já eram registrados (com um novo id)
    //   for (const partner of partnersList) {
    //     const isPFPartner = JSON.parse(partner.isPFPartner)
    //     const partnerObject = {
    //       name: isPFPartner ?
    //         partner.name :
    //         partner.socialName,
    //       cpf: partner.CPF,
    //       cnpj: partner.CNPJ,
    //       birth_date: partner.birthDate,
    //       politically_exposed: partner.isPPE,
    //       ...(isPFPartner && {
    //         phone: partner.telephone,
    //         phone_type_tag: "business",
    //         street: partner.address.street,
    //         number: partner.address.number,
    //         complement: partner.address.complement,
    //         neighborhood: partner.address.neighborhood,
    //         city: partner.address.city,
    //         state: partner.address.state,
    //         zipcode: partner.address.zipCode,
    //         mother_name: partner.motherName,
    //       }),
    //       business_partner_kind_id: 1,
    //       profile: "OWNER",
    //       profile_type: "REGULAR",
    //     }
    //     if (JSON.parse(partner.isPFPartner)) {
    //       delete partnerObject.cnpj
    //     } else {
    //       delete partnerObject.cpf
    //       delete partnerObject.politically_exposed
    //       delete partnerObject.birth_date
    //     }
    //     await fetchCreateBusinessPartner(partnerObject)
    //   }
    // }

    // ####

    // if (excludedPartnersList.length > 0) { // Delete partner
    //   for (const excludedPartner of excludedPartnersList) {
    //     await fetchDeleteBusinessPartner({
    //       partner_id: excludedPartner.partnerID
    //     })
    //   }
    // }

    // if (JSON.stringify(partnersList) !== JSON.stringify(oldValues.partnersList) &&
    //   partnersList.length > 0
    // ) {
    //   for (const partner of partnersList) {
    //     if (partner.isNewRegister) { // Create partner
    //       await fetchCreateBusinessPartner({
    //         ...partner,
    //         business_partner_kind_id: 1,
    //         profile: "OWNER",
    //         profile_type: "REGULAR",
    //       })
    //     } else { // Update partner
    //       const isCPFAlreadyInUse = (
    //         oldValues.partnersList.filter((e) => e.cpf === partner.cpf).length > 0 && // se esse CPF já existia
    //         excludedPartnersList.filter((e) => e.cpf === partner.cpf).length > 0 // e ele não está na lista de sócios excluídos
    //       )
    //       if (isCPFAlreadyInUse) {
    //         delete partner.cpf
    //       }
    //       await fetchUpdateBusinessPartner({})
    //     }
    //   }
    // }

    if (reloadAfterSubmit) {
      await fetchAccountData()
    }
    setSnackbarOpen(true)
    afterSubmit()
  }

  useEffect(() => {
    fetchAccountData()
  }, [fetchAccountData])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    isSnackbarOpen,
    setSnackbarOpen,
    displayedName,
    identifier,
  })
}