import React from 'react';
import { StickyHeaderContainer, StyledButton } from './styles';
import LandingVindiIcon from 'assets/icons/LandingVindiIcon';

export default function StickyHeader({
  handleInstallApp = () => { },
  disabled = false,
}) {
  return <>
    <StickyHeaderContainer>
      <div>
        <LandingVindiIcon />
        <StyledButton
          onClick={handleInstallApp}
          disabled={disabled}
          id={"landing-page-plugin_sticky_header_botao_instalar-app"}
        >
          Instale agora
        </StyledButton>
      </div>
    </StickyHeaderContainer>
  </>;
}
