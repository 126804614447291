import apiPrivate from 'services/apiPrivate'
import { parseDateToMilliseconds } from 'utils/parseDateToMilliseconds'
import { getDataStorage } from 'utils/storage'

const emptyObject = {
  status: {
    tag: "unsent"
  },
  created_at: null,
  status_changed_at: null,
  reason: null
}

const getLatestChangedAtDateElement = (receivedArray = []) => {
  if (receivedArray.length > 0) {
    let reducedRegister = receivedArray?.reduce((a, b) => {
      return parseDateToMilliseconds(a?.status_changed_at) > parseDateToMilliseconds(b?.status_changed_at) ? a : b
    })
    if (reducedRegister.status.tag === "approved") { // status "approved" mas com active false tem que reenviar
      return emptyObject
    } else {
      return reducedRegister
    }
  } else {
    return emptyObject
  }
}

const getFittestStatus = (statusList) => {
  return statusList?.find((e) => e.active) || getLatestChangedAtDateElement(statusList)
  // return emptyObject
}

export const fetchGetDocumentStatus = async (group) => {
  const code = getDataStorage('yapay_code')
  const token_id = getDataStorage('token_id')

  return apiPrivate.get("/document/status", {
    headers: {
      code,
      token_id,
      ...(!!group && { group }),
    }
  })
    .then(({ data }) => {
      if (!!group) {
        return getFittestStatus(data?.data)
      } else {
        return data?.data
      }
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}