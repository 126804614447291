import CheckCircleIcon from 'assets/icons/CheckCircleIcon';
import ErrorIcon from 'assets/icons/ErrorIcon';
import React from 'react';
import { grayScale, primaryColors } from 'theme/styleguide';
import { StepItemDisplayerContainer, StepDescription, StepItemContent, StepTitle, StyledButton, InnerContainer } from './styles';

export default function StepItemDisplayer({
  title = "",
  description = "",
  doneDescription = "Estes dados já foram informados.",
  buttonText = "Informar dados",
  buttonFunction = () => { },
  isCompleted = false,
  buttonId = "",
}) {
  return <>
    <StepItemDisplayerContainer>
      {
        isCompleted ?
          <CheckCircleIcon
            size={1.875}
            color={primaryColors.green_5}
          /> :
          <ErrorIcon
            size={0.88}
            color={grayScale.gray_60p100}
          />
      }
      <InnerContainer>
        {
          isCompleted ?
            <CheckCircleIcon
              size={1.875}
              color={primaryColors.green_5}
            /> :
            <ErrorIcon
              size={0.88}
              color={grayScale.gray_60p100}
            />
        }
        <StepItemContent>
          <StepTitle>
            {title}
          </StepTitle>
          <StepDescription>
            {
              isCompleted ?
                doneDescription :
                description
            }
          </StepDescription>
        </StepItemContent>
      </InnerContainer>
      {
        !isCompleted &&
        <StyledButton
          onClick={buttonFunction}
          {...!!buttonId && { id: buttonId }}
        >
          {buttonText}
        </StyledButton>
      }
    </StepItemDisplayerContainer>
  </>;
}
