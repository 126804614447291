import LoadingIndicator from 'components/shared/LoadingIndicator';
import React from 'react';
import { UserFacePictureUploadFormContainer, InstructionsContainer, InstructionsInnerLeftContainer, InstructionsList, InstructionsText, ImageContainer } from './styles';
import SelfieImage from 'assets/images/selfie.png'
import UserInfoDisplayer from 'components/shared/UserInfoDisplayer';
import FilePicker from 'components/shared/FilePicker';
import DocumentsRequiredAlert from 'components/Onboarding/OnboardingForms/AccountVerificationForms/shared/DocumentsRequiredAlert';

export default function UserFacePictureUploadForm({
  userData = {},
  isLoading = true,
  documentRequired = false,
  documentStatus = {},
  sendButtonText = "Continuar",
  setFieldValue = () => { },
}) {
  return <>
    <UserFacePictureUploadFormContainer>
      {
        isLoading ?
          <LoadingIndicator /> :
          <>
            <InstructionsContainer>
              <InstructionsInnerLeftContainer>
                <InstructionsText>
                  {`Envie uma foto do rosto (selfie) do(a) titular da conta.\n\nProcure locais claros para tirar a foto e não use chapéu e boné, nem óculos ou qualquer outro tipo de acessório que cubra parte do rosto.`}
                </InstructionsText>
                <UserInfoDisplayer
                  receivedName={userData?.name}
                  receivedIdentifier={userData?.cpf}
                />
                <InstructionsList>
                  <li>
                    {`Formatos de imagem aceitos: jpeg, png, gif, bmp e webp`}
                  </li>
                  <li>
                    {`Tamanho máximo do arquivo: 4,5 MB`}
                  </li>
                </InstructionsList>
                <FilePicker
                  fieldName={"selfiePhoto"}
                  setFieldValue={setFieldValue}
                  title="Adicionar foto"
                  disabled={!documentRequired}
                />
              </InstructionsInnerLeftContainer>
              <ImageContainer>
                <img
                  src={SelfieImage}
                  alt="Foto do rosto"
                />
              </ImageContainer>
            </InstructionsContainer>
            <DocumentsRequiredAlert
              documentStatus={documentStatus}
              sendButtonText={sendButtonText}
            />
          </>
      }
    </UserFacePictureUploadFormContainer>
  </>;
}
