import styled from "styled-components";
import { Paper, Select } from "@material-ui/core";
import { grayScale, shadowStyles, textStyles } from "theme/styleguide";

export const BoletoFormContainer = styled.div`
  
`
export const StyledSelect = styled(Select)`
  width: 151px;
  margin-right: 16px;
  ${textStyles.body_1_14px}
  .MuiSelect-select:focus{
    background: transparent !important;
  }
  .MuiOutlinedInput-input {
    padding: 9px 14px;
  }
  @media (min-width: 768px)
  {
    width: 160px;
  }
`
export const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
`
export const SelectLabel = styled.span`
  display: block;
  ${textStyles.body_1_14px}
`
export const WarningInfo = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-bottom: 32px;
  @media (min-width: 768px)
  {
    margin-bottom: 56px;
  }
`
export const Instructions = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  white-space: pre-wrap;
`
export const InstructionsContainer = styled.div`
@media (min-width: 768px)
  {
    width: 100%;
    max-width: 616px;
    margin-right: 16px;
  }
`
export const InstructionsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`
export const ImageCard = styled(Paper)`
  display: none;
  ${shadowStyles.drop_shadow_2}
  background: ${grayScale.white};
  border-radius: 16px;
  padding: 8px;
  //margin-left: 10%;
  img{
    max-width: 304px;
  }
  @media (min-width: 992px)
  {
    display: initial;
    min-height: 184px;
    min-width: 320px;
    margin: 0 auto;
  }
`