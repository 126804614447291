import apiPublic from 'services/apiPublic'

export const fetchSearchCep = async (cep) => {
  return apiPublic.get("/myaccount/address/cep", {
    params: {
      cep,
    }
  })
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}