/* eslint-disable max-len */
import React from 'react';

export default function ArrowBackIcon({ size = 1, color = "#546E7A" }) {
  return <>
    <i>
      <svg
        width={30 * size}
        height={30 * size}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M25 13.75H9.7875L16.775 6.7625L15 5L5 15L15 25L16.7625 23.2375L9.7875 16.25H25V13.75Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
