import React from 'react';
import { Form, Formik } from 'formik';
import SnackbarAlert from 'components/shared/SnackbarAlert';
import LoadableButton from 'components/shared/LoadableButton';
import { StyledButtonsContainer } from 'components/Dashboard/AccountDataMenu/styles';
import { Button, Collapse } from '@material-ui/core';
import { UserNameFormContainer } from './styles';
import UserNameFields from 'fields/Dashboard/MyAaccountTab/UserDataMenu/shared/UserNameFields/UserNameFields';
import useUserNameDataSetup from 'setup/Dashboard/MyAaccountTab/UserDataMenu/shared/useUserNameDataSetup';

export default function UserNameForm() {
  const { initialValues, validationSchema, isLoading, handleSubmit, isSnackbarOpen, setSnackbarOpen, isDisabled, toggleDisabled } = useUserNameDataSetup({
    afterSubmit: () => {
      toggleDisabled()
    },
    reloadAfterSubmit: true,
  })
  const handleCancel = (formikProps) => {
    formikProps.resetForm()
    toggleDisabled()
  }
  return <>
    <UserNameFormContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <UserNameFields
                {...formikProps}
                isLoading={isLoading}
                disabledForm={isDisabled}
                toggleDisabled={toggleDisabled}
              />
              <Collapse
                in={!isDisabled}
              >
                <StyledButtonsContainer>
                  <LoadableButton
                    type="submit"
                    disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                    isLoading={formikProps.isSubmitting}
                  >
                    Salvar
                  </LoadableButton>
                  <Button
                    variant="outlined"
                    onClick={() => { handleCancel(formikProps) }}
                    disabled={formikProps.isSubmitting || isLoading}
                  >
                    Cancelar
                  </Button>
                </StyledButtonsContainer>
              </Collapse>
            </Form>
          )
        }
      </Formik>
      <SnackbarAlert
        isOpen={isSnackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        message="Salvo"
      />
    </UserNameFormContainer>
  </>;
}