import { Switch, withStyles } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, primaryColors, textStyles } from "theme/styleguide";

export const SwitchableOptionContainer = styled.div`
  width: 100%;
  max-width: 586px;
  margin-bottom: ${({ $hasDescription }) => $hasDescription ? "64px" : "16px"};
  @media (min-width: 768px)
  {
    margin-bottom: ${({ $hasDescription }) => $hasDescription ? "80px" : "16px"};
  }
`
export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`
export const LabelText = styled.span`
  ${textStyles.tray_h5_15px}
  color: ${grayScale.gray_3};
`
export const StyledSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: primaryColors.green_3,
    },
    '&$checked + $track': {
      backgroundColor: "#00000044",
    },
  },
  checked: {},
  track: {},
})(Switch)

export const Description = styled.span`
  display: block;
  width: 100%;
  max-width: 478px;
  ${({ $descriptionStyle }) =>
    $descriptionStyle ?
      $descriptionStyle :
      textStyles.body_1_14px
  }
`