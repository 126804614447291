import React from 'react';
import { Form, Formik } from 'formik';
import SnackbarAlert from 'components/shared/SnackbarAlert';
import { PartnersFormContainer } from './styles';
import PartnersFields from 'fields/Dashboard/MyAccountTab/AccountDataMenu/BusinessAccount/PartnersFields/PartnersFields';
import usePartnersDataSetup from 'setup/Dashboard/MyAccountTab/AccountDataMenu/BusinessAccount/usePartnersDataSetup';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';

export default function PartnersForm() {
  const { toggleAccountDataMenuFormsDisabled } = useDashboardMyAccount()
  const { initialValues, oldValues, validationSchema, isLoading, handleSubmit, isSnackbarOpen, setSnackbarOpen } = usePartnersDataSetup({
    afterSubmit: () => {
      toggleAccountDataMenuFormsDisabled()
    },
    reloadAfterSubmit: true,
  })
  return <>
    <PartnersFormContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <PartnersFields
                {...formikProps}
                isLoading={isLoading}
                oldValues={oldValues}
                handleSubmit={handleSubmit}
              />
            </Form>
          )
        }
      </Formik>
      <SnackbarAlert
        isOpen={isSnackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        message="Salvo"
      />
    </PartnersFormContainer>
  </>;
}