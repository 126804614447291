/* eslint-disable max-len */
import React from 'react';

export default function WatchLaterIcon({ size = 1, color = "#2E7EE7" }) {
  return <>
    <i>
      <svg
        width={30 * size}
        height={30 * size}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 2.5C8.125 2.5 2.5 8.125 2.5 15C2.5 21.875 8.125 27.5 15 27.5C21.875 27.5 27.5 21.875 27.5 15C27.5 8.125 21.875 2.5 15 2.5ZM15 25C9.4875 25 5 20.5125 5 15C5 9.4875 9.4875 5 15 5C20.5125 5 25 9.4875 25 15C25 20.5125 20.5125 25 15 25ZM15.625 8.75H13.75V16.25L20.25 20.25L21.25 18.625L15.625 15.25V8.75Z"
          fill="#2E7EE7"
        />
      </svg>
    </i>
  </>;
}
