import apiPrivate from 'services/apiPrivate'
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService'
import { getDataStorage } from 'utils/storage'

export const fetchUploadBankAccount = async (file) => {
  const code = getDataStorage('yapay_code')
  const token_id = getDataStorage('token_id') || await fetchGetAccountMyAccount()
  const formData = new FormData()
  formData.append("file", file);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      code,
      token_id
    }
  }
  return apiPrivate.post("/document/bank_account ", formData, config)
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}