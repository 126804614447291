import { Button } from "@material-ui/core";
import { StyledTextField } from "components/Onboarding/OnboardingForms/AccountDataForms/TitularDataForm/styles";
import styled from "styled-components";
import { buttonStyles } from "theme/styleguide";

export const TelephoneContactFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1024px)
  {
    align-items: flex-start;
  }
`
export const TelephoneContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 24px;
  padding-right: ${({ $hideContainerPadding }) => $hideContainerPadding ? "0px" : "20px"};
  >div{
    
  }
  @media (min-width: 768px)
  {
    >button{
      margin-right: 20px;
    }
  }
  @media (min-width: 1024px)
  {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 0px;
    >button{
      margin-right: 0px;
    }
  }
`
export const InnerStyledTextField = styled(StyledTextField)`
  max-width: 100%;
  margin-bottom: 0px;
  @media (min-width: 1024px)
  {
    max-width: ${({ $fullWidth }) => $fullWidth ? "100%" : "50%"};
    margin-bottom: 8px;
    padding-right: ${({ $hidePaddingRight }) => $hidePaddingRight ? "0px" : "20px"};
  }
`
export const StyledButton = styled(Button)`
  ${buttonStyles.yapay_bluish_add}
  margin-bottom: 32px;
`