/* eslint-disable max-len */
import React from 'react';

export default function ReportProblem({ size = 1, color = "#1F1F1F" }) {
  return <>
    <i>
      <svg
        width={size * 24}
        height={size * 24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 5.99L19.53 19H4.47L12 5.99ZM12 2L1 21H23L12 2ZM13 16H11V18H13V16ZM13 10H11V14H13V10Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
