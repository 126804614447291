import apiPrivate from 'services/apiPrivate'
import { getStoredCredential } from 'utils/getStoredCredential'

export const fetchGetLastTransactionService = async (initial_date, final_date) => {

  return apiPrivate.post("/reports/last_transactions",
    {
      initial_date,
      final_date
    },
    {
      headers: {
        ...getStoredCredential(),
      },
    })
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}