export const formatToBRL = (
  receivedMoney = 0,
  hideCurrencySymbol = false,
) => {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: "BRL"
  }
  !hideCurrencySymbol &&
    Object.assign(options, { style: "currency" });
  return new Intl.NumberFormat("pt-BR", {
    ...options
  }).format(receivedMoney)
}