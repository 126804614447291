import { Button, IconButton } from '@material-ui/core';
import ArrowBackIcon from 'assets/icons/ArrowBackIcon';
import { StyledButtonsContainer, StyledDashboardTitle, TitleContainer } from 'components/Dashboard/DashboardMyAccount/styles';
import usePhoneSMSVerificationSetup from 'components/FormsSetup/AccountData/usePhoneSMSVerificationSetup';
import SMSPhoneVerificationForm from 'components/Onboarding/OnboardingForms/AccountDataForms/SMSPhoneVerificationForm';
import LoadableButton from 'components/shared/LoadableButton';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import { Form, Formik } from 'formik';
import React from 'react';
import { PhoneSmsVerificationMenuItemContainer } from './styles';

export default function PhoneSmsVerificationMenuItem() {
  const { setCurrentMyAccountMenuItem, goBackToUserDataMenu, setSnackbarOpen, setSnackbarMessage } = useDashboardMyAccount()
  const { initialValues, validationSchema, isLoading, handleSubmit } = usePhoneSMSVerificationSetup({
    afterSubmit: () => {
      goBackToUserDataMenu()
      setSnackbarOpen(true)
      setSnackbarMessage("Celular verificado alterado")
    },
    errorSubmit: () => {
      setCurrentMyAccountMenuItem("verifiedPhoneEdit")
    },
  })
  const handleGoBack = () => {
    setCurrentMyAccountMenuItem("verifiedPhoneEdit")
  }
  return <>
    <PhoneSmsVerificationMenuItemContainer>
      <TitleContainer>
        <IconButton
          size="small"
          onClick={handleGoBack}
        >
          <ArrowBackIcon />
        </IconButton>
        <StyledDashboardTitle>
          Celular verificado
        </StyledDashboardTitle>
      </TitleContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <SMSPhoneVerificationForm
                {...formikProps}
                isLoading={isLoading}
                initialValues={initialValues}
                isOnboarding={true}
              />
              <StyledButtonsContainer>
                <LoadableButton
                  type="submit"
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  isLoading={formikProps.isSubmitting}
                >
                  Continuar
                </LoadableButton>
                <Button
                  variant="outlined"
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  onClick={handleGoBack}
                >
                  Cancelar
                </Button>
              </StyledButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </PhoneSmsVerificationMenuItemContainer>
  </>;
}
