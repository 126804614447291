/* eslint-disable max-len */
import React from 'react';

export default function ErrorIcon({ size = 1, color = "#FFC300" }) {
  return <>
    <i>
      <svg
        width={34 * size}
        height={34 * size}
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17 0.333332C7.80004 0.333332 0.333374 7.8 0.333374 17C0.333374 26.2 7.80004 33.6667 17 33.6667C26.2 33.6667 33.6667 26.2 33.6667 17C33.6667 7.8 26.2 0.333332 17 0.333332ZM18.6667 25.3333H15.3334V22H18.6667V25.3333ZM18.6667 18.6667H15.3334V8.66667H18.6667V18.6667Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
