import React, { Fragment, useEffect, useRef } from 'react';
import { DesktopDashboardHeaderContainer, PortalButton, StyledButton, StyledMenu, StyledMenuItem } from './styles';
import { dashboardTabsDictionary } from 'library/dashboardTabsDictionary';
import { getDataStorage } from 'utils/storage';
import { useState } from 'react';
import { useDashboard } from 'context/DashboardContext';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
export default function DesktopDashboardHeader({
  showAccountDataMenu = false,
  showAccountVerificationMenu = false,
  handleOpenPortal = () => { },
  handleGAEventTracker = () => { },
}) {
  const { currentTab, setCurrentTab } = useDashboard()
  const { setCurrentMyAccountMenuItem } = useDashboardMyAccount()
  const isMainUser = getDataStorage("main_user")
  const [anchorEl, setAnchorEl] = useState(null)
  const mounted = useRef(false)
  const open = Boolean(anchorEl)

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleSelectMenuItem = ({ event, tabKey }) => {
    handleGAEventTracker(tabKey)
    if (dashboardTabsDictionary[tabKey]?.subMenuTabs) {
      handleOpenMenu(event)
    } else {
      setCurrentTab(tabKey)
    }
  }

  const handleSelectSubMenuItem = ({ subMenuTabName, tabKey }) => {
    handleGAEventTracker(subMenuTabName)
    setCurrentTab(tabKey)
    setCurrentMyAccountMenuItem(subMenuTabName)
    handleCloseMenu()
  }

  window.onscroll = () => {
    if (mounted.current) {
      if (open) {
        const lastPageYOffset = window.pageYOffset
        handleCloseMenu()
        window.scrollTo({
          top: lastPageYOffset,
        })
      }
    }
  }

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  return <DesktopDashboardHeaderContainer>
    {
      Object.keys(dashboardTabsDictionary).map((tabKey, tabIndex) => {
        return (
          <Fragment
            key={tabIndex}
          >
            {
              (isMainUser || !dashboardTabsDictionary?.[tabKey]?.isMainUserOnlyTab) &&
              <StyledButton
                onClick={(event) => { handleSelectMenuItem({ event, tabKey }) }}
                id={dashboardTabsDictionary[tabKey].id}
                // onMouseOver={(event) => {
                //   if (dashboardTabsDictionary[tabKey]?.subMenuTabs) {
                //     handleOpenMenu(event)
                //   }
                // }}
                variant="text"
                $isSelected={tabKey === currentTab}
                disableRipple
                key={tabIndex}
                aria-controls={open ? `${dashboardTabsDictionary[tabKey].id}-positioned-menu` : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                {dashboardTabsDictionary[tabKey].title}
              </StyledButton>
            }
            <StyledMenu
              id={`${dashboardTabsDictionary[tabKey].id}-positioned-menu`}
              aria-labelledby={`${dashboardTabsDictionary[tabKey].id}-positioned-button`}
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              // MenuListProps={{
              //   onMouseLeave: handleCloseMenu
              // }}
              getContentAnchorEl={null}
              disableScrollLock
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {
                dashboardTabsDictionary[tabKey]?.subMenuTabs &&
                Object.keys(dashboardTabsDictionary[tabKey]?.subMenuTabs)?.map((subMenuTabName, subMenuTabIndex) => {
                  const shouldShowSubMenuTab = (
                    (subMenuTabName !== "accountDataMenu" || showAccountDataMenu) &&
                    (subMenuTabName !== "accountVerificationMenu" || showAccountVerificationMenu)
                  )
                  if (shouldShowSubMenuTab) {
                    return (
                      <StyledMenuItem
                        onClick={() => { handleSelectSubMenuItem({ subMenuTabName, tabKey }) }}
                        id={dashboardTabsDictionary[tabKey]?.subMenuTabs[subMenuTabName].id}
                        key={subMenuTabIndex}
                      >
                        {dashboardTabsDictionary[tabKey]?.subMenuTabs[subMenuTabName].title}
                      </StyledMenuItem>
                    )
                  } else {
                    return null
                  }
                })
              }
            </StyledMenu>
          </Fragment>
        )
      })
    }
    <PortalButton
      onClick={handleOpenPortal}
      id={"menu_superior_botao_portal-vindi"}
    >
      Portal Vindi
    </PortalButton>
  </DesktopDashboardHeaderContainer>;
}
