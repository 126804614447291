import { useCallback, useEffect, useState } from 'react';
import { object, string } from 'yup';
import { useRef } from 'react';
import { parseDataURLtoFile } from 'utils/parseDataURLtoFile';
import { fetchUploadBankAccount } from 'services/Document/POST/UploadBankAccountService';
import { fetchGetDocumentStatus } from 'services/Document/GET/GetDocumentStatusService';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';

export default function useBankAccountProofingUploadSetup({
  afterSubmit = () => { }
}) {
  const mounted = useRef(false);
  const [isLoading, setLoading] = useState(true)
  const [documentRequired, setDocumentRequired] = useState(false)
  const [documentStatus, setDocumentStatus] = useState()

  const initialValues = useState({
    proofPhoto: ""
  })
  const validationSchema = object({
    proofPhoto: string().required()
  })

  const getDocumentStatus = useCallback(async () => {
    const accountData = await fetchGetAccountMyAccount()
    if (accountData?.automatic_verifications) {
      setDocumentRequired(false);
      setDocumentStatus({ status: { tag: "automatic_verifications" } })
    } else {
      fetchGetDocumentStatus("bank_account")
        .then(async (data) => {
          if (mounted.current) {
            setDocumentStatus(data)
            if (["pending", "approved"].includes(data?.status?.tag)) {
              setDocumentRequired(false);
            } else {
              setDocumentRequired(true)
            }
          }
        })
    }
    if (mounted.current) {
      setLoading(false)
    }
  }, [])

  const handleSubmit = async ({
    proofPhoto
  }) => {
    if (proofPhoto) {
      const fileProofPhoto = await parseDataURLtoFile(proofPhoto, "bankAccount")
      await fetchUploadBankAccount(fileProofPhoto)
        .then((res) => {
          if (res?.status === 200) {
            afterSubmit()
          }
        })
    } else {
      afterSubmit()
    }
  }

  useEffect(() => {
    getDocumentStatus()
  }, [getDocumentStatus])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    documentRequired,
    documentStatus,
  })
}