export const createEmptyPartner = () => {
  return {
    isPFPartner: String(true),
    name: "",
    CPF: "",
    birthDate: null,
    telephone: "",
    isPPE: String(false),
    address: {
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
      zipCode: "",
    },
    motherName: "",
    socialName: "",
    CNPJ: "",
    hasPartnersWithOver25Percent: String(false),
    isNewRegister: true,
    disabledForm: false,
    isLoading: false,
  }
}