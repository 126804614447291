import YapayIcon from 'assets/icons/YapayIcon';
import { useOnboarding } from 'context/OnboardingContext';
import React from 'react';
import useWindowWidth from 'utils/useWindowWidth';
import { OnboardingHeaderContainer, StyledStepper, StyledStep, StyledStepLabel } from './styles';
import { grayScale, primaryColors } from 'theme/styleguide';
import { getDataStorage } from 'utils/storage';
import { onboardingVariantsDictionary } from 'library/onboardingVariantsDictionary';
import { onboardingSectionsDictionary } from 'library/onboardingSectionsDictionary';

export default function OnboardingHeader() {
  const { onboardingSectionIndex, lastCompletedOnboardingSection } = useOnboarding()
  const windowWidth = useWindowWidth()
  const onboardingVariant = getDataStorage("onboardingVariant") || "onboardingDVariant"

  const getIconSize = () => {
    return windowWidth < 768 ? 0.678 : 1.26
  }
  return <>
    <OnboardingHeaderContainer>
      {
        windowWidth &&
        <YapayIcon size={getIconSize()} />
      }
      <StyledStepper
        activeStep={onboardingSectionIndex}
      >
        {
          onboardingVariantsDictionary?.[onboardingVariant]?.sections.map((e, i) => {
            const isCompleted = i <= lastCompletedOnboardingSection
            return (
              !onboardingSectionsDictionary[e].hideOnHeader &&
              <StyledStep
                key={i}
                completed={isCompleted}
              >
                <StyledStepLabel
                  $isCompleted={isCompleted}
                  icon={
                    onboardingSectionsDictionary[e].headerIcon({
                      color: isCompleted ?
                        primaryColors.blue_5 :
                        grayScale.gray_20p100
                    })
                  }
                >
                  {onboardingSectionsDictionary[e].headerTitle}
                </StyledStepLabel>
              </StyledStep>
            )
          })
        }
      </StyledStepper>
    </OnboardingHeaderContainer>
  </>;
}
