import { useCallback, useEffect, useState } from 'react';
import { object, string } from 'yup';
import { useRef } from 'react';
import { useAccountData } from 'context/AccountDataContext';
import { fetchUpdateSigninProfiles } from 'services/Account/POST/UpdateSigninProfilesService';
import { formatPhone } from 'utils/formatPhone';
import { fetchGetSigninUser } from 'services/Signin/GET/GetSigninUserService';

export default function usePhoneDataSetup({
  afterSubmit = () => { },
  afterSubmitSkipSMS = () => { }
}) {
  const mounted = useRef(false);
  const [isLoading, setLoading] = useState(true)
  const [oldPhone, setOldPhone] = useState("")
  const [phoneValidated, setPhoneValidated] = useState(false)
  const { setMessageIdSMSPhone, setNewPhone } = useAccountData()
  const [initialValues, setInitialValues] = useState({
    phone: '',
  })
  const validationSchema = object({
    phone: string()
      .min(14, "O número de celular informado é inválido")
      .required("O número de celular informado é inválido"),
  })

  const fetchAccountData = useCallback(async () => {
    const { phone_validated, phone } = await fetchGetSigninUser()
    if (mounted.current) {
      setPhoneValidated(phone_validated)
      setOldPhone(formatPhone(phone))
      setInitialValues({
        phone: formatPhone(phone),
      })
    }
    setLoading(false)
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async ({
    phone
  }) => {
    if (phone === oldPhone && phoneValidated) {
      afterSubmitSkipSMS()
    } else {
      await fetchUpdateSigninProfiles({
        phone,
      })
        .then(({ data }) => {
          setNewPhone(phone)
          setMessageIdSMSPhone(data.message_id)
          afterSubmit()
        })
    }
  }

  useEffect(() => {
    fetchAccountData()
  }, [fetchAccountData])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
  })
}