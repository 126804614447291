import { Button } from '@material-ui/core';
import useUserFacePictureUploadSetup from 'components/FormsSetup/AccountVerification/useUserFacePictureUploadSetup';
import { SameSizeButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import UserFacePictureUploadForm from 'components/Onboarding/OnboardingForms/AccountVerificationForms/UserFacePictureUploadForm';
import LoadableButton from 'components/shared/LoadableButton';
import { useOnboarding } from 'context/OnboardingContext';
import { Form, Formik } from 'formik';
import React from 'react';
import { getCurrentOnboardingStepsCollection } from 'utils/getCurrentOnboardingStepsCollection';
import { UserFacePictureUploadStepContainer } from './styles';

export default function UserFacePictureUploadStep() {
  const { accountVerificationStep, nextAccountVerificationStep, nextOnboardingSection } = useOnboarding()
  const { initialValues, validationSchema, isLoading, handleSubmit, userData, documentRequired, documentStatus } = useUserFacePictureUploadSetup({
    afterSubmit: () => {
      const stepsCollectionLength = getCurrentOnboardingStepsCollection("documentsSendingSteps").length - 1
      if (accountVerificationStep < stepsCollectionLength) {
        nextAccountVerificationStep()
      } else {
        nextOnboardingSection()
      }
    }
  })
  return <>
    <UserFacePictureUploadStepContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={documentRequired && validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <UserFacePictureUploadForm
                userData={userData}
                isLoading={isLoading}
                documentStatus={documentStatus}
                documentRequired={documentRequired}
                {...formikProps}
              />
              <SameSizeButtonsContainer>
                <LoadableButton
                  type="submit"
                  disabled={(documentRequired && !formikProps.isValid) || (formikProps.isSubmitting || isLoading)}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_envio_selfie_onboarding_botao_enviar"}
                >
                  Continuar
                </LoadableButton>
                <Button
                  variant="outlined"
                  disabled={formikProps.isSubmitting || !documentRequired || isLoading}
                  onClick={nextAccountVerificationStep}
                  id={"tela_envio_selfie_onboarding_botao_enviar_depois"}
                >
                  Enviar depois
                </Button>
              </SameSizeButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </UserFacePictureUploadStepContainer>
  </>;
}
