import React from 'react';
import { Redirect } from 'react-router-dom';
import { getDataStorage } from 'utils/storage';

export default function RedirectToLogin() {
  const access_token = getDataStorage('access_token');
  return <>
    {
      access_token === null &&
      <Redirect to="/login" />
    }
  </>;
}
