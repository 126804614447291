/* eslint-disable max-len */
import React from 'react';

export default function LandingDollarSignIcon({ size = 1, color = "#00A3FF" }) {
  return <>
    <i>
      <svg
        width={64 * size}
        height={64 * size}
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M31.9268 4V59"
          stroke={color}
          strokeWidth="6.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M44.3659 14.3125H25.7073C23.398 14.3125 21.1833 15.2179 19.5503 16.8296C17.9174 18.4412 17 20.627 17 22.9062C17 25.1855 17.9174 27.3713 19.5503 28.9829C21.1833 30.5946 23.398 31.5 25.7073 31.5H38.1464C40.4557 31.5 42.6705 32.4054 44.3034 34.0171C45.9364 35.6287 46.8537 37.8145 46.8537 40.0937C46.8537 42.373 45.9364 44.5588 44.3034 46.1704C42.6705 47.7821 40.4557 48.6875 38.1464 48.6875H17"
          stroke={color}
          strokeWidth="6.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </i>
  </>;
}
