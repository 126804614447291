import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { fetchSearch } from 'services/StoreKeys/POST/SearchService';
import { checkCookie, getDataStorage, setDataStorage } from 'utils/storage';
import { useQuery } from 'utils/useQuery';
import { useRef } from 'react';
import { GAMapRoutes } from 'utils/GAMapRoutes';
import StickyHeader from 'components/LandingPage/StickyHeader';
import FirstFold from 'components/LandingPage/FirstFold';
import SecondFold from 'components/LandingPage/SecondFold';
import ThirdFold from 'components/LandingPage/ThirdFold';
import { fetchStore } from 'services/StoreKeys/POST/StoreService';
import StickyAlert from 'components/LandingPage/StickyAlert/StickyAlert';

export default function LandingPage() {
  GAMapRoutes()
  const query = useQuery();
  const history = useHistory()
  const mounted = useRef(false);
  const [loading, setLoading] = useState(true)
  const [cookieRequired, setCookieRequired] = useState(false)

  const handleInstallApp = async () => {
    if (!cookieRequired) {
      await fetchSearch()
        .then(async (response) => {
          const storeHost = getDataStorage('store_host')
          const storeID = getDataStorage('store_id')
          const storePlanID = getDataStorage('store_plan_id')
          const adminUser = getDataStorage('store_admin_user')
          const callbackLoginURL = `/login?store=${storeID}&adm_user=${adminUser}&store_plan_id=${storePlanID}&url=${storeHost}`
          if (typeof (response?.onboarding_step) !== "number") {
            fetchStore()
              .then(({ success }) => {
                if (success) {
                  history.replace(callbackLoginURL)
                }
              })
          } else {
            history.replace(callbackLoginURL)
          }
        })
    }
  }

  const getQueryParams = useCallback(async () => {
    if (!checkCookie()) {
      setCookieRequired(true)
    } else {
      setCookieRequired(false)
      const storeHost = query.get("store_host") || query.get("url")
      const storeID = query.get("store")
      const storePlanID = query.get("store_plan_id")
      const adminUser = query.get("adm_user")
      if (storeID && adminUser) { // precisa ser adm
        setDataStorage('store_host', decodeURI(storeHost))
        setDataStorage('store_id', storeID)
        setDataStorage('store_plan_id', storePlanID)
        setDataStorage('store_admin_user', adminUser)
        const res = await fetchSearch(storeID, adminUser)
        if (mounted.current) {
          if (res?.length !== 0) {
            const callbackLoginURL = `/login?store=${storeID}&adm_user=${adminUser}&store_plan_id=${storePlanID}&url=${storeHost}`
            history.replace(callbackLoginURL)
          }
        }
      } else {
        history.replace("/login")
      }
    }
    setLoading(false);
  }, [query, history])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  useEffect(() => {
    getQueryParams()
  })
  return <>
    {
      !loading &&
      <>
        <StickyAlert
          isOpen={cookieRequired}
        />
        <StickyHeader
          handleInstallApp={handleInstallApp}
          disabled={cookieRequired}
        />
        <FirstFold
          handleInstallApp={handleInstallApp}
          disabled={cookieRequired}
        />
        <SecondFold
          handleInstallApp={handleInstallApp}
          disabled={cookieRequired}
        />
        <ThirdFold
          handleInstallApp={handleInstallApp}
          disabled={cookieRequired}
        />
      </>
    }
  </>;
}
