import React from 'react';
import { ThirdFoldContainer, BenefitDescription, BenefitItem, BenefitTitle, Description, StyledButton, Subtitle, Title, LeftContent, MainContent, RightContent } from './styles';
import LandingSquareCheckIcon from 'assets/icons/LandingSquareCheckIcon';
import LandingCheckIcon from 'assets/icons/LandingCheckIcon';

const benefitsList = [
  {
    description: "Parcelamento e antecipação de recebíveis",
  },
  {
    description: "Checkout transparente e compra com um click",
  },
  {
    description: "Recursos para melhorar a performance das suas vendas",
  },
  {
    description: "Pague sua mensalidade, compre temas de loja e etiquetas com seu saldo da plataforma",
  },
]
export default function ThirdFold({
  handleInstallApp = () => { },
  disabled = false,
}) {
  return <>
    <ThirdFoldContainer>
      <div>
        <Title>
          A Vindi é a solução de pagamentos nativa da Tray!
        </Title>
        <Subtitle>
          O que significa que você não precisa criar uma conta separada, é só baixar e começar a usar
        </Subtitle>
        <MainContent>
          <LeftContent>
            <LandingSquareCheckIcon />
            <Description>
              A Vindi tem um ecossistema completo de soluções para receber suas vendas online, planos e assinaturas e também vendas físicas. Com soluções que ajudam seu negócio a vender mais e receber pagamentos de forma mais assertiva e eficiente.
            </Description>
          </LeftContent>
          <RightContent>
            <BenefitTitle>
              Na sua solução de pagamentos nativa da Tray você tem ainda:
            </BenefitTitle>
            {
              benefitsList.map((e, i) => {
                return (
                  <BenefitItem
                    key={i}
                  >
                    <LandingCheckIcon />
                    <BenefitDescription>
                      {e.description}
                    </BenefitDescription>
                  </BenefitItem>
                )
              })
            }
          </RightContent>
        </MainContent>
        <StyledButton
          onClick={handleInstallApp}
          disabled={disabled}
          id={"landing-page-plugin_botao_instale-e-comece-a-usar-2"}
        >
          Instale e comece a usar
        </StyledButton>
      </div>
    </ThirdFoldContainer>
  </>;
}
