import AlertDialog from 'components/shared/AlertDialog';
import { useOnboarding } from 'context/OnboardingContext';
import React from 'react';
import { AlertCustomContent, AlertTitle } from './styles';

export default function CompleteLaterDialog({
  completeNowFunction = () => { },
  completeLaterFunction = () => { },
  isFromDashboard = false,
}) {
  const { isCompleteLaterAlertOpen, toggleCompleteLaterAlert } = useOnboarding()

  const handleCompleteLater = () => {
    toggleCompleteLaterAlert()
    completeLaterFunction()
  }
  const handleCompleteNow = () => {
    toggleCompleteLaterAlert()
    completeNowFunction()
  }
  return <>
    <AlertDialog
      open={isCompleteLaterAlertOpen}
      hasLargerMinWidthButtons
      buttonsProps={[
        {
          title: "Completar depois",
          function: handleCompleteLater
        },
        {
          title: "Completar cadastro agora",
          function: handleCompleteNow
        }
      ]}
      customContent={
        <AlertCustomContent>
          <AlertTitle>
            {`Tem certeza que deseja completar seu cadastro depois?\n\n`}
          </AlertTitle>
          {
            isFromDashboard ?
              `Os recursos da conta Vindi serão liberados somente após completar o cadastro e enviar alguns documentos para verificação.` :
              `Os dados que você já informou neste formulário não serão salvos e todos os recursos da conta Vindi serão liberados somente após completar o cadastro e enviar alguns documentos para verificação.`}
        </AlertCustomContent>
      }
    />
  </>;
}
