import React from 'react';
import { DetailedStepperContainer, Column, StepIndex, StepSubtitle, StepTitle } from './styles';

export default function DetailedStepper({
  title = "",
  currentStep = 0,
  totalSteps = 0,
  hideSubtitle = false,
}) {
  return <>
    {
      title.length > 0 &&
      <DetailedStepperContainer>
        <StepIndex>
          {currentStep}
        </StepIndex>
        <Column>
          <StepTitle>
            {title}
          </StepTitle>
          {
            !hideSubtitle &&
            <StepSubtitle>
              Etapa {currentStep} de {totalSteps}
            </StepSubtitle>
          }
        </Column>
      </DetailedStepperContainer>
    }
  </>;
}
