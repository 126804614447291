import React from 'react';
import { IconCard, QrCodeAlertContainer, BodyText, Title, ContainerFlex, Container } from './styles';
import QrCode from 'assets/icons/QrCode';

export default function QrCodeAlert() {
  return <>
    <QrCodeAlertContainer>
      <ContainerFlex>
        <IconCard>
          <QrCode />
        </IconCard>
        <Container>
          <Title>
            O que é QR Code?
          </Title>
          <BodyText>
            É uma figura quadrada semelhante a um código de barras e que pode ser facilmente lida pela câmera de smartphones ou aplicativos para fazer pagamentos ou transmitir informações.
          </BodyText>
        </Container>
      </ContainerFlex>
    </QrCodeAlertContainer>
  </>;
}
