import { IconButton, Tooltip } from '@material-ui/core';
import EditIcon from 'assets/icons/EditIcon';
import { DashboardTitle } from 'components/Dashboard/MyAccountMenu/styles';
import { StyledCollapse, StyledTab, StyledTabs, RowContainer, SelectedTabTitle } from 'components/Onboarding/OnboardingForms/AccountDataForms/TitularDataForm/styles';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import { accountDataMenuTabsDictionary } from 'library/accountDataMenuTabsDictionary';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { fetchGetAccount } from 'services/Account/GET/GetAccountService';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { grayScale, primaryColors } from 'theme/styleguide';
import useWindowWidth from 'utils/useWindowWidth';
import { AccountDataMenuContainer } from './styles';

export default function AccountDataMenu() {
  const mounted = useRef(false)
  const { selectedAccountDataMenuTab, setSelectedAccountDataMenuTab, isAccountDataMenuFormsDisabled, toggleAccountDataMenuFormsDisabled } = useDashboardMyAccount()
  const [accountType, setAccountType] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [hasDigitalAccount, setHasDigitalAccount] = useState(false)
  const windowWidth = useWindowWidth()

  const handleTabChange = (_e, value) => {
    setSelectedAccountDataMenuTab(value)
  }

  const getAccoutDataMenuData = useCallback(async () => {
    const { type } = await fetchGetAccount()
    const { use_digital_account } = await fetchGetAccountMyAccount()
    if (mounted.current) {
      setAccountType(type)
      setHasDigitalAccount(use_digital_account)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getAccoutDataMenuData()
  }, [getAccoutDataMenuData])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  return <>
    <AccountDataMenuContainer>
      <DashboardTitle>
        Dados da conta
      </DashboardTitle>
      {
        isLoading ?
          <LoadingIndicator /> :
          <>
            <StyledTabs
              value={selectedAccountDataMenuTab}
              onChange={handleTabChange}
              variant="fullWidth"
            >
              {
                accountDataMenuTabsDictionary[accountType]?.map((e, tabIndex) => {
                  return (
                    (hasDigitalAccount || !e.hasDigitalAccountDependency) &&
                    <StyledTab
                      value={tabIndex}
                      label={windowWidth >= 768 && e.title}
                      icon={
                        e?.icon({
                          color: (tabIndex === selectedAccountDataMenuTab) ? primaryColors.blue_5 : grayScale.gray_60p100,
                          opacity: (tabIndex === selectedAccountDataMenuTab) ? 1 : 0.6,
                        })
                      }
                      key={tabIndex}
                    />
                  )
                })
              }
            </StyledTabs>
            <RowContainer>
              <SelectedTabTitle>
                {accountDataMenuTabsDictionary[accountType][selectedAccountDataMenuTab].title}
              </SelectedTabTitle>
              <StyledCollapse
                in={
                  isAccountDataMenuFormsDisabled &&
                  !accountDataMenuTabsDictionary[accountType][selectedAccountDataMenuTab]?.hideEditButton
                }
              >
                <Tooltip
                  title="Editar"
                  arrow
                >
                  <IconButton
                    onClick={toggleAccountDataMenuFormsDisabled}
                  >
                    <EditIcon
                      size={1.25}
                    />
                  </IconButton>
                </Tooltip>
              </StyledCollapse>
            </RowContainer>
            {
              accountDataMenuTabsDictionary[accountType][selectedAccountDataMenuTab]?.component
            }
          </>
      }
    </AccountDataMenuContainer>
  </>;
}
