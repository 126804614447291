import EnterpriseProofingUploadForm from 'components/Onboarding/OnboardingForms/AccountVerificationForms/EnterpriseProofingUploadForm';
import UserIdUploadForm from 'components/Onboarding/OnboardingForms/AccountVerificationForms/UserIdUploadForm';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import React from 'react';
import { FormTitle, PartnersDocumentsUploadFormContainer } from './styles';

export default function PartnersDocumentsUploadForm({
  selectedPartnerData = {},
  isLoading = true,
  documentStatus = {},
  documentRequired = false,
  sendButtonText = "Continuar",
  formikProps = {},
  showTitle = true,
}) {
  return <>
    <PartnersDocumentsUploadFormContainer>
      {
        isLoading ?
          <LoadingIndicator /> :
          <>
            {
              showTitle &&
              <FormTitle>
                {
                  selectedPartnerData?.isPFPartner ?
                    "Foto do RG ou CNH dos(as) sócios(as)" :
                    "Documento de constituição da empresa sócia"
                }
              </FormTitle>
            }
            {
              selectedPartnerData?.isPFPartner ?
                <UserIdUploadForm
                  userData={selectedPartnerData}
                  isLoading={isLoading}
                  documentStatus={documentStatus}
                  documentRequired={documentRequired}
                  sendButtonText={sendButtonText}
                  isPartnerForm
                  {...formikProps}
                /> :
                <EnterpriseProofingUploadForm
                  enterpriseData={selectedPartnerData}
                  isLoading={isLoading}
                  documentStatus={documentStatus}
                  documentRequired={documentRequired}
                  sendButtonText={sendButtonText}
                  isPartnerForm
                  {...formikProps}
                />
            }
          </>
      }
    </PartnersDocumentsUploadFormContainer>
  </>;
}
