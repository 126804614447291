import AssessmentIcon from 'assets/icons/AssessmentIcon';
import SettingsIcon from 'assets/icons/SettingsIcon';
import DashboardMyAccount from 'components/Dashboard/DashboardMyAccount';
import DashboardOverview from 'components/Dashboard/DashboardOverview';
import DashboardSettings from 'components/Dashboard/DashboardSettings';

export const dashboardTabsDictionary = {
  "overview": {
    title: "Visão Geral",
    icon: AssessmentIcon,
    component: <DashboardOverview />,
    id: "menu_superior_visao-geral",
  },
  "settings": {
    title: "Configurações",
    icon: SettingsIcon,
    component: <DashboardSettings />,
    id: "menu_superior_configuracoes",
  },
  "myaccount": {
    title: "Minha Conta",
    icon: SettingsIcon,
    isMainUserOnlyTab: true,
    component: <DashboardMyAccount />,
    id: "menu_superior_minha-conta",
    subMenuTabs: {
      "accountDataMenu": {
        title: "Dados da conta",
        id: "submenu_minha-conta_dados-da-conta",
      },
      "userDataMenu": {
        title: "Dados de pessoa usuária",
        id: "submenu_minha-conta_dados-de-pessoa-usuaria",
      },
      "accountVerificationMenu": {
        title: "Envio de documentos",
        id: "submenu_minha-conta_envio-de-documentos",
      }
    }
  }
}