import { StepBody } from 'components/Onboarding/OnboardingBody/styles';
import React from 'react';
import { PixFormContainer, ImageCard, ContentContainer, Container, SubTitle, SelectContainer, ContainerForm, ContainerTextNumberWeb, ContainerTextNumberMobile, StyledTextField, StyledSelect } from './styles';
import QRCode from 'assets/images/qr-code.jpg'
import { FormControl, MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import QrCodeAlert from 'components/shared/QrCodeAlert';
import { useEffect } from 'react';
import TextNumberIncrementMobile from 'components/shared/TextNumberIncrementMobile';
import LoadingIndicator from 'components/shared/LoadingIndicator';

export default function PixForm({
  disabled = false,
  pixLifespanMinutesMetricList,
  values,
  setFieldValue,
  oldMetricSelected,
  setOldMetricSelected,
  isLoading,
  initialValues
}) {
  const fixDecimal = (number) => {
    if (number % 1) {
      return Math.round(number)
    } else {
      return number
    }
  }
  const blockLessOneValue = () => {
    if (values.pixLifespanMinutes <= 0) {
      setFieldValue("pixLifespanMinutes", 1)
    }
  }
  const updateNumberValue = () => {
    switch (values.pixLifespanMinutesMetric) {
      case 0:
        if (oldMetricSelected === 1) {
          setFieldValue("pixLifespanMinutes", fixDecimal(values.pixLifespanMinutes / 60))
        } else if (oldMetricSelected === 2) {
          setFieldValue("pixLifespanMinutes", fixDecimal(values.pixLifespanMinutes * 24))
        }
        break;
      case 1:
        if (oldMetricSelected === 0 && initialValues.pixLifespanMinutes !== values.pixLifespanMinutes) {
          setFieldValue("pixLifespanMinutes", fixDecimal(values.pixLifespanMinutes * 60))
        } else if (oldMetricSelected === 2 && initialValues.pixLifespanMinutes !== values.pixLifespanMinutes) {
          setFieldValue("pixLifespanMinutes", fixDecimal(values.pixLifespanMinutes * 24 * 60))
        }
        break;
      case 2:
        if (oldMetricSelected === 0) {
          setFieldValue("pixLifespanMinutes", fixDecimal(values.pixLifespanMinutes / 24))
        } else if (oldMetricSelected === 1) {
          setFieldValue("pixLifespanMinutes", fixDecimal((values.pixLifespanMinutes / 60) / 24))
        }
        break;
      default:
        break;
    }
    setOldMetricSelected(values.pixLifespanMinutesMetric)

  }
  useEffect(() => {
    updateNumberValue()
    // eslint-disable-next-line
  }, [values?.pixLifespanMinutesMetric])
  useEffect(() => {
    blockLessOneValue()
    // eslint-disable-next-line
  }, [values?.pixLifespanMinutes])
  return <>
    {
      isLoading ?
        <LoadingIndicator /> :
        <PixFormContainer>
          <ContentContainer>
            <Container>
              <SubTitle>
                Duração do QR code
              </SubTitle>
              <StepBody>
                Defina o tempo de duração do QR code para recebimento de vendas pix{"\n\n"}
                Após o término da duração, a venda será cancelada e o QR code ficará inativo, não sendo possível receber o valor.
              </StepBody>
            </Container>
            <ImageCard>
              <img
                src={QRCode}
                alt="Exemplo de QRCode"
              />
            </ImageCard>
          </ContentContainer>

          <ContainerForm>
            <ContainerTextNumberMobile>
              <TextNumberIncrementMobile
                setFieldValue={setFieldValue}
                name="pixLifespanMinutes"
                value={values?.pixLifespanMinutes}
                isLoading={isLoading}
              />
            </ContainerTextNumberMobile>
            <ContainerTextNumberWeb>
              <Field
                name="pixLifespanMinutes"
                as={StyledTextField}
                type="number"
              >
              </Field>
            </ContainerTextNumberWeb>
            <SelectContainer>
              <FormControl disabled={disabled}>
                <Field
                  as={StyledSelect}
                  name="pixLifespanMinutesMetric"
                  variant="outlined"
                  disabled={isLoading}
                >
                  {
                    pixLifespanMinutesMetricList.map((e, i) => {
                      return (
                        <MenuItem
                          value={i}
                          key={i}
                        >
                          {e}
                        </MenuItem>
                      )
                    })
                  }
                </Field>
              </FormControl>
            </SelectContainer>
          </ContainerForm>
          <QrCodeAlert />

        </PixFormContainer>
    }
  </>;
}
