import React from 'react';
import { UserEmailFormContainer } from './styles';
import useUserEmailDataSetup from 'setup/Dashboard/MyAaccountTab/UserDataMenu/shared/useUserEmailDataSetup';
import TextFieldRequester from 'components/shared/TextFieldRequester';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';

export default function UserEmailForm() {
  const { setCurrentMyAccountMenuItem } = useDashboardMyAccount()
  const { userEmail, hasVerifiedPhone, isLoading } = useUserEmailDataSetup()

  const handleEmailEditNavigation = () => {
    setCurrentMyAccountMenuItem("emailInstructions")
  }

  return <>
    <UserEmailFormContainer>
      <TextFieldRequester
        value={userEmail}
        label={isLoading ? "Carregando..." : "E-mail"}
        isLoadingRequest={isLoading}
        disabledButton={!hasVerifiedPhone}
        receivedFunction={handleEmailEditNavigation}
        isFromDashboard
      />
    </UserEmailFormContainer>
  </>;
}