import { Alert } from "@material-ui/lab";
import { StyledTextField } from "components/Onboarding/OnboardingForms/AccountDataForms/TitularDataForm/styles";
import styled from "styled-components";
import { grayScale, primaryColors, textStyles } from "theme/styleguide";

export const BankAccountFieldsContainer = styled.div`
  
`
export const InstructionDetails = styled.span`
  display: block;
  width: 100%;
  ${textStyles.tray_text_14px};
  color: ${grayScale.gray_60p100};
  margin-bottom: 20px;
  margin-top: 4px;
`
export const AccountNumberStyledTextField = styled(StyledTextField)`
  max-width: 50%;
  padding-right: 5px;
  @media (min-width: 768px)
  {
    flex: 1;
    max-width: 100%;
    padding-right: 20px;
  }
`
export const VerifyingDigitStyledTextField = styled(StyledTextField)`
  max-width: 50%;
  padding-left: 5px;
  @media (min-width: 768px)
  {
    flex: 1;
    max-width: 208px;
    padding-left: 0px;
  }
`
export const StyledAlert = styled(Alert)`
  width: 100%;
  margin-bottom: 32px;
  color: ${grayScale.gray_3};
  white-space: pre-wrap;
  ${({ severity }) => severity === "info" && `
    background: ${primaryColors.alert_blue};
  `}
  a{
    color: ${grayScale.gray_3};
    font-weight: bold;
  }
`