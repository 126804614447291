/* eslint-disable max-len */
import React from 'react';

export default function MarkEmailReadIcon({ size = 1, color = "#2E7EE7" }) {
  return <>
    <i>
      <svg
        width={30 * size}
        height={30 * size}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25 5H5C3.625 5 2.5125 6.125 2.5125 7.5L2.5 22.5C2.5 23.875 3.625 25 5 25H15V22.5H5V10L15 16.25L25 10V16.25H27.5V7.5C27.5 6.125 26.375 5 25 5ZM15 13.75L5 7.5H25L15 13.75ZM21.675 27.5L17.25 23.075L19.0125 21.3125L21.6625 23.9625L26.9625 18.6625L28.75 20.425L21.675 27.5Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
