import styled from "styled-components";
import { grayScale, primaryColors, textStyles } from "theme/styleguide";

export const DetailedStepperContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  margin-bottom: 24px;
  @media (min-width: 768px)
  {
    margin-top: 72px;
    margin-bottom: 48px;
  }
`
export const StepIndex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${primaryColors.blue_5};
  ${textStyles.tray_h4_18px}
  color: ${grayScale.white};
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-right: 16px;
  @media (min-width: 768px)
  {
    ${textStyles.tray_h5_15px}
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }
`
export const StepTitle = styled.span`
  ${textStyles.tray_h4_18px}
  color: ${grayScale.black};
`
export const StepSubtitle = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_60p100};
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 80%;
  @media (min-width: 768px)
  {
    max-width: 100%;
  }
`