import styled from "styled-components";

export const HorizontalScrollableContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  overflow-x: scroll;
    ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 0px 16px;
  margin-left: -16px;
  margin-right: -16px;
  @media (min-width: 768px)
  {
    margin-left: -16px;
  }
`