import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { LoadingIndicatorContainer } from './styles';

export default function LoadingIndicator({
  $minHeight,
  size = 1,
}) {
  return <>
    <LoadingIndicatorContainer
      $minHeight={$minHeight}
    >
      <CircularProgress
        size={42 * size}
        thickness={4}
      />
    </LoadingIndicatorContainer>
  </>;
}
