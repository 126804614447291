import { useCallback, useEffect, useState, useRef } from 'react';
import { fetchGetAccount } from 'services/Account/GET/GetAccountService';
import { fetchRequestUpdateCPFCNPJ } from 'services/Account/POST/RequestUpdateCPFCNPJService';
import { fetchUpdateAccountData } from 'services/Account/POST/UpdateAccountData';
import { fetchGetAddress } from 'services/Address/GET/GetAddressService';
import { fetchUpdateAddress } from 'services/Address/POST/UpdateAddressService';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { formatIdentifier } from 'utils/formatIdentifier';
import { parseStringToCEP } from 'utils/parseStringToCEP';
import { unmaskCPForCNPJ } from 'utils/unmaskCPForCNPJ';
import { validateIdentifier } from 'utils/validateIdentifier';
import { object, string } from 'yup';

export default function useTitularAndAddressDataSetup({
  reloadAfterSubmit = false,
  afterSubmit = () => { },
}) {
  const mounted = useRef(false)
  const [initialValues, setInitialValues] = useState({})
  const [oldValues, setOldValues] = useState({})
  const [validationSchema, setValidationSchema] = useState()
  const [isBusinessAccount, setBusinessAccount] = useState(false)
  const [isLoading, setLoading] = useState(true)

  const fetchData = useCallback(async () => {
    const fetchedMyAccount = await fetchGetAccountMyAccount()
    const fetchedAdress = await fetchGetAddress()
    const { type } = await fetchGetAccount()

    const validationObject = {
      name: string()
        .required("Este campo é obrigatório"),
      CPF: string()
        .test('valid-identifier', 'O CPF informado é inválido', validateIdentifier)
        .required("Este campo é obrigatório"),
      address: object()
        .shape({
          street: string(),
          number: string(),
          complement: string(),
          neighborhood: string(),
          city: string(),
          state: string(),
          zipCode: string()
            .min(9, 'O CEP informado é inválido'),
        }),
      ...(type === "business" && {
        socialName: string()
          .required("Este campo é obrigatório"),
        CNPJ: string()
          .test('valid-identifier', 'O CNPJ informado é inválido', validateIdentifier)
          .required("Este campo é obrigatório"),
      })
    }

    const initialValuesObject = {
      name: fetchedMyAccount?.name || "",
      CPF: formatIdentifier(fetchedMyAccount?.cpf),
      address: {
        street: fetchedAdress?.street || "",
        number: fetchedAdress?.number || "",
        complement: fetchedAdress?.complement || "",
        neighborhood: fetchedAdress?.neighborhood || "",
        city: fetchedAdress?.city || "",
        state: fetchedAdress?.state || "",
        zipCode: parseStringToCEP(fetchedAdress?.zipcode || ""),
      },
      ...(type === "business" && {
        socialName: fetchedMyAccount?.social_name || "",
        CNPJ: formatIdentifier(fetchedMyAccount?.cnpj),
      })
    }

    if (mounted.current) {
      setInitialValues(initialValuesObject)
      setOldValues(initialValuesObject)
      setValidationSchema(object(validationObject))
      setBusinessAccount(type === "business")
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async ({
    name,
    socialName,
    CPF,
    address,
  }) => {
    const accountObject = {
      ...(!!name && name !== oldValues?.name && {
        name: name
      }),
      ...(!!socialName && socialName !== oldValues?.socialName && {
        social_name: socialName
      })
    }
    const accountCPFObject = {
      ...(!!CPF && CPF !== oldValues?.CPF && {
        cpf: unmaskCPForCNPJ(CPF)
      }),
    }

    if (Object.keys(accountObject).length > 0) {
      await fetchUpdateAccountData(accountObject)
    }

    if (Object.keys(accountCPFObject).length > 0) {
      await fetchRequestUpdateCPFCNPJ(accountCPFObject)
    }

    if (JSON.stringify(address) !== JSON.stringify(oldValues.address) &&
      !!address.street &&
      !!address.number &&
      !!address.neighborhood &&
      !!address.city &&
      !!address.state &&
      !!address.zipCode
    ) {
      await fetchUpdateAddress({
        street: address.street,
        number: address.number,
        complement: address.complement,
        neighborhood: address.neighborhood,
        city: address.city,
        state: address.state,
        zipcode: address.zipCode,
      })
    }

    if (reloadAfterSubmit) {
      await fetchData()
    }
    afterSubmit()
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    isBusinessAccount,
  })
}