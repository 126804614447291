import React from 'react';
import SettingsMenu from 'components/Dashboard/SettingsMenu';
import { DashboardSettingsContainer, MenuTitle, StyledAlert, TitleContainer } from './styles';
import PaletteIcon from 'assets/icons/PaletteIcon';
import CreditCardIcon from 'assets/icons/CreditCardIcon';
import RequestQuoteIcon from 'assets/icons/RequestQuoteIcon';
import MarkEmailReadIcon from 'assets/icons/MarkEmailReadIcon';
import { useDashboard } from 'context/DashboardContext';
import CustomizeMenuItem from 'components/SettingsMenu/CustomizeMenuItem';
import CreditCardMenuItem from 'components/SettingsMenu/CreditCardMenuItem';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from 'assets/icons/ArrowBackIcon';
import BoletoMenuItem from 'components/SettingsMenu/BoletoMenuItem';
import BillingEmailsMenuItem from 'components/SettingsMenu/BillingEmailsMenuItem';
import QrCode2 from 'assets/icons/QrCode2';
import PixMenuItem from 'components/SettingsMenu/PixMenuItem';

export const menuDictionary = {
  "customize": {
    title: "Personalização ",
    description: "Nome da loja na fatura do cartão e boleto",
    icon: <PaletteIcon />,
    component: <CustomizeMenuItem />,
    id: "menu_configuracoes_submenu_personalizacao",
  },
  "creditCard": {
    title: "Cartão de crédito",
    description: "Parcelamento sem juros",
    icon: <CreditCardIcon />,
    component: <CreditCardMenuItem />,
    id: "menu_configuracoes_submenu_cartao-de-credito",
  },
  "boleto": {
    title: "Boleto",
    description: "Vencimento e instruções",
    icon: <RequestQuoteIcon />,
    component: <BoletoMenuItem />,
    id: "menu_configuracoes_submenu_boleto",
  },
  "pix": {
    title: "QR code pix",
    description: "Duração do QR code",
    icon: <QrCode2 />,
    component: <PixMenuItem />,
    id: "menu_configuracoes_submenu_qrcode-pix",
  },
  // TODO: Remover definitivamente quando for definido se havará ou não esta opção 
  // "withdraw": {
  //   title: "Saque automático",
  //   description: "Configurações de valor e dias da semana",
  //   icon: <AccountBalanceIcon size={1.25} color={primaryColors.blue_3} />,
  //   component: <WithdrawMenuItem />,
  //   id: "menu_configuracoes_submenu_saque-automatico",
  // },
  "billingEmail": {
    title: "E-mails transacionais",
    description: "E-mails que os compradores recebem ao fazerem uma compra",
    icon: <MarkEmailReadIcon />,
    component: <BillingEmailsMenuItem />,
    id: "menu_configuracoes_submenu_emails-transacionais",
  },
}

export default function DashboardSettings() {
  const { currentSettingsMenuItem, goBackToSettingsMenu, disabledEdition } = useDashboard()
  return <>
    <DashboardSettingsContainer>
      {
        currentSettingsMenuItem !== "menu" &&
        disabledEdition &&
        <StyledAlert
          severity="info"
        >
          O seu perfil de usuário não tem permissão para editar as configurações desta página. Para fazer edições, peça ao administrador da loja para alterar o seu perfil.
        </StyledAlert>
      }
      <TitleContainer>
        {
          currentSettingsMenuItem !== "menu" &&
          <IconButton
            size="small"
            onClick={goBackToSettingsMenu}
          >
            <ArrowBackIcon />
          </IconButton>
        }
        <MenuTitle>
          {
            currentSettingsMenuItem === "menu" ?
              "Configurações" :
              menuDictionary[currentSettingsMenuItem]?.title
          }
        </MenuTitle>
      </TitleContainer>
      {
        currentSettingsMenuItem === "menu" ?
          <SettingsMenu /> :
          menuDictionary[currentSettingsMenuItem]?.component
      }
    </DashboardSettingsContainer>
  </>;
}
