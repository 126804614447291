import LoadingIndicator from 'components/shared/LoadingIndicator';
import React from 'react';
import { AttorneyPowerUploadFormContainer, Container, ContainerLeft, ContainerRight, ContainerFilePicker, BodyText, FileRulesList, ContainerList } from './styles';
import Procuration from 'assets/images/procuration.png'
import FilePicker from 'components/shared/FilePicker';
import InsertDriveFileIcon from 'assets/icons/InsertDriveFileIcon';
import { groupsAcceptedFilesDictionary } from 'library/groupsAcceptedFilesDictionary';
import DocumentsRequiredAlert from 'components/Onboarding/OnboardingForms/AccountVerificationForms/shared/DocumentsRequiredAlert';
export default function AttorneyPowerUploadForm({
  isLoading,
  documentRequired = false,
  documentStatus,
  sendButtonText = "Continuar",
  setFieldValue = () => { },
}) {

  return <>
    <AttorneyPowerUploadFormContainer>
      {
        isLoading ?
          <LoadingIndicator /> :
          <>
            <Container>
              <ContainerLeft>
                <BodyText>
                  Se você é procurador, é necessário que envie também o documento de procuração.
                </BodyText>
                <ContainerList>
                  <FileRulesList>
                    <li>
                      {`Formato de arquivo aceito: ${groupsAcceptedFilesDictionary.attorney.business.description}`}
                    </li>
                    <li>
                      Tamanho máximo do arquivo: 4,5 MB
                    </li>
                  </FileRulesList>
                </ContainerList>
                <ContainerFilePicker>
                  <FilePicker
                    fieldName={"letterOfAttorney"}
                    setFieldValue={setFieldValue}
                    title="Adicionar procuração"
                    Icon={InsertDriveFileIcon}
                    acceptedFiles={groupsAcceptedFilesDictionary.attorney.business.value}
                    marginRight={'0px'}
                    disabled={!documentRequired}
                    id={"envio-documentos_procuracao_botao_add-procuracao"}
                  />
                </ContainerFilePicker>
              </ContainerLeft>
              <ContainerRight>
                <img
                  src={Procuration}
                  alt="Procuração"
                />
              </ContainerRight>
            </Container>
            <DocumentsRequiredAlert
              documentStatus={documentStatus}
              sendButtonText={sendButtonText}
            />
          </>
      }
    </AttorneyPowerUploadFormContainer>
  </>;
}
