import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Login from "pages/Login";
import Welcome from "pages/Welcome";
import ScrollToTop from "components/shared/ScrollToTop";
import Onboarding from 'pages/Onboarding';
import Dashboard from 'pages/Dashboard';
import LandingPage from 'pages/LandingPage';
// import OldLandingPage from 'pages/UNUSED_LandingPage';
import Logout from 'pages/Logout';

export default function AppRouting() {
  return <>
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/logout" component={Logout} />
        <Route exact path="/welcome" component={Welcome} />
        <Route exact path="/onboarding" component={Onboarding} />
        <Route exact path="/dashboard" component={Dashboard} />
        {/* <Route exact path={["/old_landing"]} component={OldLandingPage} /> */}
        <Route exact path={["", "/", "/landing"]} component={LandingPage} />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  </>;
}
