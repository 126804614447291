import React from 'react';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { UserIdUploadFormContainer, Description, Container, ProfileCard, IconContainer, TextContainer, StyledList, ListItem, FilePickerContainer, ContainerLeft, ContainerRight, CNHInstructions, StyledFormControlLabel } from './styles';
import Document from 'assets/images/document.png'
import Profile from 'assets/icons/Profile';
import FilePicker from 'components/shared/FilePicker';
import DocumentsRequiredAlert from 'components/Onboarding/OnboardingForms/AccountVerificationForms/shared/DocumentsRequiredAlert';
import RadioOptions from 'components/shared/RadioOptions';
import { Checkbox, Collapse } from '@material-ui/core';
import { parseStringToCPF } from 'utils/parseStringToCPF';
import { Field } from 'formik';
import { groupsAcceptedFilesDictionary } from 'library/groupsAcceptedFilesDictionary';

const documentTypesOptions = [
  {
    value: "identity_card",
    label: "RG"
  },
  {
    value: "driver_license",
    label: "CNH"
  },
]

const getAcceptedFiles = (isPartnerForm, isBusinessAccount) => {
  if (isPartnerForm) {
    return groupsAcceptedFilesDictionary.partner.document
  } else if (isBusinessAccount) {
    return groupsAcceptedFilesDictionary.document.business
  } else {
    return groupsAcceptedFilesDictionary.document.personal
  }
}

export default function UserIdUploadForm({
  userData = {},
  isLoading = true,
  documentRequired = false,
  documentStatus = {},
  sendButtonText = "Continuar",
  isPartnerForm = false,
  setFieldValue = () => { },
  values,
  isBusinessAccount = false,
  showAttorneyCheckbox = false,
}) {
  return <>
    <UserIdUploadFormContainer>
      {
        isLoading ?
          <LoadingIndicator /> :
          <>
            <Container>
              <ContainerLeft>
                <Description>
                  {
                    isPartnerForm ?
                      `Envie uma foto aproximada do RG ou CNH de forma que os dados fiquem legíveis.\nEste documento precisa ser do(a) sócio(a) abaixo:` :
                      `Envie uma foto aproximada do seu RG ou CNH de forma que os dados fiquem legíveis.\nEste documento precisa ser do(a) titular abaixo cadastrado na conta:`
                  }
                </Description>
                <ProfileCard>
                  <IconContainer>
                    <Profile />
                  </IconContainer>
                  <TextContainer>
                    <Description>
                      <b>
                        {userData.name}
                      </b>
                    </Description>
                    <Description>
                      CPF: {parseStringToCPF(userData.cpf)}
                    </Description>
                  </TextContainer>
                </ProfileCard>
                <StyledList>
                  <ListItem>
                    {`Formatos de imagem aceitos: ${getAcceptedFiles(isPartnerForm, isBusinessAccount).description}`}
                  </ListItem>
                  <ListItem>
                    Tamanho máximo do arquivo: 4,5 MB
                  </ListItem>
                </StyledList>
                <RadioOptions
                  title={"Selecione o documento que será enviado:"}
                  receivedValue={values.selectedDocumentType}
                  receivedSetter={(e) => { setFieldValue("selectedDocumentType", e) }}
                  options={documentTypesOptions}
                  disabled={!documentRequired}
                />
                <Collapse
                  in={values.selectedDocumentType === "driver_license"}
                >
                  <CNHInstructions>
                    Obs: retire a CNH da capa plástica para fazer as fotos e envie uma foto de cada lado do documento.
                  </CNHInstructions>
                </Collapse>
                <FilePickerContainer>
                  <FilePicker
                    fieldName={"frontPagePhoto"}
                    setFieldValue={setFieldValue}
                    title="Adicionar foto da frente"
                    acceptedFiles={getAcceptedFiles(isPartnerForm, isBusinessAccount).value}
                    disabled={!documentRequired}
                    id={
                      isPartnerForm ?
                        "envio-documentos_foto-RG-CNH-socios_botao_add-foto-frente" :
                        "envio-documentos_foto-RG-CNH_botao_add-foto-frente"
                    }
                    marginRight={"32px"}
                  />
                  <FilePicker
                    fieldName={"versePagePhoto"}
                    setFieldValue={setFieldValue}
                    title="Adicionar foto do verso"
                    acceptedFiles={getAcceptedFiles(isPartnerForm, isBusinessAccount).value}
                    disabled={!documentRequired}
                    id={
                      isPartnerForm ?
                        "envio-documentos_foto-RG-CNH-socios_botao_add-foto-verso" :
                        "envio-documentos_foto-RG-CNH_botao_add-foto-verso"
                    }
                  />
                </FilePickerContainer>
              </ContainerLeft>
              <ContainerRight>
                <img
                  src={Document}
                  alt="Ilustração de documento"
                />
              </ContainerRight>
            </Container>
            <DocumentsRequiredAlert
              documentStatus={documentStatus}
              sendButtonText={sendButtonText}
            />
            {
              isBusinessAccount &&
              showAttorneyCheckbox &&
              <StyledFormControlLabel
                label="Sou procurador da empresa"
                control={
                  <Field
                    as={Checkbox}
                    name={"isAttorney"}
                    type="checkbox"
                  />
                }
              />
            }
          </>
      }
    </UserIdUploadFormContainer>
  </>;
}
