import styled from "styled-components";
import { DashboardTitle } from 'components/Dashboard/MyAccountMenu/styles';
import { ButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';

export const DashboardMyAccountContainer = styled.div`
  margin-top: 16px;
  @media (min-width: 1024px)
  {
    margin-top: 24px;
  }
`
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  @media (min-width: 768px)
  {
    margin-left: 0px;
    button{
      display: none;
    }
  }
`
export const StyledDashboardTitle = styled(DashboardTitle)`
  margin-left: 12px;
  @media (min-width: 768px)
  {
    margin-left: 0px;
  }
`
export const StyledButtonsContainer = styled(ButtonsContainer)`
  @media (max-width: 767px)
  {
    flex-direction: column;
    margin-bottom: 16px;
    button{
      min-width: 254px;
    }
  }
`