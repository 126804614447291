import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const AttorneyPowerUploadFormContainer = styled.div`
  margin-bottom: 24px;
  @media (min-width: 768px)
  {
    margin-bottom: 40px;
  }
`
export const BodyText = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  @media (min-width: 768px)
  {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 48px;
  }
`
export const ContainerLeft = styled.div`
  width: 100%;
`
export const ContainerRight = styled.div`
  text-align: center;
  >img{
    display: none;
  }
  @media (min-width: 768px){
    width: 100%;
    >img{
      display: initial;
    }
  }
`
export const ContainerFilePicker = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (min-width: 768px){
  justify-content: flex-start;
  } 
`
export const FileRulesList = styled.ul`
padding: 0px 15px;
  li{
    ${textStyles.tray_text_14px}
    text-align: left;
    opacity: 0.6;
    color:${grayScale.gray_3};
  }
`
export const ContainerList = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  @media (min-width: 768px){
    margin-top: 48px;
    margin-bottom: 64px;
  }
`