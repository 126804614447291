import { Button } from "@material-ui/core";
import styled from "styled-components";
import { textStyles } from "theme/styleguide";

export const SallesStatusChartContainer = styled.div`
  margin-top: 36px;
  @media (min-width: 820px)
  {
    margin-top: 75px;
  }
`
export const CanvasContainer = styled.div`
    height: 192px;
    width: 100%;
  canvas{
    height: 100% !important;
    width: 100% !important;
  }
`
export const LabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  @media (min-width: 820px)
  {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 72px;
  }
`
export const LabelElementContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 2px;
  @media (min-width: 820px)
  {
    width: calc(33%);
  }
`
export const StyledButton = styled(Button)`
  ${textStyles.body_2_12px}
  min-width: fit-content;
  padding: 4px 8px;
  :hover{
    background: transparent;
  }
  cursor: default;
`