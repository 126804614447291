import React, { Fragment } from 'react';
import { AccountVerificationContainer } from './styles';
import { useOnboarding } from 'context/OnboardingContext';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import DetailedStepper from 'components/shared/DetailedStepper';
import StepFormHeader from 'components/Onboarding/shared/StepFormHeader';
import { accountVerificationStepsDictionary } from 'library/accountVerificationStepsDictionary';
import { getCurrentOnboardingStepKey } from 'utils/getCurrentOnboardingStepKey';
import { getStepsCollectionShowingCounter } from 'utils/getStepsCollectionShowingCounter';
import { getHideOnCountStepsGoingBack } from 'utils/getHideOnCountStepsGoingBack';
import { accountDataStepsDictionary } from 'library/accountDataStepsDictionary';
import AccountVerificationProvider from 'context/AccountVerificationContext';

export default function AccountVerificationSection() {
  const { accountVerificationStep, profileSettingsStep, previousAccountVerificationStep, previousProfileSettingsStep, previousOnboardingStep, isLoadingContext } = useOnboarding()
  const currentStepKey = getCurrentOnboardingStepKey("documentsSendingSteps", accountVerificationStep)
  const totalSteps = getStepsCollectionShowingCounter("documentsSendingSteps", accountVerificationStepsDictionary)

  const handleGoBack = () => {
    if (accountVerificationStep > 0) {
      const hideOnCountStepsGoingBack = getHideOnCountStepsGoingBack("documentsSendingSteps", accountVerificationStep, accountVerificationStepsDictionary)
      for (let i = 0; i < hideOnCountStepsGoingBack; i++) {
        previousAccountVerificationStep()
      }
    } else {
      if (profileSettingsStep > 0) {
        if (accountDataStepsDictionary[getCurrentOnboardingStepKey("accountDataSteps", profileSettingsStep)]?.hideOnCounting) {
          const hideOnCountStepsGoingBack = getHideOnCountStepsGoingBack("accountDataSteps", profileSettingsStep, accountDataStepsDictionary)
          for (let i = 0; i < hideOnCountStepsGoingBack; i++) {
            previousProfileSettingsStep()
          }
        }
      }
      previousOnboardingStep()
    }
  }

  return <>
    {
      isLoadingContext ?
        <LoadingIndicator /> :
        <>
          < StepFormHeader
            handleGoBack={handleGoBack}
          />
          <AccountVerificationProvider>
            <AccountVerificationContainer>
              <DetailedStepper
                currentStep={accountVerificationStep}
                totalSteps={totalSteps}
                title={accountVerificationStepsDictionary[currentStepKey]?.stepTitle}
                hideSubtitle={accountVerificationStepsDictionary[currentStepKey]?.hideSubtitle}
              />
              {
                Object.keys(accountVerificationStepsDictionary).map((key, i) => {
                  return (
                    <Fragment
                      key={i}
                    >
                      {
                        key === currentStepKey &&
                        accountVerificationStepsDictionary[key].stepComponent
                      }
                    </Fragment>
                  )
                })
              }
            </AccountVerificationContainer>
          </AccountVerificationProvider>
        </>
    }
  </>;
}