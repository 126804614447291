import usePixSetup from 'components/FormsSetup/MainSettings/usePixSetup';
import PixForm from 'components/Onboarding/OnboardingForms/MainSettingsForms/PixForm';
import { useDashboard } from 'context/DashboardContext';
import { Form, Formik } from 'formik';
import React from 'react';
import FormButtons from 'components/SettingsMenu/FormButtons';
import { PixMenuItemContainer } from './styles';

export default function PixMenuItem() {
  const { disabledEdition } = useDashboard()
  const { initialValues,
    validationSchema,
    isLoading,
    handleSubmit,
    pixLifespanMinutesMetricList,
    oldMetricSelected,
    setOldMetricSelected,
    isSnackbarOpen,
    setSnackbarOpen } = usePixSetup({
      reloadAfterSubmit: true,
    })

  return <>
    <PixMenuItemContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <PixForm
                {...formikProps}
                pixLifespanMinutesMetricList={pixLifespanMinutesMetricList}
                oldMetricSelected={oldMetricSelected}
                setOldMetricSelected={setOldMetricSelected}
                disabled={disabledEdition}
                isLoading={isLoading}
              />
              <FormButtons
                isSnackbarOpen={isSnackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                saveButtonId={"configuracoes_qrcode-pix_botao_salvar"}
                cancelButtonId={"configuracoes_qrcode-pix_botao_cancelar"}
                {...formikProps}
              />
            </Form>
          )
        }
      </Formik>
    </PixMenuItemContainer>
  </>;
}
