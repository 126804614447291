import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SuccessUploadingContainer, Title, Instruction, StyledButton } from './styles';
import { getDataStorage, setDataStorage } from 'utils/storage';
import { fetchUpdateOnboardingStep } from 'services/StoreKeys/POST/UpdateOnboardingStepService';
import CircleCheckedIcon from 'assets/icons/CircleCheckedIcon';
import { fetchValidateDocument } from 'services/MyAccount/POST/ValidateDocumentService';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { fetchSearchSellerSplits } from 'services/SellerSplits/POST/SearchSellerSplitsService';
import { getParcelWithBiggestAvaiableValue } from 'utils/getParcelWithBiggestAvaiableValue';
import { parseParcelsOptionsToArray } from 'utils/parseParcelsOptionsToArray';
import { fetchCreateSellerSplits } from 'services/SellerSplits/POST/CreateSellerSplitsService';
import { fetchUpdateAccountSellerSettings } from 'services/AccountsSellerSettings/POST/UpdateAccountSellerSettingsService';
import useWindowWidth from 'utils/useWindowWidth';
import { GAEventTracker } from 'utils/GAEventTracker';

export default function SuccessUploading() {
  const history = useHistory()
  const windowWidth = useWindowWidth()
  const [loading, setLoading] = useState(false)

  const creditCardBackgroundSetup = async () => {
    const { seller_split: parcelsOptions } = await fetchSearchSellerSplits()
    const parcelValues = getParcelWithBiggestAvaiableValue(parcelsOptions)
    const parcelsArray = parseParcelsOptionsToArray(parcelsOptions, parcelValues)
    await fetchCreateSellerSplits(parcelsArray)
  }

  const accountSellerSettingsBackgroundSetup = async () => {
    await fetchUpdateAccountSellerSettings({
      automatic_cancel: "true",
      automatic_cancel_time: 5,
    })
  }

  const handleFinish = async () => {
    setLoading(true)
    GAEventTracker({
      category: "Onboarding",
      action: "Finalização do Onboarding",
      label: "Clicar no botão OK da última página do onboarding"
    })
    const userData = await fetchGetAccountMyAccount()
    const origem = getDataStorage("origem")
    const isOrigemRecurrence = origem === "recurrence"
    if (userData) {
      const { cpf, cnpj } = userData
      if (cpf) {
        await fetchValidateDocument(cpf)
      }
      if (cnpj) {
        await fetchValidateDocument(cnpj)
      }
      if (isOrigemRecurrence) {
        await creditCardBackgroundSetup()
        await accountSellerSettingsBackgroundSetup()
      }
    }
    setLoading(false)
    // const finishStep = Number(getDataStorage("onboarding_step")) + 1
    const finishStep = -1
    setDataStorage("onboarding_step", finishStep);
    await fetchUpdateOnboardingStep({
      onboarding_step: finishStep,
    }).then(() => {
      history.replace("/dashboard")
    })
  }

  return <>
    <SuccessUploadingContainer>
      <CircleCheckedIcon
        size={windowWidth >= 768 ? 1 : 0.815}
      />
      <Title>
        Tudo certo!
      </Title>
      <Instruction>
        As configurações de recebimento foram atualizadas e agora você já pode navegar pelo app Vindi.
      </Instruction>
      <StyledButton
        onClick={handleFinish}
        disabled={loading}
        isLoading={loading}
        id={"tela_sucesso_onboarding_botao_ok"}
      >
        OK
      </StyledButton>
    </SuccessUploadingContainer>
  </>;
}
