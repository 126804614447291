import { fetchUsers } from "services/StoreAPI/GET/UsersService"
import { getDataStorage, setDataStorage } from "./storage"

export const checkUserAllowence = async () => {
  const { Users } = await fetchUsers()
  const adminUser = getDataStorage('store_admin_user')
  let allowence = false
  Users?.forEach((e) => {
    if (e.User.name === adminUser) {
      allowence = Boolean(Number(e.User.main_user))
    }
  })
  setDataStorage("main_user", allowence)
  return allowence
}