/* eslint-disable max-len */
import React from 'react';

export default function BadgeIcon({ size = 1, color = "#546E7A" }) {
  return <>
    <i>
      <svg
        width={30 * size}
        height={30 * size}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g
          clipPath="url(#clip0_5948_2492)">
          <path
            d="M22.5 15H17.5V16.875H22.5V15Z"
            fill={color}
            fillOpacity="0.6"
          />
          <path
            d="M22.5 18.75H17.5V20.625H22.5V18.75Z"
            fill={color}
            fillOpacity="0.6"
          />
          <path
            d="M25 8.75H18.75V5C18.75 3.625 17.625 2.5 16.25 2.5H13.75C12.375 2.5 11.25 3.625 11.25 5V8.75H5C3.625 8.75 2.5 9.875 2.5 11.25V25C2.5 26.375 3.625 27.5 5 27.5H25C26.375 27.5 27.5 26.375 27.5 25V11.25C27.5 9.875 26.375 8.75 25 8.75ZM13.75 8.75V5H16.25V8.75V11.25H13.75V8.75ZM25 25H5V11.25H11.25C11.25 12.625 12.375 13.75 13.75 13.75H16.25C17.625 13.75 18.75 12.625 18.75 11.25H25V25Z"
            fill={color}
            fillOpacity="0.6"
          />
          <path
            d="M11.25 18.75C12.2855 18.75 13.125 17.9105 13.125 16.875C13.125 15.8395 12.2855 15 11.25 15C10.2145 15 9.375 15.8395 9.375 16.875C9.375 17.9105 10.2145 18.75 11.25 18.75Z"
            fill={color}
            fillOpacity="0.6"
          />
          <path
            d="M13.85 20.225C13.05 19.875 12.175 19.6875 11.25 19.6875C10.325 19.6875 9.45 19.875 8.65 20.225C7.95 20.525 7.5 21.2 7.5 21.9625V22.5H15V21.9625C15 21.2 14.55 20.525 13.85 20.225Z"
            fill={color}
            fillOpacity="0.6"
          />
        </g>
        <defs>
          <clipPath
            id="clip0_5948_2492">
            <rect
              width={30 * size}
              height={30 * size}
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </i>
  </>;
}
