import { TextField } from "@material-ui/core";
import styled from "styled-components";
import { textStyles } from "theme/styleguide";

export const TextNumberIncrementMobileContainer = styled.div`
    text-align: -webkit-center;
    padding: 14px;
    justify-content:space-between;
    display: flex;
    align-items: center;
`
export const StyledTextField = styled(TextField)`
  width: 100%;
  text-align: -webkit-center;
  ${textStyles.body_1_14px}
  .MuiSelect-select:focus{
    background: transparent !important;
  }
  .MuiOutlinedInput-input {
    padding: 9px 14px;
    text-align: -webkit-center;
  }

`