/* eslint-disable max-len */
import React from 'react';

export default function PaidIcon({ size = 1, color = "#2E7EE7" }) {
  return <>
    <i>
      <svg
        width={30 * size}
        height={30 * size}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.001 2.5C8.10098 2.5 2.50098 8.1 2.50098 15C2.50098 21.9 8.10098 27.5 15.001 27.5C21.901 27.5 27.501 21.9 27.501 15C27.501 8.1 21.901 2.5 15.001 2.5ZM15.001 25C9.48848 25 5.00098 20.5125 5.00098 15C5.00098 9.4875 9.48848 5 15.001 5C20.5135 5 25.001 9.4875 25.001 15C25.001 20.5125 20.5135 25 15.001 25ZM16.1135 13.875C13.8885 13.1375 12.8135 12.675 12.8135 11.5C12.8135 10.225 14.201 9.7625 15.076 9.7625C16.7135 9.7625 17.3135 11 17.451 11.4375L19.426 10.6C19.2385 10.05 18.401 8.2125 16.101 7.8125V6.25H13.9135V7.825C10.6635 8.525 10.6385 11.3875 10.6385 11.525C10.6385 14.3625 13.451 15.1625 14.826 15.6625C16.801 16.3625 17.676 17 17.676 18.2C17.676 19.6125 16.3635 20.2125 15.201 20.2125C12.926 20.2125 12.276 17.875 12.201 17.6L10.126 18.4375C10.9135 21.175 12.976 21.9125 13.901 22.1375V23.75H16.0885V22.2C16.7385 22.0875 19.8635 21.4625 19.8635 18.175C19.876 16.4375 19.1135 14.9125 16.1135 13.875Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
