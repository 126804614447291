import { FormControlLabel } from "@material-ui/core";
import styled from "styled-components";
import { borderStyles, grayScale, primaryColors, textStyles } from "theme/styleguide";

export const UserIdUploadFormContainer = styled.div`
  margin-bottom: 32px;
  @media (min-width: 768px)
  {
    margin-bottom: 96px;
  }
`
export const Description = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  color:${grayScale.gray_3};
  white-space: pre-wrap;
`
export const StyledList = styled.ul`
  margin-top: 24px;
  margin-bottom: 32px;
  padding-left: 16px;
  @media (min-width: 768px)
  {
    margin-bottom: 72px;
  }
`
export const ListItem = styled.li`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  opacity: 0.6;
`
export const Container = styled.div`
  display:flex;
  flex-direction: column;
  margin-top: 24px;
  @media (min-width: 768px)
  {
    flex-direction: row;
    justify-content:space-between;
    margin-top: 48px;
  }
`
export const ContainerLeft = styled.div`
  width: 100%;
  @media (min-width: 768px){
    width: 100%;
    max-width:556px;
  }
`
export const ContainerRight = styled.div`
  display: none;
  text-align: center;
  @media (min-width: 768px){
    width: 100%;
    display: block;
  }
`
export const ProfileCard = styled.div`
  ${borderStyles.border_1};
  display: flex;
  margin-top: 24px;
  margin-bottom: 32px;
  padding: 24px;
`
export const IconContainer = styled.div`
  margin-right: 16px;
  align-self:center;
`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const FilePickerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  margin-top: 16px;
  @media (min-width: 768px)
  {
    flex-direction: row;
  }
`
export const CNHInstructions = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  color:${grayScale.gray_3};
  margin-bottom: 16px;
  @media (min-width: 768px){
    margin-top: 24px;
  }
`
export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-top: 32px;
  .MuiTypography-root{
    ${textStyles.body_1_14px}
    user-select: none;
  }
  .MuiIconButton-root{
    color: ${grayScale.gray_blend};
  }
  .Mui-checked {
    color: ${primaryColors.blue_3};
  }
  .PrivateSwitchBase-root-4 {
    padding-bottom: 2px;
    padding-top: 2px;
  }
  @media (min-width: 768px){
    margin-top: 80px;
  }
`