import { createContext, useContext, useEffect, useState, useCallback, useRef } from 'react';
import { fetchGetAccount } from 'services/Account/GET/GetAccountService';
import { fetchGetBankAccount } from 'services/Account/GET/GetBankAccountService';
import { fetchUpdateOnboardingStep } from 'services/StoreKeys/POST/UpdateOnboardingStepService';
import { filterActiveBankAccount } from 'utils/filterActiveBankAccount';
import { getDataStorage, setDataStorage } from 'utils/storage';
const OnboardingContext = createContext();

export default function OnboardingProvider({ children }) {
  const mounted = useRef(false);

  const [onboardingSectionIndex, setOnboardingSectionIndex] = useState(Number(getDataStorage("onboarding_step") || 0))
  const [lastCompletedOnboardingSection, setLastCompletedOnboardingSection] = useState(Number(getDataStorage("onboarding_step") || 0))

  const [profileSettingsStep, setProfileSettingsStep] = useState(Number(getDataStorage("profile_settings_step") || 0))
  const [lastCompletedProfileSettingsStep, setLastCompletedProfileSettingsStep] = useState(Number(getDataStorage("profile_settings_step") || 0))

  const [mainSettingsStep, setMainSettingsStep] = useState(Number(getDataStorage("main_settings_step") || 0))
  const [lastCompletedMainSettingsStep, setLastCompletedMainSettingsStep] = useState(Number(getDataStorage("main_settings_step") || 0))

  const [accountVerificationStep, setAccountVerificationStep] = useState(Number(getDataStorage("account_verification_step") || 0))
  const [lastCompletedAccountVerificationStep, setLastCompletedAccountVerificationStep] = useState(Number(getDataStorage("account_verification_step") || 0))

  const [completeRegisterStep, setCompleteRegisterStep] = useState(0)

  const [isLoadingContext, setLoadingContext] = useState(true)
  const [accountType, setAccountType] = useState("")
  const [isBusinessAccount, setBusinessAccount] = useState(false)
  const [isCompleteLaterAlertOpen, setCompleteLaterAlertOpen] = useState(false)
  // const [hasActiveBankAccount, setHasActiveBankAccount] = useState(false)

  const getAccountType = useCallback(async () => {
    const { type } = await fetchGetAccount()
    const fetchedBankAccountList = await fetchGetBankAccount()
    const fetchedBankAccount = filterActiveBankAccount(fetchedBankAccountList)
    if (mounted.current) {
      setAccountType(type)
      setBusinessAccount(type === "business")
      setDataStorage("isBusinessAccount", type === "business")
      // setHasActiveBankAccount(!!fetchedBankAccount?.active)
      setDataStorage("hasActiveBankAccount", !!fetchedBankAccount?.active)
      setLoadingContext(false)
    }
  }, [])

  const toggleCompleteLaterAlert = () => {
    setCompleteLaterAlertOpen((oldValue) => !oldValue)
  }

  const nextOnboardingSection = () => {
    if (lastCompletedOnboardingSection <= onboardingSectionIndex) {
      setLastCompletedOnboardingSection((oldValue) => oldValue + 1)
    }
    setOnboardingSectionIndex((oldValue) => oldValue + 1)
  }
  const nextProfileSettingsStep = () => {
    if (lastCompletedProfileSettingsStep <= profileSettingsStep) {
      setLastCompletedProfileSettingsStep((oldValue) => oldValue + 1)
    }
    setProfileSettingsStep((oldValue) => oldValue + 1)
  }
  const nextMainSettingsStep = () => {
    if (lastCompletedMainSettingsStep <= mainSettingsStep) {
      setLastCompletedMainSettingsStep((oldValue) => oldValue + 1)
    }
    setMainSettingsStep((oldValue) => oldValue + 1)
  }
  const nextAccountVerificationStep = () => {
    if (lastCompletedAccountVerificationStep <= accountVerificationStep) {
      setLastCompletedAccountVerificationStep((oldValue) => oldValue + 1)
    }
    setAccountVerificationStep((oldValue) => oldValue + 1)
  }

  const previousOnboardingStep = () => {
    setOnboardingSectionIndex((oldValue) => oldValue - 1)
  }
  const previousProfileSettingsStep = () => {
    setProfileSettingsStep((oldValue) => oldValue - 1)
  }
  const previousMainSettingsStep = () => {
    setMainSettingsStep((oldValue) => oldValue - 1)
  }
  const previousAccountVerificationStep = () => {
    setAccountVerificationStep((oldValue) => oldValue - 1)
  }
  const previousCompleteRegisterStep = () => {
    setCompleteRegisterStep((oldValue) => oldValue - 1)
  }

  useEffect(() => {
    setDataStorage("onboarding_step", lastCompletedOnboardingSection);
    fetchUpdateOnboardingStep({
      onboarding_step: lastCompletedOnboardingSection,
    })
  }, [lastCompletedOnboardingSection])

  useEffect(() => {
    setDataStorage("profile_settings_step", lastCompletedProfileSettingsStep);
    fetchUpdateOnboardingStep({
      profile_settings_step: lastCompletedProfileSettingsStep,
    })
  }, [lastCompletedProfileSettingsStep])

  useEffect(() => {
    setDataStorage("main_settings_step", lastCompletedMainSettingsStep);
    fetchUpdateOnboardingStep({
      main_settings_step: lastCompletedMainSettingsStep,
    })
  }, [lastCompletedMainSettingsStep])

  useEffect(() => {
    setDataStorage("account_verification_step", lastCompletedAccountVerificationStep);
    fetchUpdateOnboardingStep({
      account_verification_step: lastCompletedAccountVerificationStep,
    })
  }, [lastCompletedAccountVerificationStep])

  useEffect(() => {
    getAccountType()
  }, [getAccountType])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  return <>
    <OnboardingContext.Provider value={{
      onboardingSectionIndex,
      lastCompletedOnboardingSection,
      profileSettingsStep,
      mainSettingsStep,
      accountVerificationStep,
      completeRegisterStep,
      nextOnboardingSection,
      nextProfileSettingsStep,
      nextMainSettingsStep,
      nextAccountVerificationStep,
      previousOnboardingStep,
      previousProfileSettingsStep,
      previousMainSettingsStep,
      previousAccountVerificationStep,
      previousCompleteRegisterStep,
      setProfileSettingsStep,
      setAccountVerificationStep,
      isLoadingContext,
      accountType,
      isBusinessAccount,
      isCompleteLaterAlertOpen,
      toggleCompleteLaterAlert,
    }}>
      {children}
    </OnboardingContext.Provider>
  </>;
}

export function useOnboarding() {
  const {
    onboardingSectionIndex,
    lastCompletedOnboardingSection,
    profileSettingsStep,
    mainSettingsStep,
    accountVerificationStep,
    completeRegisterStep,
    nextOnboardingSection,
    nextProfileSettingsStep,
    nextMainSettingsStep,
    nextAccountVerificationStep,
    previousOnboardingStep,
    previousProfileSettingsStep,
    previousMainSettingsStep,
    previousAccountVerificationStep,
    previousCompleteRegisterStep,
    setProfileSettingsStep,
    setAccountVerificationStep,
    isLoadingContext,
    accountType,
    isBusinessAccount,
    isCompleteLaterAlertOpen,
    toggleCompleteLaterAlert,
  } = useContext(OnboardingContext)
  return {
    onboardingSectionIndex,
    lastCompletedOnboardingSection,
    profileSettingsStep,
    mainSettingsStep,
    accountVerificationStep,
    completeRegisterStep,
    nextOnboardingSection,
    nextProfileSettingsStep,
    nextMainSettingsStep,
    nextAccountVerificationStep,
    previousOnboardingStep,
    previousProfileSettingsStep,
    previousMainSettingsStep,
    previousAccountVerificationStep,
    previousCompleteRegisterStep,
    setProfileSettingsStep,
    setAccountVerificationStep,
    isLoadingContext,
    accountType,
    isBusinessAccount,
    isCompleteLaterAlertOpen,
    toggleCompleteLaterAlert,
  }
}
