/* eslint-disable max-len */
import React from 'react';

const defaultColor = '#234581'

export default function YapayIcon({ size = 1, color = defaultColor }) {
  return <>
    <i>
      <svg
        width={size * 135}
        height={size * 63}
        viewBox="0 0 243 115"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M109.117 73.5141C109.712 73.0579 110.14 72.417 110.334 71.6913L112.55 63.6556L116.003 51.1296H124.149L120.344 63.6556L116.754 75.3942C116.199 77.2537 115.057 78.8828 113.501 80.0366C111.945 81.1904 110.058 81.8065 108.123 81.7931H100.039L94.5084 63.6603L90.7041 51.1344H98.8501L102.302 63.6603L105.227 74.2298H106.996C107.765 74.2465 108.515 73.9936 109.117 73.5141Z"
          fill={color === defaultColor ? "#6F6F84" : color}
        />
        <path
          d="M127.292 57.123V51.1296H134.549V57.123H127.292ZM127.468 81.7788V59.5662H134.549V81.7788H127.468Z"
          fill={color === defaultColor ? "#6F6F84" : color}
        />
        <path
          d="M147.697 59.5662V61.2554C148.128 60.919 148.588 60.6222 149.072 60.3678C149.6 60.0877 150.156 59.8635 150.731 59.6998C151.355 59.5165 151.991 59.3763 152.634 59.2799C153.365 59.1706 154.105 59.1181 154.845 59.1224H156.614C158.07 59.0957 159.517 59.3686 160.865 59.9241C162.045 60.4132 163.114 61.1375 164.008 62.0523C164.862 62.9398 165.532 63.9896 165.977 65.1396C166.434 66.3068 166.667 67.5503 166.667 68.8043V81.7788H159.581V68.7614C159.584 68.2188 159.502 67.6796 159.339 67.1628C159.185 66.6714 158.919 66.2219 158.564 65.8506C158.174 65.4593 157.705 65.1568 157.189 64.9631C156.553 64.7283 155.879 64.6147 155.201 64.629H153.737C152.554 64.5727 151.372 64.7631 150.265 65.1873C149.583 65.4622 148.971 65.8859 148.473 66.428C148.134 66.7988 147.897 67.2507 147.783 67.7402C147.734 67.9893 147.697 68.2408 147.674 68.4942V81.7788H140.617V59.5662H147.697Z"
          fill={color === defaultColor ? "#6F6F84" : color}
        />
        <path
          d="M193.202 51.1296H200.287V81.7788H194.533L194.091 79.245C193.076 80.1473 191.915 80.8688 190.658 81.378C189.128 81.9754 187.496 82.2627 185.855 82.2226H182.935C181.416 82.234 179.906 81.994 178.465 81.5116C177.144 81.0755 175.918 80.3888 174.855 79.4884C173.845 78.6232 173.024 77.5577 172.444 76.3581C171.84 75.0978 171.536 73.7145 171.555 72.3164V69.1908C171.548 67.7731 171.874 66.3736 172.506 65.1062C173.114 63.8741 173.975 62.7847 175.031 61.9091C176.138 60.992 177.406 60.2915 178.769 59.8429C180.26 59.3424 181.824 59.0942 183.396 59.1081H185.831C187.24 59.0866 188.641 59.3195 189.968 59.7952C191.137 60.2247 192.234 60.8312 193.221 61.5942L193.202 51.1296ZM193.202 72.7601V68.3176C193.115 67.955 192.953 67.6152 192.726 67.3203C192.375 66.8236 191.931 66.4003 191.418 66.0749C190.714 65.623 189.946 65.2785 189.141 65.0537C188.057 64.7521 186.936 64.609 185.812 64.629H184.751C183.937 64.6252 183.126 64.7225 182.336 64.9201C181.636 65.0862 180.976 65.3877 180.391 65.8077C179.841 66.2118 179.401 66.7463 179.107 67.3633C178.777 68.0785 178.614 68.8606 178.631 69.6489V71.6483C178.618 72.3793 178.754 73.1051 179.031 73.7813C179.289 74.393 179.69 74.9332 180.2 75.356C180.761 75.8098 181.408 76.1429 182.103 76.3342C182.944 76.5699 183.816 76.6821 184.69 76.6682H185.798C186.945 76.693 188.088 76.5318 189.183 76.1911C189.99 75.9439 190.752 75.5702 191.442 75.084C191.942 74.7318 192.37 74.2862 192.702 73.7718C192.914 73.4592 193.083 73.119 193.202 72.7601Z"
          fill={color === defaultColor ? "#6F6F84" : color}
        />
        <path
          d="M206.35 57.123V51.1296H213.612V57.123H206.35ZM206.531 81.7788V59.5662H213.612V81.7788H206.531Z"
          fill={color === defaultColor ? "#6F6F84" : color}
        />
        <path
          d="M44.4819 39.0666H32.6552C32.0707 39.0576 31.493 39.193 30.9731 39.4609C30.4531 39.7289 30.0069 40.1211 29.6737 40.603C29.3405 41.0849 29.1307 41.6417 29.0627 42.2243C28.9946 42.8068 29.0705 43.3972 29.2837 43.9433L47.1638 88.3209L54.2113 57.6431C54.4139 56.7599 54.3411 55.8351 54.0021 54.9948L48.7379 41.9058C48.3907 41.0613 47.8001 40.34 47.0418 39.8342C46.2836 39.3284 45.3923 39.0611 44.4819 39.0666Z"
          fill={color}
        />
        <path
          d="M86.9759 26.6408H73.8653C72.9418 26.6377 72.0388 26.9149 71.2746 27.4361C70.5109 27.9573 69.9221 28.698 69.5855 29.5611L47.1781 88.3305H65.0108C65.9348 88.3348 66.8383 88.058 67.6025 87.5369C68.3667 87.0154 68.9549 86.2738 69.2906 85.4101L90.0384 31.0117C90.2238 30.5188 90.2861 29.9879 90.2205 29.4652C90.1544 28.9425 89.9618 28.4439 89.6598 28.0129C89.3579 27.5819 88.9556 27.2316 88.4876 26.9924C88.0197 26.7532 87.5009 26.6325 86.9759 26.6408Z"
          fill={color === defaultColor ? "#00A3FF" : color}
        />
      </svg>
    </i>
  </>;
}
