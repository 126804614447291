import apiPrivate from 'services/apiPrivate'
import { hardResetToYapayPortal } from 'utils/hardResetToYapayPortal'
import { setDataStorage } from 'utils/storage'
export const fetchAccessToken = async (code) => {
  code = code.replace("/auth/callback/", "")
  setDataStorage('yapay_code', code)
  return apiPrivate.get("/signin/tokens", {
    headers: {
      code
    }
  }).then(async ({ data }) => {
    const { token } = data
    if (token) {
      setDataStorage("session_id", token)
    } else {
      hardResetToYapayPortal()
    }
  }).catch((error) => {
    console.log(error)
    hardResetToYapayPortal()
  })
}