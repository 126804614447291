import { Button } from '@material-ui/core';
import LoadableButton from 'components/shared/LoadableButton';
import SnackbarAlert from 'components/shared/SnackbarAlert';
import React from 'react';
import { GAEventTracker } from 'utils/GAEventTracker';
import { FormButtonsContainer } from './styles';

export default function FormButtons({
  dirty,
  isValid,
  isSubmitting,
  resetForm,
  isSnackbarOpen,
  setSnackbarOpen,
  onCancel = () => { },
  isEnabled = false,
  fromMyAccount = false,
  saveButtonId = "",
  cancelButtonId = "",
}) {
  const handleCancel = () => {
    resetForm()
    onCancel()
  }

  return <>
    <FormButtonsContainer
      in={dirty || isEnabled}
    >
      <LoadableButton
        onClick={() => {
          if (fromMyAccount) {
            GAEventTracker({
              category: "Página minha conta",
              action: "Salvar editar dados",
              label: "No modo de ediçao da página 'Minha conta', clicar em 'salvar'"
            })
          }
        }}
        type="submit"
        disabled={isSubmitting || !isValid}
        isLoading={isSubmitting}
        {...(!!saveButtonId && { id: saveButtonId })}
      >
        Salvar
      </LoadableButton>
      <Button
        onClick={handleCancel}
        disabled={isSubmitting}
        variant="outlined"
        {...(!!cancelButtonId && { id: cancelButtonId })}
      >
        Cancelar
      </Button>
    </FormButtonsContainer>
    <SnackbarAlert
      isOpen={isSnackbarOpen}
      onClose={() => { setSnackbarOpen(false) }}
      message="Salvo"
    />
  </>;
}
