import EmailDataStep from 'components/Onboarding/OnboardingSteps/AccountDataSteps/EmailDataStep';
import EmailSMSVerificationStep from 'components/Onboarding/OnboardingSteps/AccountDataSteps/EmailSMSVerificationStep';
import EmailEditStep from 'components/Onboarding/OnboardingSteps/AccountDataSteps/EmailEditStep';
import PhoneDataStep from 'components/Onboarding/OnboardingSteps/AccountDataSteps/PhoneDataStep';
import PhoneSMSVerificationStep from 'components/Onboarding/OnboardingSteps/AccountDataSteps/PhoneSMSVerificationStep';
// import AdditionalDataStep from 'components/Onboarding/OnboardingSteps/AccountDataSteps/AdditionalDataStep';
// import CompleteBusinessAccountTitularDataStep from 'components/Onboarding/OnboardingSteps/AccountDataSteps/CompleteBusinessAccountTitularDataStep';
// import PartnersDataStep from 'components/Onboarding/OnboardingSteps/AccountDataSteps/PartnersDataStep';
// import TitularDataStep from 'components/Onboarding/OnboardingSteps/AccountDataSteps/TitularDataStep';
import TitularAndAddressForm from 'forms/Onboarding/AccountDataSection/TitularAndAddressForm';
import BankAccountForm from 'forms/Onboarding/AccountDataSection/BankAccountForm';
// import CompleteRegistrationInstructions from 'components/Onboarding/OnboardingSteps/AccountDataSteps/CompleteRegistrationInstructions';

export const accountDataStepsDictionary = {
  titularAndAddressStep: {
    stepTitle: "Titularidade e endereço",
    stepComponent: <TitularAndAddressForm />,
    stepIndex: 1,
  },
  phoneDataStep: {
    stepTitle: "Verificação de celular",
    stepComponent: <PhoneDataStep />,
    stepIndex: 2,
  },
  phoneSMSVerificationStep: {
    stepTitle: "Verificação de celular",
    stepComponent: <PhoneSMSVerificationStep />,
    stepIndex: 2,
    hideOnCounting: true,
  },
  emailDataStep: {
    stepTitle: "E-mail",
    stepComponent: <EmailDataStep />,
    stepIndex: 3,
  },
  emailSMSVerificationStep: {
    stepTitle: "E-mail",
    stepComponent: <EmailSMSVerificationStep />,
    stepIndex: 3,
    hideOnCounting: true,
  },
  emailEditStep: {
    stepTitle: "E-mail",
    stepComponent: <EmailEditStep />,
    stepIndex: 3,
    hideOnCounting: true,
  },
  bankAccountForm: {
    stepTitle: "Conta bancária",
    stepComponent: <BankAccountForm />,
    stepIndex: 4,
  }
}

export const UNUSED_accountDataStepsDictionary = [
  {
    stepTitle: "Titularidade e endereço",
    stepComponent: <TitularAndAddressForm />,
    stepIndex: 1,
  },
  {
    stepTitle: "Verificação de celular",
    stepComponent: <PhoneDataStep />,
    stepIndex: 2,
  },
  {
    stepTitle: "Verificação de celular",
    stepComponent: <PhoneSMSVerificationStep />,
    stepIndex: 2,
  },
  {
    stepTitle: "E-mail",
    stepComponent: <EmailDataStep />,
    stepIndex: 3,
  },
  {
    stepTitle: "E-mail",
    stepComponent: <EmailSMSVerificationStep />,
    stepIndex: 3,
  },
  {
    stepTitle: "E-mail",
    stepComponent: <EmailEditStep />,
    stepIndex: 3,
  },
  {
    stepTitle: "Conta bancária",
    stepComponent: <BankAccountForm />,
    stepIndex: 4,
  },
]