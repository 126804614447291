const getShortLocaleStringDate = (receivedDate) => {
  return new Date(receivedDate).toLocaleString("pt-BR", {
    dateStyle: 'short',
  })
}
const getShouldShowYear = (receivedDate = "", showYear) => {
  if (showYear) {
    return receivedDate
  } else {
    return receivedDate.split("/")[2] === String(new Date().getFullYear()) ?
      receivedDate.split("/", 2).join("/") :
      receivedDate
  }

}
export const getFormatedDate = (
  receivedDate,
  showYear = false,
  previousText = "",
) => {
  const today = new Date()
  const tomorrow = new Date(today)
  tomorrow.setDate(tomorrow.getDate() + 1)
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)
  const formatedDate = getShortLocaleStringDate(new Date(receivedDate))
  switch (formatedDate) {
    case getShortLocaleStringDate(today):
      return previousText ? "hoje" : "Hoje"

    case getShortLocaleStringDate(tomorrow):
      return previousText ? "amanhã" : "Amanhã"

    case getShortLocaleStringDate(yesterday):
      return previousText ? "ontem" : "Ontem"

    default:
      return `${previousText} ${getShouldShowYear(formatedDate, showYear)}`
  }
}