/* eslint-disable max-len */
import React from 'react';

export default function VindiPackageIcon({ size = 1, color = "#0092FA" }) {
  return <>
    <i>
      <svg
        width={25 * size}
        height={25 * size}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.11699 4.29376C7.39289 3.81532 8.0044 3.65113 8.48283 3.92703L17.4828 9.11703C17.9613 9.39293 18.1255 10.0044 17.8496 10.4829C17.5737 10.9613 16.9622 11.1255 16.4837 10.8496L7.48372 5.6596C7.00529 5.3837 6.8411 4.77219 7.11699 4.29376Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.9854 1.98605C11.441 1.72355 11.9575 1.58536 12.4833 1.58536C13.0091 1.58536 13.5256 1.72356 13.9812 1.98608C13.9819 1.98648 13.9826 1.98689 13.9833 1.98729L20.9833 5.98728C21.4389 6.25033 21.8173 6.62856 22.0806 7.08403C22.3439 7.5395 22.4827 8.0562 22.4833 8.58229V16.5843C22.4827 17.1104 22.3439 17.6271 22.0806 18.0826C21.8173 18.5381 21.4389 18.9163 20.9833 19.1793L20.9794 19.1816L13.9833 23.1793C13.9827 23.1797 13.9821 23.18 13.9815 23.1804C13.5259 23.443 13.0092 23.5813 12.4833 23.5813C11.9574 23.5813 11.4407 23.443 10.9851 23.1804C10.9845 23.1801 10.9839 23.1797 10.9833 23.1793L3.98714 19.1816L3.98328 19.1793C3.52767 18.9163 3.14925 18.5381 2.88597 18.0826C2.62269 17.6271 2.48382 17.1104 2.48328 16.5843V8.58229C2.48382 8.0562 2.62269 7.5395 2.88597 7.08403C3.14925 6.62856 3.52767 6.25033 3.98328 5.98729L3.98713 5.98506L10.9854 1.98605ZM12.4833 3.58536C12.3077 3.58536 12.1353 3.63157 11.9833 3.71934L11.9794 3.72156L4.98328 7.71934C4.98275 7.71964 4.98222 7.71995 4.9817 7.72025C4.83053 7.80789 4.70496 7.93362 4.61751 8.08492C4.52982 8.23662 4.48353 8.40869 4.48328 8.58391V16.5827C4.48353 16.7579 4.52982 16.93 4.61751 17.0817C4.70496 17.233 4.83053 17.3587 4.9817 17.4464C4.98222 17.4467 4.98275 17.447 4.98328 17.4473L11.9833 21.4473C12.1353 21.5351 12.3077 21.5813 12.4833 21.5813C12.6588 21.5813 12.8313 21.5351 12.9833 21.4473L12.9871 21.4451L19.9833 17.4473C19.9838 17.447 19.9843 17.4467 19.9849 17.4464C20.136 17.3587 20.2616 17.233 20.349 17.0817C20.4368 16.9299 20.4831 16.7577 20.4833 16.5823V8.58434C20.4831 8.40898 20.4368 8.23674 20.349 8.08492C20.2616 7.93363 20.136 7.8079 19.9849 7.72026C19.9843 7.71995 19.9838 7.71964 19.9833 7.71934L12.9833 3.71935C12.8313 3.63158 12.6588 3.58536 12.4833 3.58536Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.88767 7.04259C3.16421 6.56453 3.77594 6.40116 4.254 6.67771L12.4833 11.4381L20.7126 6.67771C21.1906 6.40116 21.8023 6.56453 22.0789 7.04259C22.3554 7.52065 22.1921 8.13238 21.714 8.40892L12.984 13.4589C12.6742 13.6381 12.2923 13.6381 11.9826 13.4589L3.25256 8.40892C2.77449 8.13238 2.61113 7.52065 2.88767 7.04259Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4833 11.5833C13.0356 11.5833 13.4833 12.031 13.4833 12.5833V22.6633C13.4833 23.2156 13.0356 23.6633 12.4833 23.6633C11.931 23.6633 11.4833 23.2156 11.4833 22.6633V12.5833C11.4833 12.031 11.931 11.5833 12.4833 11.5833Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
