import React from 'react';
import { Field } from 'formik';
import { Collapse, MenuItem } from '@material-ui/core';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import BankNameAutocompleteField from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/BankNameAutocompleteField';
import { StyledTextField } from 'components/Dashboard/AccountDataMenu/styles';
import { BankAccountFieldsContainer, AccountNumberStyledTextField, VerifyingDigitStyledTextField } from './styles';
import WarningCircle from 'assets/icons/WarningCircle';
import { StyledAlert } from 'components/MyAccountMenu/AtentionAlertMyAccount/styles';

export default function BankAccountFields({
  values,
  errors,
  touched,
  isLoading = true,
  disabledForm = false,
  setFieldValue = () => { },
  bankOptionsList = [],
  accountTypesList = [],
}) {
  const handleBankAgencyUpdate = (e) => {
    setFieldValue("bankAccount.bankAgency", e.target.value.replace(/[^\d]/g, ""))
  }

  const handleBankAccountNumberUpdate = (e) => {
    setFieldValue("bankAccount.bankAccountNumber", e.target.value.replace(/[^\d]/g, ""))
  }

  const handleVerifyingDigitUpdate = (e) => {
    setFieldValue("bankAccount.verifyingDigit", e.target.value.replace(/[^\d]/g, ""))
  }

  return <>
    <BankAccountFieldsContainer>
      {
        isLoading ?
          <LoadingIndicator /> :
          <>
            <Field
              as={BankNameAutocompleteField}
              name="bankAccount.bankCode"
              label="Banco"
              size="small"
              error={!!errors.bankAccount?.bankCode}
              helperText={!disabledForm && errors.bankAccount?.bankCode}
              disabled={disabledForm}
              values={values}
              setFieldValue={setFieldValue}
              bankOptionsList={bankOptionsList}
            />
            <Field
              as={StyledTextField}
              name="bankAccount.bankAccountType"
              label="Tipo de conta"
              select
              size="small"
              error={touched.bankAccount?.bankAccountType && !!errors.bankAccount?.bankAccountType}
              helperText={touched.bankAccount?.bankAccountType && errors.bankAccount?.bankAccountType}
              disabled={disabledForm}
            >
              {
                accountTypesList.map((e, i) => (
                  <MenuItem
                    value={e.id}
                    key={i}
                  >
                    {e.description}
                  </MenuItem>
                ))
              }
            </Field>
            <Field
              as={StyledTextField}
              name="bankAccount.bankAgency"
              label="Agência (sem dígito)"
              size="small"
              error={touched.bankAccount?.bankAgency && !!errors.bankAccount?.bankAgency}
              helperText={touched.bankAccount?.bankAgency && errors.bankAccount?.bankAgency}
              disabled={disabledForm}
              onChange={handleBankAgencyUpdate}
            />
            <Field
              as={AccountNumberStyledTextField}
              name="bankAccount.bankAccountNumber"
              label="Conta"
              size="small"
              error={touched.bankAccount?.bankAccountNumber && !!errors.bankAccount?.bankAccountNumber}
              helperText={touched.bankAccount?.bankAccountNumber && errors.bankAccount?.bankAccountNumber}
              disabled={disabledForm}
              onChange={handleBankAccountNumberUpdate}
            />
            <Field
              as={VerifyingDigitStyledTextField}
              name="bankAccount.verifyingDigit"
              label="Dígito"
              size="small"
              error={touched.bankAccount?.verifyingDigit && !!errors.bankAccount?.verifyingDigit}
              helperText={
                (touched.bankAccount?.verifyingDigit && errors.bankAccount?.verifyingDigit) ||
                (!disabledForm && "Se o dígito for x, use 0 (zero)")
              }
              disabled={disabledForm}
              onChange={handleVerifyingDigitUpdate}
            />
            <Collapse
              in={!disabledForm}
            >
              <StyledAlert
                severity="info"
                icon={<WarningCircle />}
              >
                <b>
                  Atenção:
                </b>
                {" ao cadastrar uma nova conta bancária, faremos um crédito de valor simbólico na conta bancária informada. Este crédito é apenas para verificação de segurança e será feito automaticamente após a alteração da conta."}
              </StyledAlert>
            </Collapse>
          </>
      }
    </BankAccountFieldsContainer>
  </>;
}