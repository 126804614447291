import React from 'react';
import { DashboardMyAccountContainer } from './styles';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import AccountDataProvider from 'context/AccountDataContext';
import { myAccountDictionary } from 'library/myAccountDictionary';
import AccountVerificationProvider from 'context/AccountVerificationContext';

export default function DashboardMyAccount() {
  const { currentMyAccountMenuItem } = useDashboardMyAccount()

  return <>
    <AccountDataProvider>
      <AccountVerificationProvider>
        <DashboardMyAccountContainer>
          {
            myAccountDictionary[currentMyAccountMenuItem].component
          }
        </DashboardMyAccountContainer>
      </AccountVerificationProvider>
    </AccountDataProvider>
  </>;
}
