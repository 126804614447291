import styled from "styled-components";
import { Collapse, Tab, Tabs, TextField } from "@material-ui/core";
import { grayScale, primaryColors, textStyles } from "theme/styleguide";

export const TitularDataFormContainer = styled.div`
  margin-bottom: ${(({ $isOnboardingStep }) => $isOnboardingStep ? "48px" : "0px")};
`
export const StepBody = styled.span`
  display: block;
  white-space: pre-wrap;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-top: 32px;
`
export const InputSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 48px;
`
export const SectionTitle = styled.span`
  display: block;
  ${textStyles.tray_h5_15px}
  color: ${grayScale.gray_3};
  margin-bottom: 24px;
  @media (min-width: 768px)
  {
    ${textStyles.tray_h4_18px}
  }
`
export const StyledTextField = styled(TextField)`
  width: 100%;
  max-width: 100%;
  min-height: 64px;
  border-radius: 4px;
  .MuiFormHelperText-contained{
    margin-bottom: 16px;
    white-space: pre-wrap;
  }
  @media (min-width: 768px)
  {
    max-width: ${({ $fullWidth }) => $fullWidth ? "100%" : "50%"};
    padding-right: 20px;
  }
`
export const InputContainerRow = styled.div`
  flex: 1;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:space-between;
  .layoutSpacing{
    width: 100%;
  }
  @media (min-width: 768px)
  {
    .layoutSpacing{
      width: 50%;
    }
  }
`
export const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator{
    background-color: ${primaryColors.blue_5};
  }
  margin-bottom: 24px;
  @media (min-width: 768px)
  {
    margin-bottom: 32px;
  }
`
export const StyledTab = styled(Tab)`
  min-height: fit-content;
  margin-top: 24px;
  ${textStyles.tray_h5_15px}
  opacity: 1;
  color: ${({ selected }) => selected ?
    primaryColors.blue_5 :
    grayScale.gray_60p100
  };
  @media (min-width: 768px)
  {
    min-height: 56px;
    margin-top: 64px;
    .MuiTab-wrapper{
      flex-direction: row;
      i{
        margin-right: 16px;
      }
    }
  }
`
export const StyledCollapse = styled(Collapse)`
  .MuiCollapse-wrapperInner{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
`
export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  @media (min-width: 768px)
  {
    justify-content: flex-end;
    margin-bottom: 32px;
    >span{
      display: none;
    }
  }
`
export const SelectedTabTitle = styled.span`
  ${textStyles.tray_h5_15px}
  color: ${grayScale.gray_3};
  padding: 12px 0px;
`