import LoadableButton from "components/shared/LoadableButton";
import styled from "styled-components";
import { textStyles, grayScale } from "theme/styleguide";

export const SuccessUploadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
`
export const Title = styled.span`
  ${textStyles.tray_h4_18px}
  color: ${grayScale.black};
  margin-top: 28px;
  margin-bottom: 16px;
  color: ${grayScale.black};
  @media (min-width: 768px)
  {
    ${textStyles.tray_h3_24px}
    margin-top: 36px;
    margin-bottom: 24px;
  }
`
export const Instruction = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  max-width: 288px;
  margin-bottom: 112px;
  @media (min-width: 768px)
  {
    margin-bottom: 32px;
    max-width: 472px;
  }
`
export const StyledButton = styled(LoadableButton)`
  min-width: 254px;
  margin-bottom: 72px;
  @media (min-width: 768px)
  {
    min-width: 124px;
  }
`