/* eslint-disable max-len */
import React from 'react';

export default function AccountBoxIcon({ size = 1, color = "#2E7EE7" }) {
  return <>
    <i>
      <svg
        width={30 * size}
        height={30 * size}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M23.75 3.75H6.25C4.875 3.75 3.75 4.875 3.75 6.25V23.75C3.75 25.125 4.875 26.25 6.25 26.25H23.75C25.125 26.25 26.25 25.125 26.25 23.75V6.25C26.25 4.875 25.125 3.75 23.75 3.75ZM21.25 23.75H8.75V23.45C10.525 22.025 12.7 21.25 15 21.25C17.3 21.25 19.475 22.025 21.25 23.45V23.75ZM23.75 22.325C21.5 20.1125 18.4125 18.75 15 18.75C11.5875 18.75 8.5 20.1125 6.25 22.325V6.25H23.75V22.325ZM15 16.25C17.4125 16.25 19.375 14.2875 19.375 11.875C19.375 9.4625 17.4125 7.5 15 7.5C12.5875 7.5 10.625 9.4625 10.625 11.875C10.625 14.2875 12.5875 16.25 15 16.25ZM15 10C16.0375 10 16.875 10.8375 16.875 11.875C16.875 12.9125 16.0375 13.75 15 13.75C13.9625 13.75 13.125 12.9125 13.125 11.875C13.125 10.8375 13.9625 10 15 10Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
