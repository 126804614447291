import apiPublic from 'services/apiPublic'
import { getDataStorage, setDataStorage } from 'utils/storage'

export const fetchRefreshAccessToken = async () => {
  const access_token = getDataStorage('yapay_permissioned_access_token')
  const refresh_token = getDataStorage('yapay_permissioned_refresh_token')

  return apiPublic.post("/v1/authorizations/refresh", {
    access_token,
    refresh_token,
  })
    .then(({ data }) => {
      setDataStorage('yapay_permissioned_access_token', data.authorization.data_response.authorization.access_token)
      setDataStorage('yapay_permissioned_refresh_token', data.authorization.data_response.authorization.refresh_token)
      setDataStorage('access_token_expiration', data.authorization.data_response.authorization.access_token_expiration)
      setDataStorage('refresh_token_expiration', data.authorization.data_response.authorization.refresh_token_expiration)
      return data.authorization.data_response.authorization.access_token
    })
    .catch((error) => {
      console.error(error)
      return null
    })
}