import styled from "styled-components";

export const SallesStatusContainer = styled.div`
  padding: 0px;
  @media (min-width: 768px)
  {
    padding-bottom: 28px;
  }
`
export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 64px;
  i{
    margin-left: 8px;
  }
  @media (min-width: 920px)
  {
    margin-top: 0px;
    i{
      margin-left: 16px;
    }
  }
`
export const EmptyStateImage = styled.img`
  max-width: 133px;
`

export const SpinnerContainer = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`