import { useOnboarding } from 'context/OnboardingContext';
import { Form, Formik } from 'formik';
import React from 'react';
import { EmailEditStepContainer } from './styles';
import { ButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import EmailEditForm from 'components/Onboarding/OnboardingForms/AccountDataForms/EmailEditForm';
import useEmailEditSetup from 'components/FormsSetup/AccountData/useEmailEditSetup';
import LoadableButton from 'components/shared/LoadableButton';
import { getCurrentOnboardingStepsCollection } from 'utils/getCurrentOnboardingStepsCollection';
import { Button } from '@material-ui/core';

export default function EmailEditStep() {
  const stepsCollectionLength = getCurrentOnboardingStepsCollection("accountDataSteps").length - 1
  const { nextProfileSettingsStep, previousProfileSettingsStep, nextOnboardingSection, profileSettingsStep } = useOnboarding()
  const { initialValues, validationSchema, isLoading, handleSubmit } = useEmailEditSetup({
    afterSubmit: () => {
      if (profileSettingsStep < stepsCollectionLength) {
        nextProfileSettingsStep()
      } else {
        nextOnboardingSection()
      }
    },
    errorSubmit: () => {
      previousProfileSettingsStep()
      previousProfileSettingsStep()
    }
  })
  const handleCancel = () => {
    previousProfileSettingsStep()
    previousProfileSettingsStep()
  }
  return <>
    <EmailEditStepContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <EmailEditForm
                {...formikProps}
                isLoading={isLoading}
                initialValues={initialValues}
              />
              <ButtonsContainer>
                <LoadableButton
                  type="submit"
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_informe_novo_email_onboarding_botao_continuar"}
                >
                  Continuar
                </LoadableButton>
                <Button
                  variant="outlined"
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  onClick={handleCancel}
                  id={"tela_informe_novo_email_onboarding_botao_cancelar"}
                >
                  Cancelar
                </Button>
              </ButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </EmailEditStepContainer>
  </>;
}
