import PaidIcon from 'assets/icons/PaidIcon';
import React, { useCallback, useEffect, useState } from 'react';
import { fetchGetBalance } from 'services/Balance/GET/GetBalanceService';
import { formatToBRL } from 'utils/formatToBRL';
import { MoneySubtitle, MoneyText } from 'components/Dashboard/DashboardOverview/styles';
import { AvaiableBalanceContainer, Column, SpinnerContainer } from './styles';
import { CircularProgress } from '@material-ui/core';
import { useRef } from 'react';

export default function AvaiableBalance() {
  const mounted = useRef(false);
  const [avaiableMoney, setAvaiableMoney] = useState("R$ 0,00")
  const [loading, setLoading] = useState(true)

  const fetchAvaiableBalanceData = useCallback(async () => {
    setLoading(true)
    const { available } = await fetchGetBalance()
    if (mounted.current) {
      setAvaiableMoney(formatToBRL(available))
      setLoading(false)
    }
  }, [])
  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  useEffect(() => {
    fetchAvaiableBalanceData()
  }, [fetchAvaiableBalanceData])
  return <>
    <AvaiableBalanceContainer>
      <PaidIcon />
      <Column>
        {loading ? <SpinnerContainer>
          <CircularProgress size="25px" />
        </SpinnerContainer> : <MoneyText>
          {avaiableMoney}
        </MoneyText>}
        <MoneySubtitle>
          Saldo disponível
        </MoneySubtitle>
      </Column>
    </AvaiableBalanceContainer>
  </>;
}
