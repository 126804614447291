import React from 'react';
import { useOnboarding } from 'context/OnboardingContext';
import { ButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import { Form, Formik } from 'formik';
import { BillingEmailsStepContainer } from './styles';
import BillingEmailForm from 'components/Onboarding/OnboardingForms/MainSettingsForms/BillingEmailForm';
import useBillingEmailSetup from 'components/FormsSetup/MainSettings/useBillingEmailsSetup';
import LoadableButton from 'components/shared/LoadableButton';
import { getOrigemTypeVariantSectionKey } from 'utils/getOrigemTypeVariantSectionKey';
import { getCurrentOnboardingStepsCollection } from 'utils/getCurrentOnboardingStepsCollection';

export default function BillingEmailsStep() {
  const { mainSettingsStep, nextMainSettingsStep, nextOnboardingSection } = useOnboarding()
  const { initialValues, validationSchema, isLoading, handleSubmit } = useBillingEmailSetup(({
    afterSubmit: () => {
      const origemTypeVariantSectionKey = getOrigemTypeVariantSectionKey()
      const stepsCollectionLength = getCurrentOnboardingStepsCollection(origemTypeVariantSectionKey).length - 1
      if (mainSettingsStep < stepsCollectionLength) {
        nextMainSettingsStep()
      } else {
        nextOnboardingSection()
      }
    },
  }))

  return <>
    <BillingEmailsStepContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <BillingEmailForm
                {...formikProps}
                isLoading={isLoading}
              />
              <ButtonsContainer>
                <LoadableButton
                  type="submit"
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_config_emails_onboarding_botao_continuar"}
                >
                  Continuar
                </LoadableButton>
              </ButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </BillingEmailsStepContainer>
  </>;
}
