import { GlobalStyle } from "./styles";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "theme/theme";
import AppRouting from "components/AppRouting";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBRLocale from "date-fns/locale/pt-BR";
import YupSetup from "components/shared/YupSetup";

function App() {
  return (
    <>
      <CssBaseline />
      <GlobalStyle />
      <YupSetup />
      <ThemeProvider
        theme={theme}
      >
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={ptBRLocale}
        >
          <AppRouting />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
