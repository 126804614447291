import { Accordion, Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import RadioOptions from "components/shared/RadioOptions";
import styled from "styled-components";
import { borderStyles, buttonStyles, grayScale, primaryColors, textStyles } from "theme/styleguide";
export const PartnersFieldsContainer = styled.div`
  
`
export const StyledRadioOptions = styled(RadioOptions)`
  margin-bottom: 16px;
  .MuiFormControlLabel-root{
    margin-right: 42px;
  }
  @media (min-width: 768px)
  {
    margin-bottom: 32px;
  }
`
export const AccountTypeViewer = styled.span`
  ${textStyles.h5_16px}
  white-space: pre-wrap;
  margin-top: 8px;
  margin-bottom: 24px;
  b{
    color: ${primaryColors.blue_3};
  }
  display: ${({ $isMobile }) => $isMobile ? "block" : "none"};
  @media (min-width: 768px)
  {
    display: ${({ $isMobile }) => $isMobile ? "none" : "block"};
  }
`
export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  path{
    opacity: 1;
  }
  ${borderStyles.border_2};
  margin-bottom: 12px;
  :before{
    height: 0px;
  }
  .MuiAccordionSummary-root{
    min-height: fit-content;
    ${textStyles.tray_h5_15px}
    font-weight: 700;
    color: ${grayScale.black};
  }
  .MuiAccordionSummary-expandIcon{
      margin-right: 0px;
    }
  .MuiAccordionSummary-content{
    justify-content: space-between;
    align-items: center;
    margin-right: 32px;
  }
  .MuiAccordionDetails-root{
    flex-direction: column;
  }
  @media (min-width: 768px)
  {
    .MuiAccordionSummary-root{
      min-height: 76px;
      ${textStyles.h5_16px}
      font-weight: 700;
      color: ${grayScale.black};
    }
    .MuiAccordionSummary-expandIcon{
      margin-right: 0px;
    }
  }
`
export const StyledAddButton = styled(Button)`
  ${buttonStyles.yapay_bluish_add}
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 32px;
`
export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const ActionsRow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
  .circularProgress{
    display: block;
    margin: 4px 8px;
    color: ${grayScale.gray_3};
  }
`
export const StyledAlert = styled(Alert)`
  white-space: pre-wrap;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-bottom: 36px;
  @media (min-width: 768px)
  {
    margin-right: 20px;
  }
`