import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchValidateDocument = async (document = "") => {
  const yapay_code = getDataStorage('yapay_code')
  const store_id = getDataStorage('store_id')
  const adm_user = getDataStorage('store_admin_user')
  return apiPrivate.post("/myaccount/account/validate_document", {
    store_id,
    adm_user,
    document: document.replace(/[^\d]+/g, '')
  }, {
    headers: {
      code: yapay_code
    }
  })
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}