import { Collapse, MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import React from 'react';
import { InputContainerRow, StyledTextField } from 'components/Onboarding/OnboardingForms/AccountDataForms/TitularDataForm/styles';
import BankNameAutocompleteField from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/BankNameAutocompleteField';
import { AccountNumberStyledTextField, BankAccountFieldsContainer, InstructionDetails, VerifyingDigitStyledTextField, StyledAlert } from './styles';

export default function BankAccountFields({
  values,
  errors,
  touched,
  disabledForm = false,
  isOnboardingStep = false,
  bankOptionsList = [],
  accountTypesList = [],
  missingPendencies = {},
  setFieldValue = () => { },
}) {
  const handleBankAgencyUpdate = (e) => {
    setFieldValue("bankAccount.bankAgency", e.target.value.replace(/[^\d]/g, ""))
  }

  const handleBankAccountNumberUpdate = (e) => {
    setFieldValue("bankAccount.bankAccountNumber", e.target.value.replace(/[^\d]/g, ""))
  }

  const handleVerifyingDigitUpdate = (e) => {
    setFieldValue("bankAccount.verifyingDigit", e.target.value.replace(/[^\d]/g, ""))
  }

  return <>
    <BankAccountFieldsContainer>
      {
        missingPendencies?.bank_account &&
        <StyledAlert
          severity="info"
        >
          <b>
            {`Atenção: `}
          </b>
          {`Identificamos que `}
          <b>
            {`você não possui conta bancária cadastrada `}
          </b>
          {`em nossa plataforma ou seus dados bancários estão incompletos.\n\nPedimos que cadastre uma conta bancária de sua titularidade para garantir o recebimento de suas vendas, conforme a `}
          <a
            href="https://atendimento.yapay.com.br/hc/pt-br/articles/4948199391259"
            target="_blank"
            rel="noreferrer"
          >
            {`Resolução BCB n° 150 de 6/10/2021`}
          </a>
          {`.\nCaso contrário, você poderá ficar impossibilitado de vender temporariamente e seus recebíveis ficarem retidos.`}
        </StyledAlert>
      }
      {
        missingPendencies?.bank_cip &&
        <StyledAlert
          severity="info"
        >
          <b>
            {`Atenção: `}
          </b>
          {`Identificamos que seu banco não faz parte dos bancos ativos na CIP. Portanto é necessário trocar sua conta bancária por outro banco para garantir o recebimento de suas vendas, conforme a `}
          <a
            href="https://atendimento.yapay.com.br/hc/pt-br/articles/4948199391259"
            target="_blank"
            rel="noreferrer"
          >
            {`Resolução BCB n° 150 de 6/10/2021`}
          </a>
          {`. Caso contrário, você poderá ficar impossibilitado de vender temporariamente e seus recebíveis ficarem retidos.\n\n`}
          <b>
            {`O que é a CIP?`}
          </b>
          {`\nA Câmara Interbancária de Pagamentos (CIP) é responsável por centralizar todos os pagamentos de vendas de cartão e antecipações, além de garantir que o lojista receba suas vendas com maior segurança.`}
        </StyledAlert>
      }
      <InputContainerRow>
        <Field
          as={BankNameAutocompleteField}
          name="bankAccount.bankCode"
          label="Banco"
          size="small"
          error={!!errors.bankAccount?.bankCode}
          helperText={!disabledForm && errors.bankAccount?.bankCode}
          disabled={disabledForm}
          values={values}
          setFieldValue={setFieldValue}
          bankOptionsList={bankOptionsList}
        />
        <Field
          as={StyledTextField}
          name="bankAccount.bankAccountType"
          label="Tipo de conta"
          select
          size="small"
          error={touched.bankAccount?.bankAccountType && !!errors.bankAccount?.bankAccountType}
          helperText={touched.bankAccount?.bankAccountType && errors.bankAccount?.bankAccountType}
          disabled={disabledForm}
        >
          {
            accountTypesList.map((e, i) => (
              <MenuItem
                value={e.id}
                key={i}
              >
                {e.description}
              </MenuItem>
            ))
          }
        </Field>
        <Field
          as={StyledTextField}
          name="bankAccount.bankAgency"
          label="Agência (sem dígito)"
          size="small"
          error={touched.bankAccount?.bankAgency && !!errors.bankAccount?.bankAgency}
          helperText={touched.bankAccount?.bankAgency && errors.bankAccount?.bankAgency}
          disabled={disabledForm}
          onChange={handleBankAgencyUpdate}
        />
        <Field
          as={AccountNumberStyledTextField}
          name="bankAccount.bankAccountNumber"
          label="Conta"
          size="small"
          error={touched.bankAccount?.bankAccountNumber && !!errors.bankAccount?.bankAccountNumber}
          helperText={touched.bankAccount?.bankAccountNumber && errors.bankAccount?.bankAccountNumber}
          disabled={disabledForm}
          onChange={handleBankAccountNumberUpdate}
        />
        <Field
          as={VerifyingDigitStyledTextField}
          name="bankAccount.verifyingDigit"
          label="Dígito"
          size="small"
          error={touched.bankAccount?.verifyingDigit && !!errors.bankAccount?.verifyingDigit}
          helperText={
            (touched.bankAccount?.verifyingDigit && errors.bankAccount?.verifyingDigit) ||
            (!disabledForm && "Se o dígito for x, use 0 (zero)")
          }
          disabled={disabledForm}
          onChange={handleVerifyingDigitUpdate}
        />
      </InputContainerRow>
      <Collapse
        in={!disabledForm && isOnboardingStep}
      >
        <InstructionDetails>
          Ao cadastrar uma nova conta bancária, faremos um crédito de valor simbólico na conta bancária informada. Este crédito é apenas para verificação de segurança e será feito automaticamente após o cadastro da conta.
        </InstructionDetails>
      </Collapse>
    </BankAccountFieldsContainer>
  </>;
}
