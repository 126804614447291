import { addMethod, object } from "yup";

export default function YupSetup() {

  addMethod(object, 'uniqueProperty', function (propertyName, message, customWhen, customIs) {
    return this.test('unique', message, function (value) {
      if (!value || !value[propertyName]) {
        return true
      }

      const { path } = this
      const options = [...this.parent]
      const currentIndex = options.indexOf(value)

      const subOptions = options.slice(0, currentIndex)
      let foundEntries = false

      if (customWhen !== undefined && customIs !== undefined) {
        foundEntries = subOptions.some((option) =>
          value[customWhen] === customIs && // Prossegue a verificação apenas se o valor em value[customWhen] corresponder ao valor esperado para customIs
          option[propertyName] === value[propertyName]
        )
      } else {
        foundEntries = subOptions.some((option) =>
          option[propertyName] === value[propertyName]
        )
      }

      if (foundEntries) {
        throw this.createError({
          path: `${path}.${propertyName}`,
          message,
        })
      }

      return true
    })
  })

  return null;
}
