/* eslint-disable max-len */
import React from 'react';

export default function CardBrandPlaceholderIcon({ size = 1, color = "#C4C4C4" }) {
  return <>
    <i>
      <svg
        width={40*size}
        height={40*size}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width={40*size}
          height={40*size}
          rx="4"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
