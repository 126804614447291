import React from 'react';
import { Field } from 'formik';
import { MenuItem } from '@material-ui/core';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { BankAccountFieldsContainer, AccountNumberStyledTextField, VerifyingDigitStyledTextField, BankAccountInstructions, StyledAlert } from './styles';
import { StyledTextField, InputContainerRow } from 'components/Onboarding/OnboardingBody/styles';
import BankNameAutocompleteField from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/BankNameAutocompleteField';
import InfoBlackIcon from 'assets/icons/InfoBlackIcon';
import { primaryColors } from 'theme/styleguide';

export default function BankAccountFields({
  values,
  errors,
  touched,
  isLoading = true,
  disabledForm = false,
  setFieldValue = () => { },
  bankOptionsList = [],
  accountTypesList = [],
}) {
  const handleBankAgencyUpdate = (e) => {
    setFieldValue("bankAccount.bankAgency", e.target.value.replace(/[^\d]/g, ""))
  }

  const handleBankAccountNumberUpdate = (e) => {
    setFieldValue("bankAccount.bankAccountNumber", e.target.value.replace(/[^\d]/g, ""))
  }

  const handleVerifyingDigitUpdate = (e) => {
    setFieldValue("bankAccount.verifyingDigit", e.target.value.replace(/[^\d]/g, ""))
  }
  return <>
    <BankAccountFieldsContainer>
      {
        isLoading ?
          <LoadingIndicator /> :
          <>
            <BankAccountInstructions>
              {`Informe os dados da conta bancária que vai receber os valores das vendas.\nA conta bancária deve ser de mesma titularidade do CNPJ da empresa (para contas Pessoa Jurídica) ou da pessoa titular da conta (para contas Pessoa Física).`}
            </BankAccountInstructions>
            <InputContainerRow>
              <Field
                as={BankNameAutocompleteField}
                name="bankAccount.bankCode"
                label="Banco"
                size="small"
                error={!!errors.bankAccount?.bankCode}
                helperText={!disabledForm && errors.bankAccount?.bankCode}
                disabled={disabledForm}
                values={values}
                setFieldValue={setFieldValue}
                bankOptionsList={bankOptionsList}
              />
              <Field
                as={StyledTextField}
                name="bankAccount.bankAccountType"
                label="Tipo de conta"
                select
                size="small"
                error={touched.bankAccount?.bankAccountType && !!errors.bankAccount?.bankAccountType}
                helperText={touched.bankAccount?.bankAccountType && errors.bankAccount?.bankAccountType}
                disabled={disabledForm}
              >
                {
                  accountTypesList.map((e, i) => (
                    <MenuItem
                      value={e.id}
                      key={i}
                    >
                      {e.description}
                    </MenuItem>
                  ))
                }
              </Field>
              <Field
                as={StyledTextField}
                name="bankAccount.bankAgency"
                label="Agência (sem dígito)"
                size="small"
                error={touched.bankAccount?.bankAgency && !!errors.bankAccount?.bankAgency}
                helperText={touched.bankAccount?.bankAgency && errors.bankAccount?.bankAgency}
                disabled={disabledForm}
                onChange={handleBankAgencyUpdate}
              />
              <Field
                as={AccountNumberStyledTextField}
                name="bankAccount.bankAccountNumber"
                label="Conta"
                size="small"
                error={touched.bankAccount?.bankAccountNumber && !!errors.bankAccount?.bankAccountNumber}
                helperText={touched.bankAccount?.bankAccountNumber && errors.bankAccount?.bankAccountNumber}
                disabled={disabledForm}
                onChange={handleBankAccountNumberUpdate}
              />
              <Field
                as={VerifyingDigitStyledTextField}
                name="bankAccount.verifyingDigit"
                label="Dígito"
                size="small"
                error={touched.bankAccount?.verifyingDigit && !!errors.bankAccount?.verifyingDigit}
                helperText={
                  (touched.bankAccount?.verifyingDigit && errors.bankAccount?.verifyingDigit) ||
                  (!disabledForm && "Se o dígito for x, use 0 (zero)")
                }
                disabled={disabledForm}
                onChange={handleVerifyingDigitUpdate}
              />
            </InputContainerRow>
            <StyledAlert
              severity="info"
              icon={
                <InfoBlackIcon
                  color={primaryColors.blue_3}
                />
              }
            >
              <b>Atenção:</b> ao cadastrar uma conta bancária, faremos um crédito de valor simbólico na conta informada. Este crédito é apenas para verificação de segurança e será feito automaticamente após informar os dados da conta bancária.
            </StyledAlert>
          </>
      }
    </BankAccountFieldsContainer>
  </>;
}