import apiPrivate from 'services/apiPrivate'
import { getStoredCredential } from 'utils/getStoredCredential'

export const fetchSearchSellerSplits = async () => {

  return apiPrivate.post("/seller_splits/search", {
    ...getStoredCredential(),
  })
    .then(({ data }) => {
      return data.seller_split.data_response.seller_splits
    })
    .catch((error) => {
      console.error(error)
      throw error
    })
}