/* eslint-disable max-len */
import React from 'react';

export default function PhotoCameraIcon({ size = 1, color = "#2E7EE7" }) {
  return <>
    <i>
      <svg
        width={24 * size}
        height={24 * size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g
          clipPath="url(#clip0_8250_2004)">
          <path
            d="M14.12 4L15.95 6H20V18H4V6H8.05L9.88 4H14.12ZM15 2H9L7.17 4H4C2.9 4 2 4.9 2 6V18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4H16.83L15 2ZM12 9C13.65 9 15 10.35 15 12C15 13.65 13.65 15 12 15C10.35 15 9 13.65 9 12C9 10.35 10.35 9 12 9ZM12 7C9.24 7 7 9.24 7 12C7 14.76 9.24 17 12 17C14.76 17 17 14.76 17 12C17 9.24 14.76 7 12 7Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath
            id="clip0_8250_2004">
            <rect
              width={24 * size}
              height={24 * size}
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </i>
  </>;
}
