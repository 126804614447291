
import styled from "styled-components";
import { grayScale, primaryColors, textStyles } from "theme/styleguide";
import { TextField } from "@material-ui/core";
import { Alert } from "@material-ui/lab";


export const EmailEditFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 88px;
  @media (min-width: 768px)
  {
    margin-bottom: 192px;
  }
`
export const Label = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
`
export const Input = styled(TextField)`
  max-width: 568px;
  width: 100%;
  margin-right: 10px;
  margin-top: 37px;
`
export const StyledAlert = styled(Alert)`
  ${textStyles.tray_h5_15px}
  color: ${grayScale.gray_3};
  max-width: 568px;
  width: 100%;
  background: ${primaryColors.alert_blue};
  margin-top: 28px;
  padding-left: 24px;
`
