import React from 'react';
import { StyledTextField } from './styles';
import { grayScale } from 'theme/styleguide';
import { CircularProgress, IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import EditIcon from 'assets/icons/EditIcon';

export default function TextFieldRequester({
  isLoadingRequest,
  disabledButton = false,
  isFromDashboard = false,
  receivedFunction = () => { },
  ...props
}) {
  return <>
    <StyledTextField
      {...props}
      size="small"
      disabled={true}
      $isFromDashboard={isFromDashboard}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
          >
            {
              isLoadingRequest ?
                <CircularProgress
                  size="20px"
                  thickness={4}
                  color="inherit"
                  style={{ marginRight: "4px" }}
                /> :
                <Tooltip
                  title="Editar"
                  arrow
                >
                  <span>
                    <IconButton
                      size="small"
                      disabled={disabledButton}
                      style={{ cursor: "pointer" }}
                      onClick={receivedFunction}
                    >
                      <EditIcon
                        color={
                          disabledButton ?
                            grayScale.gray_20p100 :
                            grayScale.gray_3
                        }
                      />
                    </IconButton>
                  </span>
                </Tooltip>
            }
          </InputAdornment>
        )
      }}
    />
  </>;
}
