import { TextField } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, shadowStyles, textStyles } from "theme/styleguide";

export const PhoneDataFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ $isFromDashboard }) => $isFromDashboard ? "88px" : "50px"};
  @media (min-width: 768px){
    margin-bottom: 200px;
    flex-direction: row;
    justify-content: space-between;
  }
  
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px){
    width: 100%;
  }
`
export const Instruction = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-bottom: 32px;
  white-space: pre-wrap;
`
export const DropdownText = styled.span`
  ${textStyles.tray_text_12px}
  color: ${grayScale.gray_3};
  white-space: pre-wrap;
`
export const StyledTextField = styled(TextField)`
  width: 100%;
  max-width: 560px;
`
export const InstructionContainer = styled.div`
  display:none;
  @media (min-width: 768px){
    display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 21px;
    ${shadowStyles.drop_shadow_2}
    border-radius: 16px;
    max-width: 456px;
    margin: auto;
    margin-top: ${({ $isFromDashboard }) => $isFromDashboard ? "-22px" : "-88px"};
  }
`
export const InstructionContainerMobile = styled.div`
  display:flex;
  margin-top: 90px;
  @media (min-width: 768px){
    display: none;
  }

`
export const InstructionTitle = styled.span`
  ${textStyles.tray_h4_18px}
  margin-top: 16px;
  margin-bottom: 28px;
`
