import { Button, darken } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, primaryColors, textStyles } from "theme/styleguide";

export const BlockedTransactionsBannerContainer = styled.div`
  background: ${primaryColors.blue_Y};
  border-radius: 4px;
  padding: 12px 12px 24px 12px;
  margin-top: 24px;
  margin-bottom: 12px;
  @media (min-width: 768px)
  {
    padding: 16px 28px 28px 28px;
    margin-top: 4px;
  }
`
export const BannerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  @media (max-width: 767px)
  {
    justify-content: center;
    >i{
      display: none;
    }
  }
`
export const HeaderTitle = styled.span`
  ${textStyles.tray_h4_18px}
  color: ${grayScale.white};
  @media (min-width: 768px)
  {
    ${textStyles.tray_h3_24px}
    color: ${grayScale.white};
    margin-left: 16px;
  }
  @media (max-width: 767px)
  {
    text-align: center;
  }
`
export const BannerContent = styled.div`
  display: flex;
  flex-direction: row;
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px)
  {
    align-items: flex-start;
  }
`
export const ContentDescription = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.white};
  white-space: pre-wrap;
  margin-bottom: 16px;
  @media (max-width: 767px)
  {
    text-align: center;
  }
`
export const StyledButton = styled(Button)`
  min-width: 200px;
  ${textStyles.tray_h5_15px}
  color: ${primaryColors.blue_Y};
  background: ${grayScale.white};
  :hover{
    background: ${darken(grayScale.white, .1)};
  }
`