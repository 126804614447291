import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchGetAccountMyAccount = async () => {
  const yapay_code = getDataStorage('yapay_code')
  return apiPrivate.get("/myaccount/account/from_permissions", {
    headers: {
      code: yapay_code
    }
  })
    .then(({ data }) => {
      // return { ...data, use_digital_account: true }
      return data
    })
    .catch((_error) => {
      return {}
    })
}