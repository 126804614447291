/* eslint-disable max-len */
import React from 'react';

export default function StorefrontIcon({ size = 1, color = "#2E7EE7", ...props }) {
  return <>
    <i
      {...props}
    >
      <svg
        width={40 * size}
        height={40 * size}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.5 14.8167L34.75 7.53333C34.3833 6.03333 33.0833 5 31.5667 5H8.41668C6.91668 5 5.60001 6.05 5.25001 7.53333L3.50001 14.8167C3.10001 16.5167 3.46668 18.25 4.53334 19.6167C4.66668 19.8 4.85001 19.9333 5.00001 20.1V31.6667C5.00001 33.5 6.50001 35 8.33334 35H31.6667C33.5 35 35 33.5 35 31.6667V20.1C35.15 19.95 35.3333 19.8 35.4667 19.6333C36.5333 18.2667 36.9167 16.5167 36.5 14.8167ZM31.5167 8.31667L33.2667 15.6C33.4333 16.3 33.2833 17 32.85 17.55C32.6167 17.85 32.1167 18.3333 31.2833 18.3333C30.2667 18.3333 29.3833 17.5167 29.2667 16.4333L28.3 8.33333L31.5167 8.31667ZM21.6667 8.33333H24.9333L25.8333 15.8667C25.9167 16.5167 25.7167 17.1667 25.2833 17.65C24.9167 18.0833 24.3833 18.3333 23.7 18.3333C22.5833 18.3333 21.6667 17.35 21.6667 16.15V8.33333ZM14.15 15.8667L15.0667 8.33333H18.3333V16.15C18.3333 17.35 17.4167 18.3333 16.1833 18.3333C15.6167 18.3333 15.1 18.0833 14.7 17.65C14.2833 17.1667 14.0833 16.5167 14.15 15.8667ZM6.73334 15.6L8.41668 8.33333H11.7L10.7333 16.4333C10.6 17.5167 9.73335 18.3333 8.71668 18.3333C7.90001 18.3333 7.38335 17.85 7.16668 17.55C6.71668 17.0167 6.56668 16.3 6.73334 15.6ZM8.33334 31.6667V21.6167C8.46668 21.6333 8.58334 21.6667 8.71668 21.6667C10.1667 21.6667 11.4833 21.0667 12.45 20.0833C13.45 21.0833 14.7833 21.6667 16.3 21.6667C17.75 21.6667 19.05 21.0667 20.0167 20.1167C21 21.0667 22.3333 21.6667 23.8333 21.6667C25.2333 21.6667 26.5667 21.0833 27.5667 20.0833C28.5333 21.0667 29.85 21.6667 31.3 21.6667C31.4333 21.6667 31.55 21.6333 31.6833 21.6167V31.6667H8.33334Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
