import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const CompleteRegisterMenuContainer = styled.div`
  margin-bottom: 16px;
`
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 32px;
  button{
    margin-right: 16px;
  }
  @media (min-width: 1024px)
  {
    margin-bottom: 56px;
  }
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: -16px;
  @media (min-width: 1024px)
  {
    flex-direction: row;
    margin-bottom: 32px;
  }
`
export const InnerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  >img{
    display: none;
  }
  @media (min-width: 1024px)
  {
  >img{
    display: initial;
    align-self: center;
    width: 100%;
    max-width: 272px;
    margin-top: -40px;
    }
  }
`
export const Instructions = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  white-space: pre-wrap;
  margin-bottom: 42px;
  @media (min-width: 1024px)
  {
    max-width: 576px;
  }
`
export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px)
  {
    max-width: 605px;
  }
`