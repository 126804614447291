import { useDashboard } from 'context/DashboardContext';
import { useCallback, useEffect, useState, useRef } from 'react';
import { fetchUpdateSigninProfiles } from 'services/Account/POST/UpdateSigninProfilesService';
import { fetchGetSigninUser } from 'services/Signin/GET/GetSigninUserService';
import { object, string } from 'yup';

export default function useUserNameDataSetup({
  reloadAfterSubmit = false,
  afterSubmit = () => { },
}) {
  const mounted = useRef(false)
  const { setUserName } = useDashboard()
  const [initialValues, setInitialValues] = useState()
  const [oldValues, setOldValues] = useState({})
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [isDisabled, setDisabled] = useState(true)
  const validationSchema = object({
    name: string()
      .required('Este campo é obrigatório'),
  })

  const toggleDisabled = () => {
    setDisabled((oldValue) => !oldValue)
  }

  const fetchData = useCallback(async () => {
    const { name } = await fetchGetSigninUser()

    const initialValuesObject = {
      name: name,
    }

    if (mounted.current) {
      setInitialValues(initialValuesObject)
      setOldValues(initialValuesObject)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async ({
    name,
  }) => {
    const userNameObject = {}
    if (!!name && name !== oldValues?.name) {
      userNameObject.name = name
    }
    if (Object.keys(userNameObject).length > 0) {
      await fetchUpdateSigninProfiles(userNameObject)
      setUserName(name.split(" ")[0])
    }
    if (reloadAfterSubmit) {
      await fetchData()
    }
    setSnackbarOpen(true)
    afterSubmit()
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    isSnackbarOpen,
    setSnackbarOpen,
    isDisabled,
    toggleDisabled,
  })
}