import React from 'react';
import { CompleteRegisterInviteDialogContainer, StyledDialog, Title, StyledDialogContent, Subtitle, DialogHeader, StyledDialogActions, BenefitItem, BenefitTitle, BenefitsContainer, StyledImage, RowContainer, StyledButton } from './styles';
import GoodBillingImage from 'assets/images/good-billing.png'
import VindiPaperMoneyIcon from 'assets/icons/VindiPaperMoneyIcon';
import CloseIcon from 'assets/icons/CloseIcon';
import { useDashboard } from 'context/DashboardContext';
import { IconButton } from '@material-ui/core';
import VindiRepeatIcon from 'assets/icons/VindiRepeatIcon';
import VindiDollarAccountIcon from 'assets/icons/VindiDollarAccountIcon';
import VindiPackageIcon from 'assets/icons/VindiPackageIcon';
import ExternalService from 'utils/ExternalService';

const benefitsList = [
  {
    title: "Visualizar seu saldo acumulado no painel Vindi",
    icon: <VindiPaperMoneyIcon />,
  },
  {
    title: "Fazer transferência entre contas Vindi",
    icon: <VindiRepeatIcon />,
  },
  {
    title: "Realizar saques quando desejar",
    icon: <VindiDollarAccountIcon />,
  },
  {
    title: "Pagar faturas Tray e etiquetas de frete",
    icon: <VindiPackageIcon />,
  },
]
export default function CompleteRegisterInviteDialog() {
  const { isCompleteRegisterInviteDialogOpen, hasSeenCompleteRegisterInviteDialog, setCompleteRegisterInviteDialogOpen, setSeenCompleteRegisterInviteDialog } = useDashboard()

  const handleClose = () => {
    setCompleteRegisterInviteDialogOpen(false)
    setSeenCompleteRegisterInviteDialog(true)
  }

  const handleNavigate = () => {
    // setCurrentTab("myaccount")
    // setCurrentMyAccountMenuItem("completeRegister")
    window.open(`${ExternalService.buildExternalUrl('myaccount')}/seller/`, "_blank")
    handleClose()
  }
  return <>
    <CompleteRegisterInviteDialogContainer>
      <StyledDialog
        open={isCompleteRegisterInviteDialogOpen && !hasSeenCompleteRegisterInviteDialog}
      >
        <DialogHeader>
          <Title
            id="complete-register-alert-dialog-title"
          >
            Garanta mais benefícios com a conta digital Vindi
          </Title>
          <IconButton
            size="small"
            onClick={handleClose}
            id={"plugin_modal_aquisicao_cd_botao_fechar-modal"}
          >
            <CloseIcon
              size={1.33}
              color={"#454559"}
            />
          </IconButton>
        </DialogHeader>
        <StyledDialogContent>
          <Subtitle>
            Finalize seu cadastro para:
          </Subtitle>
          <RowContainer>
            <BenefitsContainer>
              {
                benefitsList.map((e, i) => {
                  return (
                    <BenefitItem
                      key={i}
                    >
                      {e.icon}
                      <BenefitTitle>
                        {e.title}
                      </BenefitTitle>
                    </BenefitItem>
                  )
                })
              }
            </BenefitsContainer>
            <StyledImage
              src={GoodBillingImage}
              alt="Finalizar cadastro"
            />
          </RowContainer>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton
            onClick={handleNavigate}
            color="primary"
            autoFocus
            id={"plugin_modal_aquisicao_cd_botao_finalizar-cadastro"}
          >
            Finalizar cadastro agora
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
    </CompleteRegisterInviteDialogContainer>
  </>;
}
