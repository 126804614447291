import LoadingIndicator from 'components/shared/LoadingIndicator';
import { Field } from 'formik';
import React, { useEffect, useState } from 'react';
import SMSImage from 'assets/images/sms-code.png'
import { FormControl } from '@material-ui/core';
import { SMSEmailVerificationFormContainer, CodeInput, InstructionLabel, InstructionLabelSMS, CodeContainer, InputsContainer, Image, StyledButton } from './styles';
import { fetchChangeEmailSms } from 'services/Account/POST/ChangeEmailSmsService';
import { phoneMask } from 'utils/phoneMask';
import { useRef } from 'react';
import SnackbarAlert from 'components/shared/SnackbarAlert';
import { useAccountData } from 'context/AccountDataContext';
import { fetchGetSigninUser } from 'services/Signin/GET/GetSigninUserService';

export default function SMSEmailVerificationForm({
  isLoading = true,
  values,
  isFromDashboard = false,
}) {
  const { setMessageIdSMSEmail } = useAccountData()
  const [phone, setPhone] = useState('')
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [hasRequestSuccessed, setRequestSuccessed] = useState(true)
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])
  const getPhoneNumber = async () => {
    const { phone } = await fetchGetSigninUser()
    if (mounted.current) {
      setPhone(phoneMask(phone))
    }
  }

  useEffect(() => {
    getPhoneNumber()
  }, [])

  const requestSMS = async () => {
    fetchChangeEmailSms()
      .then(({ data }) => {
        if (data?.message_id) {
          setMessageIdSMSEmail(data.message_id)
          setRequestSuccessed(true)
        } else {
          setRequestSuccessed(false)
        }
        setSnackbarOpen(true)
      })
  }

  useEffect(() => {
    if (values.code1.length === 1) {
      const nextInput = document.querySelector(`input[name=code2]`);
      nextInput.focus();
      nextInput.select();
    }
  }, [values.code1])

  useEffect(() => {
    if (values.code2.length === 1) {
      const nextInput = document.querySelector(`input[name=code3]`);
      nextInput.focus();
      nextInput.select();
    }
  }, [values.code2])

  useEffect(() => {
    if (values.code3.length === 1) {
      const nextInput = document.querySelector(`input[name=code4]`);
      nextInput.focus();
      nextInput.select();
    }
  }, [values.code3])

  useEffect(() => {
    if (values.code4.length === 1) {
      const currentInput = document.querySelector(`input[name=code4]`);
      currentInput.blur();
    }
  }, [values.code4])

  return (
    <>
      {isLoading ?
        <LoadingIndicator /> :
        <SMSEmailVerificationFormContainer>
          <InputsContainer>
            <InstructionLabel>Enviamos um código de verificação por SMS para o número de celular {phone} verificado em sua conta.
            </InstructionLabel>
            <InstructionLabel>Por favor, informe o código recebido para prosseguir com a edição do e-mail:</InstructionLabel>
            <FormControl>
              <CodeContainer>
                <Field
                  name="code1"
                  size="small"
                  inputProps={{
                    maxLength: 1
                  }}
                  as={CodeInput}
                />
                <Field
                  name="code2"
                  size="small"
                  inputProps={{
                    maxLength: 1
                  }}
                  as={CodeInput}
                />
                <Field
                  name="code3"
                  size="small"
                  inputProps={{
                    maxLength: 1
                  }}
                  as={CodeInput}
                />
                <Field
                  name="code4"
                  size="small"
                  inputProps={{
                    maxLength: 1
                  }}
                  as={CodeInput}
                />
              </CodeContainer>
            </FormControl>
            <InstructionLabelSMS>
              O código recebido é válido por 1 minuto. Após este período, é necessário reenviar um novo código.
            </InstructionLabelSMS>
            <StyledButton
              variant="outlined"
              onClick={requestSMS}
            >
              Reenviar
            </StyledButton>
          </InputsContainer>
          {
            !isFromDashboard &&
            <Image src={SMSImage} />
          }
          <SnackbarAlert
            isOpen={isSnackbarOpen}
            onClose={() => { setSnackbarOpen(false) }}
            message={hasRequestSuccessed ? "Código reenviado." : "Ops! Você já atingiu o limite de reenvio de código por SMS hoje.\nTente novamente após 24h."}
            hasSuccessed={hasRequestSuccessed}
          />
        </SMSEmailVerificationFormContainer>
      }
    </>
  )
}
