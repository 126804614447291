import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const QrCodeAlertContainer = styled.div`
    background-color:  rgba(84, 110, 122, 0.1);
    max-width: 550px;
    border-radius: 3px;
    margin-bottom: 60px;
    margin-top: 50px;
    @media (min-width: 768px){
        
        margin-bottom: 90px;
        margin-top: 100px;
    }

`
export const Title = styled.span`
    ${textStyles.tray_h5_15px};
`
export const BodyText = styled.span`
    ${textStyles.tray_text_14px};
    color:${grayScale.gray_3};
    margin-top: 7px;
`
export const IconCard = styled.div`
    align-self: flex-start;
    margin-left: 30px;
    margin-top: 19px;
    @media (min-width: 768px)
  {
    align-self: center;
    margin-top: 0px;

  }
`

export const ContainerFlex = styled.div`
    display: flex;
`
export const Container = styled.div`
 display:flex;
 flex-direction: column;
 padding: 19px;
`