import usePixSetup from 'components/FormsSetup/MainSettings/usePixSetup';
import PixForm from 'components/Onboarding/OnboardingForms/MainSettingsForms/PixForm';
import { useOnboarding } from 'context/OnboardingContext';
import { Form, Formik } from 'formik';
import React from 'react';
import { PixStepContainer } from './styles';
import { ButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import LoadableButton from 'components/shared/LoadableButton';
import { getOrigemTypeVariantSectionKey } from 'utils/getOrigemTypeVariantSectionKey';
import { getCurrentOnboardingStepsCollection } from 'utils/getCurrentOnboardingStepsCollection';

export default function PixStep() {
  const { mainSettingsStep, nextMainSettingsStep, nextOnboardingSection } = useOnboarding()
  const { initialValues, validationSchema, isLoading, handleSubmit, pixLifespanMinutesMetricList, oldMetricSelected, setOldMetricSelected } = usePixSetup({
    afterSubmit: () => {
      const origemTypeVariantSectionKey = getOrigemTypeVariantSectionKey()
      const stepsCollectionLength = getCurrentOnboardingStepsCollection(origemTypeVariantSectionKey).length - 1
      if (mainSettingsStep < stepsCollectionLength) {
        nextMainSettingsStep()
      } else {
        nextOnboardingSection()
      }
    },
    fromOnboarding: true
  })

  return <>
    <PixStepContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <PixForm
                {...formikProps}
                pixLifespanMinutesMetricList={pixLifespanMinutesMetricList}
                isLoading={isLoading}
                oldMetricSelected={oldMetricSelected}
                setOldMetricSelected={setOldMetricSelected}
              />
              <ButtonsContainer>
                <LoadableButton
                  type="submit"
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_config_qrcode_onboarding_botao_continuar"}
                >
                  Continuar
                </LoadableButton>
              </ButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </PixStepContainer>
  </>;
}
