import { TextField } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import styled from "styled-components";

export const DatePickerContainer = styled.div`
`
export const InnerStyledTextField = styled(TextField)`
  width: 100%;
  max-width: 100%;
  min-height: 64px;
  border-radius: 4px;
  .MuiFormHelperText-contained{
    margin-bottom: 16px;
    white-space: pre-wrap;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 8px;
  }
  .MuiIconButton-root{
    padding: 4px;
  }
`
export const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  .MuiPickersBasePicker-pickerView{
    margin: 0 auto;
  }
  @media (min-width: 768px)
  {
    max-width: ${({ $fullWidth }) => $fullWidth ? "100%" : "50%"};
    padding-right: 20px;
  }
`