import React from 'react';
import { CompleteRegistrationInstructionsContainer, InstructionsTitle, InstructionsText, ContentContainer, ListTitle, DataUpdateList, DocumentsInstructionItemHeader, DocumentsInstructionItemContainer, DocumentsList, DocumentsInstructionItemBody } from './styles';
import DadosBacenImage from 'assets/images/dados-bacen.png';
import { Button } from '@material-ui/core';
import { useOnboarding } from 'context/OnboardingContext';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import CompleteLaterDialog from 'components/Onboarding/OnboardingSteps/AccountDataSteps/shared/CompleteLaterDialog';
import { SameSizeButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import AccountBoxIcon from 'assets/icons/AccountBoxIcon';
import FaceIcon from 'assets/icons/FaceIcon';
import DescriptionIcon from 'assets/icons/DescriptionIcon';
import BorderColorIcon from 'assets/icons/BorderColorIcon';
import { setDataStorage } from 'utils/storage';
import { fetchUpdateOnboardingStep } from 'services/StoreKeys/POST/UpdateOnboardingStepService';
import { useHistory } from 'react-router-dom';

const DocumentsInstructionItem = ({
  title,
  icon,
  body,
}) => {
  return (
    <DocumentsInstructionItemContainer>
      <DocumentsInstructionItemHeader>
        {icon}
        {title}
      </DocumentsInstructionItemHeader>
      {
        body &&
        <DocumentsInstructionItemBody>
          {body}
        </DocumentsInstructionItemBody>
      }
    </DocumentsInstructionItemContainer>
  )
}

export default function CompleteRegistrationInstructions() {
  const history = useHistory()
  const { isBusinessAccount, isLoadingContext, toggleCompleteLaterAlert } = useOnboarding()

  const finishOnboarding = async () => {
    // const finishStep = Number(getDataStorage("onboarding_step")) + 1
    const finishStep = -1
    setDataStorage("onboarding_step", finishStep);
    await fetchUpdateOnboardingStep({
      onboarding_step: finishStep,
    }).then(() => {
      history.replace("/dashboard")
    })
  }

  const handleCompleteNow = async () => {
    setDataStorage("completeRegisterNow", true);
    await finishOnboarding()
  }
  const handleCompleteLater = async () => {
    await finishOnboarding()
  }
  return <>
    {
      isLoadingContext ?
        <LoadingIndicator /> :
        <CompleteRegistrationInstructionsContainer>
          <ContentContainer>
            <div>
              <InstructionsTitle>
                Completar cadastro
              </InstructionsTitle>
              <InstructionsText>
                {`Para utilizar todos os recursos da conta Vindi e gerenciar seu saldo de vendas da forma que preferir, é necessário completar seu cadastro. Com isso, você passa a ter um saldo cumulativo das suas vendas aqui no painel Vindi e pode resgatá-lo para sua conta bancária quando quiser, no valor definido por você.\n\nContas que não possuem cadastro completo, recebem todo o valor das vendas diretamente na conta bancária, sem acumular saldo na Vindi.\n\n\nPara completar o cadastro, você precisa:`}
              </InstructionsText>
              <ListTitle>
                1 - Informar mais alguns dados:
              </ListTitle>
              <DataUpdateList>
                {
                  isBusinessAccount ?
                    <>
                      <li>
                        <b>dados da pessoa titular da conta</b> - RG, nome completo da mãe, data de nascimento, renda mensal, entre outros;
                      </li>
                      <li>
                        <b>dados sobre a empresa</b> - natureza jurídica, data de abertura, faturamento mensal, entre outros;
                      </li>
                      <li>
                        <b>dados de todos os(as) sócios(as) da empresa com mais de 25% de participação</b> - CPF ou CNPJ, data de nascimento, endereço, entre outros.
                      </li>
                    </> :
                    <>
                      <li>
                        <b>dados da pessoa titular da conta</b> - RG, nome completo da mãe, data de nascimento, renda mensal, entre outros.
                      </li>
                    </>
                }
              </DataUpdateList>
              <ListTitle>
                2 - Enviar os seguintes documentos para comprovação dos dados da conta:
              </ListTitle>
              <DocumentsList>
                {
                  isBusinessAccount ?
                    <>
                      <DocumentsInstructionItem
                        icon={<AccountBoxIcon />}
                        title={"Foto do RG ou CNH da pessoa titular"}
                      />
                      <DocumentsInstructionItem
                        icon={<FaceIcon />}
                        title={"Foto do rosto da pessoa titular (selfie)"}
                      />
                      <DocumentsInstructionItem
                        icon={<AccountBoxIcon />}
                        title={"Foto do RG ou CNH de cada sócio(a) pessoa física com 25% ou mais de participação"}
                      />
                      <DocumentsInstructionItem
                        icon={<DescriptionIcon />}
                        title={"Documento de constituição da empresa"}
                        body={
                          <>
                            <span>
                              Será necessário enviar um dos documentos abaixo, de acordo com a natureza jurídica de sua empresa:
                            </span>
                            <ul>
                              <li>
                                Para empresa Micro Empresário Individual (MEI), enviar Certificado de Cadastro de Micro Empresário Individual
                              </li>
                              <li>
                                Para empresa Empresário Individual (EI), enviar Requerimento de Empresário Individual
                              </li>
                              <li>
                                Para empresa Empresa Individual de Responsabilidade Limitada (EIRELI), enviar Ato Constitutivo Eireli
                              </li>
                              <li>
                                Para empresa Limitada (LTDA), Sociedade Simples (SS), Cooperativa (COOP) ou outros, enviar Contrato Social
                              </li>
                              <li>
                                Para empresa Sociedade Anônima (SA), enviar Estatuto Social
                              </li>
                            </ul>
                            <span>
                              Formato de arquivo aceito: PDF
                            </span>
                          </>
                        }
                      />
                      <DocumentsInstructionItem
                        icon={<DescriptionIcon />}
                        title={"Documento de constituição da empresa sócia com 25% ou mais de participação - se houver"}
                        body={
                          <span>
                            Será necessário enviar o documento de constituição da empresa sócia, se houver. Os documentos aceitos são os mesmos descritos no item anterior.
                          </span>
                        }
                      />
                      <DocumentsInstructionItem
                        icon={<BorderColorIcon />}
                        title={"Documento de procuração - apenas se o(a) titular for procurador(a) da empresa"}
                        body={
                          <span>
                            Formato de arquivo aceito: PDF
                          </span>
                        }
                      />
                    </> :
                    <>
                      <DocumentsInstructionItem
                        icon={<AccountBoxIcon />}
                        title={"Foto do RG ou CNH da pessoa titular"}
                      />
                      <DocumentsInstructionItem
                        icon={<FaceIcon />}
                        title={"Foto do rosto da pessoa titular (selfie)"}
                      />
                    </>
                }
              </DocumentsList>
            </div>
            <div>
              <img
                src={DadosBacenImage}
                alt="Completar cadastro"
              />
            </div>
          </ContentContainer>
          <SameSizeButtonsContainer>
            <Button
              onClick={handleCompleteNow}
              id={"tela_completar_cadastro_onboarding_botao_completar_cadastro"}
            >
              Completar cadastro agora
            </Button>
            <Button
              variant="outlined"
              onClick={toggleCompleteLaterAlert}
              id={"tela_completar_cadastro_onboarding_botao_completar_depois"}
            >
              Completar depois
            </Button>
          </SameSizeButtonsContainer>
        </CompleteRegistrationInstructionsContainer>
    }
    <CompleteLaterDialog
      completeNowFunction={handleCompleteNow}
      completeLaterFunction={handleCompleteLater}
      isFromDashboard
    />
  </>;
}
