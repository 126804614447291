import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const TitularAndAddressFieldsContainer = styled.div`
  
`
export const StepBody = styled.span`
  display: block;
  white-space: pre-wrap;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-top: 32px;
`
export const InputSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 48px;
`
export const SectionTitle = styled.span`
  display: block;
  ${textStyles.tray_h5_15px}
  color: ${grayScale.gray_3};
  margin-bottom: 24px;
  @media (min-width: 768px)
  {
    ${textStyles.tray_h4_18px}
  }
`
export const InputContainerRow = styled.div`
  flex: 1;
  display:flex;
  flex-direction: row;
  justify-content:space-between;
  flex-wrap: wrap;
  .layoutSpacing{
    width: 50%;
  }
`