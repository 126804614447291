import { useCallback, useEffect, useState, useRef } from 'react';
import { fetchGetSigninUser } from 'services/Signin/GET/GetSigninUserService';
import { formatPhone } from 'utils/formatPhone';

export default function useUserVerifiedPhoneNumberDataSetup() {
  const mounted = useRef(false)
  const [userPhoneNumber, setUserPhoneNumber] = useState("")
  const [isLoading, setLoading] = useState(true)

  const fetchData = useCallback(async () => {
    const { phone } = await fetchGetSigninUser()
    setUserPhoneNumber(formatPhone(phone))

    if (mounted.current) {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return ({
    userPhoneNumber,
    isLoading,
  })
}