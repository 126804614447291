import { Button } from '@material-ui/core';
import CancelFormFillingDialog from 'components/CompleteRegisterMenu/shared/CancelFormFillingDialog';
import usePartnersDataPreludeSetup from 'components/FormsSetup/AccountData/usePartnersDataPreludeSetup';
import { ButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import PartnersDataPreludeForm from 'components/Onboarding/OnboardingForms/AccountDataForms/PartnersDataPreludeForm';
import AdditionalDataDetails from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/AdditionalDataDetails';
import LoadableButton from 'components/shared/LoadableButton';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import { Form, Formik } from 'formik';
import React from 'react';
import { PartnersDataPreludeMenuItemContainer } from './styles';

export default function PartnersDataPreludeMenuItem() {
  const { goBackToCompleteRegisterMenu, toggleCancelFormFillingDialog, updateCompletedRegisterStep, setCurrentCompleteRegisterMenuItem } = useDashboardMyAccount()
  const { initialValues, validationSchema, isLoading, handleSubmit, displayedName, identifier } = usePartnersDataPreludeSetup({
    afterSubmit: () => {
      setCurrentCompleteRegisterMenuItem("partnersData")
    },
    afterSubmitAlternative: () => {
      updateCompletedRegisterStep("partnersDataPrelude")
      goBackToCompleteRegisterMenu()
    },
  })
  return <>
    <PartnersDataPreludeMenuItemContainer>
      {
        !isLoading &&
        <AdditionalDataDetails
          displayedName={displayedName}
          identifier={identifier}
          isBusinessAccount
        />
      }
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <PartnersDataPreludeForm
                {...formikProps}
                isLoading={isLoading}
                initialValues={initialValues}
                isOnboardingStep
              />
              <ButtonsContainer
                $shouldNotReverseColumn
              >
                <LoadableButton
                  type="submit"
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_completar_cadastro_possui_socios_botao_salvar"}
                >
                  Salvar
                </LoadableButton>
                <Button
                  variant="outlined"
                  onClick={toggleCancelFormFillingDialog}
                  disabled={formikProps.isSubmitting || isLoading}
                  id={"tela_completar_cadastro_possui_socios_botao_cancelar"}
                >
                  Cancelar
                </Button>
              </ButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </PartnersDataPreludeMenuItemContainer>
    <CancelFormFillingDialog
      cancelFillingFormFunction={goBackToCompleteRegisterMenu}
    />
  </>;
}
