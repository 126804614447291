import { getDataStorage } from "utils/storage"
import { onboardingVariantsDictionary } from "library/onboardingVariantsDictionary"

const specifySectionKey = (sectionKey, currentVariant) => {
  if (sectionKey === "documentsSendingSteps") {
    const isBusinessAccount = JSON.parse(getDataStorage("isBusinessAccount") || false)
    return isBusinessAccount ? "documentsSendingBusinessVariantSteps" : "documentsSendingPersonalVariantSteps"
  } else if (sectionKey === "accountDataSteps" && !!currentVariant?.stepsCollection?.accountDataStepsWithBanking) {
    const hasActiveBankAccount = JSON.parse(getDataStorage("hasActiveBankAccount") || false)
    return hasActiveBankAccount ? "accountDataSteps" : "accountDataStepsWithBanking"
  } else {
    return sectionKey
  }
}
export const getCurrentOnboardingStepsCollection = (sectionKey) => {
  const onboardingVariant = getDataStorage("onboardingVariant") || "onboardingDVariant"
  const currentVariant = onboardingVariantsDictionary[onboardingVariant]
  const currentStepsCollection = currentVariant.stepsCollection[specifySectionKey(sectionKey, currentVariant)]
  return currentStepsCollection
}