import apiPrivate from 'services/apiPrivate'
import { getStoredCredential } from 'utils/getStoredCredential'

export const fetchGetAccountEmailTypes = async () => {

  return apiPrivate.get("/v3/account_email_types", {
    headers: {
      ...getStoredCredential(),
    }
  })
    .then(({ data }) => {
      return data.data
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}