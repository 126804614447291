import { Field } from 'formik';
import React from 'react';
import { SwitchableOptionContainer, FirstRow, LabelText, StyledSwitch, Description } from './styles';

export default function SwitchableOption({
  label = "",
  description = "",
  receivedValue = false,
  receivedSetter = () => { },
  descriptionStyle,
  disabled = false,
  name = "",
  ...props
}) {
  return <>
    <SwitchableOptionContainer
      {...props}
      $hasDescription={!!description}
    >
      <FirstRow>
        <LabelText>
          {label}
        </LabelText>
        {
          !!name ?
            <Field
              as={StyledSwitch}
              name={name}
              disabled={disabled}
              type="checkbox"
            /> :
            <StyledSwitch
              value={receivedValue}
              onChange={receivedSetter}
              disabled={disabled}
            />
        }
      </FirstRow>
      <Description
        $descriptionStyle={descriptionStyle}
      >
        {description}
      </Description>
    </SwitchableOptionContainer>
  </>;
}
