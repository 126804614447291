import { Alert } from "@material-ui/lab";
import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const DocumentsUploadInstructionsContainer = styled.div`
  
`
export const Title = styled.span`
  ${textStyles.tray_h5_15px}
  color: ${grayScale.gray_3};
  margin-bottom: 28px;
  @media (min-width: 768px)
  {
    ${textStyles.tray_h4_18px}
  }
`
export const BodyText = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-bottom: ${({ $extraMarginBottom }) => $extraMarginBottom ? "40px" : "32px"};
  white-space: pre-wrap;
`
export const IntroductionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  @media (min-width: 768px)
  {
    margin-bottom: 32px;
  }
`
export const IntroductionColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 568px;
`
export const StyledImage = styled.img`
  display: none;
  margin: 0 auto;
  @media (min-width: 768px)
  {
    display: initial;
  }
`
export const ItemsRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 48px;
  margin-bottom: 32px;
  @media (min-width: 768px)
  {
    flex-direction: column;
  }
`
export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
  @media (min-width: 768px)
  {
    margin-bottom: 48px;
  }
`
export const ItemTitle = styled.span`
  ${textStyles.tray_h4_18px}
  color: ${grayScale.black};
  padding-top: 6px;
  margin-bottom: 8px;
  @media (min-width: 768px)
  {
    ${textStyles.tray_h5_15px}
    color: ${grayScale.black};
  }
`
export const ItemBody = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  white-space: pre-wrap;
`
export const ItemInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
`
export const ListTitle = styled.span`
  display: block;
  margin-top: 24px;
`
export const StyledList = styled.ul`
  padding-left: 24px;
  margin-top: 8px;
  margin-bottom: 0px;
  >li{
    margin-bottom: 8px;
  }
`
export const StyledAlert = styled(Alert)`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  align-items: center;
`