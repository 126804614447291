import { Button, IconButton } from '@material-ui/core';
import LoadableButton from 'components/shared/LoadableButton';
import { useAccountData } from 'context/AccountDataContext';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import React, { useEffect, useRef, useState } from 'react';
import { fetchChangeEmailSms } from 'services/Account/POST/ChangeEmailSmsService';
import { EmailUpdateInstructionsMenuItemContainer, Instruction } from './styles';
import { StyledButtonsContainer, StyledDashboardTitle, TitleContainer } from 'components/Dashboard/DashboardMyAccount/styles';
import ArrowBackIcon from 'assets/icons/ArrowBackIcon';
import SnackbarAlert from 'components/shared/SnackbarAlert';

export default function EmailUpdateInstructionsMenuItem() {
  const { setCurrentMyAccountMenuItem, goBackToUserDataMenu } = useDashboardMyAccount()
  const { setMessageIdSMSEmail } = useAccountData()
  const [isLoadingSMSRequest, setLoadingSMSRequest] = useState(false)
  const [requestHasFailed, setRequestHasFailed] = useState(false)
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const mounted = useRef(false);

  const handleSMSRequest = async () => {
    setLoadingSMSRequest(true)
    await fetchChangeEmailSms()
      .then(({ data }) => {
        if (data?.message_id && mounted.current) {
          setMessageIdSMSEmail(data.message_id)
          setCurrentMyAccountMenuItem("emailSMSVerification")
        } else {
          console.error("erro ", data)
          setRequestHasFailed(true)
          setSnackbarOpen(true)
        }
      })
    if (mounted.current) {
      setLoadingSMSRequest(false)
    }
  }

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  return <>
    <EmailUpdateInstructionsMenuItemContainer>
      <TitleContainer>
        <IconButton
          size="small"
          onClick={goBackToUserDataMenu}
        >
          <ArrowBackIcon />
        </IconButton>
        <StyledDashboardTitle>
          Editar e-mail
        </StyledDashboardTitle>
      </TitleContainer>
      <Instruction>
        {
          requestHasFailed ?
            <>
              {`Ops! Você já atingiu o limite de tentativas de alteração de e-mail no dia de hoje.\n\nPara fazer uma nova alteração, tente novamente após 24 horas.`}
            </> :
            <>
              {`Para editar o e-mail, é necessário fazer uma validação de código que será enviado por SMS para o celular verificado da conta.\n`}
              <b>
                {`Importante: `}
              </b>
              {`o e-mail do login também será alterado para o novo e-mail informado.\n\n\nDeseja continuar?`}
            </>
        }
      </Instruction>
      {
        requestHasFailed ?
          <StyledButtonsContainer>
            <Button
              disabled={isLoadingSMSRequest}
              onClick={goBackToUserDataMenu}
            >
              Ok
            </Button>
          </StyledButtonsContainer> :
          <StyledButtonsContainer>
            <LoadableButton
              onClick={handleSMSRequest}
              disabled={isLoadingSMSRequest}
              isLoading={isLoadingSMSRequest}
            >
              Continuar
            </LoadableButton>
            <Button
              variant="outlined"
              disabled={isLoadingSMSRequest}
              onClick={goBackToUserDataMenu}
            >
              Cancelar
            </Button>
          </StyledButtonsContainer>
      }
      <SnackbarAlert
        isOpen={isSnackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        message={"Ops! Você já atingiu o limite de reenvio de código por SMS hoje.\nTente novamente após 24h."}
        hasSuccessed={!requestHasFailed}
      />
    </EmailUpdateInstructionsMenuItemContainer>
  </>;
}
