import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const CreditCardMenuItemContainer = styled.div` 
`

export const Subtitle = styled.span`
  display: block;
  ${textStyles.tray_h5_15px}
  color: ${grayScale.gray_3};
  margin-bottom: 10px;
  margin-top: 55px;
  @media (min-width: 768px)
  {
    ${textStyles.tray_h4_18px}
    margin-top: 92px;
  }
`
export const Instruction = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  white-space: pre-wrap;
  margin-bottom: 32px;
  @media (min-width: 768px)
  {
    margin-bottom: ${({ $smallBorderBottom }) => $smallBorderBottom ? "32px" : "64px"};
  }
`