/* eslint-disable max-len */
import React from 'react';

export default function LandingVindiIcon({ size = 1, color = "#6F6F84" }) {
  return <>
    <i>
      <svg
        width={123 * size}
        height={41 * size}
        viewBox="0 0 123 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M53.3717 30.9944C53.7671 30.6914 54.0521 30.2679 54.183 29.7886L55.6599 24.4731L57.9595 16.1913H63.3872L60.8505 24.4731L58.4623 32.2259C58.092 33.4557 57.3311 34.5327 56.2937 35.2952C55.2563 36.0578 53.9985 36.4647 52.709 36.455H47.3385L43.6505 24.4731L41.1138 16.1913H46.5415L48.8411 24.4731L50.7893 31.4637H51.9691C52.4769 31.4725 52.9723 31.3068 53.3717 30.9944Z"
          fill={color} />
        <path
          d="M65.484 20.1559V16.1913H70.3203V20.1559H65.484ZM65.6011 36.455V21.7713H70.3203V36.455H65.6011Z"
          fill={color} />
        <path
          d="M79.0846 22.1552V22.8861C79.3714 22.6645 79.6773 22.4685 79.9987 22.3002C80.3512 22.1162 80.7216 21.9684 81.1042 21.8594C81.5247 21.7352 81.9532 21.6402 82.3869 21.575C82.8759 21.5043 83.3696 21.47 83.8638 21.4726H85.0436C86.0105 21.4602 86.9699 21.6441 87.8631 22.013C88.6503 22.3431 89.3632 22.8266 89.9599 23.435C90.5281 24.0228 90.9738 24.7168 91.2711 25.477C91.5759 26.2487 91.7311 27.0709 91.7282 27.9001V36.4749H87.0204V27.8518C87.0223 27.4928 86.9674 27.1357 86.8576 26.7938C86.7545 26.4689 86.5776 26.1721 86.3405 25.9264C86.0828 25.6665 85.7707 25.4664 85.4264 25.3405C85.0016 25.1839 84.5509 25.1086 84.098 25.1187H83.1268C82.3374 25.0816 81.5488 25.2065 80.81 25.4856C80.3565 25.6695 79.949 25.9501 79.6159 26.3075C79.3924 26.548 79.235 26.8419 79.1589 27.1607C79.1235 27.3258 79.0987 27.493 79.0846 27.6612V36.4408H74.3625V21.7713H78.6961C78.747 21.7709 78.7974 21.7805 78.8445 21.7997C78.8917 21.8188 78.9345 21.847 78.9706 21.8827C79.0067 21.9184 79.0354 21.9608 79.055 22.0076C79.0745 22.0544 79.0846 22.1045 79.0846 22.1552Z"
          fill={color} />
        <path
          d="M109.411 16.1913H114.144V36.455H110.296L110.011 34.7827C109.337 35.3845 108.564 35.8657 107.725 36.2047C106.707 36.6034 105.62 36.7968 104.526 36.7735H102.578C101.556 36.7767 100.541 36.6095 99.5754 36.2787C98.6962 35.9911 97.8806 35.5383 97.1729 34.9448C96.4978 34.3737 95.95 33.6685 95.5646 32.8744C95.1637 32.0403 94.9614 31.1255 94.9733 30.201V28.1447C94.9643 27.2082 95.1766 26.2827 95.5932 25.4429C96.0002 24.6288 96.5732 23.9082 97.2757 23.3269C98.0145 22.723 98.859 22.2602 99.7667 21.9618C100.761 21.6321 101.804 21.4688 102.852 21.4783H104.475C105.413 21.4642 106.347 21.6183 107.231 21.9334C108.011 22.2155 108.743 22.6166 109.399 23.1222L109.411 16.1913ZM109.411 30.4939V27.556C109.354 27.3175 109.249 27.0929 109.102 26.8962C108.869 26.5681 108.573 26.2888 108.231 26.0743C107.762 25.7761 107.252 25.548 106.717 25.3974C105.993 25.1965 105.243 25.1008 104.492 25.113H103.783C103.241 25.109 102.701 25.173 102.175 25.3036C101.708 25.4156 101.267 25.6155 100.875 25.8923C100.509 26.1586 100.214 26.5114 100.018 26.919C99.8013 27.3935 99.6958 27.9109 99.7096 28.432V29.7545C99.6984 30.2414 99.7879 30.7254 99.9724 31.1765C100.146 31.5805 100.415 31.9374 100.755 32.2174C101.129 32.5168 101.561 32.7366 102.024 32.863C102.581 33.0176 103.158 33.0923 103.738 33.0848H104.477C105.241 33.1024 106.003 32.9977 106.734 32.7748C107.271 32.611 107.778 32.3633 108.237 32.0411C108.57 31.8133 108.856 31.5236 109.079 31.1878C109.221 30.9725 109.332 30.7391 109.411 30.4939Z"
          fill={color} />
        <path
          d="M118.175 20.1559V16.1913H123V20.1559H118.175ZM118.292 36.455V21.7713H123V36.455H118.292Z"
          fill={color} />
        <path
          d="M10.2961 8.21373H2.41462C2.02472 8.20707 1.63918 8.29613 1.29207 8.47306C0.944959 8.64998 0.646975 8.9093 0.424451 9.22812C0.201927 9.54693 0.0617179 9.91541 0.0162164 10.301C-0.0292851 10.6866 0.0213223 11.0774 0.163565 11.4389L12.0844 40.7835L16.7865 20.4942C16.9208 19.9098 16.872 19.2983 16.6465 18.7423L13.1328 10.0879C12.9006 9.53025 12.5067 9.0541 12.0015 8.72028C11.4962 8.38645 10.9025 8.2101 10.2961 8.21373Z"
          fill="#001A9E" />
        <path
          d="M38.6171 0.000221262H29.8728C29.2565 -0.00285709 28.6536 0.179999 28.1437 0.52471C27.6338 0.869422 27.2407 1.35982 27.0162 1.93132L12.093 40.7836H23.9767C24.5932 40.7874 25.1964 40.6049 25.7065 40.2601C26.2166 39.9153 26.6095 39.4244 26.8333 38.8525L40.6596 2.867C40.7791 2.54222 40.8176 2.19336 40.7716 1.85051C40.7255 1.50765 40.5965 1.18108 40.3954 0.898944C40.1944 0.61681 39.9275 0.38758 39.6177 0.231012C39.3079 0.0744439 38.9645 -0.00476034 38.6171 0.000221262Z"
          fill="#00A3FF" />
      </svg>
    </i>
  </>;
}
