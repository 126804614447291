import styled from "styled-components";
import { Button, Paper, TextField } from "@material-ui/core";
import { buttonStyles, grayScale, shadowStyles, textStyles } from "theme/styleguide";

export const CustomizeFormContainer = styled.div`
  
`
export const Content = styled.div`
  width: 100%;
  max-width: 586px;
`
export const InstructionsColumn = styled.div`
  width: 100%;
  @media (min-width: 768px)
  {
    max-width: 586px;
  }
`
export const ImageCard = styled(Paper)`
  display: none;
  ${shadowStyles.drop_shadow_2}
  background: ${grayScale.white};
  border-radius: 16px;
  padding: 16px;
  img{
    max-width: 296px;
  }
  @media (min-width: 992px)
  {
    display: initial;
    min-width: 328px;
    min-height: 180px;
    margin: 0 auto;
    height: fit-content;
  }
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 56px;
  @media (min-width: 992px)
  {
    margin-bottom: 72px;
  }
`
export const StyledButton = styled(Button)`
  ${buttonStyles.yapay_grayed_add}
`
export const StyledList = styled.ul`
  padding: 0px;
  list-style: inside;
  margin-bottom: 56px;
  li{
    ${textStyles.tray_text_12px}
    color: ${grayScale.gray_60p100}
  }
  @media (min-width: 768px)
  {
    margin-bottom: 32px;
  }
`
export const StyledTextField = styled(TextField)`
  width: 100%;
  max-width: 557px;
`
export const SubTitle = styled.h4`
  ${textStyles.tray_h4_18px}
  margin: 0px;
  color: ${grayScale.gray_3};
  margin-bottom: 8px;
`
export const UploadedImage = styled.img`
  display: block;
  width: 100%;
  max-width: 80px;
  margin-bottom: 8px;
`
export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (min-width: 768px)
  {
    width: fit-content;
  }
`