export const formatIdentifier = (identifier = "") => {
  if (typeof identifier === "string") {
    identifier = identifier.replace(/[^\d]/g, "");

    if (identifier.length < 12) {
      identifier = identifier.replace(/(\d{3})(\d)/, "$1.$2");
      identifier = identifier.replace(/(\d{3})(\d)/, "$1.$2");
      identifier = identifier.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      identifier = identifier.replace(/(\d{2})(\d)/, "$1.$2");
      identifier = identifier.replace(/(\d{3})(\d)/, "$1.$2");
      identifier = identifier.replace(/(\d{3})(\d)/, "$1/$2");
      identifier = identifier.replace(/(\d{4})(\d{2})$/, "$1-$2");
    }
  } else {
    identifier = ""
  }

  return identifier;
}