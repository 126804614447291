import React from 'react';
import { StepFormHeaderContainer, SettingsTitle, StyledIconButton } from './styles';
import ArrowBackIcon from 'assets/icons/ArrowBackIcon';
import { useOnboarding } from 'context/OnboardingContext';
import { getCurrentOnboardingSectionKey } from 'utils/getCurrentOnboardingSectionKey';
import { onboardingSectionsDictionary } from 'library/onboardingSectionsDictionary';

export default function StepFormHeader({
  handleGoBack = () => { }
}) {
  const { onboardingSectionIndex } = useOnboarding()
  const currentSectionKey = getCurrentOnboardingSectionKey(onboardingSectionIndex)
  return <>
    <StepFormHeaderContainer>
      <StyledIconButton
        size="small"
        onClick={handleGoBack}
      >
        <ArrowBackIcon />
      </StyledIconButton>
      <SettingsTitle>
        {
          onboardingSectionsDictionary[currentSectionKey].headerTitle
        }
      </SettingsTitle>
    </StepFormHeaderContainer>
  </>;
}
