import { useCallback, useEffect, useState } from 'react';
import { object, string, bool } from 'yup';
import { useRef } from 'react';
import { fetchGetBusinessPartnersList } from 'services/BusinessPartners/GET/GetBusinessPartnersListService';
import { fetchUploadDocument } from 'services/Document/POST/UploadDocumentService';
import { parseDataURLtoFile } from 'utils/parseDataURLtoFile';
import { GAEventTracker } from 'utils/GAEventTracker';
import { fetchGetPartnersDocumentStatus } from 'services/Document/GET/GetPartnersDocumentStatusService';
import { findPartnerStatus } from 'utils/findPartnerStatus';

export default function usePartnersDocumentsUploadDataSetup({
  afterSubmit = () => { },
  isFilteringPartner = false,
  filteredPartnerId,
}) {
  const mounted = useRef(false);
  const [isLoading, setLoading] = useState(true)
  const [partnersList, setParntersList] = useState([])
  const [selectedPartnerIndex, setSelectedParterIndex] = useState(0)
  const [selectedPartnerData, setSelectedPartnerData] = useState({})
  const [documentRequired, setDocumentRequired] = useState(true)
  const [documentStatus, setDocumentStatus] = useState({})
  const [partnersDocumentsStatus, setPartnersDocumentsStatus] = useState([])
  const [initialValues, setInitialValues] = useState()
  const [validationSchema, setValidationSchema] = useState()
  const PFInitialValues = {
    frontPagePhoto: "",
    versePagePhoto: "",
    selectedDocumentType: "identity_card",
  }
  const PJInitialValues = {
    enterpriseProofingPhoto: "",
    selectedDocumentType: "ccmei",
  }
  const PFValidationSchema = object({
    frontPagePhoto: string()
      .required(),
    versePagePhoto: string()
      .required(),
    selectedDocumentType: string()
      .required(),
  })
  const PJValidationSchema = object({
    enterpriseProofingPhoto: string()
      .required(),
    selectedDocumentType: string()
      .required(),
    isAttorney: bool()
  })

  const fetchPartnersData = useCallback(async () => {
    let fetchedPartnersList = await fetchGetBusinessPartnersList()
    if (isFilteringPartner) {
      fetchedPartnersList = fetchedPartnersList.filter((e) => e.id === filteredPartnerId)
    }
    fetchedPartnersList = fetchedPartnersList.map((e) => {
      e.isPFPartner = !(!!e?.cnpj)
      return e
    })
    const personalPartnersIdList = fetchedPartnersList.filter((e) => e?.isPFPartner).map((e) => e.id)
    const businessPartnersIdList = fetchedPartnersList.filter((e) => !e?.isPFPartner).map((e) => e.id)

    const [
      document_partner,
      company_partner,
    ] = await Promise.all([
      fetchGetPartnersDocumentStatus("document_partner", personalPartnersIdList),
      fetchGetPartnersDocumentStatus("company_partner", businessPartnersIdList),
    ])
    const fetchedPartnersDocumentsStatus = [...document_partner, ...company_partner]

    if (mounted.current) {
      setParntersList(fetchedPartnersList)
      setSelectedPartnerData(fetchedPartnersList[0])
      setPartnersDocumentsStatus(fetchedPartnersDocumentsStatus)
      setLoading(false)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchPartnersData()
  }, [fetchPartnersData])

  useEffect(() => {
    if (mounted.current) {
      if (selectedPartnerData.isPFPartner) {
        setInitialValues(PFInitialValues)
        setValidationSchema(PFValidationSchema)
      } else {
        setInitialValues(PJInitialValues)
        setValidationSchema(PJValidationSchema)
      }

      const filteredDocument = findPartnerStatus(selectedPartnerData, partnersDocumentsStatus)
      setDocumentStatus(filteredDocument)
      if (["pending", "approved"].includes(filteredDocument?.status?.tag)) {
        setDocumentRequired(false);
      } else {
        setDocumentRequired(true)
      }
    }
  }, [selectedPartnerData, partnersDocumentsStatus])  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async (
    {
      frontPagePhoto,
      versePagePhoto,
      selectedDocumentType,
      enterpriseProofingPhoto,
    },
    {
      resetForm,
    },
  ) => {
    if (frontPagePhoto) {
      const fileFrontPagePhoto = await parseDataURLtoFile(frontPagePhoto, "frontPagePhoto")
      await fetchUploadDocument({
        file: fileFrontPagePhoto,
        group: "document_partner",
        document_type: "img_front",
        document_identify: selectedDocumentType,
        business_partner_id: selectedPartnerData.id,
      })
      GAEventTracker({
        category: "Onboarding",
        action: "Anexar foto da frente do documento do sócio",
        label: "Anexar uma foto de documento do sócio e salvar"
      })
    }
    if (versePagePhoto) {
      const fileVersePagePhoto = await parseDataURLtoFile(versePagePhoto, "versePagePhoto")
      await fetchUploadDocument({
        file: fileVersePagePhoto,
        group: "document_partner",
        document_type: "img_back",
        document_identify: selectedDocumentType,
        business_partner_id: selectedPartnerData.id,
      })
      GAEventTracker({
        category: "Onboarding",
        action: "Anexar foto do verso do documento do sócio",
        label: "Anexar uma foto de documento do sócio e salvar"
      })
    }
    if (enterpriseProofingPhoto) {
      const fileEnterpriseProofingPhoto = await parseDataURLtoFile(enterpriseProofingPhoto, "enterpriseProofingPhoto")
      await fetchUploadDocument({
        file: fileEnterpriseProofingPhoto,
        group: "company_partner",
        category: selectedDocumentType,
        business_partner_id: selectedPartnerData.id,
      })
      GAEventTracker({
        category: "Onboarding",
        action: "Anexar documento de constituiçao da empresa do sócio",
        label: "Anexar aconstituiçao da empresa do sócio e salvar"
      })
    }
    if (selectedPartnerIndex === partnersList.length - 1) {
      afterSubmit()
    } else {
      setLoading(true)
      resetForm()
      setSelectedPartnerData(partnersList[selectedPartnerIndex + 1])
      setSelectedParterIndex((oldValue) => oldValue + 1)
      setLoading(false)
      window.scrollTo(0, 0)
    }
  }

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    selectedPartnerData,
    documentRequired,
    documentStatus,
  })
}