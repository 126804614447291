import { Typography, Select } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, primaryColors, textStyles } from "theme/styleguide";

export const ReplyParcelsOptionsContainer = styled.div`
  margin-bottom:80px;
  margin-top:80px;
`
export const Instruction = styled(Typography)`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-left: 14px;
`
export const ParcelText = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-left: 56px;
  margin-right: 24px;
    margin-bottom: 8px;
    @media (min-width: 768px)
  {
    margin-bottom: 0px;
  }
`
export const CheckBoxContainer = styled.div`
  display:flex;
  align-items:center;
  .MuiCheckbox-colorPrimary.Mui-checked {
    color: ${primaryColors.blue_3} !important;
  };
`
export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  @media (min-width: 768px)
  {
  flex-direction: row;
  align-items: center;
  }
`
export const SelectStyled = styled(Select)`
  ${textStyles.body_1_14px}
  width: 232px;
  margin-left: 56px;
  .MuiSelect-select:focus{
    background: transparent !important;
  }
  .MuiOutlinedInput-input {
    padding: 9px 14px;
  }
  @media (min-width: 768px)
  {
    width: 168px;
    margin-left: 0px;
  }
`