import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchUpdateAccountData = async (body) => {
  const code = getDataStorage('yapay_code')
  
  return apiPrivate.post("/myaccount/account/update",
    body,
    {
      headers: {
        code
      }
    })
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}