import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Collapse } from '@material-ui/core';
import HighlightOffBlack from 'assets/icons/HighlightOffBlack';
import WarningBank from 'assets/icons/WarningBank';
import { BankAccountErrorContainer, StyledAlert, StyledButton } from './styles';
import { fetchGetAccountPendencies } from 'services/Account/GET/GetAccountPendenciesService';
import { fetchGetDocumentStatus } from 'services/Document/GET/GetDocumentStatusService';
import { useDashboard } from 'context/DashboardContext';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import { fetchGetAccount } from 'services/Account/GET/GetAccountService';

export default function BankAccountError() {
  const { setCurrentTab } = useDashboard()
  const { setSelectedAccountDataMenuTab } = useDashboardMyAccount()
  const [isDocumentRejected, setDocumentRejected] = useState(false)
  const [isAlertAccountBankOpen, setAlertAccountBankOpen] = useState(false)
  const [hasBankCipError, setBankCipError] = useState(false)
  const [rejectedReason, setRejectedReason] = useState("")
  const [isBusinessAccount, setBusinessAccount] = useState(false)
  const mounted = useRef(false);

  const handleBankFormNavigation = () => {
    if (isBusinessAccount) {
      setSelectedAccountDataMenuTab(3)
    } else {
      setSelectedAccountDataMenuTab(1)
    }
    setCurrentTab("myaccount")
  }

  const checkBankAccountStatus = useCallback(async () => {
    const pendencies = await fetchGetAccountPendencies({})
    const documentStatus = await fetchGetDocumentStatus("bank_account")
    const { type } = await fetchGetAccount()
    if (mounted.current) {
      setBusinessAccount(type === "business")
      setDocumentRejected(false)
      setAlertAccountBankOpen(false)
      if (documentStatus?.status?.tag === "rejected") {
        setRejectedReason(documentStatus?.reject_reason?.name || "")
        setDocumentRejected(true)
        setAlertAccountBankOpen(true)
      } else if (pendencies.bank_account) {
        setAlertAccountBankOpen(true)
        setBankCipError(false)
      } else if (pendencies.bank_cip) {
        setAlertAccountBankOpen(true)
        setBankCipError(true)
      } else {
        setAlertAccountBankOpen(false)
      }
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  useEffect(() => {
    checkBankAccountStatus()
  }, [checkBankAccountStatus])

  return <>
    <Collapse
      in={isAlertAccountBankOpen}
    >
      <BankAccountErrorContainer>
        <StyledAlert
          $isDocumentRejected={isDocumentRejected}
          icon={
            isDocumentRejected ?
              <HighlightOffBlack /> :
              <WarningBank />
          }
        >
          {
            isDocumentRejected ? // Texto de erro de conta reprovada
              <>
                <b>
                  {`Sua conta bancária foi reprovada na verificação`}
                </b>
                {`\n\nMotivo da reprovação:\n`}
                <b>
                  {rejectedReason}
                </b>
                {`\n\nValidação de conta bancária por PIX - conta bancária rejeitada.\n`}
              </>
              : hasBankCipError ? // Texto de erro da CIP
                <>
                  <b>
                    {`Atenção`}
                  </b>
                  {`\n\nIdentificamos que seu banco não faz parte dos bancos ativos na CIP. Portanto é necessário trocar sua conta bancária por outro banco para garantir o recebimento de suas vendas, conforme a `}
                  <a
                    href='https://atendimento.yapay.com.br/hc/pt-br/articles/4948199391259'
                    rel="noreferrer"
                    target="_blank">
                    {`Resolução BCB n° 150 de 6/10/2021.`}
                  </a>
                  {` Caso contrário, você poderá ficar impossibilitado de vender temporariamente e seus recebíveis ficarem retidos.`}
                  <b>{`\n\nO que é a CIP?\n`}</b>
                  {`A Camara Interbancária de Pagamentos é responsável por centralizar todos os pagamentos de vendas de cartão e antecipações, além de garantir que o lojista receba suas vendas com maior segurança.\n\n`}
                </> : // Texto de erro quando não há conta bancaria
                <>
                  <b>
                    {`Atenção`}
                  </b>
                  {`\n\nIdentificamos que você não possui conta bancária cadastrada em nossa plataforma ou seus dados bancários estão incompletos.`}
                  {`\n\nPedimos que cadastre uma conta bancária de sua titularidade para garantir o recebimento de suas vendas, conforme a `}
                  <a
                    href='https://atendimento.yapay.com.br/hc/pt-br/articles/4948199391259'
                    rel="noreferrer"
                    target="_blank">
                    {`Resolução BCB n° 150 de 6/10/2021.`}
                  </a>
                  {`Caso contrário, você poderá ficar impossibilitado de vender temporariamente e seus recebíveis ficarem retidos.\n\n`}
                </>
          }
          <StyledButton
            $isDocumentRejected={isDocumentRejected}
            onClick={handleBankFormNavigation}
          >
            {
              isDocumentRejected ?
                "Cadastrar outra conta bancária" :
                hasBankCipError ?
                  "Alterar conta bancária" :
                  "Cadastrar conta bancária"
            }
          </StyledButton>
        </StyledAlert>
      </BankAccountErrorContainer>
    </Collapse>
  </>;
}
