import styled from "styled-components";
import { borderStyles, grayScale, textStyles } from "theme/styleguide";

export const FeesInfoCardContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  background: ${grayScale.white};
  ${borderStyles.border_1}
  width: 100%;
  max-width: 635px;
  padding: 28px 36px;
  margin-bottom: 16px;
`
export const CardColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 32px;
`
export const CardTitle = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  font-weight: 400;
  margin-bottom: 2px;
`
export const CardInfo = styled.span`
  display: block;
  ${textStyles.tray_h4_18px}
  color: ${grayScale.black};
`