import { Button } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, primaryColors, shadowStyles, textStyles } from "theme/styleguide";
import { Menu, MenuItem } from '@material-ui/core';
export const DesktopDashboardHeaderContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  max-width: 752px;
  justify-content: flex-end;
  align-items: center;
  button:not(:last-child){
    margin-right: 96px;
  }
`
export const PortalButton = styled(Button)`
  border-radius: 2px;
  width: calc(100% - 16px); //254px
  @media (min-width: 820px)
  {
    width: 124px;
  }
`
export const StyledButton = styled(Button)`
  width: fit-content !important;
  min-width: fit-content;
  height: 36.5px;
  padding: 0px;
  transition: ease-in-out 100ms;
  ${({ $isSelected }) => $isSelected ? textStyles.tray_h5_15px : textStyles.tray_text_14px}
  :hover{
    background: transparent;
  }
  .MuiButton-label{
    color: ${({ $isSelected }) => $isSelected ? primaryColors.blue_3 : grayScale.gray_3};
  }
  @media (min-width: 820px)
  {
    width: 124px;
  }
`

export const StyledMenu = styled(Menu)`
  .MuiList-padding{
    padding: 0px 0px;
  }
  box-shadow: none;
  border-radius: 4px;
  .MuiPaper-elevation8{
  ${shadowStyles.drop_shadow_4}
  }
`
export const StyledMenuItem = styled(MenuItem)`
  max-width: 176px;
  min-height: 40px;
  white-space: pre-wrap;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  padding: 16px 32px;
`