import { useAccountData } from 'context/AccountDataContext';
import { useCallback, useEffect, useState, useRef } from 'react';
import { fetchGetSigninUser } from 'services/Signin/GET/GetSigninUserService';

export default function useUserEmailDataSetup() {
  const { setOldEmail } = useAccountData()
  const mounted = useRef(false)
  const [userEmail, setUserEmail] = useState("")
  const [hasVerifiedPhone, setVerifiedPhone] = useState(true)
  const [isLoading, setLoading] = useState(true)

  const fetchData = useCallback(async () => {
    const { email, phone_validated } = await fetchGetSigninUser()
    if (mounted.current) {
      setUserEmail(email)
      setOldEmail(email)
      setVerifiedPhone(phone_validated)
      setLoading(false)
    }
  }, [setOldEmail])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return ({
    userEmail,
    hasVerifiedPhone,
    isLoading,
  })
}