/* eslint-disable max-len */
import React from 'react';

export default function AlertTriangleIcon({ size = 1, color = "#FAB81E" }) {
  return <>
    <i>
      <svg
        width={64 * size}
        height={65 * size}
        viewBox="0 0 64 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.0754 6.38218C29.2735 5.70764 30.6253 5.35327 32.0002 5.35327C33.3752 5.35327 34.7269 5.70764 35.925 6.38218C37.1231 7.05672 38.1271 8.02867 38.8402 9.20425L38.8479 9.21691L61.4345 46.9236L61.4562 46.9604C62.1547 48.1701 62.5243 49.5416 62.5282 50.9385C62.5321 52.3354 62.1702 53.709 61.4785 54.9225C60.7867 56.1361 59.7893 57.1474 58.5854 57.8558C57.3814 58.5642 56.013 58.9451 54.6162 58.9604L54.5869 58.9608L9.38424 58.9606C7.98743 58.9452 6.619 58.5642 5.41507 57.8558C4.21114 57.1474 3.21369 56.1361 2.52195 54.9225C1.83021 53.709 1.46829 52.3354 1.4722 50.9385C1.47611 49.5416 1.84572 48.1701 2.54424 46.9604L2.56591 46.9236L25.1526 9.21693L27.4402 10.5872L25.1602 9.20425C25.8733 8.02867 26.8773 7.05672 28.0754 6.38218ZM29.7234 11.965L7.15405 49.6428C6.92691 50.0423 6.8068 50.4937 6.80551 50.9534C6.80421 51.4191 6.92485 51.8769 7.15543 52.2814C7.38601 52.686 7.7185 53.0231 8.1198 53.2592C8.5176 53.4933 8.96927 53.6201 9.43061 53.6273H54.5698C55.0312 53.6201 55.4828 53.4933 55.8806 53.2592C56.2819 53.0231 56.6144 52.686 56.845 52.2814C57.0756 51.8769 57.1962 51.4191 57.1949 50.9534C57.1936 50.4938 57.0736 50.0424 56.8465 49.6429L34.2802 11.9703C34.2791 11.9685 34.2781 11.9667 34.277 11.965C34.0395 11.5755 33.706 11.2534 33.3085 11.0296C32.9091 10.8047 32.4585 10.6866 32.0002 10.6866C31.5419 10.6866 31.0913 10.8047 30.692 11.0296C30.2944 11.2534 29.9609 11.5755 29.7234 11.965Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.0002 21.6273C33.4729 21.6273 34.6668 22.8212 34.6668 24.2939V34.9606C34.6668 36.4333 33.4729 37.6273 32.0002 37.6273C30.5274 37.6273 29.3335 36.4333 29.3335 34.9606V24.2939C29.3335 22.8212 30.5274 21.6273 32.0002 21.6273Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.3335 45.6273C29.3335 44.1545 30.5274 42.9606 32.0002 42.9606H32.0268C33.4996 42.9606 34.6935 44.1545 34.6935 45.6273C34.6935 47.1001 33.4996 48.294 32.0268 48.294H32.0002C30.5274 48.294 29.3335 47.1001 29.3335 45.6273Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
