import { useCallback, useEffect, useState } from 'react';
import { object, string, date } from 'yup';
import { useRef } from 'react';
import { parseStringToCEP } from 'utils/parseStringToCEP';
import { fetchGetBusinessPartnersList } from 'services/BusinessPartners/GET/GetBusinessPartnersListService';
import { fetchCreateBusinessPartner } from 'services/BusinessPartners/POST/CreateBusinessPartnerService';
import { formatIdentifier } from 'utils/formatIdentifier';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { fetchUpdateBusinessPartner } from 'services/BusinessPartners/POST/UpdateBusinessPartnerService';
import { getDataStorage } from 'utils/storage';
import { formatDate } from 'utils/formatDate';
import { formatPhone } from 'utils/formatPhone';

export default function useCompleteBusinessAccountTitularDataSetup({
  reloadAfterSubmit = false,
  afterSubmit = () => { },
  afterSubmitAlternative = () => { },
}) {
  const mounted = useRef(false);
  const [isLoading, setLoading] = useState(true)
  const [initialValues, setInitialValues] = useState()
  const [oldValues, setOldValues] = useState({})
  const [validationSchema, setValidationSchema] = useState()
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [displayedName, setDisplayedName] = useState("")
  const [identifier, setIdentifier] = useState("")

  const fetchAccountData = useCallback(async () => {
    const [
      fetchedTitularPartner,
      fetchedMyAccount,
    ] = await Promise.all([
      fetchGetBusinessPartnersList(true),
      fetchGetAccountMyAccount(),
    ])

    const validationObject = {
      birthDate: date()
        .nullable()
        .typeError("Informe uma data válida"),
      telephone: string()
        .min(14, 'O número de telefone informado deve possuir ao menos 14 dígitos')
        .max(15, 'O número de telefone informado deve possuir no máximo 15 dígitos')
        .required('Este campo é obrigatório'),
      motherName: string()
        .required("Este campo é obrigatório"),
      isPPE: string()
        .required('Este campo é obrigatório'),
      address: object()
        .shape({
          street: string(),
          number: string(),
          complement: string(),
          neighborhood: string(),
          city: string(),
          state: string(),
          zipCode: string()
            .min(9, 'O CEP informado é inválido'),
        }),
    }
    const initialValuesObject = {
      name: fetchedMyAccount?.name || "",
      CPF: formatIdentifier(fetchedMyAccount?.cpf) || "",
      birthDate: formatDate(fetchedTitularPartner?.birth_date),
      telephone: formatPhone(fetchedTitularPartner?.phone?.phone) || "",
      motherName: fetchedTitularPartner?.mother_name || "",
      isPPE: String(fetchedTitularPartner?.politically_exposed || false),
      address: {
        street: fetchedTitularPartner?.address?.street || "",
        number: fetchedTitularPartner?.address?.number || "",
        complement: fetchedTitularPartner?.address?.complement || "",
        neighborhood: fetchedTitularPartner?.address?.neighborhood || "",
        city: fetchedTitularPartner?.address?.city || "",
        state: fetchedTitularPartner?.address?.state || "",
        zipCode: parseStringToCEP(fetchedTitularPartner?.address?.zipcode || ""),
      },
      partnerID: fetchedTitularPartner?.id,
    }

    if (mounted.current) {
      setDisplayedName(fetchedMyAccount?.social_name)
      setIdentifier(fetchedMyAccount?.cnpj)
      setOldValues(initialValuesObject)
      setInitialValues(initialValuesObject)
      setValidationSchema(object(validationObject))
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async ({
    name,
    CPF,
    birthDate,
    telephone,
    motherName,
    isPPE,
    address,
    partnerID,
  }) => {
    const titularPartnerObject = {
      ...(!!motherName && motherName !== oldValues?.motherName) && {
        mother_name: motherName,
      },
      ...(!!birthDate && birthDate !== oldValues?.birthDate) && {
        birth_date: birthDate,
      },
      ...(!!telephone && telephone !== oldValues?.telephone) && {
        phone: telephone,
        phone_type_tag: "business",
      },
      ...(isPPE !== oldValues?.isPPE) && {
        politically_exposed: isPPE,
      },
      ...(JSON.stringify(address) !== JSON.stringify(oldValues.address) &&
        !!address.street &&
        !!address.number &&
        !!address.neighborhood &&
        !!address.city &&
        !!address.state &&
        !!address.zipCode
      ) && {
        street: address.street,
        number: address.number,
        complement: address.complement,
        neighborhood: address.neighborhood,
        city: address.city,
        state: address.state,
        zipcode: address.zipCode,
      }
    }

    if (Object.keys(titularPartnerObject).length > 0) {
      if (partnerID === undefined) { // Create partner
        await fetchCreateBusinessPartner({
          ...titularPartnerObject,
          name: name,
          cpf: CPF,
          business_partner_kind_id: 1,
          profile: "OWNER",
          profile_type: "MASTER",
        })
      } else { // Update partner
        await fetchUpdateBusinessPartner({
          ...titularPartnerObject,
          partner_id: partnerID,
        })
      }
    }

    if (reloadAfterSubmit) {
      await fetchAccountData()
    }
    setSnackbarOpen(true)
    const hasPartnersWithOver25Percent = getDataStorage('hasPartnersWithOver25Percent') || null
    if (JSON.parse(hasPartnersWithOver25Percent)) {
      afterSubmit()
    } else {
      afterSubmitAlternative()
    }
  }

  useEffect(() => {
    fetchAccountData()
  }, [fetchAccountData])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    isSnackbarOpen,
    setSnackbarOpen,
    displayedName,
    identifier,
  })
}