/* eslint-disable max-len */
import React from 'react';

export default function AccountCircleIcon({ size = 1, color = "#235390" }) {
  return <>
    <i>
      <svg
        width={40 * size}
        height={40 * size}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.9999 3.3335C10.7999 3.3335 3.33325 10.8002 3.33325 20.0002C3.33325 29.2002 10.7999 36.6668 19.9999 36.6668C29.1999 36.6668 36.6666 29.2002 36.6666 20.0002C36.6666 10.8002 29.1999 3.3335 19.9999 3.3335ZM11.7833 30.4668C12.4999 28.9668 16.8666 27.5002 19.9999 27.5002C23.1333 27.5002 27.5166 28.9668 28.2166 30.4668C25.9499 32.2668 23.0999 33.3335 19.9999 33.3335C16.8999 33.3335 14.0499 32.2668 11.7833 30.4668ZM30.5999 28.0502C28.2166 25.1502 22.4333 24.1668 19.9999 24.1668C17.5666 24.1668 11.7833 25.1502 9.39992 28.0502C7.69992 25.8168 6.66659 23.0335 6.66659 20.0002C6.66659 12.6502 12.6499 6.66683 19.9999 6.66683C27.3499 6.66683 33.3333 12.6502 33.3333 20.0002C33.3333 23.0335 32.2999 25.8168 30.5999 28.0502ZM19.9999 10.0002C16.7666 10.0002 14.1666 12.6002 14.1666 15.8335C14.1666 19.0668 16.7666 21.6668 19.9999 21.6668C23.2333 21.6668 25.8333 19.0668 25.8333 15.8335C25.8333 12.6002 23.2333 10.0002 19.9999 10.0002ZM19.9999 18.3335C18.6166 18.3335 17.4999 17.2168 17.4999 15.8335C17.4999 14.4502 18.6166 13.3335 19.9999 13.3335C21.3833 13.3335 22.4999 14.4502 22.4999 15.8335C22.4999 17.2168 21.3833 18.3335 19.9999 18.3335Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
