import React from 'react';
import { MainSettingsStartContainer, Title, Description, StyledImage, StyledButton } from './styles';
import ConfigImage from 'assets/images/config.png'
import { useOnboarding } from 'context/OnboardingContext';

export default function MainSettingsStart() {
  const { nextMainSettingsStep } = useOnboarding()

  const handleContinue = () => {
    nextMainSettingsStep()
  }
  return <>
    <MainSettingsStartContainer>
      <StyledImage
        src={ConfigImage}
        alt="Configurações"
      />
      <Title>
        {`Documentos enviados!\nAgora é a hora das configurações`}
      </Title>
      <Description>
        Esta é a última etapa, na qual você deverá fazer ou revisar algumas configurações essenciais para recebimento de vendas.
      </Description>
      <StyledButton
        onClick={handleContinue}
      >
        Continuar
      </StyledButton>
    </MainSettingsStartContainer>
  </>;
}
