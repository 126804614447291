import React, { Fragment, useState } from 'react';
import { createEmptyPartner } from 'utils/createEmptyPartnerObject';
import { grayScale, primaryColors } from 'theme/styleguide';
import { FieldArray } from 'formik';
import SnackbarAlert from 'components/shared/SnackbarAlert';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import LoadableButton from 'components/shared/LoadableButton';
import AlertDialog from 'components/shared/AlertDialog';
import PersonalPartnerItem from 'components/Dashboard/MyAccountTab/AccountDataMenu/BusinessAccount/components/PersonalPartnerItem';
import EmptyPartnersList from 'components/Dashboard/MyAccountTab/AccountDataMenu/BusinessAccount/components/EmptyPartnersList';
import BusinessPartnerItem from 'components/Dashboard/MyAccountTab/AccountDataMenu/BusinessAccount/components/BusinessPartnerItem';
import { StyledButtonsContainer } from 'components/Dashboard/AccountDataMenu/styles';
import WarningCircle from 'assets/icons/WarningCircle';
import TrashCan from 'assets/icons/TrashCan/TrashCan';
import ExpandMoreIcon from 'assets/icons/ExpandMoreIcon';
import EditIcon from 'assets/icons/EditIcon';
import AddCircleIcon from 'assets/icons/AddCircleIcon';
import { AccordionDetails, AccordionSummary, Collapse, FormHelperText, IconButton, Button, CircularProgress, Tooltip } from '@material-ui/core';
import { PartnersFieldsContainer, StyledRadioOptions, StyledAccordion, StyledAddButton, ActionsRow, StyledAlert, AccountTypeViewer, RowContainer } from './styles';

const partnerOptions = [
  {
    value: "true",
    label: "Sócio(a) pessoa física"
  },
  {
    value: "false",
    label: "Sócio(a) pessoa jurídica"
  },
]
export default function PartnersFields({
  values,
  errors,
  touched,
  isLoading = true,
  disabledForm = false,
  setFieldValue = () => { },
  handleSubmit = () => { },
  ...formikProps
}) {
  const [selectedPartnerIndex, setSelectedPartnerIndex] = useState()
  const [isDeletePartnerAlertOpen, setDeletePartnerAlertOpen] = useState(false)
  const [isCancelEditionAlertOpen, setCancelEditionAlertOpen] = useState(false)
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [fieldArrayHelpers, setFieldArrayHelpers] = useState()
  const [isLoadingDeleteSubmit, setLoadingDeleteSubmit] = useState(false)

  const handleCreateNewPartner = (arrayHelpers) => {
    arrayHelpers.push(createEmptyPartner())
    togglePanelExpasion(values.partnersList.length)
  }

  const handleOpenDeletePartnerAlert = (arrayHelpers, index) => {
    setSelectedPartnerIndex(index)
    setFieldArrayHelpers(arrayHelpers)
    setDeletePartnerAlertOpen(true)
  }

  const handleDismissDeletePartnerAlert = () => {
    setDeletePartnerAlertOpen(false)
  }

  const handleDeletePartner = async () => {
    setDeletePartnerAlertOpen(false)
    setLoadingDeleteSubmit(true)
    if (values.partnersList[selectedPartnerIndex].isNewRegister) {
      fieldArrayHelpers.remove(selectedPartnerIndex)
    } else {
      await handleSubmit({
        ...values,
        excludedPartnersList: [values.partnersList[selectedPartnerIndex]],
      })
    }
    togglePanelExpasion()
    setLoadingDeleteSubmit(false)
    setSnackbarOpen(true)
  }

  const handleOpenCancelEditionAlert = () => {
    setCancelEditionAlertOpen(true)
  }

  const handleDismissCancelEditionAlert = () => {
    setCancelEditionAlertOpen(false)
  }

  const handleCancelEdition = () => {
    formikProps.resetForm()
    togglePanelExpasion()
    setCancelEditionAlertOpen(false)
  }

  const togglePanelExpasion = (index) => {
    if (selectedPartnerIndex !== index) {
      setSelectedPartnerIndex(index)
    } else if (values?.partnersList[index].disabledForm) {
      setSelectedPartnerIndex()
    }
  }

  const togglePanelEdition = () => {
    if (values?.partnersList?.filter((e) => !e.disabledForm)?.length === 1) {
      handleOpenCancelEditionAlert()
    } else {
      const oldValue = values?.partnersList[selectedPartnerIndex].disabledForm
      setFieldValue(`partnersList.${selectedPartnerIndex}.disabledForm`, !oldValue)
    }
  }

  return <>
    <PartnersFieldsContainer>
      {
        isLoading ?
          <LoadingIndicator /> :
          <FieldArray
            name="partnersList"
            render={(arrayHelpers) => {
              return (
                <>
                  {
                    values?.partnersList?.map((partner, index) => {
                      return (
                        <Fragment
                          key={index}
                        >
                          <StyledAccordion
                            expanded={selectedPartnerIndex === index}
                            onChange={() => { togglePanelExpasion(index) }}
                            disabled={
                              selectedPartnerIndex !== index && (
                                isLoadingDeleteSubmit ||
                                values?.partnersList?.filter((e) => !e.disabledForm)?.length === 1
                              )
                            }
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon />
                              }
                            >
                              <span>
                                {partner?.name}
                              </span>
                            </AccordionSummary>
                            <AccordionDetails>
                              <RowContainer>
                                {
                                  partner.disabledForm &&
                                  <AccountTypeViewer
                                    $isMobile
                                  >
                                    Tipo:
                                    <b>
                                      {
                                        partner?.isPFPartner === "true" ?
                                          " Pessoa física" :
                                          " Pessoa jurídica"
                                      }
                                    </b>
                                  </AccountTypeViewer>
                                }
                                <ActionsRow>
                                  <Collapse
                                    in={partner.disabledForm && !isLoadingDeleteSubmit}
                                  >
                                    <Tooltip
                                      title="Editar"
                                      arrow
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={togglePanelEdition}
                                      >
                                        <EditIcon
                                          size={1.25}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Collapse>
                                  <Collapse
                                    in={!(formikProps.isSubmitting || isLoading || isLoadingDeleteSubmit)}
                                  >
                                    <Tooltip
                                      title="Excluir"
                                      arrow
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() => { handleOpenDeletePartnerAlert(arrayHelpers, index) }}
                                      >
                                        <TrashCan
                                          color={grayScale.gray_3}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Collapse>
                                  {
                                    isLoadingDeleteSubmit &&
                                    <CircularProgress
                                      size={22.5}
                                      className={"circularProgress"}
                                    />
                                  }
                                </ActionsRow>
                              </RowContainer>
                              {
                                !partner.disabledForm ?
                                  <StyledRadioOptions
                                    receivedValue={partner?.isPFPartner}
                                    receivedSetter={(e) => { setFieldValue(`partnersList.${index}.isPFPartner`, e) }}
                                    options={partnerOptions}
                                    disabled={partner.disabledForm}
                                  /> :
                                  <AccountTypeViewer>
                                    Tipo:
                                    <b>
                                      {
                                        partner?.isPFPartner === "true" ?
                                          " Pessoa física" :
                                          " Pessoa jurídica"
                                      }
                                    </b>
                                  </AccountTypeViewer>
                              }
                              <Collapse
                                in={!partner.disabledForm}
                              >
                                <StyledAlert
                                  severity="info"
                                  icon={<WarningCircle />}
                                >
                                  <b>
                                    Atenção:
                                  </b>
                                  {
                                    partner?.isPFPartner === "true" ?
                                      " após o preenchimento das informações, será necessário enviar uma foto do RG ou CNH desta pessoa sócia." :
                                      " após o preenchimento das informações, será necessário enviar o documento de constituição desta empresa sócia."
                                  }
                                </StyledAlert>
                              </Collapse>
                              {
                                JSON.parse(values.partnersList[index].isPFPartner) ?
                                  <PersonalPartnerItem
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    disabledForm={partner.disabledForm}
                                    setFieldValue={setFieldValue}
                                    partner={partner}
                                    index={index}
                                  /> :
                                  <BusinessPartnerItem
                                    values={values}
                                    touched={touched}
                                    errors={errors}
                                    disabledForm={partner.disabledForm}
                                    setFieldValue={setFieldValue}
                                    partner={partner}
                                    index={index}
                                  />
                              }
                              <Collapse
                                in={!partner.disabledForm}
                              >
                                <StyledButtonsContainer>
                                  <LoadableButton
                                    type="submit"
                                    disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading || isLoadingDeleteSubmit}
                                    isLoading={formikProps.isSubmitting}
                                    id={"plugin_menu-minha-conta_aba_dados-socios_botao_salvar"}
                                  >
                                    Salvar
                                  </LoadableButton>
                                  <Button
                                    variant="outlined"
                                    onClick={() => { handleOpenCancelEditionAlert(index, partner) }}
                                    disabled={formikProps.isSubmitting || isLoading || isLoadingDeleteSubmit}
                                    id={"plugin_menu-minha-conta_aba_dados-socios_botao_cancelar"}
                                  >
                                    Cancelar
                                  </Button>
                                </StyledButtonsContainer>
                              </Collapse>
                            </AccordionDetails>
                          </StyledAccordion>
                        </Fragment>
                      )
                    })
                  }
                  {
                    values?.partnersList?.length > 0 ?
                      <>
                        <StyledAddButton
                          onClick={() => { handleCreateNewPartner(arrayHelpers) }}
                          id={"plugin_menu-minha-conta_aba_dados-socios_botao_add-mais-socios"}
                          disabled={values?.partnersList?.filter((e) => !e.disabledForm)?.length === 1}
                          variant={
                            values?.partnersList?.filter((e) => !e.disabledForm)?.length === 1 ?
                              "contained" :
                              "outlined"
                          }
                          startIcon={
                            <AddCircleIcon
                              color={
                                values?.partnersList?.filter((e) => !e.disabledForm)?.length === 1 ?
                                  primaryColors.blue_5 + "33" :
                                  primaryColors.blue_5
                              }
                              size={0.8}
                            />
                          }
                        >
                          Adicionar mais sócios(as)
                        </StyledAddButton>
                        {
                          typeof (errors?.partnersList) === "string" &&
                          <FormHelperText
                            error
                          >
                            {errors?.partnersList}
                          </FormHelperText>
                        }
                      </> :
                      <EmptyPartnersList
                        receivedFuntion={() => { handleCreateNewPartner(arrayHelpers) }}
                      />
                  }
                  <AlertDialog
                    description="Deseja realmente excluir esta pessoa sócia?"
                    open={isDeletePartnerAlertOpen}
                    buttonsProps={[
                      {
                        title: "Excluir",
                        function: handleDeletePartner
                      },
                      {
                        title: "Cancelar",
                        function: handleDismissDeletePartnerAlert
                      }
                    ]}
                  />
                  <AlertDialog
                    description="Deseja realmente cancelar as alterações?"
                    open={isCancelEditionAlertOpen}
                    buttonsProps={[
                      {
                        title: "Confirmar",
                        function: handleCancelEdition
                      },
                      {
                        title: "Cancelar",
                        function: handleDismissCancelEditionAlert
                      }
                    ]}
                  />
                </>
              )
            }}
          />
      }
      <SnackbarAlert
        isOpen={isSnackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        message="Sócio(a) excluído"
      />
    </PartnersFieldsContainer>
  </>;
}