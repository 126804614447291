import { createContext, useContext, useState } from 'react';
const AccountVerificationContext = createContext();

export default function AccountVerificationProvider({ children }) {
  const [userIdUploadData, setUserIdUploadData] = useState({})
  return <>
    <AccountVerificationContext.Provider value={{
      userIdUploadData,
      setUserIdUploadData,
    }}>
      {children}
    </AccountVerificationContext.Provider>
  </>;
}

export function useAccountVerification() {
  const {
    userIdUploadData,
    setUserIdUploadData,
  } = useContext(AccountVerificationContext)
  return {
    userIdUploadData,
    setUserIdUploadData,
  }
}
