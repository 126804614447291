import styled from "styled-components";
import { TextField } from "@material-ui/core";
import { grayScale } from "theme/styleguide";

export const TextFieldRequesterContainer = styled.div`
  
`

export const StyledTextField = styled(TextField)`
  width: 100%;
  max-width: ${({ $isFromDashboard }) => $isFromDashboard ? "100%" : "580px"};
  margin-top: ${({ $isFromDashboard }) => $isFromDashboard ? "10px" : "42px"};
  margin-bottom: ${({ $isFromDashboard }) => $isFromDashboard ? "24px" : "56px"};
  padding-right: ${({ $isFromDashboard }) => $isFromDashboard ? "0px" : "initial"};
  .Mui-disabled{
  color: ${grayScale.gray_3};
  }
  @media (min-width: 768px)
  {
    max-width: ${({ $isFromDashboard }) => $isFromDashboard ? "50%" : "580px"};
    padding-right: ${({ $isFromDashboard }) => $isFromDashboard ? "20px" : "initial"};
    margin-bottom: ${({ $isFromDashboard }) => $isFromDashboard ? "0px" : "56px"};
  }
`