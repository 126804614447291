import { useCallback, useEffect, useState, useRef } from 'react';
import { fetchUpdateAccountData } from 'services/Account/POST/UpdateAccountData';
import { fetchGetBusinessPartnersList } from 'services/BusinessPartners/GET/GetBusinessPartnersListService';
import { fetchCreateBusinessPartner } from 'services/BusinessPartners/POST/CreateBusinessPartnerService';
import { fetchUpdateBusinessPartner } from 'services/BusinessPartners/POST/UpdateBusinessPartnerService';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { formatDate } from 'utils/formatDate';
import { formatIdentifier } from 'utils/formatIdentifier';
import { formatPhone } from 'utils/formatPhone';
import { parseStringToCEP } from 'utils/parseStringToCEP';
import { validateIdentifier } from 'utils/validateIdentifier';
import { object, string, date } from 'yup';

export default function useBusinessAccountTitularPersonDataSetup({
  reloadAfterSubmit = false,
  afterSubmit = () => { },
}) {
  const mounted = useRef(false)
  const [initialValues, setInitialValues] = useState()
  const [oldValues, setOldValues] = useState({})
  const [validationSchema, setValidationSchema] = useState()
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [hasDigitalAccount, setHasDigitalAccount] = useState(true)

  const fetchData = useCallback(async () => {
    const fetchedMyAccount = await fetchGetAccountMyAccount()
    let fetchedTitularPartner
    if (fetchedMyAccount?.use_digital_account) {
      fetchedTitularPartner = await fetchGetBusinessPartnersList(true)
    }

    const validationObject = {
      name: string()
        .required("Este campo é obrigatório"),
      CPF: string()
        .test('valid-identifier', 'O CPF informado é inválido', validateIdentifier)
        .required("Este campo é obrigatório"),
      ...(fetchedMyAccount?.use_digital_account && {
        birthDate: date()
          .nullable()
          .typeError("Informe uma data válida"),
        telephone: string()
          .min(14, 'O número de telefone informado deve possuir ao menos 14 dígitos')
          .max(15, 'O número de telefone informado deve possuir no máximo 15 dígitos')
          .required('Este campo é obrigatório'),
        motherName: string()
          .required("Este campo é obrigatório"),
        isPPE: string()
          .required('Este campo é obrigatório'),
        address: object()
          .shape({
            street: string(),
            number: string(),
            complement: string(),
            neighborhood: string(),
            city: string(),
            state: string(),
            zipCode: string()
              .min(9, 'O CEP informado é inválido'),
          }),
      })
    }

    const initialValuesObject = {
      name: fetchedMyAccount?.name || "",
      CPF: formatIdentifier(fetchedMyAccount?.cpf) || "",
      hasDigitalAccount: fetchedMyAccount?.use_digital_account,
      ...(fetchedMyAccount?.use_digital_account && {
        titularPartnerName: fetchedTitularPartner?.name || "",
        titularPartnerCPF: formatIdentifier(fetchedTitularPartner?.cpf) || "",
        birthDate: formatDate(fetchedTitularPartner?.birth_date),
        telephone: formatPhone(fetchedTitularPartner?.phone?.phone) || "",
        motherName: fetchedTitularPartner?.mother_name || "",
        isPPE: String(fetchedTitularPartner?.politically_exposed || false),
        address: {
          street: fetchedTitularPartner?.address?.street || "",
          number: fetchedTitularPartner?.address?.number || "",
          complement: fetchedTitularPartner?.address?.complement || "",
          neighborhood: fetchedTitularPartner?.address?.neighborhood || "",
          city: fetchedTitularPartner?.address?.city || "",
          state: fetchedTitularPartner?.address?.state || "",
          zipCode: parseStringToCEP(fetchedTitularPartner?.address?.zipcode || ""),
        },
        partnerID: fetchedTitularPartner?.id,
      })
    }

    if (mounted.current) {
      setInitialValues(initialValuesObject)
      setOldValues(initialValuesObject)
      setValidationSchema(object(validationObject))
      setHasDigitalAccount(fetchedMyAccount?.use_digital_account)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async ({
    name,
    CPF,
    titularPartnerName,
    titularPartnerCPF,
    birthDate,
    telephone,
    motherName,
    isPPE,
    address,
    partnerID,
    hasDigitalAccount,
  }) => {
    const accountObject = {
      ...(!!name && name !== oldValues?.name) && {
        name: name,
      },
      ...(!!CPF && CPF !== oldValues?.CPF) && {
        cpf: CPF,
      },
    }

    if (Object.keys(accountObject).length > 0) {
      await fetchUpdateAccountData(accountObject)
    }

    if (hasDigitalAccount) {
      const businessAccountTitularPersonObject = {
        ...(!!name && (name !== oldValues?.name || name !== titularPartnerName)) && {
          name: name,
        },
        ...(!!CPF && (CPF !== oldValues?.CPF || CPF !== titularPartnerCPF)) && {
          cpf: CPF,
        },
        ...(!!birthDate && birthDate !== oldValues?.birthDate) && {
          birth_date: birthDate,
        },
        ...(!!telephone && telephone !== oldValues?.telephone) && {
          phone: telephone,
          phone_type_tag: "business",
        },
        ...(!!motherName && motherName !== oldValues?.motherName) && {
          mother_name: motherName,
        },
        ...(!!isPPE && isPPE !== oldValues?.isPPE) && {
          politically_exposed: isPPE,
        },
        ...(JSON.stringify(address) !== JSON.stringify(oldValues.address) &&
          !!address.street &&
          !!address.number &&
          !!address.neighborhood &&
          !!address.city &&
          !!address.state &&
          !!address.zipCode
        ) && {
          street: address.street,
          number: address.number,
          complement: address.complement,
          neighborhood: address.neighborhood,
          city: address.city,
          state: address.state,
          zipcode: address.zipCode,
        },
      }

      if (Object.keys(businessAccountTitularPersonObject).length > 0) {
        if (partnerID === undefined) { // Create partner
          await fetchCreateBusinessPartner({
            ...businessAccountTitularPersonObject,
            name: name,
            CPF: CPF,
            business_partner_kind_id: 1,
            profile: "OWNER",
            profile_type: "MASTER",
          })
        } else { // Update partner
          await fetchUpdateBusinessPartner({
            ...businessAccountTitularPersonObject,
            partner_id: partnerID,
          })
        }
      }
    }

    if (reloadAfterSubmit) {
      await fetchData()
    }
    setSnackbarOpen(true)
    afterSubmit()
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    isSnackbarOpen,
    setSnackbarOpen,
    hasDigitalAccount,
  })
}