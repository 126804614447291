import LoadingIndicator from 'components/shared/LoadingIndicator';
import React from 'react';
import { EnterpriseProofingUploadFormContainer, Container, ContainerLeft, ContainerRight, ContainerFilePicker, BodyText, CompanyCard, IconContainer, TextContainer, Title, FileRulesList, DocumentTypeTitle, DocumentInstructions, RulesTitle, ContentContainer } from './styles';
import CompanyConstitution from 'assets/images/CompanyConstitution.png'
import Business from 'assets/icons/Business/Business';
import FilePicker from 'components/shared/FilePicker';
import InsertDriveFileIcon from 'assets/icons/InsertDriveFileIcon';
import DocumentsRequiredAlert from 'components/Onboarding/OnboardingForms/AccountVerificationForms/shared/DocumentsRequiredAlert';
import RadioOptions from 'components/shared/RadioOptions';
import { parseStringToCNPJ } from 'utils/parseStringToCNPJ';
import { groupsAcceptedFilesDictionary } from 'library/groupsAcceptedFilesDictionary';

const documentTypes = [
  {
    value: "ccmei",
    label: "Certificado de Cadastro de Micro Empresário Individual - para empresas que são Micro Empresário Individual (MEI)"
  },
  {
    value: "ei_registration_requirement",
    label: "Requerimento de Empresário Individual - para empresas que são Empresário Individual (EI)",
  },
  {
    value: "eireli_incorporation_statement",
    label: "Ato Constitutivo Eireli - para empresas que são Empresa Individual de Responsabilidade Limitada (EIRELI)",
  },
  {
    value: "articles_of_association",
    label: "Contrato Social - para empresas que são Limitada (LTDA), Sociedade Simples (SS), Cooperativa (COOP) ou outros",
  },
  {
    value: "company_bylaws",
    label: "Estatuto Social - para empresas que são Sociedade Anônima (SA)",
  },
]

export default function EnterpriseProofingUploadForm({
  isLoading,
  enterpriseData = {},
  documentRequired = false,
  documentStatus,
  sendButtonText = "Continuar",
  isPartnerForm = false,
  setFieldValue = () => { },
  values
}) {
  return <>
    <EnterpriseProofingUploadFormContainer>
      {
        isLoading ?
          <LoadingIndicator /> :
          <>
            <Container>
              <ContainerLeft>
                <BodyText>
                  {
                    isPartnerForm ?
                      `Envie um documento que comprove a existência da empresa sócia abaixo:` :
                      `Envie um documento que comprove a existência da empresa abaixo cadastrada na sua conta:`
                  }
                </BodyText>
                <CompanyCard>
                  <IconContainer>
                    <Business />
                  </IconContainer>
                  <TextContainer>
                    <Title>
                      {enterpriseData?.social_name || enterpriseData?.name}
                    </Title>
                    <BodyText>
                      CNPJ: {parseStringToCNPJ(enterpriseData.cnpj)}
                    </BodyText>
                  </TextContainer>
                </CompanyCard>
              </ContainerLeft>
              <ContainerRight>
                <img
                  src={CompanyConstitution}
                  alt="Constituição da Empresa"
                />
              </ContainerRight>
            </Container>
            <ContentContainer>
              <DocumentTypeTitle>
                Selecione o documento que será enviado:
              </DocumentTypeTitle>
              <RadioOptions
                receivedValue={values.selectedDocumentType}
                receivedSetter={(e) => { setFieldValue("selectedDocumentType", e) }}
                options={documentTypes}
                disabled={!documentRequired}
                isColumn
              />
              <DocumentInstructions>
                É importante que o documento enviado contemple todas as atualizações feitas.
              </DocumentInstructions>
              <RulesTitle>
                Formatos de arquivo aceitos:
              </RulesTitle>
              <FileRulesList>
                <li>
                  pdf - para arquivos com mais de 3MB
                </li>
                <li>
                  jpg ou jpeg - para arquivos até 3MB
                </li>
              </FileRulesList>
              <ContainerFilePicker>
                <FilePicker
                  fieldName={"enterpriseProofingPhoto"}
                  setFieldValue={setFieldValue}
                  title="Adicionar documento"
                  Icon={InsertDriveFileIcon}
                  acceptedFiles={groupsAcceptedFilesDictionary.company.business.value}
                  disabled={!documentRequired}
                  id={
                    isPartnerForm ?
                      "envio-documentos_doc-constituicao_socio_botao_add-documento" :
                      "envio-documentos_doc-constituicao_botao_add-documento"
                  }
                />
              </ContainerFilePicker>
              <DocumentsRequiredAlert
                documentStatus={documentStatus}
                sendButtonText={sendButtonText}
              />
            </ContentContainer>
          </>
      }
    </EnterpriseProofingUploadFormContainer>
  </>;
}
