import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchGetBankAccount = async () => {
  const token_id = getDataStorage('token_id')
  const code = getDataStorage('yapay_code')
  return apiPrivate.get("/myaccount/account/bank_accounts", {
    headers: {
      code,
    },
    params: {
      token_id
    }
  })
    .then((res) => {
      return Array.isArray(res?.data) ? res?.data : []
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}