import { Alert } from "@material-ui/lab";
import styled from "styled-components";
import { grayScale, primaryColors, textStyles } from "theme/styleguide";

export const StyledAlert = styled(Alert)`
  width: 100%;
  ${textStyles.tray_text_12px};
  white-space: pre-wrap;
  color: ${grayScale.gray_3};
  ${({ severity }) => severity === "info" && `
    background: ${primaryColors.alert_blue};
  `}
  margin-top: 16px;
  @media (min-width: 768px){
    max-width: 557px;
    ${textStyles.tray_text_14px};
    margin-top: 48px;
  }
`