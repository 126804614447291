/* eslint-disable max-len */
import React from 'react';

export default function LandingShieldIcon({ size = 1, color = "#00A3FF" }) {
  return <>
    <i>
      <svg
        width={64 * size}
        height={64 * size}
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g
          clipPath="url(#clip0_58_285)">
          <path
            d="M32.0001 58.6666C32.0001 58.6666 53.3334 48 53.3334 32V13.3333L32.0001 5.33331L10.6667 13.3333V32C10.6667 48 32.0001 58.6666 32.0001 58.6666Z"
            stroke={color}
            strokeWidth="6.5"
            strokeLinecap="round"
            strokeLinejoin="round" />
        </g>
        <defs>
          <clipPath
            id="clip0_58_285">
            <rect
              width={64 * size}
              height={64 * size}
              fill="white" />
          </clipPath>
        </defs>
      </svg>
    </i>
  </>;
}
