/* eslint-disable max-len */
import React from 'react';

export default function VindiRepeatIcon({ size = 1, color = "#0092FA" }) {
  return <>
    <i>
      <svg
        width={25 * size}
        height={25 * size}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.7762 0.717622C17.1667 0.327097 17.7999 0.327097 18.1904 0.717622L22.1904 4.71762C22.5809 5.10815 22.5809 5.74131 22.1904 6.13183L18.1904 10.1318C17.7999 10.5224 17.1667 10.5224 16.7762 10.1318C16.3856 9.74131 16.3856 9.10815 16.7762 8.71762L20.0691 5.42473L16.7762 2.13184C16.3856 1.74131 16.3856 1.10815 16.7762 0.717622Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.48328 6.42473C6.68763 6.42473 5.92457 6.7408 5.36196 7.30341C4.79935 7.86602 4.48328 8.62908 4.48328 9.42473V11.4247C4.48328 11.977 4.03556 12.4247 3.48328 12.4247C2.93099 12.4247 2.48328 11.977 2.48328 11.4247V9.42473C2.48328 8.09865 3.01006 6.82688 3.94774 5.88919C4.88542 4.95151 6.15719 4.42473 7.48328 4.42473H21.4833C22.0356 4.42473 22.4833 4.87244 22.4833 5.42473C22.4833 5.97701 22.0356 6.42473 21.4833 6.42473H7.48328Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.19038 14.7176C8.58091 15.1081 8.58091 15.7413 8.19038 16.1318L4.89749 19.4247L8.19038 22.7176C8.58091 23.1081 8.58091 23.7413 8.19038 24.1318C7.79986 24.5224 7.16669 24.5224 6.77617 24.1318L2.77617 20.1318C2.38565 19.7413 2.38565 19.1081 2.77617 18.7176L6.77617 14.7176C7.16669 14.3271 7.79986 14.3271 8.19038 14.7176Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.4833 12.4247C22.0356 12.4247 22.4833 12.8724 22.4833 13.4247V15.4247C22.4833 16.7508 21.9565 18.0226 21.0188 18.9603C20.0811 19.8979 18.8094 20.4247 17.4833 20.4247H3.48328C2.93099 20.4247 2.48328 19.977 2.48328 19.4247C2.48328 18.8724 2.93099 18.4247 3.48328 18.4247H17.4833C18.2789 18.4247 19.042 18.1087 19.6046 17.546C20.1672 16.9834 20.4833 16.2204 20.4833 15.4247V13.4247C20.4833 12.8724 20.931 12.4247 21.4833 12.4247Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
