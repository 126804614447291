/* eslint-disable max-len */
import React from 'react';

export default function LandingChargebackIcon({ size = 1, color = "#00A3FF" }) {
  return <>
    <i>
      <svg
        width={64 * size}
        height={64 * size}
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 10.5868V26.2534M6 26.2534H21.6667M6 26.2534L18.1155 14.869C21.7213 11.2693 26.3992 8.93906 31.4444 8.22922C36.4897 7.51938 41.629 8.46845 46.0879 10.9334C50.5469 13.3984 54.084 17.2457 56.1663 21.8957C58.2485 26.5457 58.7632 31.7465 57.6326 36.7144C56.5021 41.6824 53.7877 46.1483 49.8983 49.4394C46.0089 52.7305 41.1553 54.6684 36.0688 54.9612C30.9823 55.2539 25.9384 53.8856 21.6971 51.0625C17.4558 48.2394 14.2469 44.1144 12.5539 39.3089"
          stroke={color}
          strokeWidth="6.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </i>
  </>;
}
