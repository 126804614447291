import { useCallback, useEffect, useState, useRef } from 'react';
import { object, string } from 'yup';
import { fetchSearchAccountSellerSettings } from 'services/AccountsSellerSettings/POST/SearchAccountSellerSettingsService';
import { fetchUpdateAccountSellerSettings } from 'services/AccountsSellerSettings/POST/UpdateAccountSellerSettingsService';
import { parseDataURLtoFile } from 'utils/parseDataURLtoFile';
import { fetchUpdateAccountSellerLogo } from 'services/AccountsSellerSettings/POST/UpdateAccountSellerLogoService';
import { GAEventTracker } from 'utils/GAEventTracker';

export default function useCustomizeSetup({
  afterSubmit = () => { },
  fromOnboarding = false
}) {
  const mounted = useRef(false);
  const [initialValues, setInitialValues] = useState({
    storeName: '',
    logoImage: '',
  })
  const [isLoading, setLoading] = useState(true)
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const validationSchema = object({
    storeName: string()
      .required('Este campo é obrigatório'),
    logoImage: string()
    //   .required('Este campo é obrigatório'),
  })

  const fetchCustomizeData = useCallback(async () => {
    let logo_url = await fetchSearchAccountSellerSettings("logo_url")
    let billet_name_seller = await fetchSearchAccountSellerSettings("billet_name_seller")
    if (billet_name_seller === "[object Object]") {
      billet_name_seller = ""
    }
    if (logo_url === "[object Object]") {
      logo_url = ""
    }
    if (mounted.current) {
      if (mounted.current) {
        setInitialValues({
          storeName: billet_name_seller,
          logoImage: logo_url,
        })
        setLoading(false)
      }
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])


  const handleSubmit = async ({
    storeName,
    logoImage,
  }) => {
    if (initialValues.storeName !== storeName) {
      GAEventTracker({
        category: fromOnboarding ? "Onboarding" : "Página configurações",
        action: "Nome da loja",
        label: "Inserir nome da loja"
      })
    }
    await fetchUpdateAccountSellerSettings({
      billet_name_seller: storeName,
    })
    if (!!logoImage && !logoImage.match("https")) {
      const logoFile = await parseDataURLtoFile(logoImage)
      await fetchUpdateAccountSellerLogo({
        file_0: logoFile
      })
      GAEventTracker({
        category: fromOnboarding ? "Onboarding" : "Página configurações",
        action: "Anexar uma logo",
        label: "Atualizar a logo de uma loja"
      })
    }
    setSnackbarOpen(true)
    setInitialValues({
      storeName,
      logoImage
    })
    afterSubmit()
  }

  useEffect(() => {
    fetchCustomizeData()
  }, [fetchCustomizeData])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    isSnackbarOpen,
    setSnackbarOpen,
  })
}
