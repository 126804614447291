/* eslint-disable max-len */
import React from 'react';

export default function CloseIcon({ size = 1, color = "#546E7A" }) {
  return <>
    <i>
      <svg
        width={24 * size}
        height={24 * size}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={color}>
        <path
          d="M0 0h24v24H0V0z"
          fill="none" />
        <path
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
      </svg>
    </i>
  </>;
}
