import styled from "styled-components";
import { grayScale, shadowStyles } from "theme/styleguide";

export const DashboardBodyContainer = styled.div`
  background: ${grayScale.white};
  @media (min-width: 768px)
  {
    ${shadowStyles.drop_shadow_1}
    padding: 16px 20px;
  }
`
export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 72px);
`