import React from 'react';
import { Form, Formik } from 'formik';
import { StyledLoadableButton } from 'components/Dashboard/DashboardAccountVerification/styles';
import useBankAccountProofingUploadSetup from 'components/FormsSetup/AccountVerification/useBankAccountProofingUploadSetup';
import { ButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import BankAccountProofingUploadForm from 'components/Onboarding/OnboardingForms/AccountVerificationForms/BankAccountProofingUploadForm';
import { BankAccountProofingUploadMenuItemContainer } from './styles';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';

export default function BankAccountProofingUploadMenuItem() {
  const { goBackToAccountVerificationMenu } = useDashboardMyAccount()

  const { initialValues, validationSchema, isLoading, handleSubmit, documentRequired, documentStatus } = useBankAccountProofingUploadSetup({
    afterSubmit: goBackToAccountVerificationMenu
  })
  return <>
    <BankAccountProofingUploadMenuItemContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={documentRequired && validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <BankAccountProofingUploadForm
                isLoading={isLoading}
                documentStatus={documentStatus}
                documentRequired={documentRequired}
                sendButtonText={
                  ["approved", "pending", "automatic_verifications"].includes(documentStatus?.status?.tag) ?
                    "Continuar" :
                    "Enviar"
                }
                {...formikProps}
              />
              <ButtonsContainer>
                <StyledLoadableButton
                  type="submit"
                  disabled={
                    (documentRequired && !formikProps.isValid) ||
                    (isLoading || formikProps.isSubmitting)
                  }
                  isLoading={formikProps.isSubmitting}
                  id={"envio-documentos_comprovante-bancario_botao_enviar"}
                >
                  {
                    ["approved", "pending", "automatic_verifications"].includes(documentStatus?.status?.tag) ?
                      "Continuar" :
                      "Enviar"
                  }
                </StyledLoadableButton>
              </ButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </BankAccountProofingUploadMenuItemContainer>
  </>;
}
