import styled from "styled-components";
import { ButtonsContainer } from "components/Onboarding/OnboardingBody/styles";

export const PartnersFormContainer = styled.div`
  
`
export const StyledButtonsContainer = styled(ButtonsContainer)`
  @media (min-width: 768px)
  {
    button{
      min-width: 128px;
    }
  }
`