/* eslint-disable max-len */
import React from 'react';

export default function EllipseIcon({ size = 1, color = "#21C712" }) {
  return <>
    <i>
      <svg
        width={13 * size}
        height={13 * size}
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="6.5"
          cy="6.5"
          r="6.5"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
