import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchValidateSmsEmail = async (sms, message_id, oldEmail, newEmail) => {
  const code = getDataStorage('yapay_code')
  return apiPrivate.post("/signin/emails", {
    message_id: message_id,
    code: sms,
    email: oldEmail,
    new_email: newEmail
  }, {
    headers: {
      code
    }
  })
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}