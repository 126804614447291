/* eslint-disable max-len */
import React from 'react';

export default function Business({ size = 1, color = "#546E7A" }) {
  return <>
    <i>
      <svg
        width={30 * size}
        height={30 * size}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15 8.75V3.75H2.5V26.25H27.5V8.75H15ZM7.5 23.75H5V21.25H7.5V23.75ZM7.5 18.75H5V16.25H7.5V18.75ZM7.5 13.75H5V11.25H7.5V13.75ZM7.5 8.75H5V6.25H7.5V8.75ZM12.5 23.75H10V21.25H12.5V23.75ZM12.5 18.75H10V16.25H12.5V18.75ZM12.5 13.75H10V11.25H12.5V13.75ZM12.5 8.75H10V6.25H12.5V8.75ZM25 23.75H15V21.25H17.5V18.75H15V16.25H17.5V13.75H15V11.25H25V23.75ZM22.5 13.75H20V16.25H22.5V13.75ZM22.5 18.75H20V21.25H22.5V18.75Z"
          fill={color}
          fillOpacity="0.6"
        />
      </svg>
    </i>
  </>;
}
