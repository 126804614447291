import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchBankAccountCreate = async ({ number, agency, code, name, pj, bank_account_type_id }) => {
  const yapay_code = getDataStorage('yapay_code')
  const token_id = getDataStorage('token_id')
  return apiPrivate.post("/bank_accounts",
    {
      token_id,
      number,
      agency,
      code,
      name,
      pj,
      bank_account_type_id
    }
    , {
      headers: {
        code: yapay_code
      }
    })
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}