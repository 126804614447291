import styled from "styled-components";
import { borderStyles, grayScale, textStyles } from "theme/styleguide";

export const EnterpriseProofingUploadFormContainer = styled.div`
  margin-bottom: 32px;
  @media (min-width: 768px)
  {
    margin-bottom: 96px;
  }
`
export const BodyText = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  white-space: pre-wrap;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 28px;
  @media (min-width: 768px)
  {
    flex-direction: row;
  justify-content: space-between;
    margin-top: 48px;
  }
`
export const ContainerLeft = styled.div`
  flex: 1;
  
`
export const ContainerFilePicker = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  @media (min-width: 768px){
    justify-content: flex-start;
  } 
`
export const ContainerRight = styled.div`
  flex: 1;
  text-align: center;
  >img{
    display: none;
  }
  @media (min-width: 768px){
    >img{
      display: initial;
    }
  }
`
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const CompanyCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${borderStyles.border_1};
  padding: 24px;
  margin-top: 24px;
  margin-bottom: 32px;
  @media (min-width: 768px){
    margin-bottom: 64px;
    max-width: 556px;
  }
`
export const RulesTitle = styled.span`
  display: block;
  margin-top: 24px;
  ${textStyles.tray_text_14px}
  color:${grayScale.gray_60p100};
  @media (min-width: 768px){
    margin-top: 48px;
  }
`
export const FileRulesList = styled.ul`
  margin-top: 0px;
  margin-bottom: 32px;
  padding-left: 16px;
  li{
    ${textStyles.tray_text_14px}
    color:${grayScale.gray_60p100};
  }
  @media (min-width: 768px){
    margin-bottom: 48px;
  }
`
export const IconContainer = styled.div`
  margin-right: 16px;
`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const Title = styled.span`
  display: block;
  ${textStyles.tray_h5_15px}
  color:${grayScale.gray_3};
`
export const DocumentTypeTitle = styled.span`
  display: block;
  ${textStyles.tray_h5_15px}
  color:${grayScale.gray_3};
  margin-bottom: 16px;
  @media (min-width: 768px){
    margin-bottom: 24px;
  }
`
export const DocumentInstructions = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  color:${grayScale.gray_3};
  margin-top: 16px;
  @media (min-width: 768px){
    margin-top: 48px;
  }
`