import React from 'react';
import OnboardingProvider from 'context/OnboardingContext';
import OnboardingHeader from 'components/Onboarding/OnboardingHeader';
import OnboardingBody from 'components/Onboarding/OnboardingBody';
import { OnboardingContainer } from './styles';
import RedirectToLogin from 'components/shared/RedirectToLogin';
import { GAMapRoutes } from 'utils/GAMapRoutes';

export default function Onboarding() {
  GAMapRoutes()
  return <>
    <OnboardingProvider>
      <OnboardingContainer>
        <OnboardingHeader />
        <OnboardingBody />
      </OnboardingContainer>
    </OnboardingProvider>
    <RedirectToLogin />
  </>;
}
