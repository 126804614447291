import UserIdUploadStep from 'components/Onboarding/OnboardingSteps/AccountVerificationSteps/UserIdUploadStep';
import EnterpriseProofingUploadStep from 'components/Onboarding/OnboardingSteps/AccountVerificationSteps/EnterpriseProofingUploadStep';
import DocumentsUploadInstructions from 'components/Onboarding/OnboardingSteps/AccountVerificationSteps/DocumentsUploadInstructions';
import AttorneyPowerUploadStep from 'components/Onboarding/OnboardingSteps/AccountVerificationSteps/AttorneyPowerUploadStep';
import UserFacePictureUploadStep from 'components/Onboarding/OnboardingSteps/AccountVerificationSteps/UserFacePictureUploadStep';
import PartnersDocumentsUploadStep from 'components/Onboarding/OnboardingSteps/AccountVerificationSteps/PartnersDocumentsUploadStep';

export const accountVerificationStepsDictionary = {
  documentsUploadInstructions: {
    stepComponent: <DocumentsUploadInstructions />,
    hideOnCounting: true,
  },
  userIdUploadStep: {
    stepTitle: "Foto do RG ou CNH",
    stepComponent: <UserIdUploadStep />,
    hideSubtitle: true,
  },
  // TODO: Remover definitivamente quando for definido se havará ou não esta opção 
  // userFacePictureUploadStep: {
  //   stepTitle: "Foto do(a) titular",
  //   stepComponent: <UserFacePictureUploadStep />,
  //   hideSubtitle: true,
  // },
  // addressProofingUploadStep: {
  //   stepTitle: "Comprovante de endereço",
  //   stepComponent: <AddressProofingUploadStep />,
  //   hideSubtitle: true,
  // },
  //business
  attorneyPowerUploadStep: {
    stepTitle: "Procuração",
    stepComponent: <AttorneyPowerUploadStep />,
    hideSubtitle: true,
    hideOnCounting: true,
  },
  enterpriseProofingUploadStep: {
    stepTitle: "Documento de constituição da empresa",
    stepComponent: <EnterpriseProofingUploadStep />,
    hideSubtitle: true,
  },
  // TODO: Remover definitivamente quando for definido se havará ou não esta opção 
  // partnersDocumentsUploadStep: {
  //   stepComponent: <PartnersDocumentsUploadStep />,
  //   hideOnCounting: true,
  // },
}

const commonSteps = [
  {
    stepComponent: <DocumentsUploadInstructions />,
  },
  {
    stepTitle: "Foto do RG ou CNH",
    stepComponent: <UserIdUploadStep />,
  },
  {
    stepTitle: "Foto do(a) titular",
    stepComponent: <UserFacePictureUploadStep />,
  },
  // TODO: Remover definitivamente quando for definido se havará ou não esta opção 
  // { 
  //   stepTitle: "Comprovante de conta bancária",
  //   stepComponent: <BankAccountProofingUploadStep />,
  // },
  // {
  //   stepTitle: "Comprovante de endereço",
  //   stepComponent: <AddressProofingUploadStep />,
  // },
]

export const UNUSED_accountVerificationStepsDictionary = {
  personal: [
    ...commonSteps,
  ],
  business: [
    ...commonSteps,
    {
      stepTitle: "Documento de constituição da empresa",
      stepComponent: <EnterpriseProofingUploadStep />,
    },
    {
      stepTitle: "Procuração",
      stepComponent: <AttorneyPowerUploadStep />,
      hideSubtitle: true,
    },
    {
      stepComponent: <PartnersDocumentsUploadStep />,
    },
  ]
}