import { useCallback, useEffect, useState, useRef } from 'react';
import { number, object } from 'yup';
import { fetchSearchAccountSellerSettings } from 'services/AccountsSellerSettings/POST/SearchAccountSellerSettingsService';
import { fetchCreateAccountSellerSettings } from 'services/AccountsSellerSettings/POST/CreateAccountSellerSettingsService';
import { fetchUpdateAccountSellerSettings } from 'services/AccountsSellerSettings/POST/UpdateAccountSellerSettingsService';

export default function usePixSetup({
  reloadAfterSubmit = false,
  afterSubmit = () => { },
}) {

  const pixLifespanMinutesMetricList = [
    'hora(s) após a geração',
    'minuto(s) após a geração',
    'dia(s) após a geração'
  ]
  const mounted = useRef(false);
  const [initialValues, setInitialValues] = useState({
    pixLifespanMinutes: '',
    pixLifespanMinutesMetric: 1
  })
  const [oldMetricSelected, setOldMetricSelected] = useState(1)
  const [isLoading, setLoading] = useState(true)
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)

  const validationSchema = object({
    pixLifespanMinutes: number()
      .required('Este campo é obrigatório').moreThan(0).typeError('my message')

  })

  const fetchPixData = useCallback(async () => {
    let data = await fetchSearchAccountSellerSettings("pix_default_qrcode_lifespan_in_minutes")
    if (mounted.current) {
      setInitialValues({
        pixLifespanMinutes: data,
        pixLifespanMinutesMetric: 1
      })
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])


  const handleSubmit = async ({
    pixLifespanMinutes,
    pixLifespanMinutesMetric
  }) => {
    let minutes = pixLifespanMinutes
    if (pixLifespanMinutesMetric === 0) {
      minutes = Math.round(pixLifespanMinutes * 60)
    } else if (pixLifespanMinutesMetric === 2) {
      minutes = Math.round(pixLifespanMinutes * 24 * 60)
    }

    await fetchCreateAccountSellerSettings({
      pix_default_qrcode_lifespan_in_minutes: minutes
    })
      .then(async (e) => {
        if (e?.accounts_seller_setting?.error_response?.validation_errors?.validation_error.code === "6") {  //Erro de Id Configuração já está em uso
          await fetchUpdateAccountSellerSettings({
            pix_default_qrcode_lifespan_in_minutes: minutes
          })
            .then(async () => {
              setSnackbarOpen(true)
              if (reloadAfterSubmit) {
                await fetchPixData()
              }
              afterSubmit()
            })
        } else {
          setSnackbarOpen(true)
          if (reloadAfterSubmit) {
            await fetchPixData()
          }
          afterSubmit()
        }
      })
  }

  useEffect(() => {
    fetchPixData()
  }, [fetchPixData])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    isSnackbarOpen,
    setSnackbarOpen,
    pixLifespanMinutesMetricList,
    oldMetricSelected,
    setOldMetricSelected
  })
}
