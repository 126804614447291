import React, { useEffect } from 'react';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { UserProfilePictureFieldsContainer, StyledIconButton, StyledAvatar, ActionsContainer } from './styles';
import { CircularProgress, Collapse, IconButton, Tooltip } from '@material-ui/core';
import TrashCan from 'assets/icons/TrashCan/TrashCan';
import { useFilePicker } from 'use-file-picker';

const acceptFiles = [".jpeg", ".jpg", ".png", ".gif", ".bmp", ".webp"]
export default function UserProfilePictureFields({
  values,
  isLoading = true,
  setFieldValue = () => { },
  handleSubmit = () => { },
}) {
  const [openFileSelector, { filesContent, clear }] = useFilePicker({
    accept: acceptFiles,
    multiple: false,
    readAs: "DataURL",
    maxFileSize: 4.5,
  })

  const handleDeleteProfilePicture = () => {
    handleSubmit({
      deletePhoto: true,
    }).then(() => {
      clear()
    })
  }

  useEffect(() => {
    const fileName = filesContent?.[0]?.name
    if (!!fileName) {
      const fileExtension = fileName.split(".").reverse()[0]
      if (acceptFiles.includes(`.${fileExtension}`)) {
        handleSubmit({
          profilePicture: filesContent[0].content,
        }).then(() => {
          setFieldValue("profilePicture", filesContent[0].content)
        })
      }
    }
    if (!filesContent?.[0]) {
      setFieldValue("profilePicture", "")
    }
  }, [filesContent])  // eslint-disable-line react-hooks/exhaustive-deps


  return <>
    <UserProfilePictureFieldsContainer>
      {
        false ?
          <LoadingIndicator /> :
          <>
            <Tooltip
              title={!values?.profilePicture ? "Adicionar foto" : ""}
              arrow
            >
              <span>
                <StyledIconButton
                  size='small'
                  $backgroundImage={values?.profilePicture}
                  onClick={openFileSelector}
                  disabled={isLoading}
                >
                  {
                    !!values?.profilePicture ?
                      <span className='buttonText'>
                        {'Trocar\nfoto'}
                      </span> :
                      <StyledAvatar />
                  }
                </StyledIconButton>
              </span>
            </Tooltip>
            <ActionsContainer>
              <Collapse
                in={
                  !isLoading &&
                  !!values?.profilePicture
                }
              >
                <Tooltip
                  title="Excluir"
                  arrow
                >
                  <span>
                    <IconButton
                      size="small"
                      onClick={handleDeleteProfilePicture}
                      disabled={isLoading}
                    >
                      <TrashCan />
                    </IconButton>
                  </span>
                </Tooltip>
              </Collapse>
              <Collapse
                in={isLoading}
              >
                <CircularProgress
                  size={30}
                />
              </Collapse>
            </ActionsContainer>
          </>
      }
    </UserProfilePictureFieldsContainer>
  </>;
}