import { useCallback, useEffect, useState } from 'react';
import { object, string } from 'yup';
import { useRef } from 'react';
import { fetchGetSigninUser } from 'services/Signin/GET/GetSigninUserService';

export default function useEmailDataSetup({
  afterSubmit = () => { }
}) {
  const mounted = useRef(false);
  const [isLoading, setLoading] = useState(true)
  const [hasVerifiedPhone, setVerifiedPhone] = useState(true)
  const [initialValues, setInitialValues] = useState({
    email: '',
  })
  const validationSchema = object({
    email: string(),
  })

  const fetchAccountData = useCallback(async () => {
    const { phone_validated, email } = await fetchGetSigninUser()
    if (mounted.current) {
      setInitialValues({
        email: email,
      });
      setVerifiedPhone(phone_validated)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async () => {
    afterSubmit()
  }

  useEffect(() => {
    fetchAccountData()
  }, [fetchAccountData])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    hasVerifiedPhone,
  })
}