import { Step, Stepper, StepLabel } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";


export const OnboardingHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 48px;
  @media (min-width: 768px)
  {
    display: flex;
    flex-direction: row;
  }
  @media (min-width: 992px)
  {
    padding-bottom: 88px;
  }
`
export const StyledStepper = styled(Stepper)`
  width: 100%;
  max-width: 680px;
  background: transparent;
  margin-top: 42px;
  padding: 0px;
  justify-content: center;
  @media (min-width: 768px)
  {
    margin-top: 0px;
    .MuiStepConnector-root {
      min-width: 90px;
    }
  }
  @media (min-width: 992px)
  {
    margin-right: -16px;
  }
`
export const StyledStep = styled(Step)`
  svg{
    transform: scale(1.33);
  }
  .Mui-disabled{
    .MuiStepIcon-root{
    color: ${grayScale.gray_60p100};
    }
  }
  .MuiStepLabel-iconContainer {
    padding-right: 0px;
    padding-left: 0px;
  }
  svg{
      transform: scale(0.83);
    }
  @media (min-width: 768px)
  {
    svg{
      transform: scale(1);
    }
    .MuiStepLabel-iconContainer {
      padding-right: 10px;
      padding-left: 10px;
      }
  }
`
export const StyledStepLabel = styled(StepLabel)`
  .MuiStepLabel-labelContainer{
    max-width: 120px;
  }
  .MuiStepLabel-label{
    display: none;
    ${textStyles.tray_h5_15px}
    white-space: pre-wrap;
    color: ${({ $isCompleted }) => $isCompleted ? grayScale.gray_3 : grayScale.gray_20p100}
  }
  @media (min-width: 992px)
  {
    .MuiStepLabel-label{
      display: initial;
    }
  }
`