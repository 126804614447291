import { Button, TextField } from "@material-ui/core";
import styled from "styled-components";
import { buttonStyles } from "theme/styleguide";

export const InstructionsListManagerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px)
  {
    align-items: flex-start;
  }
`
export const InstructionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 24px;
  @media (min-width: 557px)
  {
    flex-direction: row;
    align-items: flex-start;
  }
`
export const StyledTextField = styled(TextField)`
  width: 100%;
  max-width: 557px;
  margin-bottom: 8px;
  @media (min-width: 557px)
  {
    margin-right: 16px;
    margin-bottom: initial;
  }
`
export const StyledButton = styled(Button)`
  ${buttonStyles.yapay_bluish_add}
  margin-bottom: 72px;
  @media (min-width: 557px)
  {
    margin-bottom: 120px;
  }
`