import apiPrivate from 'services/apiPrivate'
import { parseDateToMilliseconds } from 'utils/parseDateToMilliseconds'
import { getDataStorage } from 'utils/storage'

const emptyObject = {
  status: {
    tag: "unsent"
  },
  created_at: null,
  status_changed_at: null,
  reason: null
}

const getLatestChangedAtDateElement = (receivedArray = [], partnerId) => {
  if (receivedArray.length > 0) {
    return receivedArray?.reduce((a, b) => {
      return parseDateToMilliseconds(a?.status_changed_at) > parseDateToMilliseconds(b?.status_changed_at) ? a : b
    }
    )
  } else {
    return {
      ...emptyObject,
      business_partner: {
        id: partnerId,
      }
    }
  }
}

const getFittestStatus = (statusList, partnersId) => {
  return partnersId.map((partnerId) => {
    const partnerStatusList = statusList.filter((f) => f?.business_partner?.id === partnerId) // Retorna apenas os status que tenham o id igual ao id do partner
    const partnerFittestStatus = partnerStatusList?.find((f) => f.active) || getLatestChangedAtDateElement(partnerStatusList, partnerId) // Retorna apenas o status ativo, o mais atual ou então cria um emptyObject com status unsent
    return partnerFittestStatus // Retorna o status do partner atual
  }) // Retorna o status de todos os partners
}

export const fetchGetPartnersDocumentStatus = async (group, partnersId) => {
  // Usar apenas quando group === "document_partner" || group === "company_partner"
  const code = getDataStorage('yapay_code')
  const token_id = getDataStorage('token_id')

  return apiPrivate.get("/document/status", {
    headers: {
      code,
      token_id,
      ...(!!group && { group }),
    }
  })
    .then(({ data }) => {
      return getFittestStatus(data?.data, partnersId)
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}