import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchBankAccountType = async () => {
  const code = getDataStorage('yapay_code')
  const token_id = getDataStorage('token_id')

  return apiPrivate.get("/bank_accounts/type", {
    headers: {
      code,
      token_id
    }
  })
    .then((res) => {
      return res?.data?.data
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}