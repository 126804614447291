/* eslint-disable max-len */
import React from 'react';

export default function SavingsIcon({ size = 1, color = "#2E7EE7", opacity = 1 }) {
  return <>
    <i>
      <svg
        width={30 * size}
        height={30 * size}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.75 12.5C18.75 11.8125 19.3125 11.25 20 11.25C20.6875 11.25 21.25 11.8125 21.25 12.5C21.25 13.1875 20.6875 13.75 20 13.75C19.3125 13.75 18.75 13.1875 18.75 12.5ZM10 11.25H16.25V8.75H10V11.25ZM27.5 9.375V18.0875L23.975 19.2625L21.875 26.25H15V23.75H12.5V26.25H5.625C5.625 26.25 2.5 15.675 2.5 11.875C2.5 8.075 5.575 5 9.375 5H15.625C16.7625 3.4875 18.575 2.5 20.625 2.5C21.6625 2.5 22.5 3.3375 22.5 4.375C22.5 4.6375 22.45 4.875 22.35 5.1C22.175 5.525 22.025 6.0125 21.95 6.5375L24.7875 9.375H27.5ZM25 11.875H23.75L19.375 7.5C19.375 6.6875 19.4875 5.8875 19.7 5.1125C18.4875 5.425 17.5 6.325 17.0875 7.5H9.375C6.9625 7.5 5 9.4625 5 11.875C5 14.225 6.525 20.1875 7.5125 23.75H10V21.25H17.5V23.75H20.0125L21.95 17.3125L25 16.2875V11.875Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    </i>
  </>;
}
