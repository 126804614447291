import styled from "styled-components";
import { Button } from "@material-ui/core";
import { darken } from '@material-ui/core/styles';
import { borderStyles, grayScale, primaryColors, textStyles } from "theme/styleguide";

export const DashboardOverviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 920px)
  {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`
export const DashboardTitle = styled.span`
  display: block;
  ${textStyles.tray_h4_18px}
  color: ${grayScale.black};
  margin-top: 16px;
  margin-bottom: 45px;
  @media (min-width: 768px)
  {
    ${textStyles.tray_h3_24px}
  }
  @media (min-width: 1024px)
  {
    margin-top: 24px;
  }
`
export const CardOutterContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  ${({ $variant }) => $variant === "borderless" ?
    `
      padding: 16px 0px;
    ` :
    `
      ${borderStyles.border_2}
      padding: 16px;
    `
  }
  @media (min-width: 920px)
  {
    ${borderStyles.border_2}
    padding: 16px;
    max-width: ${({ $fullWidth }) => $fullWidth ? "100%" : "calc(50% - 10px)"};
  }
`
export const MoneyText = styled.span`
  display: block;
  ${textStyles.tray_h3_24px}
  color: ${grayScale.black};
  margin-bottom: 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width:200px;
  @media (min-width: 425px)
  {
    width: 309px;
  }
  @media (min-width: 920px)
  {
    width: 169px;
  }
  @media (min-width: 1024px)
  {
    width: 298px;
  }
  @media (min-width: 1440px)
  {
    width: 506px;
  }

`
export const MoneySubtitle = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
    margin-bottom: 4px;
  @media (min-width: 920px)
  {
    margin-bottom: 0px;
  }
`
export const RoundedButton = styled(Button)`
  border-radius: 70px;
  min-width: fit-content;
  width: 75px;
  ${textStyles.body_2_12px}
  color: ${grayScale.white};
  :not(:first-child){
    margin-right: 10px;
  }
  ${({ $selected }) => !$selected &&
    `
      color : ${grayScale.gray_3};
      background: ${grayScale.gray_20p100};
      :hover{
        background: ${darken(grayScale.gray_20p100, .3)};
      }
    `
  }
  .MuiButton-label{
    padding: 0px 4px;
  }
  padding: 6px 12px;
  @media (min-width: 920px)
  {
    padding: 6px 12px;
  }
`
export const CardTitle = styled.span`
  ${textStyles.tray_h5_15px};
  margin-left: 16px;
  @media (min-width: 920px)
  {
    ${textStyles.tray_h4_18px};
    margin-left: 24px;
  }
`
export const CardHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  i{
    margin-left: 8px;
  }
  @media (min-width: 920px)
  {
    margin-top: 30px;
    i{
      margin-left: 16px;
    }
  }
`
export const StyledTextButton = styled(Button)`
  display: block;
  min-width: 169px;
  color: ${primaryColors.blue_5};
  border-color: ${primaryColors.blue_5};
  margin: auto;
  margin-bottom: 16px;
  padding: 4px 8px;
  @media (min-width: 920px)
  {
    display: initial;
    min-width: fit-content;
  }
`
export const SmallInstruction = styled.span`
  display: block;
  ${textStyles.body_2_12px}
  color: ${grayScale.gray_3};
  text-align: center;
  @media (min-width: 920px)
  {
    text-align: start;
  }
`
export const DateStamp = styled.span`
  display: inline-block;
  background: rgba(35, 69, 129, 0.1);
  border-radius: 4px;
  padding: 2px 12px;
  ${textStyles.body_3_14px}
  color: ${primaryColors.blue_5};
  margin-top: 20px;
  margin-bottom: 10px;
  :first-letter{
    text-transform: capitalize;
  }
  @media (min-width: 920px)
  {
    margin-bottom: 0px;
  }
`
export const TransactionMoney = styled.span`
  ${textStyles.h4_18px}
  @media (min-width: 920px)
  {
    ${textStyles.body_3_14px}
  }
  color: ${({ $isPositive, $isFromFinancialEntries }) => $isPositive ?
    primaryColors.green_5 :
    $isFromFinancialEntries ?
      grayScale.black :
      primaryColors.red_balance
  } !important;
  font-weight: 800;
`