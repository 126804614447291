import { IconButton, MenuItem, Tooltip } from '@material-ui/core';
import AddCircleIcon from 'assets/icons/AddCircleIcon';
import DeleteIcon from 'assets/icons/DeleteIcon';
import AlertDialog from 'components/shared/AlertDialog';
import SnackbarAlert from 'components/shared/SnackbarAlert';
import { Field, FieldArray } from 'formik';
import React, { useState } from 'react';
import { primaryColors } from 'theme/styleguide';
import { formatPhone } from 'utils/formatPhone';
import { TelephoneContactFieldsContainer, TelephoneContactContainer, StyledButton, InnerStyledTextField } from './styles';

const telephoneTypeOtions = [
  {
    tag: "business",
    name: "Comercial"
  },
  {
    tag: "home",
    name: "Residencial"
  },
]

export default function TelephoneContactFields({
  values,
  errors,
  touched,
  setFieldValue = () => { },
  disabledForm = false,
  hideAddButton = false,
  hideContainerPadding = false,
}) {
  const [isAlertOpen, setAlertOpen] = useState(false)
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [selectedTelephoneContactIndex, setSelectedTelephoneContactIndex] = useState()
  const [fieldArrayHelpers, setFieldArrayHelpers] = useState()

  const handleOpenAlert = (arrayHelpers, index) => {
    setFieldArrayHelpers(arrayHelpers)
    setSelectedTelephoneContactIndex(index)
    setAlertOpen(true)
  }
  const handleDismiss = () => {
    setAlertOpen(false)
  }
  const handleDelete = () => {
    if (values.telephoneList[selectedTelephoneContactIndex].isNewRegister === false) {
      setFieldValue(
        'excludedTelephoneList',
        [...values.excludedTelephoneList, values.telephoneList[selectedTelephoneContactIndex]]
      )
    }
    fieldArrayHelpers.remove(selectedTelephoneContactIndex)
    setAlertOpen(false)
    setSnackbarOpen(true)
  }
  const handleCreate = (arrayHelpers) => {
    arrayHelpers.push({
      phoneType: "business",
      phoneNumber: "",
      isNewRegister: true,
      isUpdatedRegister: false,
    })
  }
  const handleUpdatePhoneNumber = (value, index) => {
    setFieldValue(`telephoneList.${index}.phoneNumber`, formatPhone(value))
    setFieldValue(`telephoneList.${index}.isUpdatedRegister`, true)
  }
  const handleUpdatePhoneType = (value, index) => {
    setFieldValue(`telephoneList.${index}.phoneType`, value)
    setFieldValue(`telephoneList.${index}.isUpdatedRegister`, true)
  }
  return <>
    <TelephoneContactFieldsContainer>
      <FieldArray
        name="telephoneList"
        validateOnChange={true}
        render={arrayHelpers => (
          <>
            {
              values?.telephoneList?.map((_, i) => (
                <TelephoneContactContainer
                  $hideContainerPadding={hideContainerPadding}
                  key={i}
                >
                  {
                    !hideAddButton &&
                    <Field
                      as={InnerStyledTextField}
                      name={`telephoneList.${i}.phoneType`}
                      label={`Tipo de Telefone ${1 + i}`}
                      select
                      variant="outlined"
                      size="small"
                      error={touched.telephoneList?.[i]?.phoneType && !!errors.telephoneList?.[i]?.phoneType}
                      helperText={touched.telephoneList?.[i]?.phoneType && errors.telephoneList?.[i]?.phoneType}
                      onChange={(e) => { handleUpdatePhoneType(e.target.value, i) }}
                      disabled={disabledForm}
                    >
                      {
                        telephoneTypeOtions.map((e, i) => (
                          <MenuItem
                            value={e.tag}
                            key={i}
                          >
                            {e.name}
                          </MenuItem>
                        ))
                      }
                    </Field>
                  }
                  <Field
                    as={InnerStyledTextField}
                    name={`telephoneList.${i}.phoneNumber`}
                    label={`Telefone ${values?.telephoneList?.length !== 1 ? 1 + i : ""}`}
                    variant="outlined"
                    size="small"
                    error={touched.telephoneList?.[i]?.phoneNumber && !!errors.telephoneList?.[i]?.phoneNumber}
                    helperText={touched.telephoneList?.[i]?.phoneNumber && errors.telephoneList?.[i]?.phoneNumber}
                    onChange={(e) => { handleUpdatePhoneNumber(e.target.value, i) }}
                    inputProps={{
                      maxLength: 15,
                      type: "tel"
                    }}
                    disabled={disabledForm}
                    $hidePaddingRight={values?.telephoneList?.length === 1}
                    $fullWidth={hideAddButton}
                  />
                  {
                    (!disabledForm && values?.telephoneList?.length !== 1) &&
                    <Tooltip
                      title="Excluir"
                      arrow
                    >
                      <IconButton
                        size="small"
                        onClick={() => { handleOpenAlert(arrayHelpers, i) }}
                        disabled={disabledForm}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </TelephoneContactContainer>
              ))
            }
            {
              (!disabledForm && !hideAddButton) &&
              <StyledButton
                variant="outlined"
                startIcon={
                  <AddCircleIcon
                    color={
                      disabledForm ?
                        primaryColors.blue_5 + "33" :
                        primaryColors.blue_5
                    }
                    size={0.8}
                  />
                }
                onClick={() => { handleCreate(arrayHelpers) }}
                disabled={disabledForm}
              >
                Adicionar telefone
              </StyledButton>
            }
          </>
        )}
      />
    </TelephoneContactFieldsContainer>
    <AlertDialog
      description="Deseja realmente excluir este telefone?"
      open={isAlertOpen}
      buttonsProps={[
        {
          title: "Excluir",
          function: handleDelete
        },
        {
          title: "Cancelar",
          function: handleDismiss
        }
      ]}
    />
    <SnackbarAlert
      isOpen={isSnackbarOpen}
      onClose={() => { setSnackbarOpen(false) }}
      message="Telefone excluído"
    />
  </>;
}