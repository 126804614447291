import styled from "styled-components";
import { Autocomplete } from '@material-ui/lab';
import { StyledTextField } from "components/Onboarding/OnboardingForms/AccountDataForms/TitularDataForm/styles";

export const BankNameAutocompleteFieldContainer = styled.div`
  
`
export const StyledAutocomplete = styled(Autocomplete)`
  display: inline-block;
  width: ${({ $fullWidth }) => $fullWidth ? "100%" : "50%"};
  @media (max-width: 767px)
  {
    width: 100%;
  }
`
export const InnerStyledTextField = styled(StyledTextField)`
  width: 100%;
  max-width: 100%;
  min-height: 64px;
  border-radius: 4px;
  .MuiFormHelperText-contained{
    margin-bottom: 16px;
    white-space: pre-wrap;
  }
  @media (min-width: 768px)
  {
    max-width: 100%;
    padding-right: 20px;
  }
`