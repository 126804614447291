import { create } from 'axios';
import { clearDataStorage, getDataStorage } from 'utils/storage';
import { fetchAuth } from 'services/StoreAPI/POST/AuthService';
import ExternalService from 'utils/ExternalService';
import { fetchRefreshAccessToken } from 'services/Authorizations/POST/RefreshAccessTokenService';
import { hardResetToYapayPortal } from 'utils/hardResetToYapayPortal';

const restartTraySession = async () => {
  const trayConsumerKey = ExternalService.buildExternalToken('tray_consumer_key')
  const hostWebApp = ExternalService.buildExternalUrl('webapp')
  const storeHost = getDataStorage('store_host')
  clearDataStorage()
  if (trayConsumerKey && hostWebApp && storeHost) {
    window.location.replace(`${decodeURI(storeHost)}/auth.php?response_type=code&consumer_key=${trayConsumerKey}&callback=${hostWebApp}/login`)
  }
}

const restartYapaySession = async () => {
  hardResetToYapayPortal()
}

const refreshMainUserYapayToken = async (error) => {
  let { config } = error;
  if (!config.__isRetryRequest) {
    config.__isRetryRequest = true
    return apiPrivate.request(config)
  } else {
    await restartYapaySession()
    return Promise.reject(error);
  }
}

const refreshSecondaryUserYapayToken = async (error) => {
  let { config } = error;
  if (!config.__isRetryRequest) {
    config.__isRetryRequest = true
    await fetchRefreshAccessToken()
      .then(({ access_token }) => {
        if (access_token) {
          apiPrivate.defaults.headers['access_token'] = access_token
          if (config.method === "post") {
            let aux = JSON.parse(config.data)
            aux.access_token = access_token
            config.data = JSON.stringify(aux)
          } else if (config.method === "get" && config.params) {
            config.params.access_token = access_token
          }
          return apiPrivate.request(config)
        } else {
          restartTraySession()
          return Promise.reject(error);
        }
      })
      .catch((_e) => {
        restartTraySession()
        return Promise.reject(error);
      })
  } else {
    restartTraySession()
    return Promise.reject(error);
  }
}

const refreshTrayToken = async (error) => {
  const storeURL = getDataStorage('store_URL')
  const storeHost = getDataStorage('store_host')
  const storeID = getDataStorage('store_id')
  const storePlanID = getDataStorage('store_plan_id')
  const adminUser = getDataStorage('store_admin_user')
  const storeCode = getDataStorage('store_code')
  const origem = getDataStorage('origem')
  let { config } = error;
  if (!config.__isRetryRequest) {
    config.__isRetryRequest = true
    // TO-DO: Alterar fetchAuth por fetchRefreshAuth
    // TO-DO: Criar service fetchRefreshAuth
    return await fetchAuth({
      storeURL,
      storeHost,
      storeID,
      storePlanID,
      adminUser,
      storeCode,
      origem,
    })
      .then(({ access_token }) => {
        if (access_token) {
          if ("store_access_token" in { ...config?.params }) {
            config.params.store_access_token = access_token
          } else if ("store_access_token" in { ...config?.headers }) {
            config.headers.store_access_token = access_token
          } else {
            let parsedConfigData = JSON.parse(config?.data || "{}")
            if ("store_access_token" in { ...parsedConfigData }) {
              parsedConfigData.store_access_token = access_token
              config.data = JSON.stringify(parsedConfigData)
            }
          }
          return apiPrivate.request(config)
        } else {
          restartTraySession()
          return Promise.reject(error);
        }
      })
      .catch((_e) => {
        restartTraySession()
        return Promise.reject(error);
      })
  } else {
    restartTraySession()
    return Promise.reject(error);
  }
}

const refreshYapayToken = async (error) => {
  const yapayPermissionedAccessToken = getDataStorage('yapay_permissioned_access_token')
  if (!!yapayPermissionedAccessToken) {
    await refreshSecondaryUserYapayToken(error)
  } else {
    await refreshMainUserYapayToken(error)
  }
}

const handleError = async (error) => {
  let { config } = error;
  const tokenType = ("store_access_token" in { ...config?.headers } || "store_access_token" in { ...config?.params }) ? "tray" : "yapay"
  switch (tokenType) {
    case 'tray':
      return refreshTrayToken(error)
    case 'yapay':
      return refreshYapayToken(error)
    default:
      return Promise.reject(error);
  }
}

const apiPrivate = create({
  baseURL: `${ExternalService.buildExternalUrl('middleware')}/api`
});

apiPrivate.interceptors.response.use(
  res => res,
  error => handleError(error)
);

export default apiPrivate