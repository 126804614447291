import { Collapse, IconButton } from '@material-ui/core';
import ExpandLessIcon from 'assets/icons/ExpandLessIcon';
import StorefrontIcon from 'assets/icons/StorefrontIcon';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { fetchConfiguration } from 'services/StoreAPI/GET/ConfigurationService';
import useWindowWidth from 'utils/useWindowWidth';
import { CancelledBoletoInfoCardContainer, InfoText, Row, Title } from './styles';

export default function CancelledBoletoInfoCard() {
  const mounted = useRef(false);
  const windowWidth = useWindowWidth()
  const [waitingPaymentDays, setPendingPaymentDays] = useState(" ")
  const [pendingPaymentDays, setWaitingPaymentDays] = useState(" ")
  const [open, setOpen] = useState(false)
  const toggleCard = () => {
    setOpen((oldValue) => !oldValue)
  }
  const fetchCancelledBoletoData = useCallback(async () => {
    const { Configurations } = await fetchConfiguration({
      registry: "data_auto_pendente,data_auto_aguardando_pagamento"
    })
    if (mounted.current) {
      setPendingPaymentDays(Configurations?.[0]?.Configuration?.value)
      setWaitingPaymentDays(Configurations?.[1]?.Configuration?.value)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  useEffect(() => {
    fetchCancelledBoletoData()
  }, [fetchCancelledBoletoData])
  return <>
    <CancelledBoletoInfoCardContainer onClick={toggleCard}>
      <Row $open={open}>
        <StorefrontIcon className="nonClickable" />
        <Title>
          Configurações de cancelamento da sua loja
        </Title>
        <IconButton>
          <ExpandLessIcon className="clickable" />
        </IconButton>
      </Row>
      <Collapse
        in={open || windowWidth >= 768}
      >
        <InfoText>
          Cancelamento automático dos pedidos com o status "Aguardando Pagamento" : <b>{waitingPaymentDays} dias</b>
        </InfoText>
        <InfoText>
          Cancelamento automático dos pedidos com o status "Pendente": <b>{pendingPaymentDays} dias</b>
        </InfoText>
      </Collapse>
    </CancelledBoletoInfoCardContainer>
  </>;
}
