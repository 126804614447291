import { useCallback, useEffect, useState } from 'react';
import { object, string } from 'yup';
import { useRef } from 'react';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { deleteDataStorage, getDataStorage, setDataStorage } from 'utils/storage';

export default function usePartnersDataPreludeSetup({
  reloadAfterSubmit = false,
  afterSubmit = () => { },
  afterSubmitAlternative = () => { },
}) {
  const mounted = useRef(false);
  const [isLoading, setLoading] = useState(true)
  const [displayedName, setDisplayedName] = useState("")
  const [identifier, setIdentifier] = useState("")
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const initialValues = {
    hasPartnersWithOver25Percent: String(getDataStorage('hasPartnersWithOver25Percent') || false),
  }
  const validationSchema = object({
    hasPartnersWithOver25Percent: string()
      .required('Este campo é obrigatório'),
  })

  const fetchAccountData = useCallback(async () => {
    const userData = await fetchGetAccountMyAccount()
    if (mounted.current) {
      setDisplayedName(userData?.social_name)
      setIdentifier(userData?.cnpj)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async ({
    hasPartnersWithOver25Percent,
  }) => {
    deleteDataStorage('hasPartnersWithOver25Percent')
    setDataStorage('hasPartnersWithOver25Percent', JSON.parse(hasPartnersWithOver25Percent))

    if (reloadAfterSubmit) {
      await fetchAccountData()
    }
    setSnackbarOpen(true)
    if (JSON.parse(hasPartnersWithOver25Percent)) {
      afterSubmit()
    } else {
      afterSubmitAlternative()
    }
  }

  useEffect(() => {
    fetchAccountData()
  }, [fetchAccountData])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    displayedName,
    identifier,
    isSnackbarOpen,
    setSnackbarOpen,
  })
}