import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'
export const fetchUpdateAddress = async ({ street, number, complement, neighborhood, city, zipcode, state }) => {
  const code = getDataStorage('yapay_code')
  const token_id = getDataStorage('token_id')
  return apiPrivate.post("/myaccount/address/update",
    {
      street,
      number,
      complement,
      neighborhood,
      city,
      zipcode,
      state,
      token_id
    },
    {
      headers: {
        code
      }
    })
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}