import React from 'react';
import { Button } from '@material-ui/core';
import useUserFacePictureUploadSetup from 'components/FormsSetup/AccountVerification/useUserFacePictureUploadSetup';
import { SameSizeButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import UserFacePictureUploadForm from 'components/Onboarding/OnboardingForms/AccountVerificationForms/UserFacePictureUploadForm';
import LoadableButton from 'components/shared/LoadableButton';
import { Form, Formik } from 'formik';
import { UserFacePictureUploadMenuItemContainer } from './styles';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import CancelFormFillingDialog from 'components/CompleteRegisterMenu/shared/CancelFormFillingDialog';

export default function UserFacePictureUploadMenuItem() {
  const { setCurrentCompleteRegisterMenuItem, goBackToCompleteRegisterMenu, toggleCancelFormFillingDialog } = useDashboardMyAccount()
  const { initialValues, validationSchema, isLoading, handleSubmit, userData, documentRequired, documentStatus } = useUserFacePictureUploadSetup({
    afterSubmit: () => {
      setCurrentCompleteRegisterMenuItem("userIdUpload")
    }
  })
  return <>
    <UserFacePictureUploadMenuItemContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={documentRequired && validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <UserFacePictureUploadForm
                userData={userData}
                isLoading={isLoading}
                documentStatus={documentStatus}
                documentRequired={documentRequired}
                {...formikProps}
              />
              <SameSizeButtonsContainer
                $shouldNotReverseColumn
              >
                <LoadableButton
                  type="submit"
                  disabled={(documentRequired && !formikProps.isValid) || (formikProps.isSubmitting || isLoading)}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_completar_cadastro_selfie_botao_enviar"}
                >
                  Continuar
                </LoadableButton>
                <Button
                  variant="outlined"
                  disabled={formikProps.isSubmitting || !documentRequired || isLoading}
                  onClick={toggleCancelFormFillingDialog}
                  id={"tela_completar_cadastro_selfie_botao_cancelar"}
                >
                  Cancelar
                </Button>
              </SameSizeButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </UserFacePictureUploadMenuItemContainer>
    <CancelFormFillingDialog
      cancelFillingFormFunction={goBackToCompleteRegisterMenu}
    />
  </>;
}
