import React from 'react';
import { UserVerifiedPhoneNumberFormContainer } from './styles';
import useUserVerifiedPhoneNumberDataSetup from 'setup/Dashboard/MyAaccountTab/UserDataMenu/shared/useUserVerifiedPhoneNumberDataSetup';
import TextFieldRequester from 'components/shared/TextFieldRequester';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';

export default function UserVerifiedPhoneNumberForm() {
  const { setCurrentMyAccountMenuItem } = useDashboardMyAccount()
  const { userPhoneNumber, isLoading, } = useUserVerifiedPhoneNumberDataSetup()

  const handlePhoneEditNavigation = () => {
    setCurrentMyAccountMenuItem("verifiedPhoneEdit")
  }

  return <>
    <UserVerifiedPhoneNumberFormContainer>
      <TextFieldRequester
        value={userPhoneNumber}
        label={isLoading ? "Carregando..." : "Celular verificado"}
        isLoadingRequest={isLoading}
        receivedFunction={handlePhoneEditNavigation}
        isFromDashboard
      />
    </UserVerifiedPhoneNumberFormContainer>
  </>;
}