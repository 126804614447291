import { Paper, TextField } from "@material-ui/core";
import MoneyTextField from "components/shared/MoneyTextField";
import styled from "styled-components";
import { grayScale, shadowStyles, textStyles } from "theme/styleguide";

export const OnboardingBodyContainer = styled.div`
`
export const StepContainer = styled(Paper)`
  width: 100%;
  background: ${grayScale.white};
  box-shadow: none;
  @media (min-width: 768px)
  {
    ${shadowStyles.drop_shadow_1}
    padding: 48px 24px;
  }
`
export const StepTitle = styled.h3`
  ${textStyles.tray_h4_18px}
  color: ${grayScale.black};
  margin-top: 0px;
  margin-bottom: 40px;
  @media (min-width: 768px)
  {
    ${textStyles.tray_h3_24px}
    margin-bottom: 56px;
  }
`
export const StepSubTitle = styled.h4`
  ${textStyles.tray_h5_15px}
  color: ${grayScale.gray_3};
  margin-bottom: 8px;
  @media (min-width: 768px)
  {
    ${textStyles.tray_h4_18px}
  }
`
export const StepBody = styled.span`
  display: block;
  white-space: pre-wrap;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
`
export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: ${({ $shouldNotReverseColumn }) => $shouldNotReverseColumn ? "column" : "column-reverse"};
  justify-content: center;
  align-items: center;
  margin: 0px 16px;
  button{
    ${({ children }) => children.length > 1 ? "margin-bottom: 16px;" : ""}
  }
  @media (min-width: 768px)
  {
    flex-direction: row;
    justify-content: flex-start;
    max-width: ${({ $smallSpaced }) => $smallSpaced ? "338px" : "100%"};
    margin: 0px;
    button{
      margin-bottom: 0px;
      ${({ children }) => children.length > 1 ? "margin-right: 16px;" : ""}
    }
  }
  @media (max-width: 767px)
  {
    button{
      width: calc(100% - 16px); //254px
      padding: 6px;
    }
  }
`
export const SameSizeButtonsContainer = styled(ButtonsContainer)`
  @media (min-width: 768px)
  {
    button{
      min-width: 200px;
    }
  }
`
const commonTextFieldStyle = `
  width: 100%;
  max-width: 100%;
  min-height: 64px;
  border-radius: 4px;
  .MuiFormHelperText-contained{
    margin-bottom: 16px;
    white-space: pre-wrap;
  }
  @media (min-width: 768px)
  {
    padding-right: 20px;
  }
`
export const StyledTextField = styled(TextField)`
  ${commonTextFieldStyle}
  @media (min-width: 768px)
  {
    max-width: ${({ $fullWidth }) => $fullWidth ? "100%" : "50%"};
  }
  :nth-of-type(even) {
    padding-right: 0px;
  }
`
export const StyledMoneyTextField = styled(MoneyTextField)`
  ${commonTextFieldStyle}
  @media (min-width: 768px)
  {
    max-width: ${({ $fullWidth }) => $fullWidth ? "100%" : "50%"};
  }
`
export const InputContainerRow = styled.div`
  flex: 1;
  display:flex;
  flex-direction: row;
  justify-content:space-between;
  flex-wrap: wrap;
`