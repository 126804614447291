import React from 'react';
import { AlternativeTitle, BenefitItem, BenefitTitle, BenefitsList, CompleteRegisterProgressBarContainer, LeftContainer, PercentageContainer, PercentageIndicator, PercentageIndicatorOffset, PercentageIndicatorText, RightContainer, StyledButton, Subtitle, Title } from './styles';
import { useDashboard } from 'context/DashboardContext';
import ExternalService from 'utils/ExternalService';
import { Collapse } from '@material-ui/core';
import VindiPaperMoneyIcon from 'assets/icons/VindiPaperMoneyIcon';
import VindiDollarAccountIcon from 'assets/icons/VindiDollarAccountIcon';
import VindiPackageIcon from 'assets/icons/VindiPackageIcon';
import VindiRepeatIcon from 'assets/icons/VindiRepeatIcon';

const benefitsList = [
  {
    title: "Visualizar seu saldo acumulado no painel Vindi",
    icon: <VindiPaperMoneyIcon />,
  },
  {
    title: "Realizar saques quando desejar",
    icon: <VindiDollarAccountIcon />,
  },
  {
    title: "Pagar faturas Tray e etiquetas de frete",
    icon: <VindiPackageIcon />,
  },
  {
    title: "Fazer transferência entre contas Vindi",
    icon: <VindiRepeatIcon />,
  },
]
export default function CompleteRegisterProgressBar() {
  const { isCompleteRegisterProgressBarOpen, hasSeenCompleteRegisterInviteDialog } = useDashboard()

  const handleNavigation = () => {
    // setCurrentTab("myaccount")
    // setCurrentMyAccountMenuItem("completeRegister")
    window.open(`${ExternalService.buildExternalUrl('myaccount')}/seller/`, "_blank")
  }
  return <>
    {
      <Collapse
        in={isCompleteRegisterProgressBarOpen && hasSeenCompleteRegisterInviteDialog}
      >
        <CompleteRegisterProgressBarContainer>
          <LeftContainer>
            <Title>
              Garanta mais benefícios com a conta digital Vindi
            </Title>
            <Subtitle>
              Finalize seu cadastro para:
            </Subtitle>
            <AlternativeTitle>
              Finalize seu cadastro e garanta mais benefícios com a conta digital Vindi
            </AlternativeTitle>
            <BenefitsList>
              {
                benefitsList.map((e, i) => {
                  return (
                    <BenefitItem
                      key={i}
                    >
                      {e.icon}
                      <BenefitTitle>
                        {e.title}
                      </BenefitTitle>
                    </BenefitItem>
                  )
                })
              }
            </BenefitsList>
            <StyledButton
              onClick={handleNavigation}
              id={"plugin_banner_aquisicao_cd_botao_finalizar-cadastro"}
            >
              Finalizar cadastro
            </StyledButton>
          </LeftContainer>
          <RightContainer>
            <PercentageContainer>
              <PercentageIndicator>
                <PercentageIndicatorOffset>
                  <PercentageIndicatorText>
                    <b>
                      {`60%\n`}
                    </b>
                    concluído
                  </PercentageIndicatorText>
                </PercentageIndicatorOffset>
              </PercentageIndicator>
            </PercentageContainer>
          </RightContainer>
        </CompleteRegisterProgressBarContainer>
      </Collapse>
    }
  </>;
}
