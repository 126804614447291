/* eslint-disable max-len */
import React from 'react';

export default function PersonIcon({ size = 1, color = "#546E7A", opacity = "0.6" }) {
  return <>
    <i>
      <svg
        width={30 * size}
        height={30 * size}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15 7.5C16.375 7.5 17.5 8.625 17.5 10C17.5 11.375 16.375 12.5 15 12.5C13.625 12.5 12.5 11.375 12.5 10C12.5 8.625 13.625 7.5 15 7.5ZM15 20C18.375 20 22.25 21.6125 22.5 22.5H7.5C7.7875 21.6 11.6375 20 15 20ZM15 5C12.2375 5 10 7.2375 10 10C10 12.7625 12.2375 15 15 15C17.7625 15 20 12.7625 20 10C20 7.2375 17.7625 5 15 5ZM15 17.5C11.6625 17.5 5 19.175 5 22.5V25H25V22.5C25 19.175 18.3375 17.5 15 17.5Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    </i>
  </>;
}
