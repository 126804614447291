import { SectionTitle } from 'components/Onboarding/OnboardingForms/AccountDataForms/AdditionalDataForm/styles';
import { BusinessAccountDataContainer, SocietyPercentageInstructions } from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/BusinessAdditionalFields/styles';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import RadioOptions from 'components/shared/RadioOptions';
import React from 'react';
import { PartnersDataPreludeFormContainer } from './styles';

const partnersWithOver25PercentageOptions = [
  {
    value: "false",
    label: "Não"
  },
  {
    value: "true",
    label: "Sim"
  },
]

export default function PartnersDataPreludeForm({
  values,
  disabledForm = false,
  setFieldValue = () => { },
  isLoading = true,
}) {
  return <>
    {
      isLoading ?
        <LoadingIndicator /> :
        <PartnersDataPreludeFormContainer>
          <BusinessAccountDataContainer>
            <SectionTitle>
              A empresa possui sócios(as) com 25% ou mais de participação?
            </SectionTitle>
            <SocietyPercentageInstructions>
              A participação do(a) sócio(a) corresponde ao seu percentual investido no capital social, ou seja, sua parte da empresa.
            </SocietyPercentageInstructions>
            <RadioOptions
              receivedValue={values.hasPartnersWithOver25Percent}
              receivedSetter={(e) => { setFieldValue("hasPartnersWithOver25Percent", e) }}
              options={partnersWithOver25PercentageOptions}
              disabled={disabledForm}
            />
          </BusinessAccountDataContainer>
        </PartnersDataPreludeFormContainer>
    }
  </>;
}
