import React from 'react';
import { useEffect } from 'react';
import { HorizontalScrollableContainer } from './styles';

export default function HorizontalScrollable({ children }) {
  useEffect(() => {
    document.querySelectorAll(".horizontalScrollable").forEach((e) => {
      e.scrollTo({ left: -9999999, behavior: 'smooth' })
    })
  })
  return <>
    <HorizontalScrollableContainer className="horizontalScrollable">
      {children}
    </HorizontalScrollableContainer>
  </>;
}