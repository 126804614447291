import React from 'react';
import AvaiableBalance from 'components/Dashboard/AvaiableBalance';
import LastTransactions from 'components/Dashboard/LastTransactions';
import NextValuesToCredit from 'components/Dashboard/NextValuesToCredit';
import SallesStatus from 'components/Dashboard/SallesStatus';
import TotalOfSelling from 'components/Dashboard/TotalOfSelling';
import { CardOutterContainer, DashboardOverviewContainer, DashboardTitle } from './styles';
import { useDashboard } from 'context/DashboardContext';
import DashboardNotifications from 'components/Dashboard/DashboardNotifications';
// import FinancialEntries from 'components/Dashboard/FinancialEntries';

export default function DashboardOverview() {
  const { hasDigitalAccount } = useDashboard()
  return <>
    <DashboardNotifications />
    <DashboardTitle>
      Visão geral
    </DashboardTitle>
    <DashboardOverviewContainer>
      {
        hasDigitalAccount &&
        <CardOutterContainer>
          <AvaiableBalance />
        </CardOutterContainer>
      }
      <CardOutterContainer
        $fullWidth={!hasDigitalAccount}
      >
        <TotalOfSelling />
      </CardOutterContainer>
      <CardOutterContainer>
        <SallesStatus />
      </CardOutterContainer>
      <CardOutterContainer $variant="borderless" >
        <NextValuesToCredit />
      </CardOutterContainer >
      <CardOutterContainer
        $variant="borderless"
        $fullWidth
      >
        {/* {
          true ? // TO-DO: Alterar lógica de exibição quando definida
            <FinancialEntries /> :
            <LastTransactions />
        } */}
        <LastTransactions />
      </CardOutterContainer>
    </DashboardOverviewContainer>
  </>;
}
