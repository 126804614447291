import { useCallback, useEffect, useState } from 'react';
import { object, string } from 'yup';
import { useRef } from 'react';
import { parseDataURLtoFile } from 'utils/parseDataURLtoFile';
import { GAEventTracker } from 'utils/GAEventTracker';
import { fetchUploadDocument } from 'services/Document/POST/UploadDocumentService';
import { useAccountVerification } from 'context/AccountVerificationContext';
import { fetchGetDocumentStatus } from 'services/Document/GET/GetDocumentStatusService';

export default function useAttorneyPowerUploadSetup({
  afterSubmit = () => { }
}) {
  const { userIdUploadData } = useAccountVerification()
  const mounted = useRef(false);
  const [isLoading, setLoading] = useState(true)
  const [documentRequired, setDocumentRequired] = useState(false)
  const [documentStatus, setDocumentStatus] = useState()
  const initialValues = {
    letterOfAttorney: ""
  }
  const validationSchema = object({
    letterOfAttorney: string()
      .required(),
  })

  const getDocumentStatus = useCallback(async () => {
    fetchGetDocumentStatus("attorney")
      .then((data) => {
        if (mounted.current) {
          setDocumentStatus(data)
          if (["pending", "approved"].includes(data?.status?.tag)) {
            setDocumentRequired(false);
          } else {
            setDocumentRequired(true)
          }
          setLoading(false)
        }
      })
  }, [])

  const handleSubmit = async ({
    letterOfAttorney
  }) => {
    if (letterOfAttorney) {
      const fileLetterOfAttorney = await parseDataURLtoFile(letterOfAttorney, "letterOfAttorney")
      await fetchUploadDocument({
        file: fileLetterOfAttorney,
        group: 'attorney',
        category: 'PUBLIC_LETTER_OF_ATTORNEY',
        lost_account_verification: false,
      })
      GAEventTracker({
        category: "Onboarding",
        action: "Anexar procuração",
        label: "Anexar procuração da empresa e salvar"
      })
    }
    if (!!userIdUploadData?.document?.img_front && !!userIdUploadData?.document?.img_back) {
      await fetchUploadDocument({
        ...userIdUploadData.document.img_front
      })
      await fetchUploadDocument({
        ...userIdUploadData.document.img_back
      })
    }
    afterSubmit()
  }

  useEffect(() => {
    getDocumentStatus()
  }, [getDocumentStatus])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    documentRequired,
    documentStatus,
  })
}