import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchUpdateSigninProfiles = async ({
  phone = "",
  name = "",
}) => {
  const code = getDataStorage('yapay_code')
  return apiPrivate.post("/signin/profiles", {
    ...(!!name && { name }),
    ...(!!phone && {
      phone: phone.replace(/\D/g, '')
    })
  }, {
    headers: {
      code
    }
  })
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}