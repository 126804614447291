import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const DashboardHeaderContainer = styled.div`
`
export const DashboardHeaderDesktopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (min-width: 820px)
  {
    margin-bottom: 84px;
  }
`
export const GreetingsColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  span{
    ${textStyles.tray_text_14px}
    color: ${grayScale.gray_3};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  b{
    ${textStyles.tray_h4_18px};
    color: ${grayScale.black};
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 33%;
`