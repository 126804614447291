import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchGetAddress = async () => {
  const yapay_code = getDataStorage('yapay_code')
  const token_id = getDataStorage('token_id')
  return apiPrivate.get("/myaccount/address", {
    headers: {
      code: yapay_code,
      token_id,
    }
  })
    .then(({ data }) => {
      return data.data[0]
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}