import styled from "styled-components";
import { Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { borderStyles, grayScale, primaryColors, textStyles } from "theme/styleguide";
export const DocumentItemContainer = styled.li`
  display: flex;
  flex-direction: column;
  ${borderStyles.border_1}
  padding: 20px;
  margin-bottom: 16px;
  >i{
    display: none;
  }
  @media (min-width: 768px)
  {
    flex-direction: row;
    padding: 32px;
    margin-bottom: 24px;
    >i{
      display: initial;
      margin-right: 16px;
    }
  }
`
export const DocumentItemInnerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`
export const DocumentItemActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px)
  {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  >i{
    margin-right: 8px;
  }
  @media (min-width: 768px)
  {
    >i{
      display: none;
    }
  }
`
export const DocumentItemTitle = styled.div`
  ${textStyles.tray_h6_12px}
  font-size: 14px;
  color: ${grayScale.black};
  @media (min-width: 768px)
  {
    ${textStyles.tray_h5_15px}
  }
`
export const DocumentItemDescription = styled.div`
  flex: 1;
  display: block;
  ${textStyles.tray_text_12px}
  white-space: pre-wrap;
  color: ${grayScale.gray_3};
  margin-bottom: 16px;
  @media (min-width: 768px)
  {
    ${textStyles.tray_text_14px}
    margin-right: 24px;
  }
`
export const DocumentStyledAlert = styled(Alert)`
  ${textStyles.tray_text_12px}
  color: ${grayScale.gray_3};
  white-space: pre-wrap;
  padding: 16px;
  margin-bottom: 16px;
  b{
    color: ${primaryColors.red_balance};
    margin-bottom: 12px;
  }
  @media (min-width: 768px)
  {
    ${textStyles.tray_text_14px}
    margin-bottom: 24px;
  }
`
export const StyledButton = styled(Button)`
  min-width: 100%;
  @media (min-width: 768px)
  {
    min-width: 128px;
    margin-right: 96px;
  }
`
