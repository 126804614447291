import styled from "styled-components";
import ExpandableCard from "components/shared/ExpandableCard";
import { grayScale, textStyles } from "theme/styleguide";

export const BillingEmailFormContainer = styled.div`
  
`
export const CardTitle = styled.span`
  display: block;
  margin-bottom: 8px;
  ${textStyles.tray_h6_12px}
  color: ${grayScale.gray_3};
  @media (min-width: 768px)
  {
    ${textStyles.tray_h5_15px}
  }
`

export const CardBody = styled.span`
  display: block;
  ${textStyles.tray_text_12px}
  color: ${grayScale.gray_60p100};
  margin-bottom: 32px;
  @media (min-width: 768px)
  {
    ${textStyles.tray_text_14px}
  }
`
export const StyledExpandableCard = styled(ExpandableCard)`
  margin-bottom: 56px;
  font-weight: bold;
  @media (min-width: 768px)
  {
    margin-bottom: 112px;
  }
`
export const BodyText = styled.span`
  display: block;
  white-space: pre-wrap;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
`
export const CardBodyInstruction = styled(CardBody)`
  color: ${grayScale.gray_3};
`