import { useOnboarding } from 'context/OnboardingContext';
import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { OnboardingBodyContainer, StepContainer } from './styles';
import { useHistory } from 'react-router-dom';
import { checkUserAllowence } from 'utils/checkUserAllowence';
import { onboardingSectionsDictionary } from 'library/onboardingSectionsDictionary';
import { getCurrentOnboardingSectionKey } from 'utils/getCurrentOnboardingSectionKey';
import LoadingIndicator from 'components/shared/LoadingIndicator';

export default function OnboardingBody() {
  const { onboardingSectionIndex, profileSettingsStep, mainSettingsStep, accountVerificationStep, isLoadingContext } = useOnboarding()
  const history = useHistory()
  const mounted = useRef(false)
  const currentSectionKey = getCurrentOnboardingSectionKey(onboardingSectionIndex)

  const fetchUserAllowance = useCallback(async () => {
    const allowence = await checkUserAllowence()
    if (mounted.current) {
      if (!allowence) {
        history.replace("/dashboard")
      }
    }
  }, [history])

  useEffect(() => {
    fetchUserAllowance()
  }, [fetchUserAllowance])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [onboardingSectionIndex, profileSettingsStep, mainSettingsStep, accountVerificationStep])

  return <>
    <OnboardingBodyContainer>
      <StepContainer>
        {
          isLoadingContext ?
            <LoadingIndicator /> :
            <>
              {
                Object.keys(onboardingSectionsDictionary).map((key, i) => {
                  return (
                    <Fragment
                      key={i}
                    >
                      {
                        key === currentSectionKey &&
                        onboardingSectionsDictionary[key].section
                      }
                    </Fragment>
                  )
                })
              }
            </>
        }
      </StepContainer>
    </OnboardingBodyContainer>
  </>;
}