import { CircularProgress } from '@material-ui/core';
import { Field } from 'formik';
import React, { useState } from 'react';
import { fetchSearchCep } from 'services/Address/GET/SearchCepService';
import { parseStringToCEP } from 'utils/parseStringToCEP';
import { InputContainerRow, StyledTextField } from 'components/Onboarding/OnboardingForms/AccountDataForms/TitularDataForm/styles';
import StatePicker from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/StatePicker';

const parseToIDNaming = (receivedNamePrefix = "") => {
  return receivedNamePrefix.replaceAll(".", "_")
}

export default function AddressFields({
  namePrefix = "address",
  errors,
  touched,
  disabledForm = false,
  setFieldValue = () => { },
}) {
  const [isSearching, setSearching] = useState(false)
  const [isCEPNotFound, setCEPNotFound] = useState(false)

  const handleFocusOnField = (inputId = "") => {
    const nextInput = document.querySelector(`input[id=${inputId}]`)
    nextInput.focus()
    nextInput.select()
  }
  const resetAddressFields = () => {
    setFieldValue(`${namePrefix}.street`, "")
    setFieldValue(`${namePrefix}.neighborhood`, "")
    setFieldValue(`${namePrefix}.city`, "")
    setFieldValue(`${namePrefix}.state`, "")
  }
  const handleUpdateAddressFields = (res) => {
    if (res.erro) {
      setCEPNotFound(true)
      resetAddressFields()
      handleFocusOnField(`${parseToIDNaming(namePrefix)}ZipCodeInput`)
    } else {
      setCEPNotFound(false)
      setFieldValue(`${namePrefix}.street`, res.logradouro)
      setFieldValue(`${namePrefix}.neighborhood`, res.bairro)
      setFieldValue(`${namePrefix}.city`, res.localidade)
      setFieldValue(`${namePrefix}.state`, res.uf)
      handleFocusOnField(`${parseToIDNaming(namePrefix)}NumberInput`)
    }
  }
  const handleCEPSearch = async (cep) => {
    setSearching(true)
    const res = await fetchSearchCep(cep)
    setSearching(false)
    handleUpdateAddressFields(res)
  }
  const handleCEPChange = (e) => {
    const parsedCEP = parseStringToCEP(e.target.value)
    setFieldValue(`${namePrefix}.zipCode`, parsedCEP)
    if (parsedCEP.length === 9) {
      handleCEPSearch(parsedCEP)
    } else {
      setCEPNotFound(false)
      resetAddressFields()
    }
  }
  return <>
    <InputContainerRow>
      <Field
        as={StyledTextField}
        name={`${namePrefix}.zipCode`}
        id={`${parseToIDNaming(namePrefix)}ZipCodeInput`}
        label="CEP"
        size="small"
        error={
          (touched?.[namePrefix]?.zipCode && !!errors?.[namePrefix]?.zipCode) ||
          isCEPNotFound
        }
        helperText={
          (touched?.[namePrefix]?.zipCode && errors?.[namePrefix]?.zipCode) ||
          (isCEPNotFound && "O CEP informado não foi encontrado")
        }
        onChange={handleCEPChange}
        disabled={isSearching || disabledForm}
        inputProps={{ maxLength: 9 }}
        InputProps={{
          endAdornment: (
            <>
              {
                isSearching &&
                <CircularProgress color="inherit" size={20} />
              }
            </>
          ),
        }}
      />
      <div className="layoutSpacing" />
      <Field
        as={StyledTextField}
        name={`${namePrefix}.street`}
        label="Rua"
        size="small"
        error={touched?.[namePrefix]?.street && !!errors?.[namePrefix]?.street}
        helperText={touched?.[namePrefix]?.street && errors?.[namePrefix]?.street}
        disabled={isSearching || disabledForm}
      />
      <Field
        as={StyledTextField}
        name={`${namePrefix}.number`}
        id={`${parseToIDNaming(namePrefix)}NumberInput`}
        label="Número"
        size="small"
        error={touched?.[namePrefix]?.number && !!errors?.[namePrefix]?.number}
        helperText={touched?.[namePrefix]?.number && errors?.[namePrefix]?.number}
        disabled={isSearching || disabledForm}
      />
      <Field
        as={StyledTextField}
        name={`${namePrefix}.complement`}
        label="Complemento"
        size="small"
        error={touched?.[namePrefix]?.complement && !!errors?.[namePrefix]?.complement}
        helperText={touched?.[namePrefix]?.complement && errors?.[namePrefix]?.complement}
        disabled={isSearching || disabledForm}
      />
      <Field
        as={StyledTextField}
        name={`${namePrefix}.neighborhood`}
        label="Bairro"
        size="small"
        error={touched?.[namePrefix]?.neighborhood && !!errors?.[namePrefix]?.neighborhood}
        helperText={touched?.[namePrefix]?.neighborhood && errors?.[namePrefix]?.neighborhood}
        disabled={isSearching || disabledForm}
      />
      <Field
        as={StyledTextField}
        name={`${namePrefix}.city`}
        label="Cidade"
        size="small"
        error={touched?.[namePrefix]?.city && !!errors?.[namePrefix]?.city}
        helperText={touched?.[namePrefix]?.city && errors?.[namePrefix]?.city}
        disabled={isSearching || disabledForm}
      />
      <Field
        as={StatePicker}
        name={`${namePrefix}.state`}
        label="Estado"
        size="small"
        error={touched?.[namePrefix]?.state && !!errors?.[namePrefix]?.state}
        helperText={touched?.[namePrefix]?.state && errors?.[namePrefix]?.state}
        disabled={isSearching || disabledForm}
      />
    </InputContainerRow>
  </>;
}