/* eslint-disable max-len */
import React from 'react';

export default function DescriptionIcon({ size = 1, color = "#2E7EE7" }) {
  return <>
    <i>
      <svg
        width={30 * size}
        height={30 * size}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 20H20V22.5H10V20ZM10 15H20V17.5H10V15ZM17.5 2.5H7.5C6.125 2.5 5 3.625 5 5V25C5 26.375 6.1125 27.5 7.4875 27.5H22.5C23.875 27.5 25 26.375 25 25V10L17.5 2.5ZM22.5 25H7.5V5H16.25V11.25H22.5V25Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
