import DashboardAccountVerification from 'components/Dashboard/DashboardAccountVerification';
import EmailSmsMenuItem from 'components/MyAccountMenu/EmailSmsMenuItem';
import EmailEditMenuItem from 'components/MyAccountMenu/EmailEditMenuItem';
import PhoneDataMenuItem from 'components/MyAccountMenu/PhoneDataMenuItem';
import PhoneSmsVerificationMenuItem from 'components/MyAccountMenu/PhoneSmsVerificationMenuItem';
import EmailUpdateInstructionsMenuItem from 'components/MyAccountMenu/EmailUpdateInstructionsMenuItem';
import CompleteRegisterMenu from 'components/Dashboard/CompleteRegisterMenu';
import AccountDataMenu from 'components/Dashboard/AccountDataMenu';
import UserDataMenu from 'components/Dashboard/UserDataMenu';
// import MyAccountMenu from 'components/Dashboard/MyAccountMenu';

export const myAccountDictionary = {
  // Referente a antiga aba Minha Conta
  // "myAccount": {
  //   title: "Minha conta",
  //   component: <MyAccountMenu />,
  // },

  // Referente as abas do "Minha Conta"
  "accountDataMenu": {
    title: "Dados da conta",
    component: <AccountDataMenu />,
  },
  "userDataMenu": {
    title: "Dados de pessoa usuária",
    component: <UserDataMenu />,
  },
  "accountVerificationMenu": {
    title: "Verificação de conta",
    component: <DashboardAccountVerification />
  },

  // Referente a chamada do banner "Completar cadastro"
  "completeRegister": {
    title: "Completar cadastro",
    component: <CompleteRegisterMenu />
  },

  // Referente a navegação para formulários que ocupam toda a página
  "emailInstructions": {
    title: "Editar e-mail",
    component: <EmailUpdateInstructionsMenuItem />,
  },
  "emailSMSVerification": {
    title: "Editar e-mail",
    component: <EmailSmsMenuItem />,
  },
  "emailEdit": {
    title: "Editar e-mail",
    component: <EmailEditMenuItem />,
  },
  "verifiedPhoneEdit": {
    title: "Celular verificado",
    component: <PhoneDataMenuItem />,
  },
  "verifiedPhoneVerification": {
    title: "Celular verificado",
    component: <PhoneSmsVerificationMenuItem />
  },
}