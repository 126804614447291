import React from 'react';
import { BrandIcon, BrandName, Column, ParcelsPickerContainer, ParcelText, PickerContainer, Row, StyledSelect } from './styles';
import CardBrandPlaceholderIcon from 'assets/icons/CardBrandPlaceholderIcon';
import { FormControl, MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import { paymentMethodIdDictionary } from 'library/paymentMethodIdDicionary';

export default function ParcelsPicker({
  brandName = "",
  brandId = "",
  parcelOptions = [],
  name = "",
  disabled = false,
  replySelectedParcels
}) {
  return <>
    <ParcelsPickerContainer>
      <Column>
        <Row>
          {
            !!paymentMethodIdDictionary[brandId] ?
              <BrandIcon
                src={paymentMethodIdDictionary[brandId].icon}
                alt={brandName}
              /> :
              <CardBrandPlaceholderIcon />
          }
          <BrandName>{brandName}</BrandName>
        </Row>
        <PickerContainer>
          <ParcelText>Parcelar em até</ParcelText>
          <FormControl
            disabled={disabled}
          >
            <Field
              as={StyledSelect}
              variant="outlined"
              name={name}
              disabled={replySelectedParcels}
            >
              {
                parcelOptions.length > 0 ?
                  parcelOptions.map((e, i) => {
                    return (
                      <MenuItem
                        value={e.split_transaction}
                        key={i}
                      >
                        {e.split_transaction}x sem juros
                      </MenuItem>
                    )
                  }) :
                  <MenuItem
                    value={parcelOptions.split_transaction}
                  >
                    {parcelOptions.split_transaction}x sem juros
                  </MenuItem>
              }
            </Field>
          </FormControl>
        </PickerContainer>
      </Column>
    </ParcelsPickerContainer>
  </>;
}