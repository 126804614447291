/* eslint-disable max-len */
import React from 'react';

export default function FaceIcon({ size = 1, color = "#2E7EE7" }) {
  return <>
    <i>
      <svg
        width={30 * size}
        height={30 * size}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.8125 16.25C12.8125 17.1125 12.1125 17.8125 11.25 17.8125C10.3875 17.8125 9.6875 17.1125 9.6875 16.25C9.6875 15.3875 10.3875 14.6875 11.25 14.6875C12.1125 14.6875 12.8125 15.3875 12.8125 16.25ZM18.75 14.6875C17.8875 14.6875 17.1875 15.3875 17.1875 16.25C17.1875 17.1125 17.8875 17.8125 18.75 17.8125C19.6125 17.8125 20.3125 17.1125 20.3125 16.25C20.3125 15.3875 19.6125 14.6875 18.75 14.6875ZM27.5 15C27.5 21.9 21.9 27.5 15 27.5C8.1 27.5 2.5 21.9 2.5 15C2.5 8.1 8.1 2.5 15 2.5C21.9 2.5 27.5 8.1 27.5 15ZM13.325 5.15C15.075 8.05 18.25 10 21.875 10C22.45 10 23.0125 9.9375 23.55 9.85C21.8 6.95 18.625 5 15 5C14.425 5 13.8625 5.0625 13.325 5.15ZM5.525 11.8375C7.6625 10.625 9.3125 8.65 10.1 6.2875C7.9625 7.5 6.3125 9.475 5.525 11.8375ZM25 15C25 14.025 24.85 13.0875 24.5875 12.2C23.7125 12.3875 22.8125 12.5 21.875 12.5C17.9625 12.5 14.475 10.7 12.175 7.8875C10.8625 11.0875 8.25 13.6 5 14.825C5.0125 14.875 5 14.9375 5 15C5 20.5125 9.4875 25 15 25C20.5125 25 25 20.5125 25 15Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
