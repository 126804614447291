/* eslint-disable max-len */
import React from 'react';

export default function TaskIcon({ size = 1, color = "#546E7A" }) {
  return <>
    <i>
      <svg
        width={30 * size}
        height={30 * size}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.5 2.5H7.5C6.125 2.5 5.0125 3.625 5.0125 5L5 25C5 26.375 6.1125 27.5 7.4875 27.5H22.5C23.875 27.5 25 26.375 25 25V10L17.5 2.5ZM22.5 25H7.5V5H16.25V11.25H22.5V25ZM11.025 16.3125L9.25 18.075L13.675 22.5L20.75 15.425L18.9875 13.6625L13.6875 18.9625L11.025 16.3125Z"
          fill={color}
          fillOpacity="0.6" />
      </svg>
    </i>
  </>;
}
