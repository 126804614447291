import { ButtonBase, IconButton } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, primaryColors, textStyles } from "theme/styleguide";

export const FilePickerContainer = styled.div`
  width: fit-content;
  cursor: ${({ disabled }) => disabled && "not-allowed"};
  @media (min-width: 768px){
    ${({ $marginRight }) => $marginRight && `margin-right: ${$marginRight};`}
  }
`
export const EmptyContainer = styled(ButtonBase)`
  width: 150px;
  height: 150px;
  background: ${({ disabled }) => disabled ? grayScale.gray_3 + 66 : primaryColors.blue_X};
  border-radius: 4px; 
  justify-content: center;
  display: flex;
  align-items: center; 
  flex-direction: column;
  margin-bottom: 16px;
  @media (min-width: 768px){
    margin-bottom: 36px;
  }
`
export const FullContainer = styled.div`
  border-radius: 4px; 
  justify-content: center;
  display: flex;
  align-items: center; 
  flex-direction:column;
`
export const FullContainerDefault = styled.div`
  width: 150px;
  height: 150px;
  background: ${grayScale.gray_3};
  border-radius: 4px; 
  justify-content: center;
  display: flex;
  align-items: center; 
  flex-direction: column;
  padding: 0px 12px;
`
export const LabelText = styled.span`
  ${textStyles.tray_h6_12px}
  text-align: center;
  text-transform: uppercase;
  color: ${({ disabled }) => disabled ? grayScale.gray_3 + 88 : grayScale.white};
  margin-top: 24px;
  padding: 0px 8px;
`
export const FullText = styled.span`
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${textStyles.tray_h6_12px}
  color:${grayScale.white};
  margin-top: 24px;
`
export const StyledIconButton = styled(IconButton)`
  margin-top:15px;
`
export const StyledImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
`