import styled from "styled-components";


export const TotalOfSellingContainer = styled.div`
  padding: 0px;
  i{
    margin-left: 8px;
  }
  @media (min-width: 768px)
  {
    padding-bottom: 28px;
    i{
      margin-left: 0px;
    }
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  @media (min-width: 920px)
  {
    margin-top: 0px;
    margin-left: 16px;
  }
`
export const Column = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-left: 16px;
  @media (min-width: 768px)
  {
    margin-left: 28px;
  }
`
export const SpinnerContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`