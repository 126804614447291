/* eslint-disable max-len */
import React from 'react';

export default function ExpandMoreLeftIcon({ size = 1, color = "#546E7A" }) {
  return <>
    <i>
      <svg
        width={24 * size}
        height={24 * size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.54"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.59961 7.4L13.1996 12L8.59961 16.6L9.99961 18L15.9996 12L9.99961 6L8.59961 7.4Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
