import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchGetSigninUser = async () => {
  const yapay_code = getDataStorage('yapay_code')
  return apiPrivate.get("/signin/users", {
    headers: {
      code: yapay_code
    }
  })
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}