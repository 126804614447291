import { MenuItem } from '@material-ui/core';
import { StyledTextField } from 'components/Onboarding/OnboardingForms/AccountDataForms/AdditionalDataForm/styles';
import React from 'react';

export default function ConstitutionPicker({
  options = [],
  ...props
}) {

  return <>
    <StyledTextField
      {...props}
      select
    >
      {
        options.map((e, i) => (
          <MenuItem
            value={e.id}
            key={i}
          >
            {e.name}
          </MenuItem>
        ))
      }
    </StyledTextField>
  </>;
}
