import React from 'react';
import { EnterpriseProofingUploadStepContainer } from './styles';
import { Button } from '@material-ui/core';
import useEnterpriseProofingUploadSetup from 'components/FormsSetup/AccountVerification/useEnterpriseProofingUploadSetup';
import { SameSizeButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import EnterpriseProofingUploadForm from 'components/Onboarding/OnboardingForms/AccountVerificationForms/EnterpriseProofingUploadForm';
import LoadableButton from 'components/shared/LoadableButton';
import { useOnboarding } from 'context/OnboardingContext';
import { Form, Formik } from 'formik';
import { getHideOnCountStepsGoingForward } from 'utils/getHideOnCountStepsGoingForward';
import { getCurrentOnboardingStepsCollection } from 'utils/getCurrentOnboardingStepsCollection';
import { accountVerificationStepsDictionary } from 'library/accountVerificationStepsDictionary';

export default function EnterpriseProofingUploadStep() {
  const { accountVerificationStep, nextAccountVerificationStep, nextOnboardingSection } = useOnboarding()
  const stepsCollectionLength = getCurrentOnboardingStepsCollection("documentsSendingSteps").length - 1

  const { initialValues, validationSchema, isLoading, handleSubmit, enterpriseData, documentRequired, documentStatus } = useEnterpriseProofingUploadSetup({
    afterSubmit: () => {
      if (accountVerificationStep < stepsCollectionLength) {
        nextAccountVerificationStep()
      } else {
        nextOnboardingSection()
      }
    },
    afterSubmitAlternative: () => {
      if (accountVerificationStep < stepsCollectionLength) {
        const hideOnCountStepsGoingBack = getHideOnCountStepsGoingForward("documentsSendingSteps", accountVerificationStep, accountVerificationStepsDictionary)
        if (accountVerificationStep + hideOnCountStepsGoingBack <= stepsCollectionLength) {
          for (let i = 0; i < hideOnCountStepsGoingBack; i++) {
            nextAccountVerificationStep()
          }
        } else {
          nextOnboardingSection()
        }
      } else {
        nextOnboardingSection()
      }
    }
  })
  return <>
    <EnterpriseProofingUploadStepContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={documentRequired && validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <EnterpriseProofingUploadForm
                isLoading={isLoading}
                enterpriseData={enterpriseData}
                documentStatus={documentStatus}
                documentRequired={documentRequired}
                {...formikProps}
              />
              <SameSizeButtonsContainer>
                <LoadableButton
                  type="submit"
                  disabled={(documentRequired && !formikProps.isValid) || (formikProps.isSubmitting || isLoading)}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_envio_doc_constituicao_onboarding_botao_enviar"}
                >
                  Continuar
                </LoadableButton>
                <Button
                  variant="outlined"
                  disabled={formikProps.isSubmitting || !documentRequired || isLoading}
                  onClick={() => {
                    if (formikProps.values.isAttorney) {
                      nextAccountVerificationStep()
                    } else {
                      nextAccountVerificationStep()
                      nextAccountVerificationStep()
                    }
                  }}
                  id={"tela_envio_doc_constituicao_onboarding_botao_enviar_depois"}
                >
                  Enviar depois
                </Button>
              </SameSizeButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </EnterpriseProofingUploadStepContainer>
  </>;
}
