import React from 'react';
import { Field } from 'formik';
import DatePicker from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/DatePicker';
import RadioOptions from 'components/shared/RadioOptions';
import AddressFields from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/AddressFields';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { BusinessAccountTitularPersonFieldsContainer } from './styles';
import { formatIdentifier } from 'utils/formatIdentifier';
import { formatPhone } from 'utils/formatPhone';
import { StyledTextField, FieldSectionTitle, FieldSectionDescription, StyledAlert } from 'components/Dashboard/AccountDataMenu/styles';
import { Collapse } from '@material-ui/core';

const isPPEOptions = [
  {
    value: "false",
    label: "Não"
  },
  {
    value: "true",
    label: "Sim"
  },
]

export default function BusinessAccountTitularPersonFields({
  values,
  errors,
  touched,
  isLoading = true,
  disabledForm = false,
  setFieldValue = () => { },
  hasDigitalAccount = false,
}) {
  return <>
    <BusinessAccountTitularPersonFieldsContainer>
      {
        isLoading ?
          <LoadingIndicator /> :
          <>
            <Field
              as={StyledTextField}
              name="name"
              label="Nome"
              size="small"
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
              disabled={disabledForm}
            />
            <Field
              as={StyledTextField}
              name="CPF"
              label="CPF"
              size="small"
              error={touched.CPF && !!errors.CPF}
              helperText={touched.CPF && errors.CPF}
              disabled={disabledForm}
              inputProps={{ maxLength: 14 }}
              onChange={(e) => {
                setFieldValue("CPF", formatIdentifier(e.target.value))
              }}
            />
            {
              hasDigitalAccount &&
              <>
                <Field
                  as={DatePicker}
                  name="birthDate"
                  label="Data de nascimento"
                  size="small"
                  setFieldValue={setFieldValue}
                  error={touched.birthDate && !!errors.birthDate}
                  helperText={touched.birthDate && errors.birthDate}
                  disabled={disabledForm}
                  $fullWidth={false}
                />
                <Field
                  as={StyledTextField}
                  name="telephone"
                  label="Telefone"
                  size="small"
                  error={touched.telephone && !!errors.telephone}
                  helperText={touched.telephone && errors.telephone}
                  onChange={(e) => { setFieldValue("telephone", formatPhone(e.target.value)) }}
                  inputProps={{
                    maxLength: 15,
                    type: "tel"
                  }}
                  disabled={disabledForm}
                />
                <Field
                  as={StyledTextField}
                  name="motherName"
                  label="Nome completo da mãe"
                  size="small"
                  error={touched.motherName && !!errors.motherName}
                  helperText={touched.motherName && errors.motherName}
                  disabled={disabledForm}
                />
                <FieldSectionTitle>
                  A pessoa titular da conta é uma Pessoa Politicamente Exposta (PPE)?
                </FieldSectionTitle>
                <FieldSectionDescription>
                  Consideram-se Pessoas Politicamente Expostas aquelas que desempenham ou tenham desempenhado cargos ou funções públicas relevantes nos últimos cinco anos, no Brasil ou em outros países.
                </FieldSectionDescription>
                <RadioOptions
                  receivedValue={values.isPPE}
                  receivedSetter={(e) => { setFieldValue("isPPE", e) }}
                  options={isPPEOptions}
                  disabled={disabledForm}
                />
                <FieldSectionTitle>
                  Endereço da pessoa titular
                </FieldSectionTitle>
                <AddressFields
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  disabledForm={disabledForm}
                />
              </>
            }
            <Collapse
              in={!disabledForm}
            >
              <StyledAlert
                severity="error"
              >
                <b>
                  {`Atenção! Seus recebimentos podem ficar temporariamente bloqueados se o nome da pessoa titular for editado.\n\n`}
                </b>
                {`Isso ocorre devido a uma rápida validação automática que fazemos do novo nome informado como medida de segurança para garantir a autenticidade da pessoa titular da conta e seu vínculo com a empresa. Em alguns casos, poderá ser solicitado que você envie um documento para comprovação.\n\nApós o novo nome ser validado, os recebimentos serão desbloqueados e a conta poderá continuar operando normalmente na Vindi.`}
              </StyledAlert>
            </Collapse>
          </>
      }
    </BusinessAccountTitularPersonFieldsContainer>
  </>;
}