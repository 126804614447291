import YapayYPIcon from 'assets/icons/YapayYPIcon';
import React from 'react';
import useWindowWidth from 'utils/useWindowWidth';
import { DashboardHeaderContainer, DashboardHeaderDesktopContainer, GreetingsColumn, Row } from './styles';
import ExternalService from 'utils/ExternalService';
import { GAEventTracker } from 'utils/GAEventTracker';
import MobileDashboardHeader from 'components/Dashboard/MobileDashboardHeader';
import DesktopDashboardHeader from 'components/Dashboard/DesktopDashboardHeader';
import { useDashboard } from 'context/DashboardContext';

export default function DashboardHeader() {
  const windowWidth = useWindowWidth()
  const { isLoading, userName, showAccountVerificationMenu, showAccountDataMenu } = useDashboard()

  const handleOpenPortal = () => {
    GAEventTracker({
      category: "Página Visão Geral",
      action: "Portal Vindi",
      label: `Clicar no botão "Portal Vindi"`
    })
    window.open(`${ExternalService.buildExternalUrl('intermediador')}/`, "_blank")
  }

  const handleGAEventTracker = (e) => {
    switch (e) {
      case "overview":
        GAEventTracker({
          category: "Página Visão Geral",
          action: "Visão geral",
          label: `Clicar na aba "Visão geral"`
        })
        break;
      case "settings":
        GAEventTracker({
          category: "Página Configurações",
          action: "Configurações",
          label: `Clicar na aba "Configurações"`
        })
        break;
      case "myaccount":
        GAEventTracker({
          category: "Página Minha Conta",
          action: "Minha Conta",
          label: `Clicar na aba "Minha Conta"`
        })
        break;
      case "accountDataMenu":
        GAEventTracker({
          category: "Página Dados da Conta",
          action: "Dados da Conta",
          label: `Clicar no submenu "Dados da Conta" da aba "Minha Conta"`
        })
        break;
      case "userDataMenu":
        GAEventTracker({
          category: "Página Dados de pessoa usuária",
          action: "Dados de pessoa usuária",
          label: `Clicar no submenu "Dados de pessoa usuária" da aba "Minha Conta"`
        })
        break;
      case "accountVerificationMenu":
        GAEventTracker({
          category: "Página Envio de documentos",
          action: "Envio de documentos",
          label: `Clicar no submenu "Envio de documentos" da aba "Minha Conta"`
        })
        break;
      default:
        break;
    }
  }

  return <>
    {
      !isLoading &&
      <>
        <DashboardHeaderContainer>
          <DashboardHeaderDesktopContainer>
            <Row>
              <YapayYPIcon />
              <GreetingsColumn>
                <span>Olá</span>
                <b>{userName?.toLowerCase()}</b>
              </GreetingsColumn>
            </Row>
            {
              windowWidth >= 820 ?
                <DesktopDashboardHeader
                  handleOpenPortal={handleOpenPortal}
                  handleGAEventTracker={handleGAEventTracker}
                  showAccountDataMenu={showAccountDataMenu}
                  showAccountVerificationMenu={showAccountVerificationMenu}
                /> :
                <MobileDashboardHeader
                  handleOpenPortal={handleOpenPortal}
                  handleGAEventTracker={handleGAEventTracker}
                  showAccountDataMenu={showAccountDataMenu}
                  showAccountVerificationMenu={showAccountVerificationMenu}
                />
            }
          </DashboardHeaderDesktopContainer>
        </DashboardHeaderContainer>
      </>
    }
  </>;
}
