import { Alert } from "@material-ui/lab";
import { StyledTextField } from "components/Onboarding/OnboardingBody/styles";
import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const BankAccountFieldsContainer = styled.div`
  
`
export const AccountNumberStyledTextField = styled(StyledTextField)`
  max-width: 100%;
  padding-right: 0px;
  @media (min-width: 768px)
  {
    max-width: 35%;
    padding-right: 20px !important;
  }
`
export const VerifyingDigitStyledTextField = styled(StyledTextField)`
  max-width: 100%;
  padding-left: 0px;
  @media (min-width: 768px)
  {
    max-width: 15%;
    padding-left: 0px;
    padding-right: 0px;
  }
`
export const BankAccountInstructions = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  font-size: 16px;
  color: ${grayScale.gray_3};
  white-space: pre-wrap;
  margin-bottom: 72px;
`
export const StyledAlert = styled(Alert)`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-top: 48px;
  margin-bottom: 208px;
`