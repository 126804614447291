import styled from "styled-components";
import { ButtonsContainer } from "components/Onboarding/OnboardingBody/styles";
import { TextField } from "@material-ui/core";
import MoneyTextField from "components/shared/MoneyTextField";
import { grayScale, textStyles } from "theme/styleguide";
import { Alert } from "@material-ui/lab";

const commonTextFieldStyle = `
  width: 100%;
  max-width: 100%;
  min-height: 64px;
  border-radius: 4px;
  .MuiFormHelperText-contained{
    margin-bottom: 16px;
    white-space: pre-wrap;
  }
  @media (min-width: 768px)
  {
    padding-right: 20px;
  }
`
export const AccountDataMenuContainer = styled.div`
  margin-bottom: 24px;
`
export const StyledButtonsContainer = styled(ButtonsContainer)`
  flex-direction: column;
  margin-top: 32px;
  @media (min-width: 768px)
  {
    flex-direction: row;
    margin-top: 64px;
    margin-bottom: 24px;
    button{
      min-width: 128px;
    }
  }
`
export const StyledTextField = styled(TextField)`
  ${commonTextFieldStyle}
  @media (min-width: 768px)
  {
    max-width: ${({ $fullWidth }) => $fullWidth ? "100%" : "50%"};
  }
`
export const StyledMoneyTextField = styled(MoneyTextField)`
  ${commonTextFieldStyle}
  @media (min-width: 768px)
  {
    max-width: ${({ $fullWidth }) => $fullWidth ? "100%" : "50%"};
  }
`
export const FieldSectionTitle = styled.span`
  display: block;
  ${textStyles.tray_h5_15px}
  font-weight: bold;
  color: ${grayScale.gray_3};
  margin-top: 32px;
  margin-bottom: 16px;
  @media (min-width: 768px)
  {
    ${textStyles.h5_16px}
    font-weight: bold;
    margin-top: 56px;
    margin-bottom: 16px;
  }
`
export const FieldSectionDescription = styled.span`
  display: block;
  white-space: pre-wrap;
  ${textStyles.tray_text_14px}
  font-size: 16px;
  color: ${grayScale.gray_3};
  margin-bottom: 16px;
`
export const StyledAlert = styled(Alert)`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  white-space: pre-wrap;
  margin-top: 16px;
  margin-bottom: 8px;
  padding: 12px;
  b{
    ${textStyles.tray_text_14px}
    color: ${grayScale.black};
    font-weight: bold;
  }
  @media (min-width: 768px)
  {
    margin-top: 48px;
    margin-bottom: 16px;
    padding: 24px;
    b{
      ${textStyles.tray_h5_15px}
    }
  }
`