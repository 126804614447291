import React from 'react';
import { AdditionalDataDetailsContainer } from './styles';
import PersonalAdditionalDataIcon from 'assets/icons/PersonalAdditionalDataIcon';
import { formatIdentifier } from 'utils/formatIdentifier';
import ApartmentIcon from 'assets/icons/ApartmentIcon';

export default function AdditionalDataDetails({
  displayedName = "",
  identifier = "",
  isBusinessAccount = false,
}) {
  return <>
    <AdditionalDataDetailsContainer>
      <span>
        Conta:
      </span>
      <div>
        {
          isBusinessAccount ?
            <ApartmentIcon /> :
            <PersonalAdditionalDataIcon />
        }
        <div>
          <span>
            {displayedName}
          </span>
          <span>
            {`${isBusinessAccount ? "CNPJ" : "CPF"} ${formatIdentifier(identifier)}`}
          </span>
        </div>
      </div>
    </AdditionalDataDetailsContainer>
  </>;
}
