import { Select } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const ParcelsPickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const PickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px)
  {
    flex-direction: row;
    align-items: center;
  }
`
export const BrandName = styled.span`
  ${textStyles.tray_h5_15px}
  color: ${grayScale.black};
  margin-left: 16px;
`
export const ParcelText = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-left: 56px;
  margin-right: 24px;
  margin-bottom: 8px;
    @media (min-width: 768px)
  {
    margin-bottom: 0px;
  }
`
export const StyledSelect = styled(Select)`
  width: 232px;
  margin-left: 56px;
  ${textStyles.body_1_14px}
  .MuiSelect-select:focus{
    background: transparent !important;
  }
  .MuiOutlinedInput-input {
    padding: 9px 14px;
  }
  @media (min-width: 768px)
  {
    width: 168px;
    margin-left: 0px;
  }
`
export const BrandIcon = styled.img`
  width: 100%;
  max-width: 40px;
  aspect-ratio: 1;
  background-color: #C4C4C4;
  border-radius: 4px;
`