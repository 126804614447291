import React from 'react';
import { PartnersDocumentsUploadStepContainer } from './styles';
import { Button } from '@material-ui/core';
import usePartnersDocumentsUploadDataSetup from 'components/FormsSetup/AccountVerification/usePartnersDocumentsUploadDataSetup';
import { SameSizeButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import PartnersDocumentsUploadForm from 'components/Onboarding/OnboardingForms/AccountVerificationForms/PartnersDocumentsUploadForm';
import CompleteLaterDialog from 'components/Onboarding/OnboardingSteps/AccountDataSteps/shared/CompleteLaterDialog';
import LoadableButton from 'components/shared/LoadableButton';
import { useOnboarding } from 'context/OnboardingContext';
import { Form, Formik } from 'formik';
import { getCurrentOnboardingStepsCollection } from 'utils/getCurrentOnboardingStepsCollection';

export default function PartnersDocumentsUploadStep() {
  const { accountVerificationStep, nextAccountVerificationStep, nextOnboardingSection, toggleCompleteLaterAlert } = useOnboarding()
  const { initialValues, validationSchema, isLoading, handleSubmit, selectedPartnerData, documentRequired, documentStatus } = usePartnersDocumentsUploadDataSetup({
    afterSubmit: () => {
      const stepsCollectionLength = getCurrentOnboardingStepsCollection("documentsSendingSteps").length - 1
      if (accountVerificationStep < stepsCollectionLength) {
        nextAccountVerificationStep()
      } else {
        nextOnboardingSection()
      }
    }
  })

  return <>
    <PartnersDocumentsUploadStepContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <PartnersDocumentsUploadForm
                selectedPartnerData={selectedPartnerData}
                isLoading={isLoading}
                documentStatus={documentStatus}
                documentRequired={documentRequired}
                formikProps={formikProps}
              />
              <SameSizeButtonsContainer>
                <LoadableButton
                  type="submit"
                  disabled={(documentRequired && !formikProps.isValid) || (formikProps.isSubmitting || isLoading)}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_envio_documento_socio_onboarding_botao_enviar"}
                >
                  Continuar
                </LoadableButton>
                <Button
                  variant="outlined"
                  disabled={formikProps.isSubmitting || isLoading}
                  onClick={toggleCompleteLaterAlert}
                  id={"tela_envio_documento_socio_onboarding_botao_depois"}
                >
                  Enviar depois
                </Button>
              </SameSizeButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </PartnersDocumentsUploadStepContainer>
    <CompleteLaterDialog
      completeLaterFunction={nextOnboardingSection}
    />
  </>;
}
