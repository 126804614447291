import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchUpdateSigninProfilePicture = async ({
  file,
}) => {
  const code = getDataStorage('yapay_code')
  const formData = new FormData()
  formData.append("file", file);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      code,
    }
  }
  return apiPrivate.post("/signin/profiles/update_photo", formData, config)
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}