import { Button } from "@material-ui/core";
import styled from "styled-components";
import { buttonStyles, primaryColors } from "theme/styleguide";

export const SecondFoldContainer = styled.div`
  background-color: ${primaryColors.landing.brand.primary[800]};
  border-bottom: 5px solid #00A3FF;
  span{
    display: block;
  }
  >div{
    max-width: 1200px;
    padding: 80px 102px 96px;
    margin: 0 auto;
  }
`
export const Title = styled.span`
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 700;
  font-size: 34px;
  line-height: 45px;
  letter-spacing: -0.02em;
  text-align: center;
  color: ${primaryColors.landing.brand.neutral[25]};
  margin-bottom: 64px;
`
export const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 32px;
`
export const FeatureItem = styled.div`
  width: 100%;
  max-width: 450px;
  margin-bottom: 56px;
  >i{
    display: inline-block;
    margin-bottom: 32px;
  }
`
export const FeatureTitle = styled.span`
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 26px;
  color: ${primaryColors.landing.brand.neutral[25]};
  margin-bottom: 16px;
`
export const FeatureDescription = styled.span`
  font-family: 'Red Hat Display';
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${primaryColors.landing.brand.neutral[25]};
`
export const ExtraInfo = styled.span`
  font-family: 'Red Hat Display';
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${primaryColors.landing.brand.neutral[25]};
  margin-bottom: 16px;
`
export const StyledButton = styled(Button)`
  display: block;
  ${buttonStyles.landing_white}
  margin: 0 auto;
`