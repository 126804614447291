import { InputContainerRow } from 'components/Onboarding/OnboardingForms/AccountDataForms/TitularDataForm/styles';
import AddressFields from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/AddressFields';
import DatePicker from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/DatePicker';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import RadioOptions from 'components/shared/RadioOptions';
import { Field } from 'formik';
import React from 'react';
import { CompleteBusinessAccountTitularDataFormContainer, SectionTitle, BusinessAccountTitularSection, PPEInstructions, StyledTextField, InputContainerColumn } from './styles';
import PersonImage from 'assets/images/person.png';
import { formatPhone } from 'utils/formatPhone';

const isPPEOptions = [
  {
    value: "false",
    label: "Não"
  },
  {
    value: "true",
    label: "Sim"
  },
]

export default function CompleteBusinessAccountTitularDataForm({
  values,
  errors,
  touched,
  isLoading = true,
  disabledForm = false,
  setFieldValue = () => { },
}) {
  return <>
    <CompleteBusinessAccountTitularDataFormContainer>
      {
        isLoading ?
          <LoadingIndicator /> :
          <>
            <BusinessAccountTitularSection>
              <SectionTitle>
                Dados da pessoa titular da conta
              </SectionTitle>
              <InputContainerRow>
                <InputContainerColumn>
                  <Field
                    as={DatePicker}
                    name="birthDate"
                    label="Data de nascimento"
                    size="small"
                    setFieldValue={setFieldValue}
                    error={touched.birthDate && !!errors.birthDate}
                    helperText={touched.birthDate && errors.birthDate}
                    disabled={disabledForm}
                  />
                  <Field
                    as={StyledTextField}
                    name="telephone"
                    label="Telefone"
                    size="small"
                    error={touched.telephone && !!errors.telephone}
                    helperText={touched.telephone && errors.telephone}
                    onChange={(e) => { setFieldValue("telephone", formatPhone(e.target.value)) }}
                    inputProps={{
                      maxLength: 15,
                      type: "tel"
                    }}
                    disabled={disabledForm}
                    $fullWidth
                  />
                  <Field
                    as={StyledTextField}
                    name="motherName"
                    label="Nome completo da mãe"
                    size="small"
                    error={touched.motherName && !!errors.motherName}
                    helperText={touched.motherName && errors.motherName}
                    disabled={disabledForm}
                    $fullWidth
                  />
                </InputContainerColumn>
                <InputContainerColumn>
                  <img
                    src={PersonImage}
                    alt="Dados da conta"
                  />
                </InputContainerColumn>
              </InputContainerRow>
            </BusinessAccountTitularSection>
            <BusinessAccountTitularSection>
              <SectionTitle>
                O(a) titular da conta é uma Pessoa Politicamente Exposta (PPE)?
              </SectionTitle>
              <PPEInstructions>
                Consideram-se Pessoas Politicamente Expostas aquelas que desempenham ou tenham desempenhado cargos ou funções públicas relevantes nos últimos cinco anos, no Brasil ou em outros países.
              </PPEInstructions>
              <RadioOptions
                receivedValue={values.isPPE}
                receivedSetter={(e) => { setFieldValue("isPPE", e) }}
                options={isPPEOptions}
                disabled={disabledForm}
              />
            </BusinessAccountTitularSection>
            <BusinessAccountTitularSection>
              <SectionTitle>
                Endereço residencial da pessoa titular
              </SectionTitle>
              <AddressFields
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                disabledForm={disabledForm}
              />
            </BusinessAccountTitularSection>
          </>
      }
    </CompleteBusinessAccountTitularDataFormContainer>
  </>;
}
