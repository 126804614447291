/* eslint-disable max-len */
import React from 'react';

export default function EyeIcon({ size = 1, color = "#546E7A" }) {
  return <>
    <i>
      <svg
        width={18 * size}
        height={14 * size}
        viewBox="0 0 18 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.8125 6.55076C11.8125 7.33152 11.5162 8.08029 10.9887 8.63237C10.4613 9.18444 9.74592 9.49459 9 9.49459C8.25408 9.49459 7.53871 9.18444 7.01126 8.63237C6.48382 8.08029 6.1875 7.33152 6.1875 6.55076C6.1875 5.77001 6.48382 5.02124 7.01126 4.46916C7.53871 3.91709 8.25408 3.60693 9 3.60693C9.74592 3.60693 10.4613 3.91709 10.9887 4.46916C11.5162 5.02124 11.8125 5.77001 11.8125 6.55076Z"
          fill={color}
        />
        <path
          d="M0 6.55069C0 6.55069 3.375 0.0742188 9 0.0742188C14.625 0.0742188 18 6.55069 18 6.55069C18 6.55069 14.625 13.0272 9 13.0272C3.375 13.0272 0 6.55069 0 6.55069ZM9 10.6721C10.0443 10.6721 11.0458 10.2379 11.7842 9.46495C12.5227 8.69204 12.9375 7.64375 12.9375 6.55069C12.9375 5.45763 12.5227 4.40934 11.7842 3.63643C11.0458 2.86352 10.0443 2.4293 9 2.4293C7.95571 2.4293 6.95419 2.86352 6.21577 3.63643C5.47734 4.40934 5.0625 5.45763 5.0625 6.55069C5.0625 7.64375 5.47734 8.69204 6.21577 9.46495C6.95419 10.2379 7.95571 10.6721 9 10.6721Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
