/* eslint-disable max-len */
import React from 'react';

export default function ExpandMoreIcon({ size = 1, color = "#546E7A" }) {
  return <>
    <i>
      <svg
        width={12 * size}
        height={8 * size}
        viewBox="0 0 12 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.59 0.5L6 5.13949L1.41 0.5L0 1.93531L6 8L12 1.93531L10.59 0.5Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
