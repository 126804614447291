import apiPublic from 'services/apiPublic'
import { getDataStorage, setDataStorage } from 'utils/storage';

export const fetchSearch = async (storeID, adminUser) => {
  
  const store_id = storeID || getDataStorage("store_id")
  return apiPublic.post("/store_keys/search", {
    store_id,
  })
    .then(({ data }) => {
      setDataStorage("onboarding_step", data?.data?.onboarding_step || 0)
      setDataStorage("main_settings_step", data?.data?.main_settings_step || 0)
      setDataStorage("account_verification_step", data?.data?.account_verification_step || 0)
      setDataStorage("profile_settings_step", data?.data?.profile_settings_step || 0)
      return data.data
    })
    .catch((error) => {
      console.error(error)
      return []
    })
}