import { TextField } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const CompleteBusinessAccountTitularDataFormContainer = styled.div`
  
`
export const SectionTitle = styled.span`
  ${textStyles.h5_16px}
  font-weight: bold;
  color: ${grayScale.gray_3};
  margin-bottom: 24px;
  @media (min-width: 768px)
  {
    ${textStyles.tray_h4_18px}
  }
`
export const BusinessAccountTitularSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  @media (min-width: 768px)
  {
    margin-bottom: 68px;
  }
`
export const PPEInstructions = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-top: -16px;
  margin-bottom: 16px;
`
export const StyledTextField = styled(TextField)`
  width: 100%;
  max-width: 100%;
  min-height: 64px;
  border-radius: 4px;
  .MuiFormHelperText-contained{
    margin-bottom: 16px;
    white-space: pre-wrap;
  }
  @media (min-width: 768px)
  {
    max-width: ${({ $fullWidth }) => $fullWidth ? "100%" : "50%"};
    padding-right: 20px;
  }
`
export const InputContainerColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  >img{
    display: none;
  }
  @media (min-width: 768px)
  {
    flex: 1;
    >img{
      display: initial;
      margin: 0px auto;
      margin-top: -40px;
    }
  }
`