import React from 'react';
import { RegularizationStatusNotifierContainer } from './styles';
import ExternalService from 'utils/ExternalService';
import { useDashboard } from 'context/DashboardContext';
import StatusNotificationAlert from 'components/Dashboard/StatusNotificationAlert';

export default function RegularizationStatusNotifier() {
  const { showCompleteRegisterWarningMessages } = useDashboard()
  const hasReachedRegistrationLimitedDate = new Date().getTime() >= new Date("05/26/2023").getTime() // TO-DO: Remover condicional após esta data

  const handleMyAccountSeller = () => {
    window.open(`${ExternalService.buildExternalUrl('myaccount')}/seller/`, "_blank");
  }

  const regularizationStatusDictionary = {
    beforeLimitedDateRegularization: {
      message: <>
        <span>
          {`Urgente! Você perderá a autonomia de transferir seus recebíveis para sua conta bancária quando quiser se não completar seu cadastro `}
          <b>
            {`até dia 25 de maio`}
          </b>
          {`. Além disso, a visualização e movimentação de saldo na plataforma também serão afetadas.`}
        </span>
      </>,
      severity: "warning",
      buttonText: "Completar cadastro ",
      buttonFunction: handleMyAccountSeller,
    },
    afterLimitedDateRegularization: {
      message: <>
        <span>
          {`Complete o cadastro para regularizar sua conta e evitar que seus saques sejam bloqueados. A regularização é obrigatória para que a conta esteja em conformidade com as exigências do Banco Central.`}
        </span>
      </>,
      severity: "warning",
      buttonText: "Completar cadastro ",
      buttonFunction: handleMyAccountSeller,
    },
  }
  return <>
    <RegularizationStatusNotifierContainer>
      {
        showCompleteRegisterWarningMessages &&
        <StatusNotificationAlert
          statusTag={hasReachedRegistrationLimitedDate ? "afterLimitedDateRegularization" : "beforeLimitedDateRegularization"}
          statusDictionary={regularizationStatusDictionary}
        />
      }
    </RegularizationStatusNotifierContainer>
  </>;
}
