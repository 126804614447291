import apiPublic from 'services/apiPublic';
import { getDataStorage } from 'utils/storage';

export const fetchUpdateOnboardingStep = async ({
  onboarding_step,
  main_settings_step,
  account_verification_step,
  profile_settings_step,
}) => {
  const store_id = getDataStorage("store_id")
  return apiPublic.post("/store_keys/update_onboarding", {
    store_id,
    onboarding_step,
    main_settings_step,
    account_verification_step,
    profile_settings_step,
  })
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}