import apiPublic from 'services/apiPublic'
import { getDataStorage } from 'utils/storage'

export const fetchSigninLogout = async () => {
  const yapay_code = getDataStorage('yapay_code')
  return apiPublic.get("/signin/tokens/logout", {
    headers: {
      yapay_code
    }
  })
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}