import React from 'react';
import FormButtons from 'components/SettingsMenu/FormButtons';
import { BoletoMenuItemContainer } from './styles';
import { useDashboard } from 'context/DashboardContext';
import { Form, Formik } from 'formik';
import BoletoForm from 'components/Onboarding/OnboardingForms/MainSettingsForms/BoletoForm';
import useBoletoSetup from 'components/FormsSetup/MainSettings/useBoletoSetup';

export default function BoletoMenuItem() {
  const { disabledEdition } = useDashboard()
  const { initialValues, validationSchema, isLoading, handleSubmit, isSnackbarOpen, setSnackbarOpen } = useBoletoSetup({})

  return <>
    <BoletoMenuItemContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <BoletoForm
                {...formikProps}
                disabled={disabledEdition}
                isLoading={isLoading}
              />
              <FormButtons
                isSnackbarOpen={isSnackbarOpen}
                setSnackbarOpen={setSnackbarOpen}
                saveButtonId={"configuracoes_boleto_botao_salvar"}
                cancelButtonId={"configuracoes_boleto_botao_cancelar"}
                {...formikProps}
              />
            </Form>
          )
        }
      </Formik>
    </BoletoMenuItemContainer>
  </>;
}
