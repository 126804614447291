import React from 'react';
import { StyledKeyboardDatePicker, InnerStyledTextField } from './styles';

const TextFieldComponent = (props) => {
  return (
    <InnerStyledTextField
      {...props}
    />
  )
}
export default function DatePicker({
  value,
  name,
  setFieldValue = () => { },
  $fullWidth = true, // TO-DO: Incluir atributo $fullWidth nas instâncias que precisam ser 100% e alterar o valor padrão de true para false
  ...props
}) {
  return <>
    <StyledKeyboardDatePicker
      {...props}
      name={name}
      value={value}
      onChange={date => setFieldValue(name, date, true)}
      TextFieldComponent={TextFieldComponent}
      format="dd/MM/yyyy"
      okLabel="Ok"
      cancelLabel="Cancelar"
      invalidDateMessage="Informe uma data válida"
      minDateMessage="Informe uma data válida"
      maxDateMessage="Informe uma data válida"
      $fullWidth={$fullWidth}
    />
  </>;
}
