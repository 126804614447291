const externalSevice = {
  buildExternalUrl: (externalApp) => {
    const services = {
      intermediador: {
        fallback: 'https://tc.intermediador.sandbox.yapay.com.br',
        sandbox: 'https://tc.intermediador.sandbox.yapay.com.br',
        production: 'https://tc.intermediador.yapay.com.br'
      },
      signin: {
        fallback: 'https://signin-sandbox.yapay.com.br',
        sandbox: 'https://signin-sandbox.yapay.com.br',
        production: 'https://signin.yapay.com.br'
      },
      myaccount: {
        fallback: 'https://myaccount-sandbox.yapay.com.br',
        sandbox: 'https://myaccount-sandbox.yapay.com.br',
        production: 'https://myaccount.yapay.com.br'
      },
      middleware: {
        development: 'http://localhost:3003',
        fallback: 'https://credenciamento-api-sandbox.yapay.com.br',
        sandbox: 'https://credenciamento-api-sandbox.yapay.com.br',
        staging: 'https://credenciamento-api-staging.yapay.net.br',
        production: 'https://credenciamento-api.yapay.com.br'
      },
      webapp: {
        development: 'http://localhost:3000',
        fallback: 'https://credenciamento-sandbox.yapay.com.br',
        sandbox: 'https://credenciamento-sandbox.yapay.com.br',
        staging: 'https://credenciamento-staging.yapay.net.br',
        production: 'https://credenciamento.yapay.com.br'
      }
    }

    if (services[externalApp][process.env.REACT_APP_STAGE] === undefined) {
      console.warn(`Ambiente ${process.env.REACT_APP_STAGE} não possui o ${externalApp} service, o fallback será utilizado.`)
      return services[externalApp]['fallback']
    }
    // console.log(`Ambiente: ${process.env.REACT_APP_STAGE}`)
    return services[externalApp][process.env.REACT_APP_STAGE]
  },

  buildExternalToken: (externalApp) => {
    const tokens = {
      tray_consumer_key: process.env.REACT_APP_TRAY_CONSUMER_KEY,
      intermediador_consumer_key: process.env.REACT_APP_YAPAY_CONSUMER_KEY,
    }
    return tokens[externalApp]
  }
}

export default externalSevice