import { useDashboard } from 'context/DashboardContext';
import React, { forwardRef } from 'react';
import { CompleteRegisterWarningDialogContainer, StyledDialog, DialogHeader, StyledButton, StyledDialogActions, StyledDialogContent, Title, Instructions } from './styles';
import ExternalService from 'utils/ExternalService';
import AlertTriangleIcon from 'assets/icons/AlertTriangleIcon';
import { useState } from 'react';
import { Slide } from '@material-ui/core';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})
export default function CompleteRegisterWarningDialog() {
  const { showCompleteRegisterWarningMessages } = useDashboard()
  const [isCompleteRegisterWarningDialogOpen, setCompleteRegisterWarningDialogOpen] = useState(true)
  const hasReachedRegistrationLimitedDate = new Date() >= new Date("05/26/2023") // TO-DO: Remover condicional após esta data
  const handleClose = () => {
    setCompleteRegisterWarningDialogOpen(false);
  }

  const handleOpenNewTab = () => {
    window.open(`${ExternalService.buildExternalUrl('myaccount')}/seller/`, "_blank");
    setCompleteRegisterWarningDialogOpen(false);
  }

  return <>
    <CompleteRegisterWarningDialogContainer>
      <StyledDialog
        open={showCompleteRegisterWarningMessages && isCompleteRegisterWarningDialogOpen}
        TransitionComponent={Transition}
        onClose={handleClose}
      >
        <DialogHeader>
          <AlertTriangleIcon />
          <Title
            id="complete-register-warning-dialog-title"
          >
            {
              hasReachedRegistrationLimitedDate ?
                `Seus saques podem ser bloqueados` :
                `Urgente - Complete seu cadastro até 25/05`
            }
          </Title>
        </DialogHeader>
        <StyledDialogContent>
          <Instructions>
            {
              hasReachedRegistrationLimitedDate ?
                `Complete o cadastro para regularizar sua conta e evitar que seus saques sejam bloqueados. A regularização é obrigatória para que a conta esteja em conformidade com as exigências do Banco Central.` :
                `Você perderá a autonomia de transferir seus recebíveis para sua conta bancária quando quiser se não completar seu cadastro até dia 25 de maio. Além disso, a visualização e movimentação de saldo na plataforma também serão afetadas.`
            }
          </Instructions>
        </StyledDialogContent>
        <StyledDialogActions>
          <StyledButton
            color="primary"
            onClick={handleOpenNewTab}
            autoFocus
          >
            Finalizar cadastro agora
          </StyledButton>
        </StyledDialogActions>
      </StyledDialog>
    </CompleteRegisterWarningDialogContainer>
  </>;
}
