import React, { useState } from 'react';
import { StyledAutocomplete, InnerStyledTextField } from './styles';

export default function BankNameAutocompleteField({
  label = "",
  values,
  error,
  helperText,
  onChange,
  setFieldValue = () => { },
  bankOptionsList = [],
  fullWidth = false,
  ...props
}) {
  const initialValues = {
    code: values.bankAccount?.bankCode,
    description: bankOptionsList
      .filter((e) => !!values.bankAccount?.bankCode && e.code === values.bankAccount?.bankCode)[0]?.description
      || "",
  }
  const [selectedOption, setSelectedOption] = useState(initialValues)

  const handleSelection = (i) => {
    if (!!i?.code) {
      setSelectedOption(i)
      setFieldValue("bankAccount.bankCode", i.code)
    }
  }

  return <>
    <StyledAutocomplete
      {...props}
      value={selectedOption}
      onChange={(_e, i) => { handleSelection(i) }}
      options={bankOptionsList}
      getOptionLabel={(option) => (option?.description || "")}
      getOptionSelected={(option, value) => option.code === value.code}
      renderInput={
        (params) => (
          <InnerStyledTextField
            {...params}
            label={label}
            placeholder="Digite aqui para buscar o nome do banco"
            size="small"
            InputProps={{
              ...params.InputProps,
            }}
            error={error}
            helperText={helperText}
          />
        )
      }
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.isDefaultMuiPrevented = true;
        }
      }}
      autoComplete
      disableClearable
      closeText="Fechar"
      openText="Abrir"
      loadingText="Carregando..."
      noOptionsText="Não encontrado"
      clearText="Limpar"
      $fullWidth={fullWidth}
    />
  </>;
}
