import styled from "styled-components";
import { grayScale, primaryColors, textStyles } from "theme/styleguide";

export const EmptyTitle = styled.span`
  ${textStyles.tray_h5_15px}
  color: ${primaryColors.blue_5};
  margin-top: 20px;
  white-space: pre-wrap;
  text-align: center;
`
export const EmptySubTitle = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_60p100};
  margin-top: 20px;
  white-space: pre-wrap;
  text-align: center;
`
export const EmptyDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  @media (min-width: 768px)
  {
    margin-bottom: 100px;
  }
`