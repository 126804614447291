import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const PartnersDocumentsUploadFormContainer = styled.div`
  
`
export const FormTitle = styled.span`
  display: block;
  ${textStyles.tray_h4_18px}
  color: ${grayScale.black};
  margin-bottom: 28px;
  @media (min-width: 768px)
  {
    margin-bottom: 56px;
  }
`