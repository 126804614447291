/* eslint-disable max-len */
import React from 'react';

export default function AssessmentIcon({ size = 1, color = "#235390" }) {
  return <>
    <i>
      <svg
        width={30 * size}
        height={30 * size}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M23.75 3.75H6.25C4.875 3.75 3.75 4.875 3.75 6.25V23.75C3.75 25.125 4.875 26.25 6.25 26.25H23.75C25.125 26.25 26.25 25.125 26.25 23.75V6.25C26.25 4.875 25.125 3.75 23.75 3.75ZM23.75 23.75H6.25V6.25H23.75V23.75ZM8.75 12.5H11.25V21.25H8.75V12.5ZM13.75 8.75H16.25V21.25H13.75V8.75ZM18.75 16.25H21.25V21.25H18.75V16.25Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
