import React from 'react';
import { Snackbar } from '@material-ui/core';
import { StyledAlert } from './styles';
import CheckCircleIcon from 'assets/icons/CheckCircleIcon';
import ErrorIcon from 'assets/icons/ErrorIcon';

export default function SnackbarAlert({
  isOpen = false,
  onClose = () => { },
  message = "",
  hasSuccessed = true,
}) {
  return <>
    <Snackbar
      open={isOpen}
      autoHideDuration={2500}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <StyledAlert
        onClose={onClose}
        severity={hasSuccessed ? "success" : "error"}
        $hasSuccessed={hasSuccessed ? "success" : "error"}
        icon={
          hasSuccessed ?
            <CheckCircleIcon /> :
            <ErrorIcon color='white' size={0.47} />
        }
      >
        {message}
      </StyledAlert>
    </Snackbar>
  </>;
}
