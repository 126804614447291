import { createTheme as createMuiTheme, unstable_createMuiStrictModeTheme } from "@material-ui/core/styles";
import { grayScale, primaryColors } from "./styleguide";

const createTheme = process.env.REACT_APP_STAGE === "development" ?
  unstable_createMuiStrictModeTheme :
  createMuiTheme

export const theme = createTheme({
  palette: {
    primary: {
      main: primaryColors.blue_5,
      contrastText: grayScale.white
    },
    secondary: {
      main: primaryColors.blue_5,
      contrastText: grayScale.white
    },
  },
  typography: {
    fontFamily: [
      "Nunito Sans",
      "sans-serif",
    ].join(","),
    button: {
      fontWeight: 400,
      fontSize: "14px",
      textTransform: "none",
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        minWidth: "163px",
        fontWeight: 500,
      },
      outlined: {
        minWidth: "163px",
        fontWeight: 600,
        ".MuiTouchRipple-root": {
          marginTop: "-1px",
          marginRight: "-1px",
          marginLeft: "-1px",
          marginBottom: "-1px",
        },
      },
      text: {
        minWidth: "163px",
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: grayScale.black,
      },
      arrow: {
        color: grayScale.black,
        backgroundColor: "transparent"
      }
    },
    MuiSwitch: {
      track: {
        backgroundColor: grayScale.gray_3,
        opacity: 1,
      }
    },
    MuiSelect: {
      select: {
        "&$select": {
          backgroundColor: "transparent",
          width: "100%",
        }
      }
    },
  },
  props: {
    MuiButton: {
      variant: "contained",
      disableElevation: true,
      color: "primary",
    },
    MuiTextField: {
      variant: "outlined",
      color: "secondary",
    },
    MuiSelect: {
      variant: "outlined"
    },
  }
})
