import styled from "styled-components";
import { Menu, MenuItem } from "@material-ui/core";
import { grayScale, textStyles } from "theme/styleguide";
export const MobileDashboardHeaderContainer = styled.div`
  
`
export const StyledMenu = styled(Menu)`
  .MuiList-padding{
    padding: 16px 0px;
  }
`
export const StyledMenuItem = styled(MenuItem)`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  padding: 0px 28px;
  min-height: 40px;
`