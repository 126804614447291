import React, { useRef } from 'react';
import { CanvasContainer, LabelElementContainer, LabelsContainer, SallesStatusChartContainer, StyledButton } from './styles';
import { Doughnut } from 'react-chartjs-2';
import { primaryColors } from 'theme/styleguide';
import EllipseIcon from 'assets/icons/EllipseIcon';
import { parseNumberToPercentage } from 'utils/parseNumberToPercentage';

export default function SallesStatusChart({
  sallesData = {},
  totalQuantity = 0,
}) {
  const chartRef = useRef()
  const colorDictionary = {
    "Aprovada": primaryColors.green_graph,
    "Aguardando Pagamento": primaryColors.yellow_graph,
    "Reprovada": primaryColors.gray_graph,
    "Em Monitoramento": primaryColors.blue_graph,
    "Em disputa": primaryColors.orange_graph,
    "Chargeback": primaryColors.red_graph,
    "Cancelada": primaryColors.gray_graph_2
  }

  const dataFinal = {
    labels: [...Object.keys(sallesData).map((e) => e)],
    datasets: [{
      data: Object.values(sallesData),
      backgroundColor: [...Object.keys(sallesData).map((e) => colorDictionary[e] || primaryColors.blue_5)],
      cutout: "88%",
      spacing: 4,
      hoverOffset: 2,
      offset: -2,
      hoverBorderWidth: 0,
      rotation: 270,
    }],
  }
  return <>
    <SallesStatusChartContainer>
      <CanvasContainer>
        <Doughnut
          data={dataFinal}
          options={{
            plugins: {
              legend: {
                display: false,
                labels: {
                  display: false
                }
              },
              tooltip: {
                callbacks: {
                  label: function (tooltipItems) {
                    return ` ${tooltipItems.label}: ${tooltipItems.formattedValue} (${parseNumberToPercentage(tooltipItems.formattedValue, totalQuantity)})`;
                  }
                },
              },
            },
            maintainAspectRatio: false,
          }}
          ref={chartRef}
        />
      </CanvasContainer>
      <LabelsContainer>
        {
          Object.keys(colorDictionary).map((e, i) => {
            return (
              <LabelElementContainer
                key={i}
              >
                <StyledButton
                  startIcon={
                    <EllipseIcon color={colorDictionary[e] || primaryColors.blue_5} />
                  }
                  variant="text"
                  disableRipple
                >
                  {e}
                </StyledButton>
              </LabelElementContainer>
            )
          })
        }
      </LabelsContainer>
    </SallesStatusChartContainer>
  </>;
}
