import { useCallback, useEffect, useState } from 'react';
import { object, string } from 'yup';
import { useRef } from 'react';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { parseStringToCPF } from 'utils/parseStringToCPF';
import { fetchGetDocumentStatus } from 'services/Document/GET/GetDocumentStatusService';
import { parseDataURLtoFile } from 'utils/parseDataURLtoFile';
import { fetchUploadDocument } from 'services/Document/POST/UploadDocumentService';
import { GAEventTracker } from 'utils/GAEventTracker';

export default function useUserFacePictureUploadSetup({
  afterSubmit = () => { },
}) {
  const mounted = useRef(false);
  const [isLoading, setLoading] = useState(true)
  const [userData, setData] = useState({})
  const [documentRequired, setDocumentRequired] = useState(false)
  const [documentStatus, setDocumentStatus] = useState()
  const initialValues = {
    selfiePhoto: "",
  }
  const validationSchema = object({
    selfiePhoto: string()
      .required(),
  })

  const fetchAccountData = useCallback(async () => {
    let { name, cpf } = await fetchGetAccountMyAccount()
    if (mounted.current) {
      setData({
        name,
        cpf: parseStringToCPF(cpf),
      })
      setLoading(false)
    }
  }, [])

  const getDocumentStatus = useCallback(async () => {
    fetchGetDocumentStatus("selfie")
      .then((data) => {
        if (mounted.current) {
          setDocumentStatus(data)
          if (["pending", "approved"].includes(data?.status?.tag)) {
            setDocumentRequired(false);
          } else {
            setDocumentRequired(true)
          }
        }
      })
  }, [])

  const handleSubmit = async ({
    selfiePhoto,
  }) => {
    if (selfiePhoto) {
      const fileSelfiePhoto = await parseDataURLtoFile(selfiePhoto, "selfiePhoto")
      await fetchUploadDocument({
        file: fileSelfiePhoto,
        group: "selfie",
        document_identify: "selfie",
      })
      GAEventTracker({
        category: "Onboarding",
        action: "Anexar foto do rosto",
        label: "Anexar uma foto do rosto e salvar"
      })
    }
    afterSubmit()
  }

  useEffect(() => {
    fetchAccountData()
    getDocumentStatus()
  }, [fetchAccountData, getDocumentStatus])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    userData,
    documentRequired,
    documentStatus,
  })
}