import Alert from "@material-ui/lab/Alert";
import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const DashboardSettingsContainer = styled.div`
  margin-top: 16px;
  @media (min-width: 1024px)
  {
    margin-top: 24px;
  }
`
export const MenuTitle = styled.span`
  display: block;
  ${textStyles.tray_h4_18px}
  color: ${grayScale.black};
  @media (min-width: 768px)
  {
    ${textStyles.tray_h3_24px}
  }
`
export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 56px;
  button{
    margin-right: 16px;
  }
  @media (min-width: 768px)
  {
    margin-bottom: 88px;
  }
`
export const StyledAlert = styled(Alert)`
  margin-bottom: 32px;
  max-width: fit-content;
  min-height: 57px;
  align-items: center;
`