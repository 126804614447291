import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchConfiguration = async ({
  registry
}) => {
  const store_access_token = getDataStorage('store_access_token')
  const dynamicBaseURL = getDataStorage('store_URL')
  return apiPrivate.get("/store_api/configuration", {
    params: {
      store_access_token,
      dynamicBaseURL,
      registry,
    }
  })
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}