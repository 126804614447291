import apiPrivate from 'services/apiPrivate'
import { getStoredCredential } from 'utils/getStoredCredential'

export const fetchCreateSellerSplits = async (splits) => {

  return apiPrivate.post("/seller_splits/create", {
    ...getStoredCredential(),
    splits,
  })
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}