import { Button } from "@material-ui/core";
import styled from "styled-components";
import { buttonStyles, grayScale, primaryColors, textStyles } from "theme/styleguide";

export const FirstFoldContainer = styled.div`
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: ${primaryColors.landing.brand.primary[25]};
  border-bottom: 5px solid #00A3FF;
`
export const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
`
export const LeftContent = styled.div`
  flex: 1;
  display: flex;
  min-width: 600px;
  flex-direction: column;
  margin-left: 8.5%;
  span{
    display: block;
  }
`
export const RightContent = styled.div`
  flex: 1;
  text-align: end;
`
export const Title = styled.span`
  max-width: 560px;
  ${textStyles.landing.page_title}
  margin-bottom: 24px;
  >span.highlight{
    color: ${primaryColors.landing.brand.secondary[800]};
  }
`
export const Subtitle = styled.span`
  max-width: 560px;
  ${textStyles.landing.page_subtitle}
  margin-bottom: 32px;
`
export const BenefitsTitle = styled.span`
  ${textStyles.landing.inner_title}
  margin-bottom: 24px;
`
export const BenefitItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  >i{
    margin-right: 12px;
  }
`
export const BenefitDescription = styled.span`
  max-width: 430px;
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${grayScale.landing.neutral[700]};
  >b{
    font-weight: 700;
    color: ${primaryColors.landing.brand.primary[800]}
  }
`
export const StyledImage = styled.img`
  width: 100%;
  max-width: 508px;
  margin-top: 32px;
`
export const StyledButton = styled(Button)`
  display: block;
  ${buttonStyles.landing_blue}
  margin: 0 auto;
`