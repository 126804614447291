import { Button, darken, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const CompleteRegisterWarningDialogContainer = styled.div`
  
`
export const StyledDialog = styled(Dialog)`
  .MuiPaper-root{
    box-shadow: none;
  }
  .MuiDialog-paperWidthSm {
    max-width: 664px;
  }
  .MuiDialog-paper {
    margin: 32px 16px;
  }
  @media (min-width: 768px)
  {
    .MuiDialog-paper {
      margin: 32px;
    }
  }
`
export const DialogHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
`
export const Title = styled.span`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  text-align: center;
  padding: 0px 32px;
  margin-top: 24px;
  @media (min-width: 768px)
  {
    font-size: 28px;
    line-height: 40px;
    font-weight: 700;
    padding: 0px 40px;
  }
`
export const StyledDialogContent = styled(DialogContent)`
  padding: 0px 32px;
  @media (min-width: 768px)
  {
    padding: 0px 40px;
  }
`
export const Instructions = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  font-size: 16px;
  text-align: center;
  margin-bottom: 32px;
`
export const StyledDialogActions = styled(DialogActions)`
  display: flex;
  flex-direction: column;
  padding: 0px 32px;
  padding-bottom: 32px;
  @media (max-width: 767px)
  {
    >button{
      margin-left: 0px !important;
      :last-child{
        margin-top: 16px;
      }
    }
  }
  @media (min-width: 768px)
  {
    padding: 0px 40px;
    padding-bottom: 40px;
    flex-direction: row;
    justify-content: center;
  }
`
export const StyledButton = styled(Button)`
  width: 100%;
  max-width: 100%;
  height: 48px;
  font-weight: bold;
  border-color: ${({ color }) => color !== "primary" ? "#0421B4" : grayScale.white};
  color: ${({ color }) => color === "primary" ? grayScale.white : "#0421B4"};
  :hover{
    background: ${({ color }) => color === "primary" ? darken("#0421B4", .3) : grayScale.white};
  }
  @media (min-width: 920px)
  {
    max-width: fit-content;
  }
`
