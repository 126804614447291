import styled from "styled-components";
import { Divider } from "@material-ui/core";
import { grayScale, textStyles } from "theme/styleguide";

export const MyAccountMenuContainer = styled.div`
  
`
export const DashboardTitle = styled.span`
  display: block;
  ${textStyles.tray_h4_18px}
  color: ${grayScale.black};
  margin-bottom: ${({ $hasMarginBottom }) => $hasMarginBottom ? "32px" : "0px"};
  @media (min-width: 768px)
  {
    ${textStyles.tray_h3_24px}
  }
`
export const StyledDivider = styled(Divider)`
  margin-top: 16px;
  margin-bottom: 42px;
  @media (min-width: 1024px)
  {
    margin-top: 24px;
    margin-bottom: 72px;
  }
`