import { useCallback, useEffect, useState, useRef } from 'react';
import { fetchUpdateSigninProfilePicture } from 'services/Account/POST/UpdateSigninProfilePictureService';
import { fetchGetSigninUser } from 'services/Signin/GET/GetSigninUserService';
import { parseDataURLtoFile } from 'utils/parseDataURLtoFile';
import { object, string } from 'yup';

export default function useUserProfilePictureDataSetup({
  reloadAfterSubmit = false,
  afterSubmit = () => { },
}) {
  const mounted = useRef(false)
  const [initialValues, setInitialValues] = useState({})
  const [oldValues, setOldValues] = useState({})
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const validationSchema = object({
    profilePicture: string()
      .required('Este campo é obrigatório'),
  })

  const fetchData = useCallback(async () => {
    const { photo } = await fetchGetSigninUser()

    const initialValuesObject = {
      profilePicture: photo || "",
    }

    if (mounted.current) {
      setInitialValues(initialValuesObject)
      setOldValues(initialValuesObject)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async ({
    profilePicture,
    deletePhoto = false,
  }) => {
    setLoading(true)
    if (deletePhoto) {
      await fetchUpdateSigninProfilePicture({
        file: "",
      })
    } else {
      const userProfilePictureObject = {}
      if (!!profilePicture && profilePicture !== oldValues?.profilePicture) {
        userProfilePictureObject.file = profilePicture
      }
      if (Object.keys(userProfilePictureObject).length > 0) {
        const fileProfilePicture = await parseDataURLtoFile(userProfilePictureObject.file, "profilepicture")
        await fetchUpdateSigninProfilePicture({
          file: fileProfilePicture,
        })
      }
    }
    setLoading(false)

    if (reloadAfterSubmit) {
      await fetchData()
    }
    setSnackbarOpen(true)
    afterSubmit()
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    isSnackbarOpen,
    setSnackbarOpen,
  })
}