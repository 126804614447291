import { IconButton } from '@material-ui/core';
import AddCircleOutlineBlack from 'assets/icons/AddCircleOutlineBlack';
import RemoveCircleOutilineBlack from 'assets/icons/RemoveCircleOutilineBlack';
import { Field } from 'formik';
import React from 'react';
import { TextNumberIncrementMobileContainer, StyledTextField } from './styles';

export default function TextNumberIncrementMobile({ name, setFieldValue, value, isLoading }) {

  const handleIncrement = () => {
    setFieldValue(name, (parseInt(value) + 1))
  }
  const handleDecrement = () => {
    setFieldValue(name, (parseInt(value) - 1))

  }
  return <>
    <TextNumberIncrementMobileContainer>
      <IconButton onClick={handleDecrement} disabled={isLoading}>
        <RemoveCircleOutilineBlack />
      </IconButton>
      <Field
        name={name}
        as={StyledTextField}
        type="number"
        disabled={isLoading}
      >
      </Field>
      <IconButton
        onClick={handleIncrement}
        disabled={isLoading}
      >
        <AddCircleOutlineBlack />
      </IconButton>
    </TextNumberIncrementMobileContainer>
  </>;
}
