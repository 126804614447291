import React from 'react';
import CustomizeForm from 'components/Onboarding/OnboardingForms/MainSettingsForms/CustomizeForm';
import { useOnboarding } from 'context/OnboardingContext';
import { Form, Formik } from 'formik';
import { ButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import { CustomizeStepContainer } from './styles';
import useCustomizeSetup from 'components/FormsSetup/MainSettings/useCustomizeSetup';
import LoadableButton from 'components/shared/LoadableButton';
import { getOrigemTypeVariantSectionKey } from 'utils/getOrigemTypeVariantSectionKey';
import { getCurrentOnboardingStepsCollection } from 'utils/getCurrentOnboardingStepsCollection';

export default function CustomizeStep() {
  const { mainSettingsStep, nextMainSettingsStep, nextOnboardingSection } = useOnboarding()
  const { initialValues, validationSchema, isLoading, handleSubmit } = useCustomizeSetup({
    afterSubmit: () => {
      const origemTypeVariantSectionKey = getOrigemTypeVariantSectionKey()
      const stepsCollectionLength = getCurrentOnboardingStepsCollection(origemTypeVariantSectionKey).length - 1
      if (mainSettingsStep < stepsCollectionLength) {
        nextMainSettingsStep()
      } else {
        nextOnboardingSection()
      }
    },
    fromOnboarding: true
  })

  return <>
    <CustomizeStepContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <CustomizeForm
                {...formikProps}
                isLoading={isLoading}
              />
              <ButtonsContainer>
                <LoadableButton
                  type="submit"
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_config_personalizacao_onboarding_botao_continuar"}
                >
                  Continuar
                </LoadableButton>
              </ButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </CustomizeStepContainer>
  </>;
}