import React from 'react';
import { DropdownCardContainer, StyledAccordion, StyledAccordionDetails, StyledAccordionSummary, Title } from './styles';
import StorefrontIcon from 'assets/icons/StorefrontIcon';
import ExpandMoreIcon from 'assets/icons/ExpandMoreIcon';

export default function DropdownCard({
  title = "",
  children,
  ...props
}) {
  return <>
    <DropdownCardContainer {...props}>
      <StyledAccordion>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <StorefrontIcon className="nonClickable" />
          <Title>
            {title}
          </Title>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {children}
        </StyledAccordionDetails>
      </StyledAccordion>
    </DropdownCardContainer>
  </>;
}
