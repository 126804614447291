import diners from 'assets/images/flags/diners.png'
import visa from 'assets/images/flags/visa.png'
import master from 'assets/images/flags/master.png'
import amex from 'assets/images/flags/amex.png'
import elo from 'assets/images/flags/elo.png'
import hipercard from 'assets/images/flags/hipercard.png'
import hiper from 'assets/images/flags/hiper.png'
import discover from 'assets/images/flags/discover.png'
import aura from 'assets/images/flags/aura.png'
import jcb from 'assets/images/flags/jcb.png'

export const paymentMethodIdDictionary = {
  3: {
    name: "Visa",
    icon: visa,
  },
  4: {
    name: "Mastercard",
    icon: master,
  },
  2: {
    name: "Diners",
    icon: diners,
  },
  5: {
    name: "American Express",
    icon: amex,
  },
  15: {
    name: "Discover",
    icon: discover,
  },
  16: {
    name: "Elo",
    icon: elo,
  },
  18: {
    name: "Aura",
    icon: aura,
  },
  19: {
    name: "JCB",
    icon: jcb,
  },
  20: {
    name: "Hipercard",
    icon: hipercard,
  },
  25: {
    name: "Hiper",
    icon: hiper,
  },
  26: {
    name: "Elo - Corona Voucher",
    icon: elo,
  },
}