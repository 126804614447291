/* eslint-disable max-len */
import React from 'react';

export default function BorderColorIcon({ size = 1, color = "#2E7EE7" }) {
  return <>
    <i>
      <svg
        width={30 * size}
        height={30 * size}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.0125 11.1758L16.325 6.48828L5 17.8133V22.5008H9.6875L21.0125 11.1758ZM7.5 20.0008V18.8508L16.325 10.0258L17.475 11.1758L8.65 20.0008H7.5Z"
          fill={color}
        />
        <path
          d="M24.6391 7.55C25.1266 7.0625 25.1266 6.275 24.6391 5.7875L21.7141 2.8625C21.4641 2.6125 21.1516 2.5 20.8266 2.5C20.5141 2.5 20.1891 2.625 19.9516 2.8625L17.6641 5.15L22.3516 9.8375L24.6391 7.55Z"
          fill={color}
        />
        <path
          d="M27.5 25H2.5V30H27.5V25Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
