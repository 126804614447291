import { Button, darken } from "@material-ui/core";
import styled from "styled-components";
import { shadowStyles } from "theme/styleguide";

export const CompleteRegisterProgressBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 32px;
  margin-top: 32px;
  margin-bottom: 16px;
  border-radius: 4px;
  ${shadowStyles.drop_shadow_6}
  @media (min-width: 920px)
  {
    justify-content: space-between;
    margin-top: 0px;
  }
`
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: 920px)
  {
    max-width: 824px;
  }
`
export const RightContainer = styled.div`
  display: none;
  @media (min-width: 920px)
  {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
export const StyledButton = styled(Button)`
  min-width: 100%;
  height: 48px;
  font-weight: bold;
  border-radius: 4px;
  background: #0421B4;
  :hover{
    background: ${darken("#0421B4", .3)};
  }
  @media (min-width: 920px)
  {
    min-width: 152px;
    margin-top: 8px;
  }
`
export const Title = styled.span`
  display: none;
  color: #2A2A3C;
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.8px;
  margin-bottom: 16px;
  @media (min-width: 920px)
  {
    display: block;
  }
`
export const Subtitle = styled.span`
  display: none;
  color: #4E4E65;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-bottom: 16px;
  @media (min-width: 920px)
  {
    display: block;
  }
`
export const AlternativeTitle = styled.span`
  display: block;
  color: #2A2A3C;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4px;
  margin-bottom: 24px;
  @media (min-width: 920px)
  {
    display: none;
  }
`
export const BenefitsList = styled.div`
  display: none;
  @media (min-width: 920px)
  {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`
export const BenefitItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 400px;
  padding: 16px;
  margin-bottom: 16px;
  :nth-child(odd){
    margin-right: 24px;
  }
  ${shadowStyles.drop_shadow_5}
  border-radius: 4px;
`
export const BenefitTitle = styled.span`
  color: #2A2A3C;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-left: 16px;
`
export const PercentageContainer = styled.div`
    display: flex;
  justify-content: center;
  align-items: center;
  width: 184px;
  height: 184px;
  border-radius: 4px;
  box-shadow: 0px 4px 20px 0px rgba(1, 33, 105, 0.16);
`
export const PercentageIndicator = styled.div`
    display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;
  border-radius: 9999px;
  border: solid 8px #007A56;
  border-bottom-color: transparent;
  border-right-color: #D9F4EC;
  rotate: -45deg;
`
export const PercentageIndicatorOffset = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;
  border-radius: 9999px;
  border: solid 8px #007A56;
  border-bottom-color: #D9F4EC;
  border-right-color: transparent;
  rotate: -50deg;
`
export const PercentageIndicatorText = styled.span`
  rotate: 95deg;
  color: var(--neutral-600, #4E4E65);
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.4px;
  white-space: pre-wrap;
  text-align: center;
  >b{  
    font-size: 28px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.8px;
  }
`