/* eslint-disable max-len */
import React from 'react';

export default function LandingSquareCheckIcon({ size = 1, color = "#001A9E" }) {
  return <>
    <i>
      <svg
        width={67 * size}
        height={64 * size}
        viewBox="0 0 67 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.8921 6.3816C65.3517 7.81631 65.372 10.1627 63.9373 11.6223L34.5795 41.4907C33.8828 42.1995 32.9305 42.5988 31.9366 42.5988C30.9426 42.5988 29.9903 42.1995 29.2936 41.4907L20.4863 32.5302C19.0516 31.0705 19.0718 28.7241 20.5315 27.2894C21.9911 25.8547 24.3375 25.875 25.7722 27.3346L31.9366 33.6062L58.6514 6.42678C60.0861 4.96712 62.4324 4.94689 63.8921 6.3816Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6058 9.41176C11.0239 9.41176 10.4658 9.64291 10.0544 10.0544C9.64293 10.4658 9.41177 11.0239 9.41177 11.6058V52.9052C9.41177 53.4871 9.64293 54.0452 10.0544 54.4566C10.4658 54.8681 11.0239 55.0992 11.6058 55.0992H52.9052C53.4871 55.0992 54.0452 54.8681 54.4566 54.4566C54.8681 54.0452 55.0993 53.4871 55.0993 52.9052V32.2555C55.0993 30.2088 56.7584 28.5496 58.8051 28.5496C60.8518 28.5496 62.511 30.2088 62.511 32.2555V52.9052C62.511 55.4528 61.499 57.8961 59.6975 59.6975C57.8961 61.499 55.4528 62.511 52.9052 62.511H11.6058C9.05818 62.511 6.61491 61.499 4.81347 59.6975C3.01204 57.8961 2 55.4528 2 52.9052V11.6058C2 9.05817 3.01204 6.6149 4.81347 4.81346C6.61491 3.01202 9.05819 1.99998 11.6058 1.99998H44.0553C46.1021 1.99998 47.7612 3.65917 47.7612 5.70587C47.7612 7.75257 46.1021 9.41176 44.0553 9.41176H11.6058Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
