import { TextField } from "@material-ui/core";
import MoneyTextField from "components/shared/MoneyTextField";
import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const AdditionalDataFormContainer = styled.div`
  
`
export const SectionTitle = styled.span`
  ${textStyles.tray_h5_15px}
  font-weight: bold;
  color: ${grayScale.gray_3};
  margin-bottom: 24px;
  @media (min-width: 768px)
  {
    ${textStyles.tray_h4_18px}
  }
`
export const StyledTextField = styled(TextField)`
  width: 100%;
  max-width: 100%;
  min-height: 64px;
  border-radius: 4px;
  .MuiFormHelperText-contained{
    margin-bottom: 16px;
    white-space: pre-wrap;
  }
  @media (min-width: 768px)
  {
        max-width: ${({ $fullWidth }) => $fullWidth ? "100%" : "50%"};
    padding-right: 20px;
  }
`
export const StyledMoneyTextField = styled(MoneyTextField)`
  width: 100%;
  max-width: 100%;
  min-height: 64px;
  border-radius: 4px;
  .MuiFormHelperText-contained{
    margin-bottom: 16px;
    white-space: pre-wrap;
  }
  @media (min-width: 768px)
  {
    max-width: 100%;
    padding-right: 20px;
  }
`