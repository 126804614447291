import React from 'react';
import { EmptyTitle, EmptySubTitle, EmptyDataContainer } from './styles';

export default function EmptyData({ Img, title, subTitle }) {
  return (
    <EmptyDataContainer>
      <Img />
      <EmptyTitle>
        {title}
      </EmptyTitle>
      <EmptySubTitle>
        {subTitle}
      </EmptySubTitle>
    </EmptyDataContainer>
  )
}