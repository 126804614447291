import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchGetBusinessPartnersList = async (
  getMasterPartnerOnly = false,
) => {
  const code = getDataStorage('yapay_code')
  return apiPrivate.get("/myaccount/business_partners", {
    headers: {
      code
    }
  })
    .then(({ data }) => {
      if (getMasterPartnerOnly) {
        return data?.data?.filter((e) =>
          e?.profile_type?.toLowerCase() === "master")?.[0] ||
          {}
      } else {
        return data?.data?.filter((e) =>
          e?.profile_type?.toLowerCase() === "regular") ||
          []
      }
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}