export const getBiggestParcelValueWithoutFee = (receivedSellerSplitList) => {
  return receivedSellerSplitList.map((e) => {
    if (e.splits?.split?.length > 0) { // Verifica se a propriedade split é um array de múltiplas parcelas (do contrário haverá apenas uma propriedade-filha split_transaction)
      return e.splits.split.filter((f) => ( // Filtra as parcelas em busca das que possuam o valor da propriedade percentage igual à 0
        parseInt(f.percentage) === 100
      )).reverse()[0]?.split_transaction || // Retorna a maior parcela sem juros possível
        e.splits?.split[0].split_transaction // Caso não encontre ao menos uma parcela sem juros, retorna por padrão o valor 1 (uma vez sem juros)
    } else {
      return e.splits.split.split_transaction // Retorna a propriedade-filha split_transaction caso a propriedade split não seja um array de múltiplas parcelas
    }
  })
}