import apiPrivate from 'services/apiPrivate'
import { getStoredCredential } from 'utils/getStoredCredential'

export const fetchUpdateAccountSellerSettings = async (body) => {

  return apiPrivate.post("/accounts_seller_settings/update", {
    ...body,
  }, {
    headers: {
      ...getStoredCredential(),
    }
  })
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}