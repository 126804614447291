import React from 'react';
import { EnterpriseProofingUploadMenuItemContainer } from './styles';
import { SameSizeButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import EnterpriseProofingUploadForm from 'components/Onboarding/OnboardingForms/AccountVerificationForms/EnterpriseProofingUploadForm';
import { Form, Formik } from 'formik';
import useEnterpriseProofingUploadSetup from 'components/FormsSetup/AccountVerification/useEnterpriseProofingUploadSetup';
import LoadableButton from 'components/shared/LoadableButton';
import { Button } from '@material-ui/core';
import CancelFormFillingDialog from 'components/CompleteRegisterMenu/shared/CancelFormFillingDialog';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import { getDataStorage } from 'utils/storage';

export default function EnterpriseProofingUploadMenuItem() {
  const { setCurrentCompleteRegisterMenuItem, goBackToCompleteRegisterMenu, toggleCancelFormFillingDialog, updateCompletedRegisterStep } = useDashboardMyAccount()
  const { initialValues, validationSchema, isLoading, handleSubmit, enterpriseData, documentRequired, documentStatus } = useEnterpriseProofingUploadSetup({
    // TO-DO: Remover quando definido novo fluxo
    // afterSubmit: () => {
    //   setCurrentCompleteRegisterMenuItem("attorneyPowerUpload")
    // },
    // afterSubmitAlternative: () => {
    //   const hasPartnersWithOver25Percent = JSON.parse(getDataStorage('hasPartnersWithOver25Percent') || null)
    //   if (hasPartnersWithOver25Percent) {
    //     setCurrentCompleteRegisterMenuItem("partnersDocumentsUpload")
    //   } else {
    //     updateCompletedRegisterStep("documentUploadChain")
    //     goBackToCompleteRegisterMenu()
    //   }
    // },
    afterSubmit: () => {
      const hasPartnersWithOver25Percent = JSON.parse(getDataStorage('hasPartnersWithOver25Percent') || null)
      if (hasPartnersWithOver25Percent) {
        setCurrentCompleteRegisterMenuItem("partnersDocumentsUpload")
      } else {
        updateCompletedRegisterStep("documentUploadChain")
        goBackToCompleteRegisterMenu()
      }
    },
  })
  return <>
    <EnterpriseProofingUploadMenuItemContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={documentRequired && validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <EnterpriseProofingUploadForm
                isLoading={isLoading}
                enterpriseData={enterpriseData}
                documentStatus={documentStatus}
                documentRequired={documentRequired}
                {...formikProps}
              />
              <SameSizeButtonsContainer
                $shouldNotReverseColumn
              >
                <LoadableButton
                  type="submit"
                  disabled={(documentRequired && !formikProps.isValid) || (formikProps.isSubmitting || isLoading)}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_completar_cadastro_doc_constituicao_botao_enviar"}
                >
                  Continuar
                </LoadableButton>
                <Button
                  variant="outlined"
                  disabled={formikProps.isSubmitting || !documentRequired || isLoading}
                  onClick={toggleCancelFormFillingDialog}
                  id={"tela_completar_cadastro_doc_constituicao_botao_cancelar"}
                >
                  Cancelar
                </Button>
              </SameSizeButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </EnterpriseProofingUploadMenuItemContainer>
    <CancelFormFillingDialog
      cancelFillingFormFunction={goBackToCompleteRegisterMenu}
    />
  </>;
}
