import apiPrivate from 'services/apiPrivate'
import { getStoredCredential } from 'utils/getStoredCredential'

export const fetchSetAccountEmailTypes = async ({
  hasOrderStatus,
  hasSaleRecovery,
  hasOrderTracking,
}) => {

  return apiPrivate.post("/v3/account_email_types/update", {
    "account_email_types": [
      {
        "email_type_id": 1,
        "allowed": hasOrderStatus
      },
      {
        "email_type_id": 3,
        "allowed": hasSaleRecovery
      },
      {
        "email_type_id": 5,
        "allowed": hasOrderTracking
      }
    ]
  }, {
    headers: {
      ...getStoredCredential(),
    },
  })
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}