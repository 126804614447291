import PersonIcon from 'assets/icons/PersonIcon';
import React from 'react';
import { UserInfoDisplayerContainer, IdentifierDisplayer, NameDisplayer } from './styles';

export default function UserInfoDisplayer({
  receivedName = "",
  receivedIdentifier = "",
  isBussinesDisplayer = false,
  icon = <PersonIcon />,
}) {
  return <>
    <UserInfoDisplayerContainer>
      {icon}
      <div>
        <NameDisplayer>
          {receivedName}
        </NameDisplayer>
        <IdentifierDisplayer>
          {`${isBussinesDisplayer ? "CNPJ" : "CPF"}: ${receivedIdentifier}`}
        </IdentifierDisplayer>
      </div>
    </UserInfoDisplayerContainer>
  </>;
}
