import apiPrivate from 'services/apiPrivate'
import { getStoredCredential } from 'utils/getStoredCredential'

export const fetchGetStatements = async (start_date, end_date) => {
  return apiPrivate.get("/v3/statements", {
    headers: {
      'content-type': 'application/json',
      ...getStoredCredential(),
    },
    params: {
      start_date,
      end_date,
    }
  })
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}