import styled from "styled-components";
import { IconButton } from '@material-ui/core';
import { grayScale, textStyles } from "theme/styleguide";

export const StepFormHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (min-width: 768px)
  {
    margin-bottom: 72px;
  }
`
export const SettingsTitle = styled.h4`
  ${textStyles.tray_h4_18px}
  color: ${grayScale.black};
  @media (min-width: 768px)
  {
    ${textStyles.tray_h3_24px}
    margin-top: 0px;
    margin-bottom: 0px;
  }
`
export const StyledIconButton = styled(IconButton)`
  margin-right: 16px;
`