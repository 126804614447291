import apiPrivate from 'services/apiPrivate'
import { formatPhone } from 'utils/formatPhone'
import { getDataStorage } from 'utils/storage'

export const fetchPhoneList = async () => {
  const code = getDataStorage('yapay_code')
  return apiPrivate.get("/myaccount/phones", {
    headers: {
      code
    }
  })
    .then(({ data }) => {
      const res = data?.data?.map((e) => {
        return {
          id: e.id,
          phoneType: e.phone_type.tag,
          phoneNumber: formatPhone(e.phone),
          isNewRegister: false,
          isUpdatedRegister: false,
        }
      })?.reverse() || []
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}