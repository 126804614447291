import { useDashboard } from 'context/DashboardContext';
import { createContext, useContext, useEffect, useState } from 'react';
import { deleteDataStorage, getDataStorage, setDataStorage } from 'utils/storage';
const DashboardMyAccountContext = createContext();

export default function DashboardMyAccountProvider({ children }) {
  const { setCurrentTab } = useDashboard()
  const [currentMyAccountMenuItem, setCurrentMyAccountMenuItem] = useState("accountDataMenu")
  const [currentAccountVerificationMenuItem, setCurrentAccountVerificationMenuItem] = useState("menu")
  const [currentCompleteRegisterMenuItem, setCurrentCompleteRegisterMenuItem] = useState("menu")
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(false)
  const [isCancelFormFillingDialogOpen, setCancelFormFillingDialogOpen] = useState(false)
  const [completedRegisterSteps, setCompletedRegisterSteps] = useState({
    additionalData: JSON.parse(getDataStorage("completedAdditionalDataStep") || false),
    documentUploadChain: JSON.parse(getDataStorage("completedDocumentUploadChainStep") || false),
    completeBusinessAccountTitularData: JSON.parse(getDataStorage("completedCompleteBusinessAccountTitularDataStep") || false),
    partnersDataPrelude: JSON.parse(getDataStorage("completedPartnersDataPreludeStep") || false),
  })
  const [selectedAccountDataMenuTab, setSelectedAccountDataMenuTab] = useState(0)
  const [isAccountDataMenuFormsDisabled, setAccountDataMenuFormsDisabled] = useState(true)
  const [completeRegisterNow, setCompleteRegisterNow] = useState(JSON.parse(getDataStorage("completeRegisterNow") || false))
  const [selectedPartnerId, setSelectedPartnerId] = useState()

  const goBackToMyAccountMenu = () => {
    setCurrentMyAccountMenuItem("myAccount")
  }

  const goBackToAccountDataMenu = () => {
    setCurrentMyAccountMenuItem("accountDataMenu")
  }

  const goBackToUserDataMenu = () => {
    setCurrentMyAccountMenuItem("userDataMenu")
  }

  const goBackToAccountVerificationMenu = () => {
    setCurrentAccountVerificationMenuItem("menu")
  }

  const goBackToCompleteRegisterMenu = () => {
    setCurrentCompleteRegisterMenuItem("menu")
  }

  const toggleCancelFormFillingDialog = () => {
    setCancelFormFillingDialogOpen((oldValue) => !oldValue)
  }

  const updateCompletedRegisterStep = (key) => {
    setCompletedRegisterSteps((oldValue) => ({
      ...oldValue,
      [key]: true,
    }))
  }

  const toggleAccountDataMenuFormsDisabled = () => {
    setAccountDataMenuFormsDisabled((oldValue) => !oldValue)
  }

  useEffect(() => {
    setDataStorage("completedAdditionalDataStep", completedRegisterSteps.additionalData)
    setDataStorage("completedDocumentUploadChainStep", completedRegisterSteps.documentUploadChain)
    setDataStorage("completedCompleteBusinessAccountTitularDataStep", completedRegisterSteps.completeBusinessAccountTitularData)
    setDataStorage("completedPartnersDataPreludeStep", completedRegisterSteps.partnersDataPrelude)
  }, [completedRegisterSteps])

  useEffect(() => {
    setAccountDataMenuFormsDisabled(true)
  }, [selectedAccountDataMenuTab])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [currentMyAccountMenuItem, currentAccountVerificationMenuItem, currentCompleteRegisterMenuItem])

  useEffect(() => {
    if (completeRegisterNow) {
      setCurrentTab("myaccount")
      setCurrentMyAccountMenuItem("completeRegister")
    }
    else {
      deleteDataStorage("completeRegisterNow")
      setCurrentTab("overview")
    }
  }, [completeRegisterNow, setCurrentTab])

  return <>
    <DashboardMyAccountContext.Provider
      value={{
        currentMyAccountMenuItem,
        setCurrentMyAccountMenuItem,
        goBackToMyAccountMenu,
        goBackToAccountDataMenu,
        goBackToUserDataMenu,
        currentAccountVerificationMenuItem,
        setCurrentAccountVerificationMenuItem,
        goBackToAccountVerificationMenu,
        currentCompleteRegisterMenuItem,
        setCurrentCompleteRegisterMenuItem,
        goBackToCompleteRegisterMenu,
        isSnackbarOpen,
        setSnackbarOpen,
        snackbarMessage,
        setSnackbarMessage,
        isCancelFormFillingDialogOpen,
        toggleCancelFormFillingDialog,
        completedRegisterSteps,
        updateCompletedRegisterStep,
        selectedAccountDataMenuTab,
        setSelectedAccountDataMenuTab,
        isAccountDataMenuFormsDisabled,
        toggleAccountDataMenuFormsDisabled,
        completeRegisterNow,
        setCompleteRegisterNow,
        selectedPartnerId,
        setSelectedPartnerId,
      }}>
      {children}
    </DashboardMyAccountContext.Provider>
  </>;
}

export function useDashboardMyAccount() {
  const {
    currentMyAccountMenuItem,
    setCurrentMyAccountMenuItem,
    goBackToMyAccountMenu,
    goBackToAccountDataMenu,
    goBackToUserDataMenu,
    currentAccountVerificationMenuItem,
    setCurrentAccountVerificationMenuItem,
    goBackToAccountVerificationMenu,
    currentCompleteRegisterMenuItem,
    setCurrentCompleteRegisterMenuItem,
    goBackToCompleteRegisterMenu,
    isSnackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    setSnackbarMessage,
    isCancelFormFillingDialogOpen,
    toggleCancelFormFillingDialog,
    completedRegisterSteps,
    updateCompletedRegisterStep,
    selectedAccountDataMenuTab,
    setSelectedAccountDataMenuTab,
    isAccountDataMenuFormsDisabled,
    toggleAccountDataMenuFormsDisabled,
    completeRegisterNow,
    setCompleteRegisterNow,
    selectedPartnerId,
    setSelectedPartnerId,
  } = useContext(DashboardMyAccountContext)
  return {
    currentMyAccountMenuItem,
    setCurrentMyAccountMenuItem,
    goBackToMyAccountMenu,
    goBackToAccountDataMenu,
    goBackToUserDataMenu,
    currentAccountVerificationMenuItem,
    setCurrentAccountVerificationMenuItem,
    goBackToAccountVerificationMenu,
    currentCompleteRegisterMenuItem,
    setCurrentCompleteRegisterMenuItem,
    goBackToCompleteRegisterMenu,
    isSnackbarOpen,
    setSnackbarOpen,
    snackbarMessage,
    setSnackbarMessage,
    isCancelFormFillingDialogOpen,
    toggleCancelFormFillingDialog,
    completedRegisterSteps,
    updateCompletedRegisterStep,
    selectedAccountDataMenuTab,
    setSelectedAccountDataMenuTab,
    isAccountDataMenuFormsDisabled,
    toggleAccountDataMenuFormsDisabled,
    completeRegisterNow,
    setCompleteRegisterNow,
    selectedPartnerId,
    setSelectedPartnerId,
  }
}
