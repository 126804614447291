/* eslint-disable max-len */
import React from 'react';

export default function RequestQuoteIcon({ size = 1, color = "#2E7EE7" }) {
  return <>
    <i>
      <svg
        width={30 * size}
        height={30 * size}
        viewBox="0 0 30 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.5 2.5H7.5C6.125 2.5 5 3.625 5 5V25C5 26.375 6.125 27.5 7.5 27.5H22.5C23.875 27.5 25 26.375 25 25V10L17.5 2.5ZM7.5 25V5H16.25V10H22.5V25H7.5ZM13.75 23.75H16.25V22.5H17.5C18.1875 22.5 18.75 21.9375 18.75 21.25V17.5C18.75 16.8125 18.1875 16.25 17.5 16.25H13.75V15H18.75V12.5H16.25V11.25H13.75V12.5H12.5C11.8125 12.5 11.25 13.0625 11.25 13.75V17.5C11.25 18.1875 11.8125 18.75 12.5 18.75H16.25V20H11.25V22.5H13.75V23.75Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
