import { FormControl, Radio } from '@material-ui/core';
import React from 'react';
import { RadioOptionsContainer, StyledFormControlLabel, StyledFormLabel, StyledRadioGroup } from './styles';

export default function RadioOptions({
  title = "",
  options = [],
  receivedValue,
  receivedSetter = () => { },
  containerStyle = {},
  disabled = false,
  isColumn = false,
  ...props
}) {
  const handleChange = (e) => {
    receivedSetter(e.target.value)
  }
  return <>
    <RadioOptionsContainer
      style={containerStyle}
      {...props}
    >
      <FormControl
        disabled={disabled}
      >
        <StyledFormLabel>
          {title}
        </StyledFormLabel>
        <StyledRadioGroup
          aria-label={title}
          value={receivedValue}
          onChange={handleChange}
          $isColumn={isColumn}
        >
          {
            options.map((e, i) => {
              return (
                <StyledFormControlLabel
                  value={e.value}
                  control={<Radio color="primary" />}
                  label={e.label}
                  key={i}
                />
              )
            })
          }
        </StyledRadioGroup>
      </FormControl>
    </RadioOptionsContainer>
  </>;
}
