import { Divider } from '@material-ui/core';
import { DashboardTitle } from 'components/Dashboard/MyAccountMenu/styles';
import SnackbarAlert from 'components/shared/SnackbarAlert';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import UserEmailForm from 'forms/Dashboard/MyAaccountTab/UserDataMenu/shared/UserEmailForm';
import UserNameForm from 'forms/Dashboard/MyAaccountTab/UserDataMenu/shared/UserNameForm';
import UserProfilePictureForm from 'forms/Dashboard/MyAaccountTab/UserDataMenu/shared/UserProfilePictureForm';
import UserVerifiedPhoneNumberForm from 'forms/Dashboard/MyAaccountTab/UserDataMenu/shared/UserVerifiedPhoneNumberForm';
import React from 'react';
import { UserDataMenuContainer, FieldSectionTitle, FieldSectionDescription, NameContainer } from './styles';

export default function UserDataMenu() {
  const { isSnackbarOpen, setSnackbarOpen, snackbarMessage, setSnackbarMessage } = useDashboardMyAccount()

  const handleCloseSnackbar = () => {
    setSnackbarMessage("")
    setSnackbarOpen(false)
  }
  return <>
    <UserDataMenuContainer>
      <DashboardTitle>
        Dados da pessoa usuária
      </DashboardTitle>
      <NameContainer>
        <div>
          <FieldSectionTitle
            $hasBiggerMarginTop
          >
            Nome da pessoa usuária
          </FieldSectionTitle>
          <FieldSectionDescription>
            {`Quem acessa e faz movimentações na conta.\nPode ser a mesma pessoa titular responsável pela conta ou outra pessoa com permissão para fazer os acessos.`}
          </FieldSectionDescription>
          <UserNameForm />
        </div>
        <div>
          <UserProfilePictureForm />
        </div>
      </NameContainer>
      <Divider />
      <FieldSectionTitle>
        E-mail de acesso à conta
      </FieldSectionTitle>
      <UserEmailForm />
      <Divider />
      <FieldSectionTitle>
        Celular verificado
      </FieldSectionTitle>
      <FieldSectionDescription>
        {`É o número de celular que receberá SMS com códigos para autorização de ações específicas na conta, como edição de alguns dados de cadastro. Sem este número de celular verificado, algumas ações não podem ser feitas.\n\nRecomenda-se que este número de celular seja da principal pessoa usuária da conta.`}
      </FieldSectionDescription>
      <UserVerifiedPhoneNumberForm />
      <SnackbarAlert
        isOpen={isSnackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </UserDataMenuContainer>
  </>;
}
