import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import styled from "styled-components";
import { borderStyles, buttonStyles, grayScale, textStyles } from "theme/styleguide";

export const PartnersDataFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  @media (min-width: 768px)
  {
    margin-bottom: 104px;
  }
`
export const SectionTitle = styled.span`
  display: block;
  ${textStyles.tray_h5_15px}
  color: ${grayScale.gray_3};
  margin-top: 8px;
  margin-bottom: 24px;
  @media (min-width: 768px)
  {
    ${textStyles.tray_h4_18px}
    margin-top: 0px;
  }
`
export const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  path{
    opacity: 1;
  }
  ${borderStyles.border_2};
  margin-top: 0px !important;
  margin-bottom: 16px !important;
  :before{
    height: 0px;
  }
`
export const StyledAccordionSummary = styled(AccordionSummary)`
  ${textStyles.tray_h5_15px}
  color: ${grayScale.gray_3};
  .MuiAccordionSummary-content{
    justify-content: space-between;
    align-items: center;
    margin-right: 4px;
  }
  @media (min-width: 768px)
  {
    ${textStyles.tray_h4_18px}
    .MuiAccordionSummary-content{
      margin-right: 32px;
    }
    .MuiAccordionSummary-expandIcon{
      margin-right: 8px;
    }
  }
`
export const StyledAccordionDetails = styled(AccordionDetails)`
  display: flex;
  flex-direction: column;
`
export const StyledAlert = styled(Alert)`
  white-space: pre-wrap;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-top: 16px;
  margin-bottom: 24px;
  @media (min-width: 768px)
  {
    margin-top: 24px;
    margin-right: 20px;
  }
`
export const PercentageInstructions = styled.span`
  max-width: 50%;
  ${textStyles.tray_text_14px}
  color:${grayScale.gray_3};
  margin-bottom: 8px;
  padding-right: 20px;
`
export const PercentageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`
export const PPEInstructions = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-top: -16px;
  margin-bottom: 16px;
`
export const PartnersDataSection = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px)
  {
    margin-bottom: 32px;
  }
`
export const StyledButton = styled(Button)`
  align-self: center;
  width: fit-content;
  ${buttonStyles.yapay_bluish_add}
  padding-left: 24px;
  padding-right: 24px;
  @media (min-width: 768px)
  {
    align-self: flex-start;
    min-height: 37px;
  }
`
export const StyledIconButton = styled(IconButton)`
  align-self: flex-end;
  margin-right: -12px;
  margin-bottom: 16px;
  @media (min-width: 768px)
  {
    margin-right: 20px;
  }
`
export const MorePartnersInstructions = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  white-space: pre-wrap;
  margin-bottom: 32px;
  a{
    text-decoration: none;
    font-weight: bold;
    color: ${grayScale.gray_3};
  }
`