/* eslint-disable max-len */
import React from 'react';

export default function VindiPaperMoneyIcon({ size = 1, color = "#0092FA" }) {
  return <>
    <i>
      <svg
        width={24 * size}
        height={24 * size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M19.625 2.00981C19.4964 1.99092 19.3623 1.99655 19.2285 2.0313L3.99023 6.00005H11.9277L17.8848 4.44927C18.1378 4.82126 18.5432 5.06066 18.9922 5.09966L19.2285 6.00005H21.2949L20.4473 2.74809C20.343 2.34759 20.0108 2.06648 19.625 2.00981ZM3 8.00005C2.448 8.00005 2 8.44805 2 9.00005V19C2 19.552 2.448 20 3 20H21C21.552 20 22 19.552 22 19V9.00005C22 8.44805 21.552 8.00005 21 8.00005H3ZM4.91211 10H19.0879C19.1625 10.2104 19.2831 10.4014 19.4409 10.5592C19.5987 10.7169 19.7897 10.8375 20 10.9122V17.0879C19.7897 17.1625 19.5987 17.2831 19.4409 17.4409C19.2831 17.5987 19.1625 17.7897 19.0879 18H4.91211C4.83749 17.7897 4.7169 17.5987 4.55911 17.4409C4.40131 17.2831 4.21031 17.1625 4 17.0879V10.9122C4.21031 10.8375 4.40131 10.7169 4.55911 10.5592C4.7169 10.4014 4.83749 10.2104 4.91211 10ZM12 11C11.2043 11 10.4413 11.3161 9.87868 11.8787C9.31607 12.4413 9 13.2044 9 14C9 14.7957 9.31607 15.5588 9.87868 16.1214C10.4413 16.684 11.2043 17 12 17C12.7956 17 13.5587 16.684 14.1213 16.1214C14.6839 15.5588 15 14.7957 15 14C15 13.2044 14.6839 12.4413 14.1213 11.8787C13.5587 11.3161 12.7956 11 12 11ZM7 13C6.448 13 6 13.447 6 14C6 14.553 6.448 15 7 15C7.552 15 8 14.553 8 14C8 13.447 7.552 13 7 13ZM17 13C16.448 13 16 13.447 16 14C16 14.553 16.448 15 17 15C17.552 15 18 14.553 18 14C18 13.447 17.552 13 17 13Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
