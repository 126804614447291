import React from 'react';
import { FeatureItem, FeatureDescription, FeatureTitle, SecondFoldContainer, Title, FeaturesContainer, ExtraInfo, StyledButton } from './styles';
import LandingThumbsUpIcon from 'assets/icons/LandingThumbsUpIcon';
import LandingDollarSignIcon from 'assets/icons/LandingDollarSignIcon';
import LandingShieldIcon from 'assets/icons/LandingShieldIcon';
import LandingChargebackIcon from 'assets/icons/LandingChargebackIcon';

const featuresList = [
  {
    title: "Plataforma simples e intuitiva",
    description: "Descomplique sua gestão de pagamentos com uma plataforma simples, segura e de fácil navegação.",
    icon: <LandingThumbsUpIcon />,
  },
  {
    title: "Mais visibilidade sobre o seu negócio",
    description: "Tenha visibilidade total dos seus pagamentos, incluindo facilidade para interagir com chargebacks e estornos.",
    icon: <LandingDollarSignIcon />,
  },
  {
    title: "Segurança e proteção contra fraudes",
    description: "Na Vindi você tem análise de risco e ferramenta antifraude integrada para vender sem dor de cabeça.",
    icon: <LandingShieldIcon />,
  },
  {
    title: "Cobertura de chargeback",
    description: "Proteção para vendas de produtos comprovadamente entregues e serviços prestados¹.",
    icon: <LandingChargebackIcon />,
  },
]
export default function SecondFold({
  handleInstallApp = () => { },
  disabled = false,
}) {
  return <>
    <SecondFoldContainer>
      <div>
        <Title>
          Funcionalidades sob medida para o seu negócio!
        </Title>
        <FeaturesContainer>
          {
            featuresList.map((e, i) => {
              return (
                <FeatureItem
                  key={i}
                >
                  {e.icon}
                  <FeatureTitle>
                    {e.title}
                  </FeatureTitle>
                  <FeatureDescription>
                    {e.description}
                  </FeatureDescription>
                </FeatureItem>
              )
            })
          }
        </FeaturesContainer>
        <ExtraInfo>
          (¹)Sujeito a condições comerciais. Solicite contato do nosso time.
        </ExtraInfo>
        <StyledButton
          onClick={handleInstallApp}
          disabled={disabled}
          id={"landing-page-plugin_botao_instale-e-comece-a-usar-1"}
        >
          Instale e comece a usar
        </StyledButton>
      </div>
    </SecondFoldContainer>
  </>;
}
