import { FormControlLabel, FormLabel, RadioGroup } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const RadioOptionsContainer = styled.div`
  margin-bottom: 8px;
`
export const StyledFormLabel = styled(FormLabel)`
  ${textStyles.tray_text_14px}
  color:${grayScale.gray_3};
`
export const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label{
    ${textStyles.tray_text_14px}
    color:${grayScale.gray_3};
  }
`
export const StyledRadioGroup = styled(RadioGroup)`
  flex-direction: ${({ $isColumn }) => $isColumn ? "column" : "row"};
`