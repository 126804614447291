/* eslint-disable max-len */
import React from 'react';

export default function LandingCheckIcon({ size = 1, color = "#0075DB" }) {
  return <>
    <i>
      <svg
        width={24 * size}
        height={24 * size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="12"
          cy="12"
          r="12"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9549 7.54894C18.515 8.11419 18.515 9.03065 17.9549 9.59591L11.1615 16.4511C10.6014 17.0163 9.69317 17.0163 9.13301 16.4511L6.04512 13.3351C5.48496 12.7698 5.48496 11.8534 6.04512 11.2881C6.60528 10.7229 7.51347 10.7229 8.07363 11.2881L10.1473 13.3806L15.9264 7.54894C16.4865 6.98369 17.3947 6.98369 17.9549 7.54894Z"
          fill="white"
        />
      </svg>
    </i>
  </>;
}
