import DescriptionIcon from "assets/icons/DescriptionIcon";
import PeopleAltIcon from "assets/icons/PeopleAltIcon";
import PersonIcon from "assets/icons/PersonIcon";
import PhotoCameraIcon from "assets/icons/PhotoCameraIcon";
import SavingsIcon from "assets/icons/SavingsIcon";
import AttorneyPowerUploadMenuItem from "components/AccountVerificationMenu/AttorneyPowerUploadMenuItem";
import BankAccountProofingUploadMenuItem from "components/AccountVerificationMenu/BankAccountProofingUploadMenuItem";
import EnterpriseProofingUploadMenuItem from "components/AccountVerificationMenu/EnterpriseProofingUploadMenuItem";
import PartnersDocumentsUploadMenuItem from "components/AccountVerificationMenu/PartnersDocumentsUploadMenuItem";
import UserFacePictureUploadMenuItem from "components/AccountVerificationMenu/UserFacePictureUploadMenuItem";
import UserIdUploadMenuItem from "components/AccountVerificationMenu/UserIdUploadMenuItem";
import { primaryColors } from "theme/styleguide";
import { createId } from "utils/createId";

export const accountVerificationDictionary = {
  titularId: {
    title: "Foto do RG ou CNH",
    description: "Após clicar em “Enviar”, você vai encontrar todas as instruções para fazer o envio corretamente.",
    icon: <PersonIcon
      size={.8}
      color={primaryColors.blue_3}
      opacity={1}
    />,
    buttonId: createId("envio_docs", "enviar_foto_RG_titular"),
    component: <UserIdUploadMenuItem />,
  },
  attorneyLetter: {
    title: "Carta do Procurador",
    description: "Após clicar em “Enviar”, você vai encontrar todas as instruções para fazer o envio corretamente.",
    icon: <DescriptionIcon
      size={.8}
      color={primaryColors.blue_3}
    />,
    buttonId: createId("envio_docs", "enviar_doc_procurador"),
    component: <AttorneyPowerUploadMenuItem />,
  },
  titularSelfie: {
    title: "Foto do rosto da pessoa titular da conta - selfie",
    description: "Após clicar em “Enviar”, você vai encontrar todas as instruções para fazer o envio corretamente.",
    icon: <PhotoCameraIcon />,
    buttonId: createId("envio_docs", "enviar_selfie_titular"),
    component: <UserFacePictureUploadMenuItem />,
  },
  // addressProofing: {
  //   title: "Comprovante de endereço da empresa ou do(a) titular (contas PF)",
  //   description: "Após clicar em “Enviar”, você vai encontrar todas as instruções e tipos de documentos aceitos para fazer o envio corretamente.",
  //   icon: <PlaceIcon
  //     color={primaryColors.blue_3}
  //   />,
  //   buttonId: createId("envio_docs", "enviar_comprovante_endereco"),
  //   component: <AddressProofingUploadMenuItem />,
  // },
  businessProofing: {
    title: "Documento de constituição da empresa",
    description: "Envie um documento que comprove a existência da empresa.\nApós clicar em “Enviar”, você vai encontrar todas as instruções e tipos de documentos aceitos para fazer o envio corretamente.",
    icon: <DescriptionIcon
      size={.8}
      color={primaryColors.blue_3}
    />,
    buttonId: createId("envio_docs", "enviar_doc_constituicao_titular"),
    component: <EnterpriseProofingUploadMenuItem />,
  },
  // partnersId: {
  //   personalPartnerTitle: "Foto do RG ou CNH",
  //   businessPartnerTitle: "Documento de constituição da empresa",
  //   personaldescription: "Envie uma foto do RG ou CNH do(a) sócio(a) da empresa.\nApós clicar em “Enviar”, você vai encontrar todas as instruções para fazer o envio corretamente.",
  //   businessdescription: "Envie o documento de constituição da empresa.\nApós clicar em “Enviar”, você vai encontrar todas as instruções para fazer o envio corretamente.",
  //   icon: <PeopleAltIcon
  //     size={.8}
  //     color={primaryColors.blue_3}
  //     opacity={1}
  //   />,
  //   component: <PartnersDocumentsUploadMenuItem />,
  // },
  businessPartnerProofing: {
    title: "Documento de constituição da empresa sócia",
    description: "Envie um documento que comprove a existência da empresa sócia.\nApós clicar em “Enviar”, você vai encontrar todas as instruções e tipos de documentos aceitos para fazer o envio corretamente.",
    icon: <DescriptionIcon
      size={.8}
      color={primaryColors.blue_3}
    />,
    buttonId: createId("envio_docs", "enviar_doc_constituicao_socio"),
    component: <PartnersDocumentsUploadMenuItem />,
  },
  personalPartnerId: {
    title: "Foto do RG ou CNH",
    description: "Envie uma foto do RG ou CNH do(a) sócio(a) da empresa.\nApós clicar em “Enviar”, você vai encontrar todas as instruções para fazer o envio corretamente.",
    icon: <PeopleAltIcon
      size={.8}
      color={primaryColors.blue_3}
      opacity={1}
    />,
    buttonId: createId("envio_docs", "enviar_foto_RG_socio"),
    component: <PartnersDocumentsUploadMenuItem />,
  },
  bankProofing: {
    title: "Comprovante de conta bancária",
    description: "Após clicar em “Enviar”, você vai encontrar todas as instruções e tipos de documentos aceitos para fazer o envio corretamente.",
    icon: <SavingsIcon
      size={.8}
      color={primaryColors.blue_3}
    />,
    buttonId: createId("envio_docs", "enviar_comprovante_conta_bancaria"),
    component: <BankAccountProofingUploadMenuItem />,
  },
}