import AccountBalanceIcon from 'assets/icons/AccountBalanceIcon';
import ShoppingCartIcon from 'assets/icons/ShoppingCartIcon';
import React from 'react';
import { grayScale } from 'theme/styleguide';
import { formatToBRL } from 'utils/formatToBRL';
import { DateStamp, TransactionMoney } from 'components/Dashboard/DashboardOverview/styles';
import { Card, CardListViewerContainer, Column, Description, ListItemContainer, Title } from './styles';
import { getDDMMYYYY } from 'utils/getDDMMYYYY';

const iconLibrary = {
  "credit": <ShoppingCartIcon color={grayScale.gray_60p100} size={0.8} />,
  "debit": <AccountBalanceIcon color={grayScale.gray_60p100} />,
}

export default function CardListViewer({
  cardData = []
}) {
  return <>
    <CardListViewerContainer>
      {
        cardData.map((e, i) => {
          const showDateStamp = (i === 0) || (i > 0 && e?.date !== cardData[i - 1]?.date)
          return (
            <ListItemContainer
              key={i}
            >
              {
                showDateStamp &&
                <DateStamp>
                  {getDDMMYYYY(e?.date * 1000)}
                </DateStamp>
              }
              <Card>
                {e?.amount >= 0 ? iconLibrary.credit : iconLibrary.debit}
                <Column>
                  <Title>
                    Descrição
                  </Title>
                  <Description>
                    {e?.description}
                  </Description>
                  <Title>
                    Pedido
                  </Title>
                  <Description>
                    {e?.order_number}
                  </Description>
                  <TransactionMoney
                    $isPositive={e?.amount >= 0}
                  >
                    {formatToBRL(e?.amount)}
                  </TransactionMoney>
                </Column>
              </Card>
            </ListItemContainer>
          )
        })
      }
    </CardListViewerContainer>
  </>;
}
