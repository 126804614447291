/* eslint-disable max-len */
import React from 'react';

export default function PersonalAdditionalDataIcon({ size = 1, color = "#2E7EE7" }) {
  return <>
    <i>
      <svg
        width={35 * size}
        height={35 * size}
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.4997 8.60409C19.1913 8.60409 20.5622 9.97492 20.5622 11.6666C20.5622 13.3583 19.1913 14.7291 17.4997 14.7291C15.808 14.7291 14.4372 13.3583 14.4372 11.6666C14.4372 9.97492 15.808 8.60409 17.4997 8.60409ZM17.4997 21.7291C21.8309 21.7291 26.3955 23.8583 26.3955 24.7916V26.3958H8.60384V24.7916C8.60384 23.8583 13.1684 21.7291 17.4997 21.7291ZM17.4997 5.83325C14.2768 5.83325 11.6663 8.44367 11.6663 11.6666C11.6663 14.8895 14.2768 17.4999 17.4997 17.4999C20.7226 17.4999 23.333 14.8895 23.333 11.6666C23.333 8.44367 20.7226 5.83325 17.4997 5.83325ZM17.4997 18.9583C13.6059 18.9583 5.83301 20.9124 5.83301 24.7916V29.1666H29.1663V24.7916C29.1663 20.9124 21.3934 18.9583 17.4997 18.9583Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
