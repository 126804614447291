import { DashboardTitle } from 'components/Dashboard/MyAccountMenu/styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CompleteRegisterMenuContainer, ContentContainer, InnerContainer, Instructions, StepsContainer, TitleContainer } from './styles';
import DadosBacenImage from 'assets/images/dados-bacen.png';
import StepItemDisplayer from 'components/Dashboard/shared/StepItemDisplayer';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from 'assets/icons/ArrowBackIcon';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { fetchGetAccount } from 'services/Account/GET/GetAccountService';
import { individualLegalNatureBusinessTags } from 'library/individualBusinessLegalNatureTags';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { completeRegisterStepsComponentDictionary } from 'library/completeRegisterStepsComponentDictionary';
import { completeRegisterStepsDescriptionDictionary } from 'library/completeRegisterStepsDescriptionDictionary';

export default function CompleteRegisterMenu() {
  const { currentCompleteRegisterMenuItem, setCurrentCompleteRegisterMenuItem, goBackToCompleteRegisterMenu, completedRegisterSteps, setCompleteRegisterNow } = useDashboardMyAccount()
  const mounted = useRef(false)
  const [accountType, setAccountType] = useState("")
  const [hasIndividualLegalNature, setIndividualLegalNature] = useState()
  const [isLoading, setLoading] = useState(true)

  const fetchAccountData = useCallback(async () => {
    if (mounted.current) {
      setLoading(true)
    }
    const { type } = await fetchGetAccount()
    if (type === "business") {
      const userData = await fetchGetAccountMyAccount()
      if (mounted.current) {
        setIndividualLegalNature(individualLegalNatureBusinessTags.includes(userData?.legal_nature?.tag))
      }
    }
    if (mounted.current) {
      setAccountType(type)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchAccountData()
  }, [fetchAccountData, currentCompleteRegisterMenuItem])

  const checkRegisterProgress = useCallback(async () => {
    const avaiableItems = completeRegisterStepsDescriptionDictionary[accountType]?.filter((e) =>
      (accountType === "business" && !hasIndividualLegalNature) ||
      (e.stepName !== "partnersDataPrelude")
    )
    const completedItems = avaiableItems?.filter((e) => completedRegisterSteps[e.stepName])
    if ((avaiableItems !== undefined) && (avaiableItems?.length === completedItems?.length)) {
      setCompleteRegisterNow(false)
    }
  }, [accountType, hasIndividualLegalNature, completedRegisterSteps, setCompleteRegisterNow])

  useEffect(() => {
    checkRegisterProgress()
  }, [checkRegisterProgress])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  return <>
    <CompleteRegisterMenuContainer>
      <TitleContainer>
        {
          currentCompleteRegisterMenuItem !== "menu" &&
          <IconButton
            size="small"
            onClick={goBackToCompleteRegisterMenu}
          >
            <ArrowBackIcon />
          </IconButton>
        }
        <DashboardTitle>
          {
            currentCompleteRegisterMenuItem === "menu" ?
              "Completar cadastro" :
              completeRegisterStepsComponentDictionary?.[accountType]?.[currentCompleteRegisterMenuItem]?.pageTitle
          }
        </DashboardTitle>
      </TitleContainer>
      {
        currentCompleteRegisterMenuItem === "menu" ?
          <ContentContainer>
            <InnerContainer>
              <Instructions>
                {`Com o cadastro completo, você passa a ter um saldo cumulativo das suas vendas no painel Vindi e pode resgatá-lo para sua conta bancária quando quiser, além de outros benefícios.\n\nPara completar seu cadastro, siga estas etapas:`}
              </Instructions>
              <StepsContainer>
                {
                  isLoading ?
                    <LoadingIndicator /> :
                    <>
                      {
                        completeRegisterStepsDescriptionDictionary[accountType].filter((e) =>
                          (accountType === "business" && !hasIndividualLegalNature) ||
                          (e.stepName !== "partnersDataPrelude")
                        ).map((e, i) => {
                          return (
                            <StepItemDisplayer
                              title={`${1 + i}. ${e.stepTitle}`}
                              description={e.stepDescription}
                              doneDescription={e?.doneDescription}
                              buttonFunction={() => { setCurrentCompleteRegisterMenuItem(e.stepName) }}
                              isCompleted={completedRegisterSteps[e.stepName]}
                              buttonText={e?.buttonText}
                              buttonId={e?.id}
                              key={i}
                            />
                          )
                        })
                      }
                    </>
                }
              </StepsContainer>
            </InnerContainer>
            <InnerContainer>
              <img
                src={DadosBacenImage}
                alt="Completar cadastro"
              />
            </InnerContainer>
          </ContentContainer> :
          completeRegisterStepsComponentDictionary[accountType]?.[currentCompleteRegisterMenuItem]?.component
      }
    </CompleteRegisterMenuContainer>
  </>;
}
