/* eslint-disable max-len */
import React from 'react';

export default function ShoppingCartIcon({ size = 1, color = "#2E7EE7" }) {
  return <>
    <i>
      <svg
        width={30*size}
        height={30*size}
        viewBox="0 0 30 30" fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.4375 16.25C20.375 16.25 21.2 15.7375 21.625 14.9625L26.1 6.85C26.5625 6.025 25.9625 5 25.0125 5H6.5125L5.3375 2.5H1.25V5H3.75L8.25 14.4875L6.5625 17.5375C5.65 19.2125 6.85 21.25 8.75 21.25H23.75V18.75H8.75L10.125 16.25H19.4375ZM7.7 7.5H22.8875L19.4375 13.75H10.6625L7.7 7.5ZM8.75 22.5C7.375 22.5 6.2625 23.625 6.2625 25C6.2625 26.375 7.375 27.5 8.75 27.5C10.125 27.5 11.25 26.375 11.25 25C11.25 23.625 10.125 22.5 8.75 22.5ZM21.25 22.5C19.875 22.5 18.7625 23.625 18.7625 25C18.7625 26.375 19.875 27.5 21.25 27.5C22.625 27.5 23.75 26.375 23.75 25C23.75 23.625 22.625 22.5 21.25 22.5Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
