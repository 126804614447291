import { InputAdornment, TextField } from '@material-ui/core';
import React from 'react';
import NumberFormat from 'react-number-format';
import IntlCurrencyInput from 'react-intl-currency-input'

function IntlCurrencyInputCustom(props) {
  const { inputRef, ...other } = props;
  return (
    <IntlCurrencyInput
      currency="BRL"
      config={{
        locale: "pt-BR",
        formats: {
          number: {
            BRL: {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            },
          },
        },
      }}
      max={100_000_000_000_000.00}
      {...other}
    />
  );
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      allowNegative={false}
      allowLeadingZeros={false}
    />
  );
}

export default function MoneyTextField({
  bestOptions = true,
  ...props
}) {
  return <>
    <TextField
      {...props}
      InputProps={{
        inputComponent: bestOptions ? IntlCurrencyInputCustom : NumberFormatCustom,
        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
      }}
      InputLabelProps={{ shrink: true }}
    />
  </>;
}
