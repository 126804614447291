import { useCallback, useEffect, useState } from 'react';
import { object, boolean } from 'yup';
import { fetchGetAccountEmailTypes } from 'services/AccountEmailTypes/GET/GetAccountEmailTypesService';
import { getEmailTypeValueById } from 'utils/getEmailTypeValueById';
import { fetchSetAccountEmailTypes } from 'services/AccountEmailTypes/POST/SetAccountEmailTypesService';
import { useRef } from 'react';

export default function useBillingEmailSetup({
  afterSubmit = () => { }
}) {
  const mounted = useRef(false);
  const [initialValues, setInitialValues] = useState({
    hasOrderStatus: false,
    hasSaleRecovery: false,
    hasOrderTracking: false,
  })

  const [isLoading, setLoading] = useState(true)
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)

  const validationSchema = object({
    hasOrderStatus: boolean(),
    hasSaleRecovery: boolean(),
    hasOrderTracking: boolean(),
  })

  const fetchBillingEmailsData = useCallback(async () => {
    const response = await fetchGetAccountEmailTypes()
    if (mounted.current) {
      setInitialValues({
        hasOrderStatus: getEmailTypeValueById(1, response),
        hasSaleRecovery: getEmailTypeValueById(3, response),
        hasOrderTracking: getEmailTypeValueById(5, response),
      })
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async ({
    hasOrderStatus,
    hasSaleRecovery,
    hasOrderTracking,
  }) => {
    await fetchSetAccountEmailTypes({
      hasOrderStatus,
      hasSaleRecovery,
      hasOrderTracking,
    })
    setSnackbarOpen(true)
    setInitialValues({
      hasOrderStatus,
      hasSaleRecovery,
      hasOrderTracking,
    })
    afterSubmit()
  }

  useEffect(() => {
    fetchBillingEmailsData()
  }, [fetchBillingEmailsData])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    isSnackbarOpen,
    setSnackbarOpen
  })
}