export const getAccountVerificationMenuVariant = ({
  account_type,
  account_register,
  verification_status,
}) => {
  let variant = "simplifiedVariant"
  if (account_type === "unlimited") {
    if (account_register?.status?.tag !== "waiting_documents") { // FLUXO DE CONTA DIGITAL
      variant = "digitalAccountVariant"
    }
    if ((account_register?.status?.tag === "active") && (verification_status?.tag !== "verified")) { // FLUXO DE REVALIDAÇÃO DE CONTA DIGITAL
      variant = "revalidatingVariant"
    }
  } else { // account_type !== "unlimited"
    if (verification_status?.tag !== "verified") {// FLUXO DE CONTA SIMPLIFICADA
      variant = "simplifiedVariant"
    }
  }
  return variant
}
