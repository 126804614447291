import apiPrivate from 'services/apiPrivate'
import { getStoredCredential } from 'utils/getStoredCredential'

export const fetchGetSchedulings = async (start_date, end_date, scheduling_status) => {

  return apiPrivate.get("/v3/schedulings",
    {
      params: {
        start_date,
        end_date,
        scheduling_status
      },
      headers: {
        'content-type': 'application/json',
        ...getStoredCredential(),
      }
    }
  )
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}