import { useOnboarding } from 'context/OnboardingContext';
import React, { useRef } from 'react';
import { ButtonsContainer, StepBody, StepSubTitle } from 'components/Onboarding/OnboardingBody/styles';
import { CreditCardStepContainer } from './styles';
import { Form, Formik } from 'formik';
import CreditCardForm from 'components/Onboarding/OnboardingForms/MainSettingsForms/CreditCardForm';
import useCreditCardSetup from 'components/FormsSetup/MainSettings/useCreditCardSetup';
import LoadableButton from 'components/shared/LoadableButton';
import ReplyParcelsOptions from 'components/Dashboard/ReplyParcelsOptions';
import { StyledAlert } from 'components/MyAccountMenu/AtentionAlertMyAccount/styles';
import { getCurrentOnboardingStepsCollection } from 'utils/getCurrentOnboardingStepsCollection';
import { getOrigemTypeVariantSectionKey } from 'utils/getOrigemTypeVariantSectionKey';

export default function CreditCardStep() {
  const { nextMainSettingsStep, mainSettingsStep, nextOnboardingSection } = useOnboarding()
  const { initialValues, validationSchema, parcelsOptions, taxValue, isLoading, handleSubmit, replySelectedParcels, setReplySelectedParcels } = useCreditCardSetup({
    afterSubmit: () => {
      const origemTypeVariantSectionKey = getOrigemTypeVariantSectionKey()
      const stepsCollectionLength = getCurrentOnboardingStepsCollection(origemTypeVariantSectionKey).length - 1
      if (mainSettingsStep < stepsCollectionLength) {
        nextMainSettingsStep()
      } else {
        nextOnboardingSection()
      }
    },
  })
  const formikRef = useRef()

  return <>
    <CreditCardStepContainer>
      {
        !isLoading &&
        <>
          <StepSubTitle>
            Parcelamento de vendas sem juros
          </StepSubTitle>
          <StepBody style={{ marginBottom: "56px" }}>
            Para parcelamentos sem juros, não haverá acréscimo para o comprador. O valor referente aos juros do parcelamento será descontado do vendedor.
          </StepBody>
          <StepBody style={{ marginBottom: "24px" }}>
            Defina o parcelamento sem juros para cada bandeira
          </StepBody>
        </>
      }
      {
        !isLoading &&
        <StyledAlert
          severity="info"
        >
          Atenção: as configurações de parcelamento sem juros devem ser iguais as configurações de parcelamento feitas no painel da sua loja.
        </StyledAlert>
      }
      <ReplyParcelsOptions
        parcelsOptions={parcelsOptions}
        formikRef={formikRef}
        replySelectedParcels={replySelectedParcels}
        setReplySelectedParcels={setReplySelectedParcels}
        fromOnboarding={true}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        innerRef={formikRef}
      >
        {
          ({ ...formikProps }) => (
            <Form>

              <CreditCardForm
                {...formikProps}
                parcelsOptions={parcelsOptions}
                taxValue={taxValue}
                isLoading={isLoading}
                replySelectedParcels={replySelectedParcels}
              />
              <ButtonsContainer>
                <LoadableButton
                  type="submit"
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_config_parcelamento_onboarding_botao_continuar"}
                >
                  Continuar
                </LoadableButton>
              </ButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </CreditCardStepContainer>
  </>;
}
