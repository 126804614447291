import StepFormHeader from 'components/Onboarding/shared/StepFormHeader';
import DetailedStepper from 'components/shared/DetailedStepper';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { useOnboarding } from 'context/OnboardingContext';
import { completeRegisterStepsDictionary } from 'library/completeRegisterStepsDictionary';
import React, { Fragment } from 'react';
import { getCurrentOnboardingStepKey } from 'utils/getCurrentOnboardingStepKey';
import { getHideOnCountStepsGoingBack } from 'utils/getHideOnCountStepsGoingBack';
import { getStepsCollectionShowingCounter } from 'utils/getStepsCollectionShowingCounter';
import { CompleteRegisterSectionContainer } from './styles';

export default function CompleteRegisterSection() {
  const { completeRegisterStep, previousCompleteRegisterStep, previousOnboardingStep, isLoadingContext } = useOnboarding()
  const currentStepKey = getCurrentOnboardingStepKey("completeRegisterSteps", completeRegisterStep)
  const totalSteps = getStepsCollectionShowingCounter("completeRegisterSteps", completeRegisterStepsDictionary)

  const handleGoBack = () => {
    if (completeRegisterStep > 0) {
      const hideOnCountStepsGoingBack = getHideOnCountStepsGoingBack("completeRegisterSteps", completeRegisterStep, completeRegisterStepsDictionary)
      for (let i = 0; i < hideOnCountStepsGoingBack; i++) {
        previousCompleteRegisterStep()
      }
    } else {
      previousOnboardingStep()
    }
  }
  return <>
    {
      isLoadingContext ?
        <LoadingIndicator /> :
        <>
          {
            !completeRegisterStepsDictionary[currentStepKey]?.hideBackButton &&
            <StepFormHeader
              handleGoBack={handleGoBack}
            />
          }
          <CompleteRegisterSectionContainer>
            <DetailedStepper
              currentStep={completeRegisterStep}
              totalSteps={totalSteps}
              title={completeRegisterStepsDictionary[currentStepKey]?.stepTitle}
              hideSubtitle={completeRegisterStepsDictionary[currentStepKey]?.hideSubtitle}
            />
            {
              Object.keys(completeRegisterStepsDictionary).map((key, i) => {
                return (
                  <Fragment
                    key={i}
                  >
                    {
                      key === currentStepKey &&
                      completeRegisterStepsDictionary[key].stepComponent
                    }
                  </Fragment>
                )
              })
            }
          </CompleteRegisterSectionContainer>
        </>
    }
  </>;
}
