import React from 'react';
import { Form, Formik } from 'formik';
import LoadableButton from 'components/shared/LoadableButton';
import { TitularAndAddressFormContainer } from './styles';
import TitularAndAddressFields from 'fields/Onboarding/AccountDataSection/TitularAndAddressFields/TitularAndAddressFields';
import useTitularAndAddressDataSetup from 'setup/Onboarding/AccountDataSection/useTitularAndAddressDataSetup';
import { ButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import { useOnboarding } from 'context/OnboardingContext';
import { getCurrentOnboardingStepsCollection } from 'utils/getCurrentOnboardingStepsCollection';

export default function TitularAndAddressForm() {
  const stepsCollectionLength = getCurrentOnboardingStepsCollection("accountDataSteps").length - 1
  const { nextProfileSettingsStep, nextOnboardingSection, profileSettingsStep } = useOnboarding()
  const { initialValues, validationSchema, isBusinessAccount, isLoading, handleSubmit } = useTitularAndAddressDataSetup({
    afterSubmit: () => {
      if (profileSettingsStep < stepsCollectionLength) {
        nextProfileSettingsStep()
      } else {
        nextOnboardingSection()
      }
    }
  })
  return <>
    <TitularAndAddressFormContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <TitularAndAddressFields
                {...formikProps}
                isLoading={isLoading}
                isBusinessAccount={isBusinessAccount}
              />
              <ButtonsContainer>
                <LoadableButton
                  type="submit"
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_titularidade_onboarding_botao_continuar"}
                >
                  Salvar e continuar
                </LoadableButton>
              </ButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </TitularAndAddressFormContainer>
  </>;
}