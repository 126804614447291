/* eslint-disable max-len */
import React from 'react';

export default function ApartmentIcon({ size = 1, color = "#2E7EE7" }) {
  return <>
    <i>
      <svg
        width={35 * size}
        height={35 * size}
        viewBox="0 0 35 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g
          clipPath="url(#clip0_7196_2343)">
          <path
            d="M24.7917 16.0417V4.375H10.2083V10.2083H4.375V30.625H16.0417V24.7917H18.9583V30.625H30.625V16.0417H24.7917ZM10.2083 27.7083H7.29167V24.7917H10.2083V27.7083ZM10.2083 21.875H7.29167V18.9583H10.2083V21.875ZM10.2083 16.0417H7.29167V13.125H10.2083V16.0417ZM16.0417 21.875H13.125V18.9583H16.0417V21.875ZM16.0417 16.0417H13.125V13.125H16.0417V16.0417ZM16.0417 10.2083H13.125V7.29167H16.0417V10.2083ZM21.875 21.875H18.9583V18.9583H21.875V21.875ZM21.875 16.0417H18.9583V13.125H21.875V16.0417ZM21.875 10.2083H18.9583V7.29167H21.875V10.2083ZM27.7083 27.7083H24.7917V24.7917H27.7083V27.7083ZM27.7083 21.875H24.7917V18.9583H27.7083V21.875Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath
            id="clip0_7196_2343">
            <rect
              width={35 * size}
              height={35 * size}
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </i>
  </>;
}
