import React from 'react';
import { Form, Formik } from 'formik';
import LoadableButton from 'components/shared/LoadableButton';
import { BankAccountFormContainer } from './styles';
import BankAccountFields from 'fields/Onboarding/AccountDataSection/BankAccountFields/BankAccountFields';
import useBankAccountDataSetup from 'setup/Onboarding/AccountDataSection/useBankAccountDataSetup';
import { ButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import { useOnboarding } from 'context/OnboardingContext';
import { getCurrentOnboardingStepsCollection } from 'utils/getCurrentOnboardingStepsCollection';
import { setDataStorage } from 'utils/storage';

export default function BankAccountForm() {
  const stepsCollectionLength = getCurrentOnboardingStepsCollection("accountDataSteps").length - 1
  const { nextProfileSettingsStep, nextOnboardingSection, profileSettingsStep, setProfileSettingsStep } = useOnboarding()
  const { initialValues, validationSchema, isLoading, handleSubmit, accountTypesList, bankOptionsList } = useBankAccountDataSetup({
    afterSubmit: () => {
      if (profileSettingsStep < stepsCollectionLength) {
        nextProfileSettingsStep()
      } else {
        setProfileSettingsStep(3)
        nextOnboardingSection()
        setDataStorage("hasActiveBankAccount", true)
      }
    }
  })
  return <>
    <BankAccountFormContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <BankAccountFields
                {...formikProps}
                isLoading={isLoading}
                accountTypesList={accountTypesList}
                bankOptionsList={bankOptionsList}
              />
              <ButtonsContainer>
                <LoadableButton
                  type="submit"
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_conta_bancaria_onboarding_botao_continuar"}
                >
                  Salvar e continuar
                </LoadableButton>
              </ButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </BankAccountFormContainer>
  </>;
}