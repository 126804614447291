import React from 'react';
import { StyledAlert } from './styles';

const alertDictionary = {
  pending: {
    severity: "info",
    message: "Este documento já foi enviado por você anteriormente e está sendo avaliado, por este motivo não será necessário enviá-lo novamente.",
  },
  rejected: {
    severity: "error",
    message: "Este documento já foi enviado por você anteriormente e foi rejeitado, por este motivo será necessário enviá-lo novamente.",
  },
  approved: {
    severity: "success",
    message: "Este documento já foi enviado por você anteriormente e está aprovado, por este motivo não será necessário enviá-lo novamente.",
  },
  automatic_verifications: {
    severity: "success",
    message: "Este documento já foi aprovado automaticamente, por este motivo não será necessário enviá-lo novamente.",
  }
}

export default function DocumentsRequiredAlert({
  documentStatus = {},
  sendButtonText = "Continuar",
}) {
  const status = documentStatus?.status?.tag
  const reason = documentStatus?.reason
  return <>
    {
      ["pending", "approved", "rejected", "automatic_verifications"].includes(status) &&
      <StyledAlert
        severity={alertDictionary[status].severity}
      >
        {
          alertDictionary[status].message
        }
        {
          (status === "rejected" && !!reason) &&
          `\n\nRazão da rejeição: ${reason}`
        }
        {
          ["pending", "approved", "automatic_verifications"].includes(status) &&
          `\n\nClique em "${sendButtonText}" para seguir.`
        }
      </StyledAlert>
    }
  </>;
}
