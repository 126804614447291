import apiPrivate from 'services/apiPrivate'
import { getDataStorage, setDataStorage } from 'utils/storage';

export const fetchStore = async () => {
  const store_id = getDataStorage('store_id')
  const adm_user = getDataStorage("store_admin_user")
  return apiPrivate.post("/store_keys/store", {
    store_id,
    adm_user,
  })
    .then(({ data }) => {
      setDataStorage("onboarding_step", 0);
      setDataStorage("main_settings_step", 0);
      setDataStorage("account_verification_step", 0);
      setDataStorage("profile_settings_step", 0);
      return data
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}