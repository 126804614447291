import React from 'react';
import { useDashboard } from 'context/DashboardContext';
import { dashboardTabsDictionary } from 'library/dashboardTabsDictionary';
import { DashboardBodyContainer, LoadingContainer } from './styles';
import LoadingIndicator from 'components/shared/LoadingIndicator';

export default function DashboardBody() {
  const { currentTab, isLoading } = useDashboard()

  return <>
    {
      isLoading ?
        <LoadingContainer>
          <LoadingIndicator
            size={1.5}
          />
        </LoadingContainer> :
        <DashboardBodyContainer>
          {
            dashboardTabsDictionary[currentTab]?.component
          }
        </DashboardBodyContainer>
    }
  </>;
}
