import React from 'react';
import { FormControl, MenuItem } from '@material-ui/core';
import BoletoImage from 'assets/images/instrucoes-boleto.jpg'
import InstructionsListManager from 'components/Onboarding/OnboardingSteps/shared/InstructionsListManager';
import CancelledBoletoInfoCard from 'components/Onboarding/OnboardingSteps/shared/CancelledBoletoInfoCard';
import { StepSubTitle } from 'components/Onboarding/OnboardingBody/styles';
import { BoletoFormContainer, ImageCard, Instructions, InstructionsContainer, InstructionsRow, SelectContainer, SelectLabel, StyledSelect, WarningInfo } from './styles';
import { Field } from 'formik';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { useEffect } from 'react';
import { GAEventTracker } from 'utils/GAEventTracker';
import { useState } from 'react';

export default function BoletoForm({
  disabled = false,
  isLoading = true,
  isOnboardingStep = false,
  ...formikProps
}) {
  const [loadingInitialValues, setLoadingInitialValues] = useState(false)
  useEffect(() => {
    if (!isLoading) {
      if (loadingInitialValues) {
        GAEventTracker({
          category: isOnboardingStep ? "Onboarding" : "Página configurações",
          action: "Opções de vencimento de boleto",
          label: "selecionar alguma opção do select de vencimento do boleto"
        })
      }
      setLoadingInitialValues(true)
    }
    // eslint-disable-next-line
  }, [formikProps?.values?.daysCounting])
  return <>
    {
      isLoading ?
        <LoadingIndicator /> :
        <BoletoFormContainer>
          <StepSubTitle style={{ marginBottom: "32px" }}>
            Vencimento do boleto
          </StepSubTitle>
          <SelectContainer>
            <FormControl disabled={disabled}>
              <Field
                as={StyledSelect}
                name="daysCounting"
                variant="outlined"
              >
                {
                  [...new Array(7)].map((_e, i) => {
                    return (
                      <MenuItem
                        value={1 + i}
                        key={i}
                      >
                        {`${1 + i} ${i === 0 ? "dia" : "dias"}`}
                      </MenuItem>
                    )
                  })
                }
              </Field>
            </FormControl>
            <SelectLabel>
              após a emissão
            </SelectLabel>
          </SelectContainer>
          <WarningInfo>
            <b>Atenção:</b> a configuração de vencimento do boleto deve considerar as configurações de cancelamento de pedido da sua loja.
          </WarningInfo>
          <CancelledBoletoInfoCard />
          <StepSubTitle style={{ marginBottom: "24px" }}>
            Instruções
          </StepSubTitle>
          <InstructionsRow>
            <InstructionsContainer>
              <Instructions style={{ marginBottom: "24px" }}>
                Você pode adicionar instruções para seus boletos.
              </Instructions>
              <Instructions style={{ marginBottom: "24px" }}>
                {`Elas servem para fornecer informações importantes para o seu cliente e para o caixa recebedor a respeito das condições de recebimento do boleto, como concessão de abatimentos, prazo para receber o documento e taxas de juros referentes à multa.\n\nVocê pode inserir até 9 instruções.`}
              </Instructions>
            </InstructionsContainer>
            <ImageCard>
              <img src={BoletoImage} alt="Imagem de um boleto" />
            </ImageCard>
          </InstructionsRow>
          <InstructionsListManager
            isOnboardingStep={isOnboardingStep}
            disabled={disabled}
            {...formikProps}
          />
        </BoletoFormContainer>
    }
  </>;
}
