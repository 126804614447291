/* eslint-disable max-len */
import React from 'react';
const defaultColor = '#234581'
export default function YapayYPIcon({ size = 1, color = defaultColor }) {
  return <>
    <i>
      <svg
        width={39 * size}
        height={40 * size}
        viewBox="0 0 39 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.274 8.70203H2.89359C2.52931 8.69659 2.16931 8.78171 1.84533 8.94987C1.52135 9.11804 1.24336 9.36408 1.03587 9.6663C0.828384 9.96852 0.697777 10.3176 0.655573 10.6828C0.613369 11.048 0.660864 11.4181 0.793869 11.7604L11.9497 39.6096L16.3498 20.3683C16.4776 19.8146 16.4329 19.2346 16.2219 18.7075L12.9281 10.4844C12.7115 9.95457 12.3433 9.50198 11.8705 9.18448C11.3977 8.86698 10.8418 8.699 10.274 8.70203Z"
          fill={color}
        />
        <path
          d="M36.7829 0.899179H28.6023C28.0252 0.896123 27.4608 1.06965 26.9834 1.39687C26.506 1.7241 26.138 2.18966 25.9281 2.73217L11.9551 39.6122H23.0808C23.6579 39.6152 24.2224 39.4417 24.6998 39.1145C25.1772 38.7873 25.5451 38.3217 25.755 37.7792L38.6945 3.63347C38.8089 3.32455 38.8468 2.99212 38.805 2.66504C38.7632 2.33796 38.6428 2.02614 38.4545 1.75667C38.2661 1.4872 38.0153 1.26824 37.724 1.1188C37.4327 0.969359 37.1097 0.893969 36.7829 0.899179Z"
          fill={color === defaultColor ? "#00A3FF" : color}
        />
      </svg>
    </i>
  </>;
}
