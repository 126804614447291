import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

//phone_type_tag = home or business
export const fetchPhoneUpdate = async ({
  phone,
  phone_type_tag,
  phone_id,
}) => {
  const code = getDataStorage('yapay_code')
  return apiPrivate.post("/myaccount/phones/update", {
    phone_id,
    phone_type_tag,
    phone
  }, {
    headers: {
      code,
    }
  })
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}