import styled from "styled-components";
import { Button } from "@material-ui/core";
import { grayScale, textStyles } from "theme/styleguide";

export const StepItemDisplayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 24px 16px;
  margin-bottom: 16px;
  background: rgba(84, 110, 122, 0.07);
  >button{
    align-self: center;
    margin-top: 24px;
  }
  @media (max-width: 767px)
  {
    >button{
      flex: 1;
      max-width: 100%;
    }
    >i{
      display: none;
    }
  }
  @media (min-width: 768px)
  {
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 24px 40px 32px 16px;
    >button{
      align-self: flex-end;
      margin-top: 0px;
    }
  }
`
export const StepItemContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  @media (min-width: 768px)
  {
    margin-right: 32px;
  }
`
export const StepTitle = styled.span`
  display: block;
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  color: ${grayScale.black};
  margin-bottom: 8px;
`
export const StepDescription = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  >ul{
    padding-left: 16px;
    >li{
      margin-bottom: 12px;
    }
  }
`
export const StyledButton = styled(Button)`
  width: 100%;
  min-width: fit-content;
  max-width: 254px;
  @media (min-width: 768px)
  {
    max-width: 152px;
  }
`
export const InnerContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  @media (min-width: 768px)
  {
    >i{
      display: none;
    }
  }
`