import styled from "styled-components";
import { borderStyles, grayScale, textStyles } from "theme/styleguide";

export const NextValuesToCreditContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
  @media (min-width: 768px)
  {
    padding-bottom: 5px;
  }
`

export const ContainerList = styled.div`
  flex-direction: column;
  display: flex;
  flex: 1;
  justify-content: space-between;
`

export const ContainerListButon = styled.div`
`

export const ValuesList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin-bottom: 20px;
`
export const ValueItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 13px 16px;
  ${borderStyles.border_1}
  :not(:last-child){
    margin-bottom: 12px;
  }
  >span:nth-child(2){
    display: none;
  }
  @media (min-width: 375px)
  {
    >span:nth-child(2){
      display: initial;
    }
  }
  @media (min-width: 768px)
  {
    >span:nth-child(2){
      display: none;
    }
  }
  @media (min-width: 920px)
  {
    >span:nth-child(2){
      display: initial;
    }
  }
`
export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
export const DateText = styled.span`
  ${textStyles.body_1_14px}
  color: ${grayScale.gray_3};
  font-weight: 600;
  margin-left: 10px;
`
export const SalesText = styled.span`
  ${textStyles.body_1_14px}
  color: ${grayScale.gray_60p100};
  font-weight: 600;
`
export const SalesMoney = styled.span`
  ${textStyles.body_1_14px}
  color: ${grayScale.gray_3};
  font-weight: 800;
`
export const EmptyStateImage = styled.img`
  max-width: 171px;
`
export const SpinnerContainer = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`