import { Button, darken, Dialog, DialogActions, DialogContent, } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, shadowStyles } from "theme/styleguide";

export const CompleteRegisterInviteDialogContainer = styled.div`
  
`
export const StyledDialog = styled(Dialog)`
  .MuiPaper-root{
    width: 92.27%;
    box-shadow: none;
  }
  .MuiDialog-paper {
    margin: 0px;
  }
  @media (min-width: 768px)
  {
    .MuiPaper-root{
      width: 83.89%;
      max-width: 1146px;
    }
    .MuiDialog-paper {
      margin: 32px;
    }
  }
`
export const DialogHeader = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  >button{
    align-self: flex-end;
  }
  padding: 32px;
  padding-bottom: 0px;
  margin-bottom: 8px;
  @media (min-width: 768px)
  {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    padding-bottom: 0px;
  }
`
export const Title = styled.span`
  max-width: 287px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 8px;
  @media (min-width: 768px)
  {
    max-width: 100%;
    font-size: 28px;
    line-height: 40px;
    text-align: start;
    margin-top: 0px;
    margin-bottom: -4px;
    margin-right: 16px;
  }
`
export const Subtitle = styled.span`
  display: block;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #4E4E65;
  text-align: center;
  margin-bottom: 24px;
  @media (min-width: 768px)
  {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    text-align: start;
    margin-bottom: 16px;
  }
`
export const StyledDialogContent = styled(DialogContent)`
  padding: 0px 32px;
  @media (min-width: 768px)
  {
    padding: 0px 40px;
  }
`
export const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`
export const BenefitItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${shadowStyles.drop_shadow_5}
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  i{
    margin-right: 16px;
  }
  @media (min-width: 920px)
  {
    width: 280px;
    margin-bottom: 16px;
    :nth-child(odd){
      margin-right: 16px;
    }
  }
`
export const BenefitTitle = styled.span`
  max-width: 194px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: ${grayScale.black};
`
export const StyledDialogActions = styled(DialogActions)`
  padding: 0px 32px;
  padding-bottom: 32px;
  @media (min-width: 768px)
  {
    padding: 0px 40px;
    padding-bottom: 40px;
    justify-content: flex-start;
  }
`
export const StyledImage = styled.img`
  display: ${({ $isHeaderImage }) => $isHeaderImage ? "block" : "none"};
  width: 100%;
  max-width: 336px;
  @media (min-width: 768px)
  {
    display: ${({ $isHeaderImage }) => $isHeaderImage ? "none" : "block"};;
    width: 50%;
    max-width: 408px;
    margin-top: -50px;
  }
`
export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
export const StyledButton = styled(Button)`
  width: 100%;
  max-width: 100%;
  height: 48px;
  font-weight: bold;
  border-radius: 4px;
  background: #0421B4;
  :hover{
    background: ${darken("#0421B4", .3)};
  }
  @media (min-width: 920px)
  {
    max-width: 329px;
    margin-top: 16px;
  }
`