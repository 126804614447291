import React, { Fragment } from 'react';
import { AccountDataContainer } from './styles';
import StepFormHeader from 'components/Onboarding/shared/StepFormHeader';
import DetailedStepper from 'components/shared/DetailedStepper';
import { useOnboarding } from 'context/OnboardingContext';
import { useHistory } from 'react-router-dom';
import { accountDataStepsDictionary } from 'library/accountDataStepsDictionary';
import AccountDataProvider from 'context/AccountDataContext';
import { getCurrentOnboardingStepKey } from 'utils/getCurrentOnboardingStepKey';
import { getStepsCollectionShowingCounter } from 'utils/getStepsCollectionShowingCounter';
import { getHideOnCountStepsGoingBack } from 'utils/getHideOnCountStepsGoingBack';

export default function AccountDataSection() {
  const { profileSettingsStep, previousProfileSettingsStep } = useOnboarding()
  const currentStepKey = getCurrentOnboardingStepKey("accountDataSteps", profileSettingsStep)
  const totalSteps = getStepsCollectionShowingCounter("accountDataSteps", accountDataStepsDictionary)
  const history = useHistory()
  const handleGoBack = () => {
    if (profileSettingsStep > 0) {
      const hideOnCountStepsGoingBack = getHideOnCountStepsGoingBack("accountDataSteps", profileSettingsStep, accountDataStepsDictionary)
      for (let i = 0; i < hideOnCountStepsGoingBack; i++) {
        previousProfileSettingsStep()
      }
    } else {
      history.push("/welcome")
    }
  }

  return <>
    <StepFormHeader
      handleGoBack={handleGoBack}
    />
    <DetailedStepper
      currentStep={accountDataStepsDictionary[currentStepKey]?.stepIndex}
      totalSteps={totalSteps}
      title={accountDataStepsDictionary[currentStepKey]?.stepTitle}
      hideSubtitle={accountDataStepsDictionary[currentStepKey]?.hideSubtitle}
    />
    <AccountDataProvider>
      <AccountDataContainer>
        {
          Object.keys(accountDataStepsDictionary).map((key, i) => {
            return (
              <Fragment
                key={i}
              >
                {
                  key === currentStepKey &&
                  accountDataStepsDictionary[key].stepComponent
                }
              </Fragment>
            )
          })
        }
      </AccountDataContainer>
    </AccountDataProvider>
  </>;
}
