import React from 'react';
import { SameSizeButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import UserIdUploadForm from 'components/Onboarding/OnboardingForms/AccountVerificationForms/UserIdUploadForm';
import { Form, Formik } from 'formik';
import useUserIdUploadSetup from 'components/FormsSetup/AccountVerification/useUserIdUploadSetup';
import { UserIdUploadMenuItemContainer } from './styles';
import LoadableButton from 'components/shared/LoadableButton';
import { Button } from '@material-ui/core';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import CancelFormFillingDialog from 'components/CompleteRegisterMenu/shared/CancelFormFillingDialog';

export default function UserIdUploadMenuItem() {
  const { setCurrentCompleteRegisterMenuItem, goBackToCompleteRegisterMenu, toggleCancelFormFillingDialog, updateCompletedRegisterStep } = useDashboardMyAccount()
  const { initialValues, validationSchema, isLoading, handleSubmit, userData, documentRequired, documentStatus, isBusinessAccount } = useUserIdUploadSetup({
    afterSubmit: () => {
      if (isBusinessAccount) {
        setCurrentCompleteRegisterMenuItem("enterpriseProofingUpload")
      } else {
        updateCompletedRegisterStep("documentUploadChain")
        goBackToCompleteRegisterMenu()
      }
    },
    afterSubmitAlternative: () => {
      setCurrentCompleteRegisterMenuItem("attorneyPowerUpload")
    },
  })
  return <>
    <UserIdUploadMenuItemContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={documentRequired && validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <UserIdUploadForm
                userData={userData}
                isLoading={isLoading}
                documentStatus={documentStatus}
                documentRequired={documentRequired}
                isBusinessAccount={isBusinessAccount}
                showAttorneyCheckbox={!userData?.attorney && documentRequired}
                {...formikProps}
              />
              <SameSizeButtonsContainer
                $shouldNotReverseColumn
              >
                <LoadableButton
                  type="submit"
                  disabled={(documentRequired && !formikProps.isValid) || (formikProps.isSubmitting || isLoading)}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_completar_cadastro_foto_RG_botao_enviar"}
                >
                  Continuar
                </LoadableButton>
                <Button
                  variant="outlined"
                  disabled={formikProps.isSubmitting || !documentRequired || isLoading}
                  onClick={toggleCancelFormFillingDialog}
                  id={"tela_completar_cadastro_foto_RG_botao_cancelar"}
                >
                  Cancelar
                </Button>
              </SameSizeButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </UserIdUploadMenuItemContainer>
    <CancelFormFillingDialog
      cancelFillingFormFunction={goBackToCompleteRegisterMenu}
    />
  </>;
}
