import { Button, TextField } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const SMSEmailVerificationFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 57px;

  @media (min-width: 768px){
    margin-bottom: 192px;
  }
`
export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const CodeContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
  align-self:center;
  @media (min-width: 768px){
    align-self:flex-start;
  }
`
export const CodeInput = styled(TextField)`
  width: 38px;
  margin-right: 10px;
`
export const InstructionLabel = styled.span`
  color: ${grayScale.gray_3};
  margin-bottom: 28px;
  ${textStyles.tray_text_14px};
`
export const InstructionLabelSMS = styled.span`
  color: ${grayScale.gray_3};
  margin-bottom: 28px;
  ${textStyles.tray_text_12px};
  @media (min-width: 768px){
    ${textStyles.tray_text_14px};
  }
`

export const Image = styled.img`
  display: none;
  @media (min-width: 768px){
    display: block;
    margin: auto;
    margin-top: -50px;
  }
`
export const StyledButton = styled(Button)`
  min-width: 96px !important;
  width: 96px !important;
  align-self:center;
  @media (min-width: 768px){
    align-self:flex-start;
  }
`