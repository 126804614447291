import { Button } from "@material-ui/core";
import styled from "styled-components";
import { buttonStyles, primaryColors, textStyles } from "theme/styleguide";

export const ThirdFoldContainer = styled.div`
  background-color: ${primaryColors.landing.brand.primary[25]};
  span{
    display: block;
  }
  >div{
    max-width: 1200px;
    padding: 70px 102px;
    margin: 0 auto;
  }
`
export const Title = styled.span`
  ${textStyles.landing.primary800_700_34px}
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 16px;
`
export const Subtitle = styled.span`
  ${textStyles.landing.neutral700_400_20px}
  text-align: center;
  margin-bottom: 64px;
`
export const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 48px;
`
export const LeftContent = styled.div`
  >i{
    display: block;
    margin-top: 32px;
    margin-bottom: 32px;
  }
`
export const RightContent = styled.div`
`
export const Description = styled.span`
  max-width: 480px;
  ${textStyles.landing.neutral700_400_20px}
`
export const BenefitTitle = styled.span`
  max-width: 460px;
  ${textStyles.landing.neutral700_400_20px}
  margin-bottom: 32px;
`
export const BenefitItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  >i{
    margin-right: 12px;
  }
`
export const BenefitDescription = styled.span`
  max-width: 396px;
  ${textStyles.landing.neutral700_700_18px}
`
export const StyledButton = styled(Button)`
  display: block;
  ${buttonStyles.landing_blue}
  margin: 0 auto;
`