import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage';

export const fetchUpdateAccountSellerLogo = async ({
  file_0
}) => {
  const session_id = getDataStorage('session_id')
  const formData = new FormData()
  formData.append("session_id", session_id);
  formData.append("file_0", file_0);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  }
  return apiPrivate.post("/accounts_seller_settings/update_logo", formData, config)
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}