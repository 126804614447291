import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchDeleteBusinessPartner = async ({
  partner_id
}) => {
  const code = getDataStorage('yapay_code')
  return apiPrivate.delete("/myaccount/business_partners", {
    headers: {
      code,
    },
    data: {
      partner_id,
    },
  })
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}