import React from 'react';
import { Form, Formik } from 'formik';
import SnackbarAlert from 'components/shared/SnackbarAlert';
import LoadableButton from 'components/shared/LoadableButton';
import { StyledButtonsContainer } from 'components/Dashboard/AccountDataMenu/styles';
import { Button, Collapse } from '@material-ui/core';
import { EnterpriseFormContainer } from './styles';
import EnterpriseFields from 'fields/Dashboard/MyAccountTab/AccountDataMenu/BusinessAccount/EnterpriseFields/EnterpriseFields';
import useEnterpriseDataSetup from 'setup/Dashboard/MyAccountTab/AccountDataMenu/BusinessAccount/useEnterpriseDataSetup';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';

export default function EnterpriseForm() {
  const { isAccountDataMenuFormsDisabled, toggleAccountDataMenuFormsDisabled } = useDashboardMyAccount()
  const { initialValues, validationSchema, constitutionList, legalNatureList, isLoading, handleSubmit, isSnackbarOpen, setSnackbarOpen, hasDigitalAccount } = useEnterpriseDataSetup({
    afterSubmit: () => {
      toggleAccountDataMenuFormsDisabled()
    },
    reloadAfterSubmit: true,
  })
  const handleCancel = (formikProps) => {
    formikProps.resetForm()
    toggleAccountDataMenuFormsDisabled()
  }
  return <>
    <EnterpriseFormContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <EnterpriseFields
                {...formikProps}
                isLoading={isLoading}
                constitutionList={constitutionList}
                legalNatureList={legalNatureList}
                disabledForm={isAccountDataMenuFormsDisabled}
                hasDigitalAccount={hasDigitalAccount}
              />
              <Collapse
                in={!isAccountDataMenuFormsDisabled}
              >
                <StyledButtonsContainer>
                  <LoadableButton
                    type="submit"
                    disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                    isLoading={formikProps.isSubmitting}
                    id={"plugin_menu-minha-conta_aba_dados-empresa_botao_salvar"}
                  >
                    Salvar
                  </LoadableButton>
                  <Button
                    variant="outlined"
                    onClick={() => { handleCancel(formikProps) }}
                    disabled={formikProps.isSubmitting || isLoading}
                    id={"plugin_menu-minha-conta_aba_dados-empresa_botao_cancelar"}
                  >
                    Cancelar
                  </Button>
                </StyledButtonsContainer>
              </Collapse>
            </Form>
          )
        }
      </Formik>
      <SnackbarAlert
        isOpen={isSnackbarOpen}
        onClose={() => { setSnackbarOpen(false) }}
        message="Salvo"
      />
    </EnterpriseFormContainer>
  </>;
}