import BillingEmailsStep from 'components/Onboarding/OnboardingSteps/MainSettingsSteps/BillingEmailsStep';
import BoletoStep from 'components/Onboarding/OnboardingSteps/MainSettingsSteps/BoletoStep';
import CreditCardStep from 'components/Onboarding/OnboardingSteps/MainSettingsSteps/CreditCardStep';
import CustomizeStep from 'components/Onboarding/OnboardingSteps/MainSettingsSteps/CustomizeStep';
import MainSettingsStart from 'components/Onboarding/OnboardingSteps/MainSettingsSteps/MainSettingsStart';
import PixStep from 'components/Onboarding/OnboardingSteps/MainSettingsSteps/PixStep';
import SuccessUploading from 'components/Onboarding/OnboardingSteps/MainSettingsSteps/SuccessUploading';

const commonSteps = [
  {
    stepTitle: "Boleto",
    stepComponent: <BoletoStep />,
  },
  {
    stepTitle: "Personalização",
    stepComponent: <CustomizeStep />,
  },
  {
    stepTitle: "QR code pix",
    stepComponent: <PixStep />,
  },
]

export const mainSettingsStepsDictionary = {
  // mainSettingsStart: {
  //   stepComponent: <MainSettingsStart />,
  //   hideBackButton: true,
  // },
  creditCardStep: {
    stepTitle: "Cartão de crédito",
    stepComponent: <CreditCardStep />,
  },
  boletoStep: {
    stepTitle: "Boleto",
    stepComponent: <BoletoStep />,
  },
  customizeStep: {
    stepTitle: "Personalização",
    stepComponent: <CustomizeStep />,
  },
  pixStep: {
    stepTitle: "QR code pix",
    stepComponent: <PixStep />,
  },
  billingEmailsStep: {
    stepTitle: "E-mails transacionais",
    stepComponent: <BillingEmailsStep />,
  },
  successUploading: {
    stepComponent: <SuccessUploading />,
    hideBackButton: true,
  },
}

export const UNUSED_mainSettingsStepsDictionary = {
  ecommerce: [
    {
      stepComponent: <MainSettingsStart />,
      hideBackButton: true,
    },
    {
      stepTitle: "Cartão de crédito",
      stepComponent: <CreditCardStep />,
    },
    ...commonSteps,
    {
      stepTitle: "E-mails transacionais",
      stepComponent: <BillingEmailsStep />,
    },
    // TODO: Remover definitivamente quando for definido se havará ou não esta opção 
    // {
    //   stepTitle: "Saque automático",
    //   stepComponent: <WithdrawStep />,
    // },
    {
      stepComponent: <SuccessUploading />,
      hideBackButton: true,
    },
  ],
  recurrence: [
    {
      stepComponent: <MainSettingsStart />,
      hideBackButton: true,
    },
    ...commonSteps,
    // TODO: Remover definitivamente quando for definido se havará ou não esta opção 
    // {
    //   stepTitle: "Saque automático",
    //   stepComponent: <WithdrawStep />,
    // },
    {
      stepComponent: <SuccessUploading />,
      hideBackButton: true,
    },
  ],
}