import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const EmailExampleListContainer = styled.ul`
  list-style: none;
  padding-left: 0px;
  margin-bottom: 44px;
  @media (min-width: 768px)
  {
    margin-bottom: 88px;
  }
`
export const ExampleListItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  width: 100%;
  font-weight: bold;
  max-width: 598px;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  text-align: start;
  margin-bottom: 8px;
  background: transparent;
  :hover{
    background: rgba(84, 110, 122, 0.1);
  }
  @media (min-width: 768px)
  {
    margin-left: -12px;
  }
`
export const EmailConcatanedList = styled.span`
  ${textStyles.tray_text_12px}
  color: ${grayScale.gray_3};
`