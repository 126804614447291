import styled from "styled-components";
import { borderStyles, grayScale, textStyles } from "theme/styleguide";

export const CardListViewerContainer = styled.div`
  margin-top: -20px;
  padding-bottom: 20px;
  @media (min-width: 920px)
  {
    display: none;
  }
`
export const ListItemContainer = styled.div`
  margin-bottom: 20px;
`
export const Card = styled.div`
  display: flex;
  flex-direction: row;
  ${borderStyles.border_1}
  padding: 16px;
  margin-bottom: 10px;
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`
export const Title = styled.span`
  ${textStyles.body_2_12px}
  color:${grayScale.gray_60p100};
  margin-bottom: 2px;
`
export const Description = styled.span`
  ${textStyles.body_1_14px}
  color:${grayScale.gray_3};
  font-weight: 600;
  margin-bottom: 20px;
`