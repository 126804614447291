import React from 'react';
import { Field } from 'formik';
import { StyledTextField } from 'components/Dashboard/AccountDataMenu/styles';
import { BusinessPartnerItemContainer } from './styles';
import { formatIdentifier } from 'utils/formatIdentifier';

export default function BusinessPartnerItem({
  touched,
  errors,
  disabledForm,
  setFieldValue,
  index,
}) {
  return <>
    <BusinessPartnerItemContainer>
      <Field
        as={StyledTextField}
        name={`partnersList.${index}.socialName`}
        label="Razão social"
        size="small"
        error={touched.partnersList?.[index]?.socialName && !!errors.partnersList?.[index]?.socialName}
        helperText={touched.partnersList?.[index]?.socialName && errors.partnersList?.[index]?.socialName}
        disabled={disabledForm}
      />
      <Field
        as={StyledTextField}
        name={`partnersList.${index}.CNPJ`}
        label="CNPJ"
        size="small"
        error={touched.partnersList?.[index]?.CNPJ && !!errors.partnersList?.[index]?.CNPJ}
        helperText={touched.partnersList?.[index]?.CNPJ && errors.partnersList?.[index]?.CNPJ}
        disabled={disabledForm}
        onChange={(e) => {
          setFieldValue(`partnersList.${index}.CNPJ`, formatIdentifier(e.target.value))
        }}
      />
    </BusinessPartnerItemContainer>
  </>;
}
