import { Button, Dialog, DialogActions, DialogContentText } from "@material-ui/core";
import styled from "styled-components";
import { primaryColors, textStyles } from "theme/styleguide";

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root{
    min-width: 600px;
    box-shadow: none;
    @media (max-width: 767px)
    {
      min-width: 150px;
      min-height: 350px;
    }
  }
`
export const StyledDialogContentText = styled(DialogContentText)`
  ${textStyles.tray_h5_15px}
  color: ${primaryColors.blue_5};
  text-align: center;
  white-space: pre-wrap;
  margin-bottom: 48px;
  padding: 0px 20px;
`
export const StyledDialogActions = styled(DialogActions)`
  justify-content: center;
  padding-bottom: 28px;
  @media (max-width: 767px)
  {
    flex-direction: column;
  }
`
export const StyledButton = styled(Button)`
  min-width: ${({ $hasLargerMinWidthButtons }) => $hasLargerMinWidthButtons ? "197px" : "95px"};
  @media (max-width: 767px)
  {
    min-width: ${({ $hasLargerMinWidthButtons }) => $hasLargerMinWidthButtons ? "197px" : "176px"};
    :first-child{
      margin-bottom: 12px;
    }
    :not(:first-child){
      margin-left: 0px;
    }
  }
`
export const StyledAlertImage = styled.img`
  display: block;
  margin: 0 auto;
  margin-bottom: 12px;
  margin-top: 16px;
`