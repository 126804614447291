import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const EmptyPartnersListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 224px;
`
export const StyledImage = styled.img`
  max-width: 382px;
`
export const Title = styled.span`
  display: block;
  ${textStyles.tray_h3_24px}
  color: ${grayScale.black};
  margin-top: 24px;
  margin-bottom: 56px;
`