import { Button, Tooltip } from '@material-ui/core';
import EyeIcon from 'assets/icons/EyeIcon';
import React, { useState } from 'react';
import useWindowWidth from 'utils/useWindowWidth';
import EmailExampleDialog from 'components/Onboarding/OnboardingSteps/shared/EmailExampleDialog';
import { EmailConcatanedList, EmailExampleListContainer, ExampleListItem } from './styles';

export default function EmailExampleList({
  list = [],
}) {
  const windowWidth = useWindowWidth()
  const [isDialogOpen, setDialogOpen] = useState(false)
  const [selectedExample, setSelectedExample] = useState()
  const handleOpenExample = (receivedExample) => {
    setDialogOpen(true)
    setSelectedExample(receivedExample)
  }
  return <>
    <EmailExampleListContainer>
      {
        windowWidth >= 768 ?
          list.map((e, i) => {
            return (
              <Tooltip
                title="Visualizar"
                placement="right"
                arrow
                key={i}
              >
                <ExampleListItem
                  as={Button}
                  role="li"
                  onClick={() => { handleOpenExample(e) }}
                >
                  {e.title}
                  <EyeIcon />
                </ExampleListItem>
              </Tooltip>
            )
          }) :
          <EmailConcatanedList>
            {
              list.map((e, i) => {
                return `${((i !== 0) && (i === list?.length - 1)) ?
                  " e " : ""} ${e.title}${(i < list?.length - 2) ?
                    ", " : ""
                  }`
              })
            }.
          </EmailConcatanedList>
      }
    </EmailExampleListContainer>
    <EmailExampleDialog
      selectedExample={selectedExample}
      isOpen={isDialogOpen}
      setOpen={setDialogOpen}
    />
  </>;
}