import React from 'react';
import { ExpandableCardContainer, StyledAccordion, StyledAccordionDetails, StyledAccordionSummary, Title } from './styles';
import StorefrontIcon from 'assets/icons/StorefrontIcon';
import ExpandMoreIcon from 'assets/icons/ExpandMoreIcon';

export default function ExpandableCard({
  title = "",
  children,
  ...props
}) {
  return <>
    <ExpandableCardContainer
      {...props}
    >
      <StyledAccordion>
        <StyledAccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <StorefrontIcon className="nonClickable" />
          <Title>
            {title}
          </Title>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          {children}
        </StyledAccordionDetails>
      </StyledAccordion>
    </ExpandableCardContainer>
  </>;
}
