import { Button } from "@material-ui/core";
import styled from "styled-components";
import { buttonStyles, primaryColors, shadowStyles } from "theme/styleguide";

export const StickyHeaderContainer = styled.div`
  min-height: 94px;
  >div{
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    ${shadowStyles.drop_shadow_1}
    z-index: 1;
    padding: 24px 8.5%;
    background-color: ${primaryColors.landing.brand.primary[25]};
    border-bottom: 5px solid ${primaryColors.landing.brand.primary[800]};
  }
`
export const StyledButton = styled(Button)`
  ${buttonStyles.landing_blue_2}
  min-width: 208px;
  height: 32px;
`