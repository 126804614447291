import React from 'react';
import { Field } from 'formik';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { UserNameFieldsContainer } from './styles';
import { IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import EditIcon from 'assets/icons/EditIcon';
import { grayScale } from 'theme/styleguide';
import { StyledTextField } from 'components/Dashboard/AccountDataMenu/styles';

export default function UserNameFields({
  errors,
  touched,
  isLoading = true,
  disabledForm = false,
  toggleDisabled = () => { },
}) {
  return <>
    <UserNameFieldsContainer>
      {
        isLoading ?
          <LoadingIndicator
            $minHeight={"16px"}
          /> :
          <>
            <Field
              as={StyledTextField}
              name="name"
              label="Nome"
              size="small"
              error={touched.name && !!errors.name}
              helperText={touched.name && errors.name}
              disabled={disabledForm}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                  >
                    <Tooltip
                      title="Editar"
                      arrow
                    >
                      <span>
                        <IconButton
                          size="small"
                          disabled={!disabledForm}
                          style={{ cursor: "pointer" }}
                          onClick={toggleDisabled}
                        >
                          <EditIcon
                            color={
                              !disabledForm ?
                                grayScale.gray_20p100 :
                                grayScale.gray_3
                            }
                          />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </InputAdornment>
                )
              }}
              $fullWidth
            />
          </>
      }
    </UserNameFieldsContainer>
  </>;
}