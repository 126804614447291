import React from 'react';
import { Field } from 'formik';
import DatePicker from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/DatePicker';
import AddressFields from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/AddressFields';
import { StyledTextField, FieldSectionTitle, FieldSectionDescription } from 'components/Dashboard/AccountDataMenu/styles';
import { StyledRadioOptions } from 'fields/Dashboard/MyAccountTab/AccountDataMenu/BusinessAccount/PartnersFields/styles';
import { formatIdentifier } from 'utils/formatIdentifier';
import { formatPhone } from 'utils/formatPhone';
import { PersonalPartnerItemContainer } from './styles';

const isPPEOptions = [
  {
    value: "false",
    label: "Não"
  },
  {
    value: "true",
    label: "Sim"
  },
]
export default function PersonalPartnerItem({
  values,
  touched,
  errors,
  disabledForm,
  setFieldValue,
  partner,
  index,
}) {
  return <>
    <PersonalPartnerItemContainer>
      <Field
        as={StyledTextField}
        name={`partnersList.${index}.name`}
        label="Nome"
        size="small"
        error={touched.partnersList?.[index]?.name && !!errors.partnersList?.[index]?.name}
        helperText={touched.partnersList?.[index]?.name && errors.partnersList?.[index]?.name}
        disabled={disabledForm}
      />
      <Field
        as={StyledTextField}
        name={`partnersList.${index}.CPF`}
        label="CPF"
        size="small"
        error={touched.partnersList?.[index]?.CPF && !!errors.partnersList?.[index]?.CPF}
        helperText={touched.partnersList?.[index]?.CPF && errors.partnersList?.[index]?.CPF}
        disabled={disabledForm}
        onChange={(e) => {
          setFieldValue(`partnersList.${index}.CPF`, formatIdentifier(e.target.value))
        }}
      />
      <Field
        as={DatePicker}
        name={`partnersList.${index}.birthDate`}
        label="Data de nascimento"
        size="small"
        setFieldValue={setFieldValue}
        error={touched.partnersList?.[index]?.birthDate && !!errors.partnersList?.[index]?.birthDate}
        helperText={touched.partnersList?.[index]?.birthDate && errors.partnersList?.[index]?.birthDate}
        disabled={disabledForm}
        $fullWidth={false}
      />
      <Field
        as={StyledTextField}
        name={`partnersList.${index}.telephone`}
        label="Telefone"
        size="small"
        error={touched.partnersList?.[index]?.telephone && !!errors.partnersList?.[index]?.telephone}
        helperText={touched.partnersList?.[index]?.telephone && errors.partnersList?.[index]?.telephone}
        disabled={disabledForm}
        onChange={(e) => { setFieldValue(`partnersList.${index}.telephone`, formatPhone(e.target.value)) }}
        inputProps={{
          maxLength: 15,
          type: "tel"
        }}
      />
      <Field
        as={StyledTextField}
        name={`partnersList.${index}.motherName`}
        label="Nome completo da mãe"
        size="small"
        error={touched.partnersList?.[index]?.motherName && !!errors.partnersList?.[index]?.motherName}
        helperText={touched.partnersList?.[index]?.motherName && errors.partnersList?.[index]?.motherName}
        disabled={disabledForm}
      />
      <FieldSectionTitle>
        Esta pessoa sócia é uma Pessoa Politicamente Exposta (PPE)?
      </FieldSectionTitle>
      <FieldSectionDescription>
        Consideram-se Pessoas Politicamente Expostas aquelas que desempenham ou tenham desempenhado cargos ou funções públicas relevantes nos últimos cinco anos, no Brasil ou em outros países.
      </FieldSectionDescription>
      <StyledRadioOptions
        receivedValue={partner?.isPPE}
        receivedSetter={(e) => { setFieldValue(`partnersList.${index}.isPPE`, e) }}
        options={isPPEOptions}
        disabled={disabledForm}
      />
      <FieldSectionTitle>
        Endereço da pessoa sócia
      </FieldSectionTitle>
      <AddressFields
        namePrefix={`partnersList.${index}.address`}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        disabledForm={disabledForm}
      />
    </PersonalPartnerItemContainer>
  </>;
}
