import React, { useState } from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import EditIcon from 'assets/icons/EditIcon';
import ErrorIcon from 'assets/icons/ErrorIcon';
import LoadingIndicator from 'components/shared/LoadingIndicator';
import { Field } from 'formik';
import { PhoneDataFormContainer, Column, StyledTextField, Instruction, InstructionContainer, InstructionTitle, DropdownText, InstructionContainerMobile } from './styles';
import { formatPhone } from 'utils/formatPhone';
import DropdownCard from 'components/shared/DropdownCard';

export default function PhoneDataForm({
  isLoading = true,
  setFieldValue = () => { },
  errors,
  isFromDashboard = false,
}) {
  const [isDisabled, setDisabled] = useState(!isFromDashboard)
  const toggleDisabled = () => {
    setDisabled((oldValue) => !oldValue)
  }
  return (
    <>
      {
        isLoading ?
          <LoadingIndicator /> :
          <PhoneDataFormContainer
            $isFromDashboard={isFromDashboard}
          >
            <Column>
              <Instruction>
                {
                  isFromDashboard ?
                    "Informe o novo número de celular" :
                    "Agora, confirme o número de celular verificado ou informe um número para verificação"
                }
              </Instruction>
              <Field
                as={StyledTextField}
                name="phone"
                size="small"
                disabled={isDisabled}
                error={!!errors.phone}
                helperText={errors.phone}
                onChange={(e) => {
                  setFieldValue("phone", formatPhone(e.target.value))
                }}
                inputProps={{
                  maxLength: 15,
                  type: "tel"
                }}
                InputProps={{
                  endAdornment: (
                    isDisabled &&
                    <InputAdornment
                      position="end"
                      style={{ cursor: "pointer" }}
                      onClick={toggleDisabled}
                    >
                      <IconButton
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Column>
            <Column>
              <InstructionContainer
                $isFromDashboard={isFromDashboard}
              >
                <ErrorIcon />
                <InstructionTitle>
                  O que é a verificação de celular?
                </InstructionTitle>
                <Instruction>
                  {`É o envio de um código via sms para validação do número de celular.\n\nContas com celular verificado tem mais permissões para editar dados específicos do cadastro. \n\nTambém é uma forma de entrarmos em contato caso sejam verificadas atividades suspeitas na conta.`}
                </Instruction>
              </InstructionContainer>
            </Column>
            <InstructionContainerMobile>
              <DropdownCard
                title={"O que é a verificação de celular?"}
              >
                <DropdownText>
                  {`É o envio de um código via sms para validação do número de celular.\n\nContas com celular verificado tem mais permissões para editar dados específicos do cadastro.\n\nTambém é uma forma de entrarmos em contato caso sejam verificadas atividades suspeitas na conta.`}
                </DropdownText>
              </DropdownCard>
            </InstructionContainerMobile>
          </PhoneDataFormContainer>
      }
    </>
  )
}
