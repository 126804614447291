import { Button } from '@material-ui/core';
import CancelFormFillingDialog from 'components/CompleteRegisterMenu/shared/CancelFormFillingDialog';
import useCompleteBusinessAccountTitularDataSetup from 'components/FormsSetup/AccountData/useCompleteBusinessAccountTitularDataSetup';
import { ButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import CompleteBusinessAccountTitularDataForm from 'components/Onboarding/OnboardingForms/AccountDataForms/CompleteBusinessAccountTitularDataForm';
import AdditionalDataDetails from 'components/Onboarding/OnboardingForms/AccountDataForms/shared/AdditionalDataDetails';
import LoadableButton from 'components/shared/LoadableButton';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import { Form, Formik } from 'formik';
import React from 'react';
import { CompleteBusinessAccountTitularDataMenuItemContainer } from './styles';

export default function CompleteBusinessAccountTitularDataMenuItem() {
  const { goBackToCompleteRegisterMenu, toggleCancelFormFillingDialog, updateCompletedRegisterStep } = useDashboardMyAccount()
  const { initialValues, validationSchema, isLoading, handleSubmit, displayedName, identifier } = useCompleteBusinessAccountTitularDataSetup({
    afterSubmit: () => { // Fluxo para contas com sócio com >25%
      updateCompletedRegisterStep("completeBusinessAccountTitularData")
      goBackToCompleteRegisterMenu()
    },
    afterSubmitAlternative: () => { // Fluxo para contas sem sócio com >25%
      updateCompletedRegisterStep("completeBusinessAccountTitularData")
      goBackToCompleteRegisterMenu()
    },
  })
  return <>
    <CompleteBusinessAccountTitularDataMenuItemContainer>
      {
        !isLoading &&
        <AdditionalDataDetails
          displayedName={displayedName}
          identifier={identifier}
          isBusinessAccount
        />
      }
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <CompleteBusinessAccountTitularDataForm
                {...formikProps}
                isLoading={isLoading}
                initialValues={initialValues}
                isOnboardingStep
              />
              <ButtonsContainer
                $shouldNotReverseColumn
              >
                <LoadableButton
                  type="submit"
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_completar_cadastro_dados_titular_botao_salvar"}
                >
                  Salvar
                </LoadableButton>
                <Button
                  variant="outlined"
                  onClick={toggleCancelFormFillingDialog}
                  disabled={formikProps.isSubmitting || isLoading}
                  id={"tela_completar_cadastro_dados_titular_botao_cancelar"}
                >
                  Cancelar
                </Button>
              </ButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </CompleteBusinessAccountTitularDataMenuItemContainer>
    <CancelFormFillingDialog
      cancelFillingFormFunction={goBackToCompleteRegisterMenu}
    />
  </>;
}
