import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchPhoneDelete = async ({
  phone_id
}) => {
  const code = getDataStorage('yapay_code')
  return apiPrivate.post("/myaccount/phones/delete", {
    phone_id,
  }, {
    headers: {
      code,
    }
  })
    .then(({ data }) => {
      return data
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}