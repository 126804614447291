import { Button, IconButton } from '@material-ui/core';
import useEmailEditSetup from 'components/FormsSetup/AccountData/useEmailEditSetup';
import EmailEditForm from 'components/Onboarding/OnboardingForms/AccountDataForms/EmailEditForm';
import LoadableButton from 'components/shared/LoadableButton';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import { Form, Formik } from 'formik';
import React from 'react';
import { EmailEditMenuItemContainer } from './styles';
import { StyledButtonsContainer, StyledDashboardTitle, TitleContainer } from 'components/Dashboard/DashboardMyAccount/styles';
import ArrowBackIcon from 'assets/icons/ArrowBackIcon';

export default function EmailEditMenuItem() {
  const { setCurrentMyAccountMenuItem, goBackToUserDataMenu, setSnackbarOpen, setSnackbarMessage } = useDashboardMyAccount()
  const { initialValues, validationSchema, isLoading, handleSubmit } = useEmailEditSetup({
    afterSubmit: () => {
      goBackToUserDataMenu()
      setSnackbarOpen(true)
      setSnackbarMessage("E-mail alterado")
    },
    errorSubmit: () => {
      setCurrentMyAccountMenuItem("emailSMSVerification")
    }
  })
  return <>
    <EmailEditMenuItemContainer>
      <TitleContainer>
        <IconButton
          size="small"
          onClick={goBackToUserDataMenu}
        >
          <ArrowBackIcon />
        </IconButton>
        <StyledDashboardTitle>
          Editar e-mail
        </StyledDashboardTitle>
      </TitleContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <EmailEditForm
                {...formikProps}
                isLoading={isLoading}
                initialValues={initialValues}
              />
              <StyledButtonsContainer>
                <LoadableButton
                  type="submit"
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  isLoading={formikProps.isSubmitting}
                >
                  Salvar
                </LoadableButton>
                <Button
                  variant="outlined"
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  onClick={goBackToUserDataMenu}
                >
                  Cancelar
                </Button>
              </StyledButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </EmailEditMenuItemContainer>
  </>;
}
