import { useCallback, useEffect, useState } from 'react';
import { object, array } from 'yup';
import { fetchSearchSellerSplits } from 'services/SellerSplits/POST/SearchSellerSplitsService';
import { fetchCreateSellerSplits } from 'services/SellerSplits/POST/CreateSellerSplitsService';
import { parseParcelsOptionsToArray } from 'utils/parseParcelsOptionsToArray';
import { getBiggestParcelValueWithoutFee } from 'utils/getBiggestParcelValueWithoutFee';
import { fetchGetAccount } from 'services/Account/GET/GetAccountService';
import { useRef } from 'react';

export default function useCreditCardSetup({
  afterSubmit = () => { }
}) {
  const mounted = useRef(false);

  const [parcelsOptions, setParcelsOptions] = useState([])
  const [replySelectedParcels, setReplySelectedParcels] = useState(false)

  const [initialValues, setInitialValues] = useState({
    parcelValues: []
  })

  const [taxValue, setTaxValue] = useState("")
  const validationSchema = object({
    parcelValues: array()
  })
  const [isLoading, setLoading] = useState(true)
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)

  const fetchCreditCardSplitsData = useCallback(async () => {
    const { seller_split } = await fetchSearchSellerSplits()
    if (mounted.current) {
      const { payment_tax } = await fetchGetAccount()
      const auxTaxValue = String(payment_tax.taxes.filter(({ type }) => type === "card")[0].split_tax.replace(".", ","))
      if (mounted.current) {
        setInitialValues({
          parcelValues: [...getBiggestParcelValueWithoutFee(seller_split)]
        })
        setParcelsOptions(seller_split)
        setTaxValue(auxTaxValue)
        setLoading(false)
      }
    }
  }, [])



  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async ({
    parcelValues
  }) => {
    const parcelsArray = parseParcelsOptionsToArray(parcelsOptions, parcelValues)
    await fetchCreateSellerSplits(
      parcelsArray,
    )
    setSnackbarOpen(true)
    setInitialValues({
      parcelValues
    })
    afterSubmit()
  }

  useEffect(() => {
    fetchCreditCardSplitsData()
  }, [fetchCreditCardSplitsData])

  return ({
    initialValues,
    setInitialValues,
    validationSchema,
    handleSubmit,
    parcelsOptions,
    taxValue,
    isLoading,
    isSnackbarOpen,
    setSnackbarOpen,
    replySelectedParcels,
    setReplySelectedParcels
  })
}