import { Button } from '@material-ui/core';
import CancelFormFillingDialog from 'components/CompleteRegisterMenu/shared/CancelFormFillingDialog';
import useAdditionalDataSetup from 'components/FormsSetup/AccountData/useAdditionalDataSetup';
import { ButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import AdditionalDataForm from 'components/Onboarding/OnboardingForms/AccountDataForms/AdditionalDataForm';
import LoadableButton from 'components/shared/LoadableButton';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import { Form, Formik } from 'formik';
import React from 'react';
import { AdditionalDataMenuItemContainer } from './styles';

export default function AdditionalDataMenuItem() {
  const { goBackToCompleteRegisterMenu, toggleCancelFormFillingDialog, updateCompletedRegisterStep } = useDashboardMyAccount()
  const { initialValues, validationSchema, isLoading, handleSubmit, displayedName, identifier, isBusinessAccount, constitutionList, legalNatureList, bankOptionsList, accountTypesList, missingPendencies } = useAdditionalDataSetup({
    afterSubmit: () => { // Fluxo para contas PF
      updateCompletedRegisterStep("additionalData")
      goBackToCompleteRegisterMenu()
    },
    afterSubmitAlternative: () => { // Fluxo para contas PJ
      updateCompletedRegisterStep("additionalData")
      goBackToCompleteRegisterMenu()
    },
  })
  return <>
    <AdditionalDataMenuItemContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        validateOnMount
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <AdditionalDataForm
                {...formikProps}
                isLoading={isLoading}
                initialValues={initialValues}
                displayedName={displayedName}
                identifier={identifier}
                isBusinessAccount={isBusinessAccount}
                constitutionList={constitutionList}
                legalNatureList={legalNatureList}
                bankOptionsList={bankOptionsList}
                accountTypesList={accountTypesList}
                missingPendencies={missingPendencies}
                isOnboardingStep
              />
              <ButtonsContainer
                $shouldNotReverseColumn
              >
                <LoadableButton
                  type="submit"
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_completar_cadastro_dados_titular_botao_salvar"}
                >
                  Salvar
                </LoadableButton>
                <Button
                  variant="outlined"
                  onClick={toggleCancelFormFillingDialog}
                  disabled={formikProps.isSubmitting || isLoading}
                  id={"tela_completar_cadastro_dados_titular_botao_cancelar"}
                >
                  Cancelar
                </Button>
              </ButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </AdditionalDataMenuItemContainer>
    <CancelFormFillingDialog
      cancelFillingFormFunction={goBackToCompleteRegisterMenu}
    />
  </>;
}
