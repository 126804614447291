import { IconButton } from '@material-ui/core';
import { Slide } from '@material-ui/core';
import CloseIcon from 'assets/icons/CloseIcon';
import React from 'react';
import useWindowWidth from 'utils/useWindowWidth';
import { StyledDialog, StyledDialogContent, DialogHeader, StyledImage, Title } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EmailExampleDialog({
  selectedExample,
  isOpen = false,
  setOpen = () => { },
}) {
  const windowWidth = useWindowWidth()
  const handleClose = () => {
    setOpen(false)
  }
  return <>
    <StyledDialog
      open={isOpen}
      TransitionComponent={Transition}
      onClose={handleClose}
      fullScreen={windowWidth <= 768}
    >
      <DialogHeader>
        <Title>
          {selectedExample?.title}
        </Title>
        <IconButton
          onClick={handleClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </DialogHeader>
      <StyledDialogContent>
        <StyledImage
          src={selectedExample?.exampleImage}
          alt={selectedExample?.title}
        />
      </StyledDialogContent>
    </StyledDialog>
  </>;
}
