import votorantim from 'assets/images/banks/votorantim.png'
import sicredi from 'assets/images/banks/sicredi.png'
import sicoob from 'assets/images/banks/sicoob.png'
import santander from 'assets/images/banks/santander.png'
import safra from 'assets/images/banks/safra.png'
import nubank from 'assets/images/banks/nubank.png'
import mercantil from 'assets/images/banks/mercantil.png'
import itau from 'assets/images/banks/itau.png'
import hsbc from 'assets/images/banks/hsbc.png'
import citibank from 'assets/images/banks/citibank.png'
import caixa from 'assets/images/banks/caixa.png'
import bradesco from 'assets/images/banks/bradesco.png'
import bb from 'assets/images/banks/bb.png'
import banrisul from 'assets/images/banks/banrisul.png'
import banco from 'assets/images/banks/banco.png'

export const bankDictionary = {
  "Votorantim": {
    name: "Votorantim",
    icon: votorantim,
  },
  "Sicredi": {
    name: "Sicredi",
    icon: sicredi,
  },
  "Sicoob": {
    name: "Sicoob",
    icon: sicoob,
  },
  "Santander": {
    name: "Santander",
    icon: santander,
  },
  "Safra": {
    name: "Safra",
    icon: safra,
  },
  "Nubank": {
    name: "Nubank",
    icon: nubank,
  },
  "Mercantil do Brasil": {
    name: "Mercantil do Brasil",
    icon: mercantil,
  },
  "Itaú": {
    name: "Itaú",
    icon: itau,
  },
  "HSBC": {
    name: "HSBC",
    icon: hsbc,
  },
  "Citibank": {
    name: "Citibank",
    icon: citibank,
  },
  "Caixa": {
    name: "Caixa",
    icon: caixa,
  },
  "Bradesco": {
    name: "Bradesco",
    icon: bradesco,
  },
  "Banco do Brasil": {
    name: "Banco do Brasil",
    icon: bb,
  },
  "Banrisul": {
    name: "Banrisul",
    icon: banrisul,
  },
  placeholder: {
    name: "Banco",
    icon: banco,
  },
}