import apiPrivate from 'services/apiPrivate'
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService'
import { getDataStorage } from 'utils/storage'

export const fetchUploadDocument = async ({
  file,
  group,
  document_identify,
  document_type,
  category,
  lost_account_verification,
  business_partner_id,
}) => {
  const code = getDataStorage('yapay_code')
  const token_id = getDataStorage('token_id') || await fetchGetAccountMyAccount()
  const formData = new FormData()
  formData.append("file", file);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      code,
      token_id,
      ...(!!group && { group }),
      ...(!!document_type && { document_type }),
      ...(!!document_identify && { document_identify }),
      ...(!!category && { category }),
      ...((lost_account_verification !== undefined) && { lost_account_verification }),
      ...(!!business_partner_id && { business_partner_id }),
    }
  }
  return apiPrivate.post("/document/document ", formData, config)
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}