/* eslint-disable max-len */
import React from 'react';

export default function VindiDollarAccountIcon({ size = 1, color = "#0092FA" }) {
  return <>
    <i>
      <svg
        width={25 * size}
        height={25 * size}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.38806 2.58331C9.12285 2.58334 8.86852 2.6887 8.68098 2.87624C8.49345 3.06377 8.38809 3.3181 8.38806 3.58331V4.08331V7.58331H6.38806C5.83606 7.58331 5.38806 7.13431 5.38806 6.58331C5.38806 6.03231 5.83606 5.58331 6.38806 5.58331V3.58331C4.73406 3.58331 3.38806 4.92931 3.38806 6.58331V19.5833C3.38806 20.6863 4.28506 21.5833 5.38806 21.5833H19.3881C20.4911 21.5833 21.3881 20.6863 21.3881 19.5833V9.58331C21.3881 8.48031 20.4911 7.58331 19.3881 7.58331H18.3881V3.58331C18.388 3.3181 18.2827 3.06377 18.0951 2.87624C17.9076 2.6887 17.6533 2.58334 17.3881 2.58331H9.88806H9.38806ZM11.3002 4.58331H15.476C15.5506 4.79362 15.6712 4.98463 15.829 5.14242C15.9867 5.30021 16.1778 5.42081 16.3881 5.49542V7.58331H14.3881C14.3881 7.03131 13.9401 6.58331 13.3881 6.58331C12.8361 6.58331 12.3881 7.03131 12.3881 7.58331H10.3881V5.49542C10.5984 5.42081 10.7894 5.30021 10.9472 5.14242C11.105 4.98463 11.2256 4.79362 11.3002 4.58331V4.58331ZM5.38806 9.39972C5.70245 9.51188 6.03567 9.58331 6.38806 9.58331H9.38806H19.3881V19.5833H5.38806V9.39972ZM16.3881 13.5833C15.8361 13.5833 15.3881 14.0313 15.3881 14.5833C15.3881 15.1353 15.8361 15.5833 16.3881 15.5833C16.9401 15.5833 17.3881 15.1353 17.3881 14.5833C17.3881 14.0313 16.9401 13.5833 16.3881 13.5833Z"
          fill={color}
        />
      </svg>
    </i>
  </>;
}
