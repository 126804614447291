import apiPrivate from 'services/apiPrivate'
import { getDataStorage } from 'utils/storage'

export const fetchGetLegalNatureList = async () => {
  const code = getDataStorage('yapay_code')
  return apiPrivate.get("/myaccount/account/legal_natures", {
    headers: {
      code
    }
  })
    .then(({ data }) => {
      return data?.data
        ?.filter((e) => e.active)
        ?.sort((a, b) => a.name.localeCompare(b.name)) ||
        []
    })
    .catch((error) => {
      console.error(error)
      return error
    })
}