import ReportProblem from 'assets/icons/ReportProblem';
import { useDashboard } from 'context/DashboardContext';
import { useDashboardMyAccount } from 'context/DashboardMyAccountContext';
import React from 'react';
import { BannerContent, BannerHeader, BlockedTransactionsBannerContainer, ContentContainer, ContentDescription, HeaderTitle, StyledButton } from './styles';

export default function BlockedTransactionsBanner() {
  const { showBlockedTransactionsBanner, setCurrentTab } = useDashboard()
  const { setCurrentMyAccountMenuItem } = useDashboardMyAccount()

  const handleNavigation = () => {
    setCurrentTab("myaccount")
    setCurrentMyAccountMenuItem("accountVerificationMenu")
  }

  return <>
    {
      showBlockedTransactionsBanner &&
      <BlockedTransactionsBannerContainer>
        <BannerHeader>
          <ReportProblem
            color="#00A3FF"
            size={1.33}
          />
          <HeaderTitle>
            <span>
              Atenção! Suas vendas estão bloqueadas
            </span>
          </HeaderTitle>
        </BannerHeader>
        <BannerContent>
          <ContentContainer>
            <ContentDescription>
              {`Devido a uma alteração feita recentemente em dados importantes da conta, será necessário enviar uma documentação para comprovação e desbloqueio das transações.\nApós esta documentação ser enviada por você e analisada pela Vindi, você poderá voltar a receber pagamentos normalmente.`}
            </ContentDescription>
            <StyledButton
              onClick={handleNavigation}
              id={"plugin_banner_perda-verificacao_botao_enviar-documentação"}
            >
              Enviar documentação
            </StyledButton>
          </ContentContainer>
        </BannerContent>
      </BlockedTransactionsBannerContainer>
    }
  </>;
}
