import React from 'react';
import { useOnboarding } from 'context/OnboardingContext';
import { Form, Formik } from 'formik';
import { EmailDataStepContainer } from './styles';
import { ButtonsContainer } from 'components/Onboarding/OnboardingBody/styles';
import EmailDataForm from 'components/Onboarding/OnboardingForms/AccountDataForms/EmailDataForm';
import useEmailDataSetup from 'components/FormsSetup/AccountData/useEmailDataSetup';
import LoadableButton from 'components/shared/LoadableButton';
import { getCurrentOnboardingStepsCollection } from 'utils/getCurrentOnboardingStepsCollection';
import { getHideOnCountStepsGoingForward } from 'utils/getHideOnCountStepsGoingForward';
import { accountDataStepsDictionary } from "library/accountDataStepsDictionary"

export default function EmailDataStep() {
  const stepsCollectionLength = getCurrentOnboardingStepsCollection("accountDataSteps").length - 1
  const { nextProfileSettingsStep, nextOnboardingSection, profileSettingsStep } = useOnboarding()
  const { initialValues, validationSchema, isLoading, handleSubmit, hasVerifiedPhone } = useEmailDataSetup({
    afterSubmit: () => {
      if (profileSettingsStep < stepsCollectionLength) {
        const hideOnCountStepsGoingBack = getHideOnCountStepsGoingForward("accountDataSteps", profileSettingsStep, accountDataStepsDictionary)
        if (profileSettingsStep + hideOnCountStepsGoingBack <= stepsCollectionLength) {
          for (let i = 0; i < hideOnCountStepsGoingBack; i++) {
            nextProfileSettingsStep()
          }
        } else {
          nextOnboardingSection()
        }
      } else {
        nextOnboardingSection()
      }
    }
  })
  return <>
    <EmailDataStepContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {
          ({ ...formikProps }) => (
            <Form>
              <EmailDataForm
                {...formikProps}
                isLoading={isLoading}
                initialValues={initialValues}
                hasVerifiedPhone={hasVerifiedPhone}
              />
              <ButtonsContainer>
                <LoadableButton
                  type="submit"
                  disabled={formikProps.isSubmitting || !formikProps.isValid || isLoading}
                  isLoading={formikProps.isSubmitting}
                  id={"tela_email_onboarding_botao_continuar"}
                >
                  Continuar
                </LoadableButton>
              </ButtonsContainer>
            </Form>
          )
        }
      </Formik>
    </EmailDataStepContainer>
  </>;
}
