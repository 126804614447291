import { Button } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import styled from "styled-components";
import { grayScale, primaryColors, textStyles } from "theme/styleguide";

export const BankAccountErrorContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
`
export const StyledAlert = styled(Alert)`
  width: 100%;
  ${textStyles.tray_text_12px};
  color: ${({ $isDocumentRejected }) => $isDocumentRejected ? grayScale.gray_3 : 'white'};
  white-space: pre-wrap;
  background: ${({ $isDocumentRejected }) => $isDocumentRejected ? 'rgba(211, 49, 49, 0.1);' : '#2E7EE7'};
  border-radius: 2px;
  b{
    font-weight: bold;
  }
  a{
    color: ${({ $isDocumentRejected }) => $isDocumentRejected ? grayScale.gray_3 : 'white'};
    font-weight: bold;
  }
  @media (min-width: 768px)
  {
    ${textStyles.tray_text_14px};
    b{
      ${textStyles.h5_16px};
      font-weight: bold;
      color: ${({ $isDocumentRejected }) => $isDocumentRejected ? grayScale.gray_3 : 'white'};
    }
  }
`
export const StyledButton = styled(Button)`
  margin-top: 20px;
  margin-bottom: 20px;
  background:${({ $isDocumentRejected }) => $isDocumentRejected ? primaryColors.blue_5 : 'white'};
  max-width:250px;
  color: ${({ $isDocumentRejected }) => $isDocumentRejected ? 'white' : primaryColors.blue_5};
  :hover{
      color:white;
  };
  @media (max-width: 767px)
  {
    width: 100%;
    padding: 6px;
    margin-bottom: 8px;
    font-size: 13px;
  }
`