import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const UserFacePictureUploadFormContainer = styled.div`
  margin-bottom: 32px;
  @media (min-width: 768px)
  {
    margin-bottom: 40px;
  }
`
export const InstructionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px)
  {
    flex-direction: row;
    justify-content: space-between;
  }
`
export const InstructionsInnerLeftContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  >div:last-child{
    align-self: center;
  }
  @media (min-width: 768px)
  {
    align-items: flex-start;
    >div:last-child{
      align-self: flex-start;
    }
  }
`
export const ImageContainer = styled.div`
  flex: 1;
  img{
    display: none;
  }
  @media (min-width: 768px)
  {
    img{
      display: block;
      max-width: 248px;
      margin: 0 auto;
      margin-top: -48px;
    }
  }
`
export const InstructionsText = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  white-space: pre-wrap;
  margin-bottom: 32px;
`
export const InstructionsList = styled.ul`
  padding-left: 20px;
  margin-top: 32px;
  margin-bottom: 32px;
  li{
    ${textStyles.tray_text_14px}
    color: ${grayScale.gray_60p100};
  }
  @media (min-width: 768px)
  {
    padding-left: 40px;
    margin-bottom: 80px;
  }
`