import { useCallback, useEffect, useState, useRef } from 'react';
import { fetchUpdateAccountData } from 'services/Account/POST/UpdateAccountData';
import { fetchGetAddress } from 'services/Address/GET/GetAddressService';
import { fetchUpdateAddress } from 'services/Address/POST/UpdateAddressService';
import { fetchGetAccountMyAccount } from 'services/MyAccount/GET/GetAccountMyAccountService';
import { fetchPhoneList } from 'services/Phone/GET/PhoneListService';
import { fetchPhoneCreate } from 'services/Phone/POST/PhoneCreateService';
import { fetchPhoneDelete } from 'services/Phone/POST/PhoneDeleteService';
import { fetchPhoneUpdate } from 'services/Phone/POST/PhoneUpdateService';
import { formatDate } from 'utils/formatDate';
import { formatIdentifier } from 'utils/formatIdentifier';
import { formatRG } from 'utils/formatRG';
import { parseStringToCEP } from 'utils/parseStringToCEP';
import { validateIdentifier } from 'utils/validateIdentifier';
import { object, string, date, number, array } from 'yup';

export default function usePersonalAccountTitularPersonDataSetup({
  reloadAfterSubmit = false,
  afterSubmit = () => { },
}) {
  const mounted = useRef(false)
  const [initialValues, setInitialValues] = useState()
  const [oldValues, setOldValues] = useState({})
  const [validationSchema, setValidationSchema] = useState()
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [hasDigitalAccount, setHasDigitalAccount] = useState(true)

  const fetchData = useCallback(async () => {
    const fetchedMyAccount = await fetchGetAccountMyAccount()
    const fetchedTelephoneList = await fetchPhoneList()
    const fetchedAdress = await fetchGetAddress()

    const validationObject = {
      name: string()
        .required("Este campo é obrigatório"),
      CPF: string()
        .test('valid-identifier', 'O CPF informado é inválido', validateIdentifier)
        .required("Este campo é obrigatório"),
      telephoneList: array()
        .of(
          object()
            .shape({
              phoneNumber: string()
                .min(14, 'O número de telefone informado deve possuir ao menos 14 dígitos')
                .max(15, 'O número de telefone informado deve possuir no máximo 15 dígitos')
                .required('Este campo deve ser preenchido ou excluído'),
              phoneType: string()
                .required("Este campo é obrigatório"),
            })
        ),
      excludedTelephoneList: array(),
      address: object()
        .shape({
          street: string(),
          number: string(),
          complement: string(),
          neighborhood: string(),
          city: string(),
          state: string(),
          zipCode: string()
            .min(9, 'O CEP informado é inválido'),
        }),
      ...(fetchedMyAccount?.use_digital_account && {
        birthDate: date()
          .nullable()
          .typeError("Informe uma data válida"),
        RG: string()
          .min(11, 'O RG informado deve possuir ao menos 11 dígitos')
          .max(12, 'O RG informado deve possuir no máximo 12 dígitos')
          .required("Este campo é obrigatório"),
        RGIssueDate: date()
          .nullable()
          .typeError("Informe uma data válida"),
        profession: string()
          .required('Este campo é obrigatório'),
        professionSearchValue: string(),
        monthlyIncome: number()
          .required('Este campo é obrigatório'),
        motherName: string()
          .required("Este campo é obrigatório"),
        isPPE: string()
          .required('Este campo é obrigatório'),
      }),
    }

    const initialValuesObject = {
      name: fetchedMyAccount?.name || "",
      CPF: formatIdentifier(fetchedMyAccount?.cpf) || "",
      telephoneList: fetchedTelephoneList || [{
        phoneType: "business",
        phoneNumber: "",
        isNewRegister: true,
        isUpdatedRegister: false,
      }],
      excludedTelephoneList: [],
      address: {
        street: fetchedAdress?.street || "",
        number: fetchedAdress?.number || "",
        complement: fetchedAdress?.complement || "",
        neighborhood: fetchedAdress?.neighborhood || "",
        city: fetchedAdress?.city || "",
        state: fetchedAdress?.state || "",
        zipCode: parseStringToCEP(fetchedAdress?.zipcode || ""),
      },
      hasDigitalAccount: fetchedMyAccount?.use_digital_account,
      ...(fetchedMyAccount?.use_digital_account && {
        birthDate: formatDate(fetchedMyAccount?.birth_date),
        RG: formatRG(fetchedMyAccount?.rg || ""),
        RGIssueDate: formatDate(fetchedMyAccount?.rg_issue_date),
        profession: fetchedMyAccount?.profession?.id || "",
        professionSearchValue: fetchedMyAccount?.profession?.name || "",
        monthlyIncome: parseFloat(fetchedMyAccount?.personal_monthly_income || 0),
        motherName: fetchedMyAccount?.mother_name || "",
        isPPE: String(fetchedMyAccount?.politically_exposed || false),
      }),
    }

    if (mounted.current) {
      setInitialValues(initialValuesObject)
      setOldValues(initialValuesObject)
      setValidationSchema(object(validationObject))
      setHasDigitalAccount(fetchedMyAccount?.use_digital_account)
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    mounted.current = true
    return () => { mounted.current = false }
  }, [])

  const handleSubmit = async ({
    name,
    birthDate,
    RG,
    RGIssueDate,
    profession,
    monthlyIncome,
    motherName,
    isPPE,
    telephoneList,
    excludedTelephoneList,
    address,
    hasDigitalAccount,
  }) => {
    const personalAccountTitularPersonObject = {
      ...(!!name && name !== oldValues?.name) && {
        name: name
      },
      ...(hasDigitalAccount && {
        ...(!!birthDate && birthDate !== oldValues?.birthDate) && {
          birth_date: birthDate
        },
        ...(!!RG && RG !== oldValues?.RG) && {
          rg: RG
        },
        ...(!!RGIssueDate && RGIssueDate !== oldValues?.RGIssueDate) && {
          rg_issue_date: RGIssueDate
        },
        ...(!!profession && profession !== oldValues?.profession) && {
          profession_id: profession
        },
        ...(!!monthlyIncome && monthlyIncome !== oldValues?.monthlyIncome) && {
          personal_monthly_income: monthlyIncome
        },
        ...(!!motherName && motherName !== oldValues?.motherName) && {
          mother_name: motherName
        },
        ...(isPPE !== oldValues?.isPPE) && {
          politically_exposed: isPPE
        },
      }),
    }

    if (Object.keys(personalAccountTitularPersonObject).length > 0) {
      await fetchUpdateAccountData(personalAccountTitularPersonObject)
    }

    if (excludedTelephoneList?.length > 0) {
      for (const excludedTelephone of excludedTelephoneList) {
        await fetchPhoneDelete({
          phone_id: excludedTelephone.id
        })
      }
    }

    if (telephoneList?.length > 0) {
      for (const telephone of telephoneList) {
        if (telephone.isNewRegister) {
          await fetchPhoneCreate({
            phone: telephone.phoneNumber,
            phone_type_tag: telephone.phoneType,
          })
        } else if (telephone.isUpdatedRegister) {
          await fetchPhoneUpdate({
            phone: telephone.phoneNumber,
            phone_type_tag: telephone.phoneType,
            phone_id: telephone.id,
          })
        }
      }
    }

    if (JSON.stringify(address) !== JSON.stringify(oldValues.address) &&
      !!address.street &&
      !!address.number &&
      !!address.neighborhood &&
      !!address.city &&
      !!address.state &&
      !!address.zipCode
    ) {
      await fetchUpdateAddress({
        street: address.street,
        number: address.number,
        complement: address.complement,
        neighborhood: address.neighborhood,
        city: address.city,
        state: address.state,
        zipcode: address.zipCode,
      })
    }

    if (reloadAfterSubmit) {
      await fetchData()
    }
    setSnackbarOpen(true)
    afterSubmit()
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return ({
    initialValues,
    validationSchema,
    handleSubmit,
    isLoading,
    isSnackbarOpen,
    setSnackbarOpen,
    hasDigitalAccount,
  })
}