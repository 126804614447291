import { Button } from "@material-ui/core";
import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const EmailDataFormContainer = styled.div`
  margin-top: 45px;
  margin-bottom: 80px;
  @media (min-width: 768px){
    margin-bottom: 200px;
  }
`
export const Instruction = styled.span`
  display: block;
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
`
export const AlertContainer = styled.div`

  align-items: flex-start;
  /* align-items: center; */
  flex-direction: row;
  display: flex;
  width: 100%;
    max-width: 580px;
    min-height: 73px;
    border-radius: 3px;
    background: #FFF9E5;
    padding: 18px 20px;
  @media (min-width: 768px){
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    >i{
      margin-right: 16px;
    }
    >span{
      max-width: 320px;
      margin-right: 20px;
    }
  }
`
export const ContainerHeaderAlert = styled.div`
  display: flex;
  flex-direction: column;
  >span{
      margin-bottom: 18px;
      margin-left: 11px;

  }
  justify-content: space-between;
  @media (min-width: 768px){
    flex-direction: row;
    align-items: flex-start;
    >i{
      margin-right: 16px;
    }
    >span{
      max-width: 320px;
      margin-right: 20px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  }
`
export const AlertButton = styled(Button)`
  max-width: 138px;
  min-width: 138px;
  padding: 9px 0px;
  margin-left:11px;
`