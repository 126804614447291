import styled from "styled-components";
import { grayScale, textStyles } from "theme/styleguide";

export const AccountVerificationMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const InstructionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  >img{
    max-width: 279px;
    margin-top: -48px;
    margin-right: 56px;
  }
  @media (max-width: 767px)
  {
    >img{
      display: none;
    }
  }
`
export const Instructions = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  margin-bottom: 32px;
  max-width: 664px;
  white-space: pre-wrap;
`
export const UserName = styled.span`
  ${textStyles.tray_h5_15px}
  color: ${grayScale.black};
  margin-bottom: 8px;
`
export const UserIdentifier = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
`
export const UserDataContainer = styled.div`  
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  @media (min-width: 768px){
    margin-bottom: 24px;
  }
`
export const BottomInstructions = styled.span`
  ${textStyles.tray_text_14px}
  color: ${grayScale.gray_3};
  white-space: pre-wrap;
  margin-top: 8px;
`
export const DocumentsListTitle = styled.span`
  ${textStyles.tray_h5_15px}
  color: ${grayScale.gray_3};
  margin-top: 24px;
  margin-bottom: 16px;
`
export const DocumentList = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
`